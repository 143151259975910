import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { GenericCard } from '../../../../components/GenericCard';
import { GenericTable } from '../../../../components/GenericTable';
import { GenericPage } from '../../../../components/GenericPage';
import { GenericInput } from '../../../../components/GenericInput';
import { api } from '../../../../services/api';
import { useToast } from '../../../../contexts/toast';
import { formatDate } from '../../../../utils/getFormatDate';
import { mapObjToArray } from '../../../../utils/mapObjToArray';

import { ICompany } from '../../../../types/ICompany';
import { ICookieBar } from '../../../../types/ICookieBar';
import { IPolicy } from '../../../../types/IPolicy';
import { ICookie } from '../../../../types/ICookie';
import { IUser } from '../../../../types/IUser';
import { IDSR } from '../../../../types/IDSR';

const USER_HEADER = [
  { 
    key: 'name', 
    label: 'Nome', 
    order: 1,
    textAlign: 'left',
  },
  { 
    key: 'email', 
    label: 'Email',
    order: 2,
    textAlign: 'left'
  },
  { 
    key: 'roles', 
    label: 'Perfis',
    order: 3,
    textAlign: 'left',
    callback: (v: string[]) => v.join('; ')
  },
  {
    key: 'createdAt', 
    label: 'Criação',
    order: 5,
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  },
  {
    key: 'updatedAt', 
    label: 'Última alteração',
    order: 6,
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  }
];
const USER_ACTIONS = [];
const COOKIE_BAR_HEADER = [
  { 
    key: 'site_url',
    order: 1,
    label: 'URL do site', 
    textAlign: 'left'
  },
  { 
    key: 'font_family',
    order: 2,
    label: 'Fonte', 
    textAlign: 'left'
  },
  { 
    key: 'font_size',
    order: 3,
    label: 'Tamanho', 
    textAlign: 'left'
  },
  { 
    key: 'cookie_list',
    order: 4,
    label: 'Cookies', 
    textAlign: 'left',
    callback: (v: ICookie[]) => `${v.length} Cookies`
  },
  {
    key: 'createdAt',
    order: 5,
    label: 'Criação',
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  },
  {
    key: 'updatedAt',
    order: 6,
    label:  'Última alteração',
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  }
];
const COOKIE_BAR_ACTIONS = [];
const POLICY_HEADER = [
  { 
    key: 'site_url',
    order: 1,
    label: 'URL do site', 
    textAlign: 'left'
  },
  {
    key: 'dpo_data',
    order: 2,
    label: 'Responsável',
    textAlign: 'left',
    callback: (v: string[]) => v[1]
  },
  {
    key: 'policy_slug',
    order: 3,
    label: 'Link',
    textAlign: 'left'
  },
  {
    key: 'createdAt',
    order: 4,
    label: 'Criação',
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  },
  {
    key: 'updatedAt',
    order: 5,
    label:  'Última alteração',
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  }
];
const POLICY_ACTIONS = [];
const DSR_HEADER = [
  { 
    key: 'site_url',
    order: 1,
    label: 'URL do site', 
    textAlign: 'left'
  },
  {
    key: 'createdAt',
    order: 4,
    label: 'Criação',
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  },
  {
    key: 'updatedAt',
    order: 5,
    label:  'Última alteração',
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  }
];
const DSR_ACTIONS = [];
const FORM_CONFIG = [
  { 
    key: 'document', 
    label: 'Documento CPF / CNPJ', 
    placeholder: 'CPF / CNPJ', 
    type: 'text'
  },
  { 
    key: 'fantasy_name', 
    label: 'Nome fantasia',
    placeholder: 'Nome fantasia',
    type: 'text'
  },
  {
    key: 'createdAt', 
    label: 'Data de criação',
    placeholder: 'dd/mm/yyyy',
    type: 'date'
  },
  {
    key: 'updatedAt', 
    label:  'Data da última alteração',
    placeholder:  'dd/mm/yyyy',
    type: 'date'
  }
];

export const ManageCompany: React.FC = () => {
  const { addToast } = useToast();
  const { id: companyId } = useParams<{ id: string }>();

  const [company, setCompany] = useState<ICompany>();
  const [companyArray, setCompanyArray] = useState([]);
  const [cookieBars, setCookieBars] = useState<ICookieBar[]>([]);
  const [policies, setPolicies] = useState<IPolicy[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [dsrForms, setDSRForms] = useState<IDSR[]>([]);
  const [pageTitle, setPageTitle] = useState<string>('Empresa');
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const loadCompany = async () => {
      try {
        setLoading(true);
        const { data } = await api.get<ICompany>(`/companies/${companyId}`);

        const companyData = _.get(data, 'company', false)
        if (companyData) {
          setPageTitle(companyData.fantasy_name);

          setCompanyArray(
            mapObjToArray(companyData).reduce((acc, curr) => {
              const found = FORM_CONFIG.find(c => c.key === curr.key);
              if (found) {
                acc.push({
                  ...curr,
                  ...found,
                })
              }

              return acc;
            }, [])
          );

          setCompany({
            _id: companyData._id,
            document: companyData.document,
            fantasy_name: companyData.fantasy_name,
            createdAt: new Date(companyData.createdAt)
              .toISOString()
              .substr(0,10),
            updatedAt: new Date(companyData.updatedAt)
              .toISOString()
              .substr(0,10),
          });
        }

        const cookie_barsData = _.get(data, 'cookie_bars', false)
        if (cookie_barsData) {
          setCookieBars(cookie_barsData);
        }

        const policiesData = _.get(data, 'policies', false)
        if (policiesData) {
          setPolicies(policiesData);
        }

        const usersData = _.get(data, 'users', false)
        if (usersData) {
          setUsers(usersData);
        }

        const dsrsData = _.get(data, 'dsrs', false)
        if (dsrsData) {
          setDSRForms(dsrsData);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Falha ao carregar',
          description: 'Ocorreu um erro ao carregar a empresa',
        });
      }
    };
      
    if (companyId) loadCompany();
  }, [addToast, companyId]);

  const handleChange = (field: string, event: ChangeEvent<HTMLInputElement>): void => {
    const companyClone = _.clone(company);
    const value = _.get(event, 'target.value', undefined);
    companyClone[field] = value;
    setCompany(companyClone);
  };
  
  const handleSubmit = useCallback(
    async (data) => {
      console.log(data)
    },
    [],
  );

  return (
    <GenericPage
      breadcrumb="BackOffice > Empresas > "
      pageTitle={pageTitle}
      loading={loading}
    >
  
      {company && (
        <GenericCard>
          <form onSubmit={handleSubmit} style={{width: 'inherit'}}>
            {company.fantasy_name && <h3>{company.fantasy_name}</h3>}

            {companyArray.map(item => (
              <GenericInput
                dark
                disabled
                key={item.key}
                id={item.key}
                name={item.key}
                type={item.type}
                label={item.label}
                placeholder={item.placeholder}
                value={company[item.key]}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(item.key, event)}
              />
            ))}
          </form>
        </GenericCard>
      )}
      
      {users.length > 0 && (
        <GenericTable 
          headerData={USER_HEADER}
          actionData={USER_ACTIONS}
          bodyData={users}
          title="Usuários"
        />
      )}

      {cookieBars.length > 0 && (
        <GenericTable 
          headerData={COOKIE_BAR_HEADER}
          actionData={COOKIE_BAR_ACTIONS}
          bodyData={cookieBars}
          title="Barras de Cookies"
        />
      )}

      {policies.length > 0 && (
        <GenericTable 
          headerData={POLICY_HEADER}
          actionData={POLICY_ACTIONS}
          bodyData={policies}
          title="Políticas"
        />
      )}

      {dsrForms.length > 0 && (
        <GenericTable 
          headerData={DSR_HEADER}
          actionData={DSR_ACTIONS}
          bodyData={dsrForms}
          title="Formulários DSR"
        />
      )}

    </GenericPage>
  )
};
