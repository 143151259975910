import React from 'react';

import {
  Container,
  Card,
  Switch,
} from './styles';
import { UpgradeTag } from '../UpgradeTag/styles';

interface ModuleBondProps {
  title: string;
  description: string;
  bond: boolean;
  enabled: boolean;
  broad?: boolean;
  upgradeTag?: boolean;
  handleBondCallback?: (bond: boolean) => void;
}

interface HandleBondType {
  event: React.ChangeEvent<HTMLInputElement>;
}

export const ModuleBond: React.FC<ModuleBondProps> = ({
  title,
  description,
  bond,
  enabled,
  broad,
  upgradeTag,
  handleBondCallback,
}) => {
  const handleBondChange = ({ event }: HandleBondType) => {
    handleBondCallback(event.target.checked);
  };

  const handleUpgrade = () => {
    if (!upgradeTag) return;
    setTimeout(() => {
      const link = process.env.REACT_APP_PRIVACY || 'https://app.privacyflow.ai'
      window.location.href = `${link}/checkout/index.html?plan=plano_basic`
    }, 200);
  };

  return (
    <Container>
      <Card
        enabled={enabled}
        broad={broad}
        clickable={upgradeTag}
        onClick={handleUpgrade}
      >
        <div>
          <h2 className="mb-17">{title}</h2>

          {upgradeTag && (
            <UpgradeTag onClick={handleUpgrade}>Faça upgrade</UpgradeTag>
          )}
          {enabled && (
            <Switch
              checked={bond}
              onChange={event =>
                handleBondChange({ event })
              }
              color="primary"
            />
          )}
        </div>
        <div>
          <p className="mb-30">{description}</p>
        </div>

      </Card>
    </Container>
  );
};
