import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 auto;
  padding: 0.5rem 1rem;

  height: 100%;
  width: 100%;
  max-width: 1800px;

  display: grid;
  grid-gap: 23px;
  grid-template-columns: repeat(auto-fill, minmax(214px, 214px));
  grid-template-rows: repeat(auto-fill, 214px);

  grid-template-areas: 'first first' 'first first';

  section {
    display: flex;

    flex-wrap: wrap;

    > div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }

  div.big {
    min-width: 30rem;
    min-height: 30rem;
    width: 100%;
    height: 100%;
    grid-area: first;

    margin-bottom: 1rem;
  }

  div.small {
    min-width: 214px;
    min-height: 214px;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 375px) {
    padding: 1rem 2rem;
  }
  @media screen and (min-width: 768px) {
    padding: 1.5rem 3rem;
  }
  @media screen and (min-width: 992px) {
    padding: 2rem 4rem;
  }
`;
