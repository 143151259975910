import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { GenericOverlay } from '../GenericOverlay';
import { Rotation } from './styles';

interface GenericLoadingProps {
  loading: boolean;
  color?: string;
}

export const GenericLoading: React.FC<GenericLoadingProps> = ({
  loading,
  color,
}) => {

  return (
    <>
      {loading && (
        <GenericOverlay>
          <Rotation color={color}>
            <AiOutlineLoading3Quarters />
          </Rotation>
        </GenericOverlay>
      )}
    </>
  )
};
