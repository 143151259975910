import React from "react";
import { IconBaseProps } from "react-icons";
import { MdFileDownload } from "react-icons/md"
import { Container, Title } from "./styles";

interface ButtonHeader {
  label: string;
  action: (data: any) => void;
  icon?: React.ComponentType<IconBaseProps>;
  alternativeIcon?: JSX.Element;
  disabled?: boolean;
}

interface ButtonHeaderProps {
  option: ButtonHeader;
}

export function ButtonHeader({ option }: ButtonHeaderProps) {
  return (
    <Container onClick={option.action} disabled={option.disabled}>
      <MdFileDownload />
      <Title>
        {option.label}
      </Title>
    </Container>
  );
}