import React, { ReactNode } from "react";
import { IoMdLink } from 'react-icons/io';

import { Overlay, ModalCard } from './styles';

interface ModalMessageImportantProps {
  title: string;
  content: string;
  handleSuccess: () => void;
  handleCancel: () => void;
  children: ReactNode;
}

export function ModalMessageImportant({
  title,
  content,
  children,
  handleSuccess,
  handleCancel,
}: ModalMessageImportantProps) {
  return (
    <Overlay>
      <ModalCard>
        {children}
        <article>
          <h2>{title}</h2>
          <p>
            {content}
          </p>
          <footer>
            <button
              type="button"
              className="cookies"
              onClick={handleCancel}
            >
              Voltar
            </button>
            <button
              type="button"
              className="forward"
              onClick={handleSuccess}
            >
              Excluir
            </button>
          </footer>
        </article>
      </ModalCard>
    </Overlay>
  );
}