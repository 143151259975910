import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Checkbox } from '@material-ui/core';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import { IoMdCheckboxOutline } from 'react-icons/io';
import { IoCheckmarkCircleSharp } from 'react-icons/io5';
import { AiOutlineLoading3Quarters, AiOutlineWarning } from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { useParams } from 'react-router';
import { useToast } from '../../../contexts/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import * as gtag from '../../../utils/gtag';

import Input from '../../../components/NewInput';
import Select, { Option } from '../../../components/Select';
import { api } from '../../../services/maturiry_api';

import {
  Container,
  Content,
  ContinueButton,
  FormWrapper,
  StyledForm,
  FinalMessage,
  ActivityIndicator,
  IframeWrapper,
} from '../styles';

const COMPANY_SIZE_OPTIONS = [
  { value: 1, label: 'Até 19 pessoas' },
  { value: 2, label: 'De 20 a 99 pessoas' },
  { value: 3, label: 'De 100 a 499 pessoas' },
  { value: 4, label: 'De 500 a 1000 pessoas' },
  { value: 5, label: 'Acima de 1000 pessoas' },
];

const LEAD_OPTIONS = [
  {
    value: 1,
    label: 'Não tenho conhecimento algum de LGPD e preciso de ajuda',
  },
  {
    value: 2,
    label:
      'Conheço um pouco sobre LGPD e quero me antecipar / adequar da forma correta',
  },
  {
    value: 3,
    label:
      'Tive um vazamento ou fui atacado(a) recentemente e preciso me adequar urgente',
  },
  {
    value: 4,
    label: 'Sou DPO da minha empresa e estou buscando uma solução / suporte',
  },
  {
    value: 5,
    label: 'Outros',
  },
];

interface FormData {
  fullName: string;
  phone: string;
  email: string;
  company: string;
  role: string;
  motive: string;
  sizeEnterprise: string;
}

export const MaturityLandingForm: React.FC = () => {
  const { addToast } = useToast();
  const { type } = useParams<{ type: string }>();

  const viewRef = useRef<HTMLDivElement>(null);
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const [companySize, setCompanySize] = useState<Option>(
    COMPANY_SIZE_OPTIONS[0],
  );
  const [leadType, setLeadType] = useState<Option>(LEAD_OPTIONS[0]);

  const [acceptDataUsage, setAcceptDataUsage] = useState(false);
  /* const [isHuman, setIsHuman] = useState(false); */

  const [showCalendly, setShowCalendly] = useState(false);
  const [isRegistrationCompleted, setIsRegistrationCompleted] = useState(false);

  const history = useHistory();

  useEffect(() => {
    gtag.pageview(`${history.location}`);
  }, [history]);

  const handleSubmit = useCallback(
    async (formData: FormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          fullName: Yup.string().required('Nome é um campo obrigatório'),
          /* role: Yup.string().required('Cargo é um campo obrigatório'), */
          company: Yup.string().required(
            'Nome da empresa é um campo obrigatório',
          ),
          email: Yup.string()
            .email('Digite um e-mail, válido')
            .required('E-mail obrigatório'),
          phone: Yup.string().required('Número de contato obrigatório'),
          motive:
            leadType.label === 'Outros'
              ? Yup.string().required('Informe um motivo')
              : Yup.string(),
        });

        await schema.validate(formData, { abortEarly: false });

        setLoading(true);
        const response = await api.post(
          `clients${type === 'iniciar' ? '?sendEmail=true' : ''}`,
          {
            name: formData.fullName,
            email: formData.email,
            phone: formData.phone,
            company: formData.company,
            type: ['privacyflow'],
            role: formData.role,
            motive:
              leadType.label === 'Outros' ? formData.motive : leadType.label,
            sizeEnterprise: companySize.label,
          },
        );
        setLoading(false);

        if (response.status === 201 && type === 'iniciar') {
          addToast({
            type: 'info',
            title: 'E-mail enviado',
            description: 'Seu acesso já foi enviado por e-mail, confere lá!',
          });
          setIsRegistrationCompleted(true);
        }
        if (type === 'agendar') setShowCalendly(true);
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao comunicar com Servidor',
        });
      }
    },
    [addToast, type, companySize, leadType],
  );

  return (
    <Container>
      {type && !isRegistrationCompleted && (
        <Content className="formContainer">
          <FormWrapper ref={viewRef} isCompleted={showCalendly}>
            <h1>
              {type === 'iniciar'
                ? 'Começar agora!'
                : 'Agendar com especialista'}
            </h1>
            <p>Insira algumas informações para continuar</p>

            <StyledForm
              ref={formRef}
              onSubmit={formData => handleSubmit(formData)}
            >
              <div>
                <Input name="fullName" placeholder="Nome e sobrenome" />
                <Input name="company" placeholder="Nome da empresa" />
              </div>

              <div>
                <Input
                  name="email"
                  type="email"
                  placeholder="E-mail corporativo"
                />
                <Input
                  name="phone"
                  placeholder="Telefone"
                  mask="(99) 99999-9999"
                />
              </div>

              <Select
                value={companySize}
                setValue={setCompanySize}
                options={COMPANY_SIZE_OPTIONS}
              />

              <Select
                value={leadType}
                setValue={setLeadType}
                options={LEAD_OPTIONS}
              />
              {leadType.label === 'Outros' && (
                <Input name="motive" placeholder="Motivo do contato" />
              )}

              <div>
                <div>
                  <Checkbox
                    icon={<MdCheckBoxOutlineBlank color="#fff" />}
                    checkedIcon={<IoMdCheckboxOutline color="#fff" />}
                    value={acceptDataUsage}
                    onChange={() => setAcceptDataUsage(!acceptDataUsage)}
                  />
                  <span>
                    {'Aceito a '}
                    <a
                      href="https://app.privacyflow.ai/slug/1637259006591-privacyflow"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Política de privacidade
                    </a>
                    {' e os '}
                    <a
                      href="https://drive.google.com/file/d/1zIF23ACUuswZi1PZ02ULsNvkMpUGbJhA/view?usp=sharing"
                      rel="noreferrer"
                      target="_blank"
                    >
                      Termos de uso
                    </a>
                  </span>
                </div>
              </div>

              <ContinueButton
                disabled={
                  //! isHuman ||
                  !acceptDataUsage ||
                  loading ||
                  showCalendly ||
                  isRegistrationCompleted
                }
                type="submit"
              >
                {loading && (
                  <ActivityIndicator>
                    <AiOutlineLoading3Quarters />
                  </ActivityIndicator>
                )}

                {!loading &&
                  (type === 'iniciar'
                    ? 'Iniciar avaliação de maturidade'
                    : 'Agendar com especialista')}
              </ContinueButton>
            </StyledForm>

            {showCalendly && <IoCheckmarkCircleSharp />}
          </FormWrapper>

          {type === 'agendar' && showCalendly && (
            <IframeWrapper>
              <iframe
                title="Calendly"
                src={process.env.REACT_APP_CALENDLY_LINK}
                width="100%"
                height="100%"
                frameBorder="0"
              />
            </IframeWrapper>
          )}
        </Content>
      )}

      {isRegistrationCompleted && type === 'iniciar' && (
        <Content>
          <FinalMessage>
            <h1>
              {type === 'iniciar'
                ? 'Seus dados foram enviados!'
                : 'Já estamos com o seu contato!'}
            </h1>
            <p>
              {type === 'iniciar'
                ? 'Verifique sua caixa de e-mail e clique no link para continuarmos a avaliação de maturidade em LGPD.'
                : 'Agora você pode agendar uma conversa com nosso especialista.'}
            </p>

            <footer>
              <AiOutlineWarning />
              <span>
                Verifique seu lixo eletrônico e seu spam caso não tenha recebido
                o e-mail.
              </span>
            </footer>
          </FinalMessage>
        </Content>
      )}
    </Container>
  );
};
