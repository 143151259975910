/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import Routes from './routes';

import GlobalStyle from './styles/global';

import AppProvider from './contexts/index';

// const tagManagerArgs: TagManagerArgs = {
//   gtmId: `${process.env.REACT_APP_GTM}`,
// };

// TagManager.initialize(tagManagerArgs);

// declare global {
//   interface Window {
//     gtag: any;
//   }
// }

const App: React.FC = () => {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-K64JKN4' });
  }, []);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <BrowserRouter>
        <AppProvider>
          <Routes />
        </AppProvider>

        <GlobalStyle />
      </BrowserRouter>
    </MuiPickersUtilsProvider>
  );
};

export default App;
