import React from "react";
import { useHistory } from 'react-router';
import { BiCookie } from "react-icons/bi";
import { HiArrowNarrowLeft, HiArrowNarrowRight, HiOutlineDocumentText } from 'react-icons/hi';
import { ModalCard } from "./styles";
import { GenericOverlay } from "../GenericOverlay";

interface NotCreatedProps {
  title: string;
  type: 'POLICY' | 'COOKIE_BAR';
  link: string;
  handleCancel: () => void;
}

export default function NotCreated({
  title,
  type,
  link,
  handleCancel
}: NotCreatedProps) {
  const history = useHistory();

  return (
    <GenericOverlay>
      <ModalCard>
        {type === "POLICY" ? (
          <BiCookie className="svg-link" />
        ) : (
          <HiOutlineDocumentText className="svg-link" />
        )}
        <article>
          <p>
            {title}
          </p>
          <footer>
            <button
              type="button"
              onClick={handleCancel}
            >
              <HiArrowNarrowLeft style={{
                height: '24px',
                width: '24px',
                marginRight: '10px'
              }}
              />
              Voltar
            </button>
            <button
              type="button"
              onClick={() => history.push(link)}
            >
              Entendi
              <HiArrowNarrowRight style={{
                height: '24px',
                width: '24px',
                marginLeft: '10px'
              }}
              />
            </button>
          </footer>
        </article>
      </ModalCard>
    </GenericOverlay>
  );
}
