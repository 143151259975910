import styled, { css } from 'styled-components';

interface ContainerProps {
  big?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  overflow: hidden;
  box-shadow: 0px 3px 6px #00000029;

  ${props =>
    props.big
      ? css`
        min-width: 21.6rem;
        `
      : css`
        min-width: 10rem;
        `
  }
`;

interface OptionProps {
  big?: boolean;
  active?: boolean;
  upgradeTag?: boolean;
  blockedTag?: boolean;
}

export const Option = styled.button<OptionProps>`
  position: relative;
  display: flex;
  align-items: center;
  flex: 1;
  width: 100%;
  padding: 0.75rem;

  ${props =>
    props.big
      ? css`
          height: 4rem;
          font-size: 1rem;
        `
      : css`
          height: 3rem;
          font-size: 0.8rem;
        `}

  background: var(--white);

  color: var(--teal);

  svg {
    width: ${props => (props.big ? '2rem' : '1rem')};
    height: ${props => (props.big ? '2rem' : '1rem')};

    + span {
      margin-left: 1rem;
    }
  }

  &:hover {
    background: var(--light-teal);
  }

  ${props =>
    !props.active &&
    css`
      cursor: not-allowed;
      background: #f6f6f6;
      color: #2ac4ea99;
    `};

  ${props =>
    !props.active && props.upgradeTag &&
    css`
      cursor: pointer;
    `};

  ${props =>
    !props.active && !props.upgradeTag &&
    css`
      cursor: not-allowed;
    `};

  ${props =>
    !props.active &&
    props.upgradeTag &&
    props.blockedTag &&
    css`
      cursor: not-allowed;
    `};

  ${props =>
    props.active &&
    css`
      &:hover {
        box-shadow: 0px 3px 1rem #000000aa;
        cursor: pointer;
      }
    `};
`;
