import styled, { css } from 'styled-components';
import { Switch as MUI_Switch, SwitchProps } from '@material-ui/core';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1800px;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 1rem;
  margin-top: 1rem;

  @media screen and (min-width: 375px) {
    padding: 0 2rem;
    margin-top: 2rem;
  }
  @media screen and (min-width: 768px) {
    padding: 0 3rem;
    margin-top: 3rem;
  }
  @media screen and (min-width: 992px) {
    padding: 0 4rem;
    margin-top: 4rem;
  }
`;

export const Constructor = styled.section`
  width: 100%;
  margin-bottom: 140px;

  h2 {
    color: var(--teal);
    font: normal normal bold 14px/18px Nunito;
    margin-bottom: 17px;
  }

  p {
    font: normal normal normal 14px/18px 'Mulish';
    letter-spacing: 0px;
    color: #707070;
  }

  .constructor__header {
    margin-bottom: 42px;
    max-width: 600px;
  }

  .cookie-button {
    min-width: 200px;
    min-height: 63px;
    border: 2px solid #28bce0;
    border-radius: 53px;
    font: normal normal bold 20px/24px 'Mulish';
    letter-spacing: 0px;
    color: #28bce0;
    padding: 15px 20px;

    &:hover {
      background-color: var(--teal);
    }
  }

  @media screen and (min-width: 768px) {
    h2 {
      font: normal normal bold 16px/20px Nunito;
    }
    .cookie-button {
      padding: 12px 18px;
      font: normal normal bold 16px/20px 'Mulish';
    }
    p {
      font: normal normal normal 16px/20px 'Mulish';
    }
  }
  @media screen and (min-width: 992px) {
    h2 {
      font: normal normal bold 24px/28px Nunito;
    }
    .cookie-button {
      padding: 15px 20px;
      font: normal normal bold 20px/24px 'Mulish';
    }
    p {
      font: normal normal normal 18px/22px 'Mulish';
    }
  }
`;

export const ConstructorURL = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    padding: 0.5rem 1rem;
    border: 1px solid var(--dark-gray);
    border-radius: 2rem;
    background-color: transparent;
    text-align: center;
  }

  .url-input {
    font: normal normal bold 14px/18px Nunito;
    border: unset;
    border-radius: 20px;
    background-color: var(--white);
    width: 100%;
    max-width: 946px;

    &.first {
      padding: 11px 80px 11px 13px;
      box-shadow: 0px 3px 20px #00000029;
    }
    &.second {
      padding: 11px 13px;
      box-shadow: 0px 3px 6px #00000029;
      color: #949494;
    }
  }

  .url-btn {
    padding: 0rem 1rem;
    border: unset;
    border-radius: 10px 20px 20px 10px;
    background-color: var(--teal);
    color: var(--white);
    min-width: 80px;
    min-height: 40px;
    margin-left: -80px;
    font: normal normal bold 14px/18px Nunito;

    &:hover {
      background-color: var(--teal);
      box-shadow: 0px 6px 30px #00000029;
    }
  }

  @media screen and (min-width: 768px) {
    .url-input {
      font: normal normal bold 16px/20px Nunito;
      &.first {
        padding: 11px 120px 11px 13px;
      }
    }
    .url-btn {
      font: normal normal bold 16px/20px Nunito;
      min-width: 120px;
      margin-left: -120px;
      min-height: 42px;
    }
  }
  @media screen and (min-width: 992px) {
    .url-input {
      font: normal normal bold 24px/28px Nunito;
      &.first {
        padding: 11px 176px 11px 13px;
      }
    }
    .url-btn {
      font: normal normal bold 24px/28px Nunito;
      min-width: 176px;
      margin-left: -176px;
      min-height: 50px;
    }
  }
`;

interface ColorButtonProps {
  color: string;
}
export const ColorButton = styled.button<ColorButtonProps>`
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  border: 0.5px solid var(--dark-gray);
  background: ${props => props.color};
  margin-left: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--green);
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const CookieTypesContainer = styled.section`
  display: flex;
  flex-direction: column;
  min-width: 25rem;
  width: calc(69% - 1rem);
  margin-left: auto;

  border: 1px solid var(--dark-gray);
  border-radius: 1rem;
  padding: 3rem 2rem;

  strong {
    margin-top: 1.5rem;
  }

  header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  > div {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex: 1;

    > span {
      max-width: 50%;
      word-wrap: break-word;
    }
  }

  p {
    text-align: justify;
    margin: 1rem 0;
  }

  .buttonContainer {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }

  button {
    height: 33px;
    width: 85px;
    margin: 0px 10px;
    font-size: 14px;
    border: 2px solid #333333;
    border-radius: 10px;
    border-radius: 53px;
    background-color: transparent;
  }

  .mandatoryCookies {
    color: var(--gray);
  }
`;

export const CustomButton = styled.button`
  height: 33px;
  width: 85px;
  margin: 0px 10px;
  font-size: 14px;
  border: 2px solid #333333;
  border-radius: 10px;
  border-radius: 53px;
  background-color: transparent;
`;

interface SectionTitleProps {
  color?: string;
}
export const SectionTitle = styled.strong<SectionTitleProps>`
  margin-top: 2rem;
  color: ${props => props.color};
`;

export const Switch = styled(MUI_Switch) <SwitchProps>`
  && {
    ${props =>
    props.checked
      ? css`
            .MuiIconButton-label {
              color: #6200ee;
            }

            .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
              background-color: #6200ee;
            }
          `
      : css`
            .MuiSwitch-switchBase {
              color: #494949;
            }
          `}
  }
`;

interface VisualizationProps {
  cookieBarColor: string;
  fontFamily: string;
  fontSize: string;
  fontWeight: string;
  fontColor: string;
}
export const Visualization = styled.section<VisualizationProps>`
  height: inherit;
  flex: 1;
  height: 12rem;
  min-width: 600px;
  border-radius: 20px;
  margin-top: 2rem;

  background-color: white;

  position: relative;
  z-index: 0;

  .tooltip {
    top: 0;
    left: 0;
    width: 100%;
    height: 32px;
    border-radius: 20px 20px 0px 0px;
    cursor: default;

    background-color: #d3d3d3;

    position: absolute;
    display: flex;
    align-items: center;

    div:first-child {
      margin-left: 15px;
    }
    div {
      margin-right: 5px;
      background-color: white;
      height: 11px;
      width: 11px;
      border-radius: 50%;
    }
  }

  .cookieBar {
    background-color: ${props => props.cookieBarColor};
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 50px;
    border-radius: 0px 0px 20px 20px;
    cursor: default;

    padding: 0 2rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--white);

    p {
      white-space: pre-line;
      font-family: ${props => props.fontFamily};
      font-size: ${props => props.fontSize};
      font-weight: ${props => props.fontWeight};
      color: ${props => props.fontColor};
    }
    .buttons {
      display: -webkit-inline-box;
      margin-left: 2rem;
      div {
        font-family: ${props => props.fontFamily};
        font-size: ${props => props.fontSize};
        font-weight: ${props => props.fontWeight};
        color: ${props => props.fontColor};
      }
      div:last-child {
        padding: 0px 15px;
        border: 2px solid ${props => props.fontColor};
        border-radius: 10px;
        margin: 0px 10px;
      }
    }
  }
`;

interface VisualProps {
  generateHTML: Boolean;
}
export const Visual = styled.section<VisualProps>`
  .generateHTML {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      width: 541px;
      text-align: left;
      font-size: 16px;
      letter-spacing: 0.15px;
      color: #333333;
    }

    div button {
      height: 33px;
      width: ${prop => (prop.generateHTML ? '50px' : '119px')};
      margin: 0px 10px;
      font-size: 14px;
      border: ${prop => (prop.generateHTML ? '' : '2px solid #333333')};
      border-radius: 10px;
      border-radius: 53px;
    }
  }

  .saveButtonWrapper {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1.25rem;
  }
`;

interface HtmlContainerProps {
  htmlCopied: Boolean;
  cookieBarColor: string;
}

export const HtmlContainer = styled.div<HtmlContainerProps>`
  box-shadow: 0px 3px 6px #00000029;

  overflow-y: scroll;
  position: fixed;
  margin: auto;

  z-index: 300;

  height: 75%;
  width: 50%;
  max-width: 55rem;

  margin-top: 30px;
  padding: 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #33333329;
  border-radius: 20px;

  display: flex;
  justify-content: space-between;
  p {
    white-space: pre-line;
    font-family: sans-serif;
    font-weight: 100;
    letter-spacing: 0px;
    color: #333333;
  }
  button {
    position: absolute;
    right: 0;
    height: 2rem;
    width: 10rem;
    margin: 0 0.75rem;
    font-size: 1rem;
    color: ${prop => (prop.htmlCopied ? prop.cookieBarColor : '')};
    border: ${prop => (prop.htmlCopied ? '' : '2px solid #333333')};
    border-radius: 3rem;
    background-color: var(--white);
  }
`;

export const ModalCard = styled.div`
  position: relative;
  width: 90%;
  max-width: 750px;
  min-height: 365px;
  background-color: var(--white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  text-align: center;
  padding: 40px 20px;

  article {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 40px 20px;
    z-index: 2;
  }

  .svg-link {
    position: absolute;
    left: -12px;
    top: 0px;
    width: 200px;
    height: 250px;
    color: #494949;
    opacity: 0.2;
    z-index: 1;
  }

  h2 {
    font: normal normal bold 24px/28px 'Mulish';
    letter-spacing: 0px;
    color: var(--teal);
    margin-bottom: 20px;
  }

  p {
    font: normal normal normal 20px/24px 'Mulish';
    letter-spacing: 0px;
    color: #707070;
    margin-bottom: 20px;
  }

  button {
    font: normal normal bold 20px/24px 'Mulish';
    letter-spacing: 0px;
    color: var(--teal);
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 5px 20px;
    border-radius: 53px;

    &:hover {
      background-color: var(--teal);
      color: var(--white);
    }

    svg {
      color: inherit;
      width: 43px;
      height: 43px;
    }

    div {
      margin-left: 15px;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 100%;
    height: 120px;

    button {
      border: 2px solid var(--teal);
      border-radius: 53px;
      padding: 10px 20px;
      min-width: 175px;

      &:hover {
        box-shadow: 0px 3px 20px #00000029;
      }

      &.forward {
        background-color: var(--teal);
        color: var(--white);

        &:hover {
          background-color: transparent;
          color: var(--teal);
        }
      }

      &.cookies {
        background-color: transparent;
        color: var(--teal);

        &:hover {
          background-color: var(--teal);
          color: var(--white);
        }
      }
    }
  }

  @media screen and (min-width: 425px) {
    min-height: 340px;
  }
  @media screen and (min-width: 768px) {
    width: 600px;
    padding: 50px 70px;
    article {
      padding: 50px 70px;
    }
    .svg-link {
      width: 250px;
    }
    h2 {
      font: normal normal bold 26px/30px 'Mulish';
    }
    p {
      font: normal normal normal 22px/26px 'Mulish';
    }
    button {
      font: normal normal bold 22px/26px 'Mulish';
    }
    footer {
      flex-direction: row;
      height: auto;
    }
  }
  @media screen and (min-width: 992px) {
    width: 720px;
    padding: 48px 72px 63px 72px;
    article {
      padding: 48px 72px 63px 72px;
    }
    .svg-link {
      width: 300px;
      height: 300px;
    }
    h2 {
      font: normal normal bold 28px/32px 'Mulish';
    }
    p {
      font: normal normal normal 24px/28px 'Mulish';
    }
    button {
      font: normal normal bold 24px/28px 'Mulish';
    }
  }
`;
