import React from 'react';
import { useHistory } from 'react-router-dom';

import { IoIosArrowRoundForward, IoMdLink } from 'react-icons/io';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { useCreatePolicy } from '../../../../../hooks/useCreatePolicy';

import { MaturityHeader } from '../../../../../components/MaturityHeader';
import { MaturityButton } from '../../../../../components/MaturityButton';
import { ServicesHeader } from '../../../../../components/ServicesHeader';
import { CookieCategories } from '../../../../../components/CookieCategories';
import { GenericOverlay } from '../../../../../components/GenericOverlay';
import { PolicyPreview } from '../../../../../components/PolicyPreview';
import { PoliciesSteps } from '../../../../../components/PoliciesSteps';
import { ActivityIndicator } from '../../../../../components/MaturityButton/styles';
import { ModuleBond } from '../../../../../components/ModuleBond';
import PublishPolicy from '../../../../../components/PublishPolicy';
import NotCreated from '../../../../../components/NotCreated';
import NotCreatedCookieBar from '../../../../../components/NotCreatedCookieBar';
import RouteLeavingGuard from '../../../../../routes/RouteLeavingGuard';

import {
  Container,
  Content,
  Constructor,
  ConstructorURL,
  ModalCard,
} from './styles';

const titles = [
  {
    title: 'Informe a URL do seu site',
    subtitle: '',
  },
  {
    title: 'Informe a URL do seu site',
    subtitle: '',
  },
  {
    title: 'Lista de cookies',
    subtitle: '',
  },
  {
    title: 'Passo a passo',
    subtitle:
      'Preencha as caixas de seleção conforme a realidade do seu site. Deixamos em modo de seleção para facilitar sua jornada.',
  },
  {
    title: 'Preview da Política de Privacidade e Cookies',
    subtitle: '',
  },
  {
    title: 'Publicar Política de Privacidade e Cookies',
    subtitle:
      'Oferecemos duas formas para que você publique a Política de Privacidade e Cookies no seu site:',
  },
];

export const CreatePolicy: React.FC = () => {
  // eslint-disable-next-line prettier/prettier
  const {
    stepsCompleted,
    setStepsCompleted,
    url,
    loading,
    previewHTML,
    setUrl,
    handleCreateEditPolicy,
    cookies,
    setCookies,
    handleClickBegin,
    showExistingSiteUrlModal,
    showCookiesModal,
    handleClickShowCookies,
    handleClickForward,
    handleForwardPreview,
    handleBackwardPreview,
    handleForwardSteps,
    handleForwardPublish,
    requestSave,
    setRequestSave,
    policySteps,
    setPolicySteps,
    saveStepsEnabled,
    isBonded,
    hasCookieBarBond,
    setHasCookieBarBond,
    isPolicyCompleted,
    notCreatedCookieBar,
    handleCloseModalCreatedCookieBar,
    pageTopRef,
  } = useCreatePolicy();

  const history = useHistory();
  return (
    <Container>
      <div ref={pageTopRef} /> 

      <MaturityHeader />
      <ServicesHeader
        pageTitle="Gerador de políticas"
        backRoute="/atividades/corporativo/politicas"
      />

      <Content>
        <Constructor>
          <div className="constructor__header">
            {titles[stepsCompleted - 1]?.title && <h2>{titles[stepsCompleted - 1]?.title}</h2>}
            {titles[stepsCompleted - 1]?.subtitle && <p>{titles[stepsCompleted - 1]?.subtitle}</p>}
          </div>

          <ConstructorURL>
            {stepsCompleted < 3 && (
              <form onSubmit={(event) => {
                event.preventDefault()
                handleCreateEditPolicy({
                  isCreation: true,
                  hasCookieBar: false,
                  isCompleted: false,
                })
              }}
              >
                <input
                  className="url-input first"
                  value={url.replace('https://', '')}
                  type="text"
                  placeholder="www.seusite.com.br"
                  onChange={e => setUrl(e.target.value)}
                  disabled={stepsCompleted > 2 || loading}
                />
                <MaturityButton
                  className="url-btn"
                  loading={loading}
                  type="button"
                  onClick={() =>
                    handleCreateEditPolicy({
                      isCreation: true,
                      hasCookieBar: false,
                      isCompleted: false,
                    })
                  }
                >
                  Concluir
                </MaturityButton>
              </form>
            )}
          </ConstructorURL>

          {stepsCompleted === 3 && (
            <CookieCategories
              cookies={cookies}
              setCookies={setCookies}
              showNextButton
              nextButtonCallback={handleClickForward}
            />
          )}

          {stepsCompleted === 4 && (
            <PoliciesSteps
              policySteps={policySteps}
              setPolicySteps={setPolicySteps}
              setRequestSave={setRequestSave}
            />
          )}

          {/* (!isPolicyCompleted) || (isPolicyCompleted && hasCookieBarBond) || (isPolicyCompleted && !isBonded) */}

          {
            stepsCompleted === 4 &&
            (
              <ModuleBond
                title="Política na Barra de cookies"
                description={
                  !isBonded && !hasCookieBarBond
                    ? "Ao habilitar essa opção, o link da Política de Privacidade e Cookies será adicionado ao lado do texto principal na Barra de cookies."
                    : "Sua Política está vinculada com a Barra de cookies. Esta ação só pode ser desfeita com a exclusão."
                }
                bond={hasCookieBarBond}
                enabled={!isBonded}
                broad
                handleBondCallback={(bond) => setHasCookieBarBond(bond)}
              />
            )}

          {stepsCompleted === 4 && saveStepsEnabled && (
            <MaturityButton
              type="button"
              color="#2AC4EA"
              loading={loading}
              style={{ marginTop: "33px" }}
              onClick={() => handleForwardSteps(policySteps, true)}
            >
              Concluir etapa
            </MaturityButton>
          )}

          {(stepsCompleted === 5 || stepsCompleted === 6 || stepsCompleted === 7) && (
            <PolicyPreview
              rawHTML={previewHTML}
              showNextButton
              showPreviousButton
              nextButtonCallback={handleForwardPreview}
              previousButtonCallback={handleBackwardPreview}
            />
          )}

          {stepsCompleted === 6 && notCreatedCookieBar && hasCookieBarBond && (
            <NotCreated
              title="Sua Política foi gerada, mas você ainda não tem uma Barra de cookies, portanto, vamos redirecionar você para gerar a Barra de cookies."
              type="POLICY"
              link="/atividades/corporativo/barra-de-cookies/nova"
              // handleCancel={() => history.push('/atividades/corporativo')}
              handleCancel={() => handleCloseModalCreatedCookieBar()}
            />
          )}

          {stepsCompleted === 6 && !notCreatedCookieBar && (
            <PublishPolicy
              origin="POLICY"
              handleFinishCallback={handleForwardPublish}
              isCompleted={isPolicyCompleted}
            />
          )}

          {/* {stepsCompleted === 7 && !hasCookieBarBond && (
            <NotCreatedCookieBar
              title="Observamos que você não criou uma Barra de cookies"
              type="POLICY"
              link="/atividades/corporativo/barra-de-cookies"
            />
          )} */}
        </Constructor>
      </Content>

      {showExistingSiteUrlModal && (
        <GenericOverlay>
          <ModalCard>
            <IoMdLink className="svg-link" />
            <article>
              <h2>{`Utilizaremos a mesma URL! (${url.replace('https://', '')})`}</h2>
              <p>
                O seu site já possui uma barra de cookies, portanto,
                utilizaremos a mesma URL para prosseguir com a geração da
                política.
              </p>
              <button type="button" onClick={handleClickBegin}>
                Começar
                {loading ? (
                  <ActivityIndicator>
                    <AiOutlineLoading3Quarters />
                  </ActivityIndicator>
                ) : (
                  <IoIosArrowRoundForward />
                )}
              </button>
            </article>
          </ModalCard>
        </GenericOverlay>
      )}

      {showCookiesModal && (
        <GenericOverlay>
          <ModalCard>
            <IoMdLink className="svg-link" />
            <article>
              <h2>Identificamos cookies na URL informada</h2>
              <p>
                Deseja ver a lista de cookies ou seguir para construção da
                política?
              </p>
              <footer>
                <button
                  type="button"
                  className="cookies"
                  onClick={handleClickShowCookies}
                >
                  Ver cookies
                </button>
                <button
                  type="button"
                  className="forward"
                  onClick={handleClickForward}
                >
                  Seguir
                </button>
              </footer>
            </article>
          </ModalCard>
        </GenericOverlay>
      )}

      <RouteLeavingGuard
        when={requestSave}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => requestSave}
        handleAction={() => setRequestSave(false)}
        icon="rightArrowCircle"
        title="Você esqueceu de salvar suas alterações, deseja sair da página mesmo assim?"
        message=" "
        closeMessage="Sair"
        successMessage="Continuar editando"
      />

      {/* @TODO save the blocks of where the user stoped  */}
      {/* handleSave={() => handleForwardSteps(policySteps, false)} */}
    </Container>
  );
};
