import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  height: 100%;
  width: 100%;
  background: #eaecf4;
`;

export const LayoutProvider: React.FC = ({ children }) => {
  return <Content>{children}</Content>;
};
