import styled from 'styled-components';

export const Card = styled.div`
  max-width: 946px;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .mb-17 {
    margin-bottom: 17px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .mb-73 {
    margin-bottom: 73px;
  }

  h2 {
    font: normal normal bold 14px/18px Nunito;
  }

  p {
    font: normal normal normal 12px/16px 'Mulish';
    letter-spacing: 0.62px;
    color: #949494;
  }

  .next-button {
    background: var(--white);
    color: var(--teal);
    border: 2px solid var(--teal);
    min-width: 200px;
    border-radius: 53px;
    font: normal normal bold 14px/18px 'Mulish';
    letter-spacing: 0px;
    padding: 10px 15px;

    &:hover {
      background-color: var(--teal);
      color: var(--white);
      box-shadow: 0px 6px 30px #00000029;
    }
  }

  @media screen and (min-width: 375px) {
    padding: 25px 25px;
  }
  @media screen and (min-width: 768px) {
    padding: 30px 45px;
    h2 {
      font: normal normal bold 16px/20px Nunito;
    }
    p {
      font: normal normal normal 14px/18px 'Mulish';
    }
    .next-button {
      padding: 12px 18px;
      font: normal normal bold 16px/20px 'Mulish';
    }
  }
  @media screen and (min-width: 992px) {
    padding: 40px 66px;
    h2 {
      font: normal normal bold 24px/28px Nunito;
    }
    p {
      font: normal normal normal 18px/22px 'Mulish';
    }
    .next-button {
      padding: 15px 20px;
      font: normal normal bold 20px/24px 'Mulish';
    }
  }
`;
