import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import InputMask from 'react-input-mask';

import { Container, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  handle?: () => void;
  mask?: string;
  dark?: boolean;
}

const Input: React.FC<InputProps> = ({
  name,
  mask = '',
  icon: Icon,
  handle,
  dark,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => setIsFocused(true), []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <Container
      hasError={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      dark={dark}
    >
      {(isFocused || isFilled) && <span>{rest.placeholder}</span>}

      <InputMask
        mask={mask}
        defaultValue={defaultValue}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
      >
        {() => <input ref={inputRef} {...rest} name={name} />}
      </InputMask>

      {Icon && <Icon size={18} onClick={handle} cursor="pointer" />}
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#ff0000" size={24} />
        </Error>
      )}
    </Container>
  );
};

export default Input;
