import styled, { keyframes, css } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const ExplanationBalloon = styled.footer`
  position: absolute;
  display: none;
  top: 20rem;
  left: 0;
  right: 0;

  padding: 1.5rem 2.5rem;
  border-radius: 3rem;
  z-index: 10;

  max-width: 50rem;

  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25rem;

  @media (max-width: 769px) {
    background: var(--white);
    box-shadow: 10px 10px 30px #00000077, 10px -10px 30px #00000066;
    top: 5rem;
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  margin-right: 2rem;
  padding: 2rem;

  > header {
    position: relative;
    display: flex;
    width: 100%;

    div {
      display: flex;
      flex-direction: column;
      flex: 1;

      ${ExplanationBalloon} {
        display: none;
      }
    }

    h2 {
      display: block;
      min-height: 8rem;
      font-size: 2rem;
      line-height: 1;
      animation: ${appearFromLeft} 0.5s;
    }

    p {
      margin-top: 1.5rem;
      font-size: 1.5rem;
    }
  }

  > div {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 2.5rem;

    textarea {
      padding: 1rem;
      line-height: 1.5;
      border-radius: 1.25rem;
      border: 1px solid;

      background: transparent;

      width: 100%;
      min-height: 6.25rem;
      margin-right: 2rem;

      font-size: 1rem;
    }
  }

  > footer {
    display: flex;

    margin-top: 1rem;
    align-self: flex-end;

    strong {
      display: flex;
      align-items: center;
      margin: 0 0.75rem;
    }

    button {
      display: flex;
      align-items: center;
      font-size: 1rem;
      font-weight: 800;
      background: transparent;

      padding: 0.5rem 1rem;
      border-radius: 1rem;

      &:hover &:active {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }

  @media (max-width: 769px) {
    padding: 2rem 0;
    margin-top: 1rem;

    > header {
      h2 {
        min-height: 7.5rem;
      }
    }

    > div {
      flex-direction: column;
      align-items: flex-end;
      margin-top: 2rem;

      button + button {
        margin-top: 1rem;
      }

      > aside {
        display: none;
      }
    }

    > footer {
      margin-top: auto;
      width: 100%;
      justify-content: space-between;

      button {
        svg {
          border: 2px solid var(--dark-gray);
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 1.5rem;
        }
        span {
          display: none;
        }
      }
    }
  }

  /* @media (max-width: 426px) {
    > header {
      h2 {
        min-height: 19rem;
      }
    }
  } */
`;

interface ButtonInterface {
  selected?: boolean;
}

export const AnswerButton = styled.button<ButtonInterface>`
  display: flex;
  align-items: center;

  height: 2.5rem;
  padding: 0 1rem;
  border-radius: 2rem;
  border: 2px solid var(--dark-gray);

  background: transparent;
  color: var(--dark-gray);
  font-weight: bold;

  transition: all 0.3s;

  font-size: 1.1rem;

  &:not(:disabled):hover {
    background-color: var(--dark-gray);
    color: var(--white);
  }

  ${props =>
    props.selected &&
    css`
      background: var(--dark-gray);
      color: var(--white);
    `};
`;

export const NotSureBalloon = styled.footer`
  position: absolute;
  display: none;
  top: 10rem;
  right: 2.5rem;
  left: 0;
  padding: 1.5rem 2.5rem;
  border-radius: 3rem;

  z-index: 10;

  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 1.25rem;

  h1 {
    font-size: 2rem;
    font-weight: 800;
  }

  strong {
    margin-top: 1.5rem;
  }

  div {
    margin: 2rem 0;
  }
`;

export const IconContainer = styled.aside`
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--dark-gray);
  background: transparent;
  height: 2rem;
  width: 2rem;
  border: 3px solid var(--dark-gray);
  border-radius: 2rem;

  transition: all 0.15s;

  &:hover {
    color: var(--white-gray);
    background: var(--dark-gray);

    + footer {
      display: block;
    }
  }

  svg {
    height: 2rem;
    width: 2rem;
  }
`;
