import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;

  background: #2AC4EA;
  color: white;
  border: 1px solid white;
  border-radius: 32px;
  box-sizing: border-box;

  padding: 8px 16px 8px 12px;

  > svg {
    color: var(--white);
    margin-right: 0.5rem;
    height: 1.5rem;
    width: 1.5rem;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Title = styled.span`
  font: Nunito;
  font-weight: 700;
  font-size: 14px;
`;