import React, { useEffect } from 'react';
import useResearch from '../../../contexts/research';
import { SectionProps } from '../../../types/researchTypes';

import Question from '../Question';

import { Container } from './styles';
import * as gtag from '../../../utils/gtag';

interface SectionComponentProps {
  section: SectionProps;
}

const Section: React.FC<SectionComponentProps> = ({ section }) => {
  const { currentSection, currentQuestion } = useResearch();

  useEffect(() => {
    gtag.pageview(`${window.location.pathname}/${section.name}`);
  }, [section.name]);

  return (
    <Container>
      <header>
        <aside>
          <h1>{currentSection}</h1>
        </aside>
        <section>
          <h3>{section.name}</h3>
          <p>{section.description}</p>
        </section>
      </header>

      {section.questions.map(
        question =>
          section.questions.indexOf(question) === currentQuestion - 1 && (
            <Question key={question.question} question={question} />
          ),
      )}
    </Container>
  );
};

export default Section;
