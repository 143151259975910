import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import _ from 'lodash';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../contexts/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/NewInput';
import { MaturityButton as Button } from '../../components/MaturityButton';

import { Container, Content } from './styles';
import { MaturityHeader } from '../../components/MaturityHeader';
import { ServicesHeader } from '../../components/ServicesHeader';
import { api } from '../../services/api';
import { useAuth } from '../../contexts/auth';

interface CreateUserForm {
  name: string;
  email: string;
}

export const AddCompanyUser: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const { user, controlPayment } = useAuth();

  async function controlPage() {
    const permission = await controlPayment(user);

    const normalAccounts = _.get(permission, 'normal_accounts', 0);
    const existingQuantityNormal = _.get(permission, 'existing_quantity_normal', 0);
    if (normalAccounts <= existingQuantityNormal) {
      history.push('/atividades/corporativo');
    }
  }

  useEffect(() => {
    controlPage();
  }, []);

  useEffect(() => {
    if (!user.company_id) {
      addToast({
        type: 'info',
        title: 'Redirecionando...',
        description: 'Você primeiro precisa cadastrar sua empresa',
      });
      history.push('/atividades/corporativo');
    }
  }, [user.company_id, history, addToast]);

  const handleSubmit = useCallback(
    async (data: CreateUserForm) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          name: Yup.string().required('Nome obrigatório'),
          email: Yup.string()
            .email('Digite um e-mail, válido')
            .required('E-mail obrigatório'),
        });

        await schema.validate(data, { abortEarly: false });

        setLoading(true);

        const userToBeCreated = {
          name: data.name,
          phone: '',
          roles: ['user'],
          company_id: user.company_id?._id,
          email: data.email,
        };

        await api.post('/users/no_pass', userToBeCreated);

        setLoading(false);

        addToast({
          type: 'success',
          title: 'Usuário adicionado com sucesso!',
          description:
            'Peça para o seu colaborador verificar a caixa de email.',
        });

        history.push('/atividades/corporativo');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao criar usuário',
        });
      }
    },
    [addToast, history, user.company_id],
  );

  return (
    <Container>
      <MaturityHeader />
      <ServicesHeader pageTitle="Adicionar Usuário" />

      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Insira os dados do novo usuário</h2>

          <section>
            <Input name="name" placeholder="Nome" dark />
            <Input name="email" placeholder="E-mail" dark />
          </section>

          <Button loading={loading}>Concluir</Button>
        </Form>
      </Content>
    </Container>
  );
};
