import styled from 'styled-components';

export const SecondCopySection = styled.div`
  margin-top: 35px;

  p {
    color: #707070;
    font: normal normal normal 12px/16px 'Mulish';

    @media screen and (min-width: 768px) {
      font: normal normal normal 14px/18px 'Mulish';
    }
    @media screen and (min-width: 992px) {
      font: normal normal normal 16px/20px 'Mulish';
    }
  }

  button {
    color: var(--teal);
    background-color: unset;
    text-decoration: underline;
    font: normal normal normal 12px/16px 'Mulish';

    @media screen and (min-width: 768px) {
      font: normal normal normal 14px/18px 'Mulish';
    }
    @media screen and (min-width: 992px) {
      font: normal normal normal 16px/20px 'Mulish';
    }
  }
`;

export const Link = styled.a`
 font-family:Mulish;
 color: #707070;
 font-weight: 400;
 :visited{
  color: #707070;
 }
;
`