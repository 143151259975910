/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';

import {
  IoIosMenu,
  IoSettingsOutline,
  FaLongArrowAltRight,
  RiBuilding4Line,
  FiUserPlus,
  BsBuilding,
  CgFileDocument,
  RiBracesLine,
  IoIosDesktop,
  MdDashboard,
  FaUsers,
  BiCookie,
  BiLock,
  CgTrashEmpty,
  AiOutlineEdit,
  RiDashboardLine,
  AiOutlineLoading3Quarters,
  RiTrophyLine,
} from 'react-icons/all';

import { useHistory } from 'react-router';

import { Grid, Service } from '../../components/Grid';
import { NavigationTabs } from '../../components/NavigationTabs';
import {
  DropdownMenu,
  DropdownMenuOption,
} from '../../components/DropdownMenu';

import {
  Container,
  Content,
  Header,
  CompanyDataModal,
  DropdownContainer,
  MenuButton,
} from './styles';
import { ConfirmDelete } from '../../components/ConfirmDelete/styles';
import { useAuth } from '../../contexts/auth';
import { api } from '../../services/api';
import { MaturityHeader } from '../../components/MaturityHeader';
import { useToast } from '../../contexts/toast';
import { ICompanyResponse } from '../../types/ICompanyResponse';
import GenericModal from '../../components/GenericModal';
import { GenericOverlay } from '../../components/GenericOverlay';
import { ActivityIndicator } from '../MaturityLanding/styles';
import GenericPlanModal from '../../components/GenericPlanModal';

const NAVIGATION_TABS = [
  { label: 'Corporativo', value: 0 },
  { label: 'Governança', value: 1 },
  { label: 'Treinamentos', value: 2 },
];

export const Activities: React.FC = () => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { addToast } = useToast();
  const { user, updateUser, controlPayment } = useAuth();

  const handleUpgrade = (planCode = '') => {
    const link = process.env.REACT_APP_PRIVACY || 'https://app.privacyflow.ai'
    window.location.href = `${link}/checkout/index.html?plan=${planCode}`
  };

  const deleteDSR = async () => {
    try {
      if (!idDeleteDSR) return;

      await api.delete(`/dsr/${idDeleteDSR}`);

      updateUser({
        ...user,
        company_id: {
          ...user.company_id,
          hasDSR: false,
          dsr_id: '',
        },
      });

      const gridServiceListClone = _.clone(gridServiceList);
      const dsrModule = gridServiceListClone.find(s => s.key === 'dsrModule');
      if (dsrModule) {
        dsrModule.dropdownOptions = undefined;
        dsrModule.path = '/atividades/corporativo/dsr/novo';
      }

      const description = 'DSR excluído com sucesso! Exclua o script do seu site!';

      addToast({
        type: 'success',
        title: 'Sucesso',
        description,
      });
      setIdDeleteDSR('');
      setOpenConfirmDelete(false);
      setOpenDeleteDsrModal(true)
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Falha ao Excluir',
        description: 'Ocorreu uma falha ao tentar excluir o DSR',
      });
    }
  };

  const hideConfirmDelete = () => {
    setOpenConfirmDelete(false);
    setIdDeleteDSR('');
  };

  const GRID_SERVICE_LIST: Service[] =
    [
      {
        key: 'cookieBarModule',
        indicator: '1/5',
        title: 'Barra de Cookies',
        description: 'Construa, edite, exclua e gerencie Barras de cookies.',
        active: false,
        upgradeTag: false,
        icon: 'cookie',
      },
      {
        key: 'policyModule',
        indicator: '1/5',
        title: 'Gerar Políticas',
        active: false,
        upgradeTag: false,
        description:
          'Construa e publique políticas de privacidade e cookies com templates simples e intuitivos.',
        icon: 'document',
      },
      {
        key: 'dsrModule',
        indicator: '1/5',
        title: 'Formulário de solicitação (DSR)',
        description: 'Construa, edite, exclua e gerencie o formulário DSR.',
        active: false,
        upgradeTag: false,
        icon: 'file2Line',
      },
    ]

  const ADMIN_DROPDOWN_OPTIONS: DropdownMenuOption[] = [
    {
      key: 'addUser',
      label: 'Adicionar usuário',
      action: (data) => {
        if (!data.active && data.upgradeTag && user.company_id.plan.code !== "PLANO_PREMIUM") {
          handleUpgrade(data.planCode);
          
          return;
        }

        if (!data.active && data.upgradeTag && user.company_id.plan.code === "PLANO_PREMIUM") return;

        setTimeout(() => {
          history.push('/cadastrar-novo-usuario');
        }, 500);
      },
      icon: FiUserPlus,
      active: false,
      upgradeTag: false,
    },
    {
      key: 'editCompany',
      label: 'Editar empresa',
      action: () => history.push('/editar-empresa'),
      icon: BsBuilding,
      active: true,
      upgradeTag: false,
    },
    {
      key: 'donwloadCode',
      label: 'Códigos gerados',
      action: () => history.push('/atividades/corporativo/codigo-fonte'),
      icon: RiBracesLine,
      active: true,
      upgradeTag: false,
    },
  ];

  const MASTER_DROPDOWN_OPTIONS: DropdownMenuOption[] = [
    {
      label: 'Planos',
      action: (data) => {
        if (!data.active) return;
        history.push('/backoffice/planos')
      },
      icon: MdDashboard,
      active: true,
    },
    {
      label: 'Empresas',
      action: (data) => {
        if (!data.active) return;
        history.push('/backoffice/empresas')
      },
      icon: BsBuilding,
      active: true,
    },
    {
      label: 'Usuários',
      action: (data) => {
        if (!data.active) return;
        history.push('/backoffice/usuarios')
      },
      icon: FaUsers,
      active: true,
    },
    {
      label: 'Barras de Cookies',
      action: (data) => {
        if (!data.active) return;
        history.push('/backoffice/barras-de-cookies')
      },
      icon: BiCookie,
      active: true,
    },
    {
      label: 'Políticas',
      action: (data) => {
        if (!data.active) return;
        history.push('/backoffice/politicas')
      },
      icon: BiLock,
    },
  ];

  const [gridServiceList, setGridServiceList] = useState(GRID_SERVICE_LIST);
  const [adminDropdownOptions, setAdminDropdownOptions] = useState(ADMIN_DROPDOWN_OPTIONS);
  const [showAdminDropdown, setShowAdminDropdown] = useState(false);
  const [showMasterDropdown, setShowMasterDropdown] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [openDeleteDsrModal, setOpenDeleteDsrModal] = useState(false);
  const [openPlanModal, setOpenPlanModal] = useState(false);
  const [idDeleteDSR, setIdDeleteDSR] = useState('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowAdminDropdown(false);
        setShowMasterDropdown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (user.company_id) {
      try {
        setLoading(true);
        api.get<ICompanyResponse>(`/companies/${user.company_id._id}`)
          .then((response) => {
            const {
              cookie_bars,
              policies,
              dsrs,
              users,
              plan,
              company
            } = response.data;

            updateUser({
              ...user,
              company_id: {
                status:company.status,
                ...user.company_id,
                hasPolicy: policies.length > 0,
                hasCookieBar: cookie_bars.length > 0,
                cookie_id: cookie_bars.length > 0 ? cookie_bars[0]._id : null,
                hasDSR: dsrs.length > 0,
                dsr_id: dsrs.length > 0 ? dsrs[0]._id : null,
                policy_slug: policies.length > 0 && policies[0].policy_slug
                  ? policies[0].policy_slug
                  : null,
                plan,
              },
            });

            handlePermitions(plan, cookie_bars, policies, dsrs, users);
          });
      } catch (error) {
        setLoading(false);
      }
    }
  }, []);

  const handlePermitions = async (plan, cookie_bars, policies, dsrs, users) => {
    try {
      setLoading(true);
      const permission = await controlPayment(user);

      const cookieBarQuant = _.get(permission, 'cookie_bars', 0);
      const policyQuant = _.get(permission, 'policies', 0);
      const dsrFormsQuant = _.get(permission, 'dsr_forms', 0);
      const interactionsEnabled = _.get(permission, 'interactions_reports', false);
      const dsrManagementEnabled = _.get(permission, 'dsr_management', false);
      const normalAccounts = _.get(permission, 'normal_accounts', 0);
      const planModal = _.get(permission, 'plan_modal', false)

      let cookieBarModuleEnabled = false;
      let policyModuleEnabled = false;
      let dsrModuleEnabled = false;
      let userMenuEnabled = false;


      if (cookieBarQuant > 0) {
        cookieBarModuleEnabled = cookieBarQuant >= cookie_bars.length;
      }
      if (policyQuant > 0) {
        policyModuleEnabled = policyQuant >= policies.length;
      }
      if (dsrFormsQuant > 0) {
        dsrModuleEnabled = dsrFormsQuant >= dsrs.length;
      }
      if (normalAccounts > 0) {
        userMenuEnabled = normalAccounts > users.filter(u => u.roles.includes('user')).length;
      }
      const gridServiceListClone = _.clone(gridServiceList);
      const adminDropdownOptionsClone = _.clone(adminDropdownOptions);

      const cookieBarModule = gridServiceListClone.find(s => s.key === 'cookieBarModule');
      if (cookieBarModule) {
        cookieBarModule.active = cookieBarModuleEnabled;
        cookieBarModule.upgradeTag = !cookieBarModuleEnabled;
        cookieBarModule.planCode = 'plano_free';

        if (cookie_bars.length > 0) {
          cookieBarModule.dropdownOptions = {
            message: 'Acesse seus cookies',
            options: [
              {
                label: 'Editar Barra de cookies',
                action: () =>
                  history.push('/atividades/corporativo/barra-de-cookies'),
                icon: IoSettingsOutline,
                active: cookieBarModuleEnabled,
                upgradeTag: !cookieBarModuleEnabled,
                planCode: 'plano_free',
              },
              {
                label: 'Painel de cookies',
                action: (data) => {
                  if (!data.active && data.upgradeTag) handleUpgrade(data.planCode);
                  if (!data.active) return;
                  history.push('/atividades/corporativo/painel-de-cookies')
                },
                icon: CgFileDocument,
                active: interactionsEnabled,
                upgradeTag: !interactionsEnabled,
                planCode: plan.code === 'PLANO_FREE' ? 'plano_basic' : 'plano_premium',
              },
            ],
          };
        } else {
          cookieBarModule.path = '/atividades/corporativo/barra-de-cookies';
        }
      }

      const dsrModule = gridServiceListClone.find(s => s.key === 'dsrModule');
      if (dsrModule) {
        dsrModule.active = dsrModuleEnabled;
        dsrModule.upgradeTag = !dsrModuleEnabled;
        dsrModule.planCode = 'plano_premium';

        if (dsrs.length > 0) {
          dsrModule.dropdownOptions = {
            message: 'Acesse seu DSR',
            options: [
              {
                label: 'Painel DSR',
                action: (data) => {
                  if (!data.active && data.upgradeTag) handleUpgrade(data.planCode);
                  if (!data.active) return;
                  history.push('/atividades/corporativo/dsr-painel');
                },
                icon: RiDashboardLine,
                active: dsrManagementEnabled,
                upgradeTag: !dsrManagementEnabled,
                planCode: 'plano_premium',
              },
              {
                label: 'Editar formulário DSR',
                action: (data) => {
                  if (!data.active && data.upgradeTag) handleUpgrade(data.planCode);
                  if (!data.active) return;
                  history.push(`/atividades/corporativo/dsr/${dsrs[0]._id}`);
                },
                icon: AiOutlineEdit,
                active: dsrManagementEnabled,
                upgradeTag: !dsrManagementEnabled,
                planCode: 'plano_premium',
              },
              {
                label: 'Excluir formulário DSR',
                action: (data) => {
                  if (!data.active) return;
                  setIdDeleteDSR(dsrs[0]._id);
                  setOpenConfirmDelete(true);
                },
                icon: CgTrashEmpty,
                active: dsrs.length > 0,
                upgradeTag: false,
                planCode: 'plano_premium',
              },
            ],
          };
        } else {
          dsrModule.path = '/atividades/corporativo/dsr/novo';
        }
      }

      const policyModule = gridServiceListClone.find(s => s.key === 'policyModule');
      if (policyModule) {
        policyModule.active = policyModuleEnabled;
        policyModule.upgradeTag = !policyModuleEnabled;
        policyModule.planCode = 'plano_basic';

        if (policies.length > 0) {
          policyModule.path = '/atividades/corporativo/politicas';
          policyModule.message = 'Acesse suas políticas';
        } else {
          policyModule.path = '/atividades/corporativo/politicas';
        }
      }

      const addUserMenu = adminDropdownOptionsClone.find(d => d.key === 'addUser');
      if (addUserMenu) {
        addUserMenu.active = userMenuEnabled;
        addUserMenu.upgradeTag = !userMenuEnabled;
        addUserMenu.planCode = plan.code === 'PLANO_FREE' ? 'plano_basic' : 'plano_premium';
        addUserMenu.blockedTag = !userMenuEnabled && plan.code === 'PLANO_PREMIUM';
      }

      setGridServiceList(gridServiceListClone);
      setAdminDropdownOptions(adminDropdownOptionsClone);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

  };

  return (
    <Container>
      <MaturityHeader />
      {!user.company_id?.document && (
        <CompanyDataModal>
          <div>
            <h1>Dados da sua empresa</h1>
            <p>
              Ei, você ainda não fez o cadastro da sua empresa, que tal começar
              a fazer isso agora?
            </p>

            <button
              type="button"
              onClick={() => history.push('/cadastrar-empresa')}
            >
              Começar
              <FaLongArrowAltRight />
            </button>

            <RiBuilding4Line />
          </div>
        </CompanyDataModal>
      )}

      <Content>
        <Header>
          <div>
            <h1>{user.company_id?.fantasy_name ?? 'Jornada LGPD'}</h1>

            <div>
              {user.roles.includes('admin') && (
                <MenuButton
                  type="button"
                  isOpen={showAdminDropdown}
                  onClick={() => setShowAdminDropdown(prev => !prev)}
                >
                  <IoIosMenu />
                </MenuButton>
              )}

              {showAdminDropdown && (
                <DropdownContainer ref={dropdownRef}>
                  <DropdownMenu options={adminDropdownOptions} big />
                </DropdownContainer>
              )}

              {user.roles.includes('dpo') && (
                <MenuButton
                  type="button"
                  isOpen={showMasterDropdown}
                  onClick={() => setShowMasterDropdown(prev => !prev)}
                >
                  <IoIosDesktop />
                </MenuButton>
              )}

              {showMasterDropdown && (
                <DropdownContainer ref={dropdownRef}>
                  <DropdownMenu options={MASTER_DROPDOWN_OPTIONS} big />
                </DropdownContainer>
              )}
            </div>
          </div>

          <NavigationTabs tabs={NAVIGATION_TABS} />
        </Header>

        <Grid serviceList={gridServiceList} />

        {openConfirmDelete && (
          <div style={{ position: 'relative', bottom: '72px' }}>
            <ConfirmDelete>
              <p>Tem certeza que deseja excluir o formulário de DSR?</p>
              <footer>
                <button type="button" onClick={deleteDSR}>
                  Sim
                </button>
                <button type="button" onClick={hideConfirmDelete}>
                  Não
                </button>
              </footer>
            </ConfirmDelete>
          </div>
        )}

      </Content>
      { user.company_id.status === 'suspended' && (
        <GenericPlanModal
          hasClose={false}
          title="Faça um upgrade"
          icon={<RiTrophyLine />}
          message="Sua experiência terminou! A partir de agora você precisa escolher um novo plano para seguir."
          PlanOpciones
          DirectActionBasic={()=> handleUpgrade('plano_basic')}
          DirectActionPremium={()=>handleUpgrade('plano_premium')}
        />
      )}
      {openDeleteDsrModal && (
        <GenericModal
          hasClose={false}
          icon=""
          title=""
          message="Seu formulário foi excluído da nossa platafoma, delete também o script no seu site."
          closeMessage="Entendi"
          closeAction={null}
          successMessage="Entendi"
          successAction={() => setOpenDeleteDsrModal(false)}
        />
      )}
      {loading && (
        <GenericOverlay>
          <ActivityIndicator style={{ width: "50px", height: "50px" }}>
            <AiOutlineLoading3Quarters style={{
              width: "50px",
              height: "50px",
              color: "var(--teal)"
            }}
            />
          </ActivityIndicator>
        </GenericOverlay>
      )}

    </Container>
  );
};