/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useRef, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
// eslint-disable-next-line import/no-unresolved
import { cpf, cnpj } from 'cpf-cnpj-validator';

import { useHistory, useLocation } from 'react-router-dom';
import { useToast } from '../../contexts/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/old_Input';
import { MaturityButton as Button } from '../../components/MaturityButton';

import { Container, Content } from './styles';
import { MaturityHeader } from '../../components/MaturityHeader';
import { ServicesHeader } from '../../components/ServicesHeader';
import { api } from '../../services/api';
import { useAuth } from '../../contexts/auth';

interface User {
  _id: string;
  roles: string[];
  name: string;
  company_id: string | null;
  email: string;
  no_pass: boolean;
}

export const CompanyRegistration: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const { pathname } = useLocation();
  const { user, updateUser } = useAuth();
  const { addToast } = useToast();

  const [document, setDocument] = useState<string>(user.company_id?.document);
  const [name, setName] = useState<string>(user.company_id?.fantasy_name);
  const [planName] = useState<string>(user.company_id?.plan?.name);
  const [errorValidate, setErrorValidate] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (pathname === '/editar-empresa') {
      formRef.current?.setData(user.company_id);
    } else if (user.company_id.document) {
      // addToast({
      //   type: 'info',
      //   title: 'Redirecionando...',
      //   description: 'Você já possui uma empresa cadastrada',
      // });
      history.push('/atividades/corporativo');
    }
  }, [addToast, history, pathname, user.company_id]);

  const handleSubmit = async () => {
    try {
      // formRef.current?.setErrors({});

      // const schema = Yup.object().shape({
      //   fantasy_name: Yup.string().required('Nome fantasia obrigatório'),
      //   document: Yup.string().required('Obrigatório'),
      // });

      // await schema.validate(data, { abortEarly: false });

      setLoading(true);

      let createCompany;

      if (user.company_id) {
        createCompany = await api.put(
          `/companies/${user.company_id._id}`,
          { document, fantasy_name: name },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          },
        );
      } else {
        createCompany = await api.post(
          '/companies',
          { document, fantasy_name: name },
          {
            headers: {
              'Access-Control-Allow-Origin': '*',
            },
          },
        );
      }

      const { _id: createdCompanyId } = createCompany.data;

      // const userWithCompanyId: User = {
      //   ...user,
      //   company_id: createdCompanyId,
      // };

      const updatedUserResponse = await api.put(`users/${user._id}`, {
        company_id: createdCompanyId,
      });

      addToast({
        type: 'success',
        title: user.company_id
          ? 'Empresa editada com sucesso!'
          : 'Empresa criada com sucesso!',
        description: '',
      });

      updateUser(updatedUserResponse.data);

      setLoading(false);

      history.push('/atividades/corporativo');
    } catch (err) {
      setLoading(false);
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRef.current?.setErrors(errors);

        return;
      }

      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Falha ao criar empresa',
      });
    }
  };

  function cpfMask(value: string): string {
    if ((value.length === 14 && !cpf.isValid(value)) && value.length <= 14) {
      setErrorValidate('O CPF não é válido.');
    } else {
      setErrorValidate('')
    }
    // return cpf.format(value);
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2');
  };

  function cnpjMask(value: string): string {
    if ((value.length === 18 && !cnpj.isValid(value)) && value.length <= 18) {
      setErrorValidate('O CNPJ não é válido.');
    } else {
      setErrorValidate('')
    }
    // return cnpj.format(value);
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  return (
    <Container>
      <MaturityHeader />
      <ServicesHeader pageTitle="Empresa" />

      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Cadastre os dados da sua empresa</h2>

          <section>
            <Input
              name="fantasy_name"
              placeholder="Nome Fantasia"
              onChange={e => setName(e.target.value)}
              dark
            />
            <Input
              name="document"
              placeholder="CPF / CNPJ"
              onChange={e =>
                setDocument(
                  document && document.length <= 14
                    ? cpfMask(e.target.value)
                    : cnpjMask(e.target.value),
                )
              }
              maxLength={18}
              value={document}
              dark
            />
            <span>
              {errorValidate}
            </span>
            {planName && (
              <h3>{planName}</h3>
            )}
          </section>

          <Button
            loading={loading}
            type="submit"
            disabled={document.length < 14 || errorValidate && (document.length === 14 || document.length === 18)}
          >
            Concluir
            {' '}
          </Button>
        </Form>
      </Content>
    </Container>
  );
};
