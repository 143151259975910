import styled from 'styled-components';

interface BarProps {
  backgroundColor?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: string;
  fontColor?: string;
}

export const Container = styled.footer<BarProps>`
  .opacityBar{
    z-index:1;
    position: absolute;
    height: 100%;
    width: 100%;
    margin-left: -2rem;
    border-radius: 4.5rem;
    background-color: rgba(0,0,0,0.4);
    p{
      display: none;
    };
    @media screen and (max-width: 768px) {
      margin-top: -1rem;
      margin-left: 0px;
    }
  }
  :hover .opacityBar{
    z-index:1;
    display: none;
    position: absolute;
    height: 100%;
    width: 100%;
    margin-left: -2rem;
    border-radius: 4.5rem;
    background-color: rgba(0,0,0,0.76);
    display:flex;
    justify-content: center;
    align-items: center;
    p{
      display: flex;
      font-family: ${props => props.fontFamily};
      font-weight: 600;
      font-size: 16px;
      line-height: 20.08px;
      color:white;
    };
    @media screen and (max-width: 768px) {
      margin-top: -1rem;
      margin-left: 0px;
    }
  }
  
  position: relative;

  width: 100%;
  height: 9rem;
  border-radius: 4.5rem;
  background-color: ${props => props.backgroundColor ?? 'var(--blue)'};
  color: ${props => props.fontColor ?? 'var(--white)'};

  padding: 1rem 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  .cookie-bar-message {
    white-space: pre-line;
    font-family: ${props => props.fontFamily};
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
    color: ${props => props.fontColor ?? 'var(--white)'};
    cursor: default;
  }

  .buttons {
    display: -webkit-inline-box;
    margin-left: 2rem;
    display: flex;
    align-items: center;
    position: relative;
    cursor: default;
    button {
      z-index:2;
      position: absolute;
      right: -1.25rem;
      height: 1.5rem;
      width: 1.5rem;
      background: inherit;
      color: white;

      svg {
        height: 100%;
        width: 100%;
      }
    }

    > div {
      font-family: ${props => props.fontFamily};
      font-size: ${props => props.fontSize};
      font-weight: ${props => props.fontWeight};
      color: ${props => props.fontColor};
    }

    > div + div {
      padding: 0px 15px;
      border: 2px solid ${props => props.fontColor};
      border-radius: 10px;
      margin: 0px 10px;
      min-width: 110px;
    }
  }

  .dropdownContainer {
    position: absolute;
    right: -3rem;
    top: 75%;
    z-index: 998;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    height: 4.5rem;
    padding: 0 2rem;
  }
`;
