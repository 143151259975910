import styled from 'styled-components';
import { WhiteBoxContainer } from './Box/styles';

export const WhiteBoxWrapper = styled(WhiteBoxContainer)`
    width: 42rem;
    height: 24rem;
    display: block;
    align-items: center;
    justify-content: center;
    padding: 0.1rem;
    background-color: #fff;
    @media (min-width: 300px) and (max-width: 360px) {
        width: 28rem;
    }
`;
export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
`;