/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { GenericPage } from '../../../../components/GenericPage';
import {
  CellBody, CellHeader, CheckBox, RowBody, RowHeader, Table, TableBody, TableHeader, CheckBoxInput
} from '../../../../components/GenericTable/styles';
import { useToast } from '../../../../contexts/toast';
import { api } from '../../../../services/api';
import { ICompanyPaymentCheck } from '../../../../types/ICompanyPaymentCheck';
import { formatDate } from '../../../../utils/getFormatDate';

const HEADERS = [
  "Empresa",
  "CNPJ/CPF",
  "Plano",
  "Nome do lead",
  "E-mail",
  "Telefone",
  "Data de criação",
]

export const CompanyList: React.FC = () => {
  const { addToast } = useToast();

  const [companies, setCompanies] = useState<ICompanyPaymentCheck[]>([]);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<string>("Não efetuado pagamento");

  useEffect(() => {
    const loadCompanies = async () => {
      try {
        setLoading(true);
        const { data } = await api.get<ICompanyPaymentCheck[]>('/companies', { 
          params: {
            status
          }
        });
        if (data && data.length > 0) {
          setCompanies(data)
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Falha ao carregar',
          description: 'Ocorreu um erro ao carregar as empresas',
        });
      }
    };
      
    loadCompanies();
      
  }, [addToast, status]);

  return (
    <GenericPage
      breadcrumb="BackOffice > "
      pageTitle="Empresas"
      loading={loading}
    >
      <div>
        <CheckBox>
          <CheckBoxInput
            type="checkbox" 
            onChange={() => setStatus("Gratuito")}
            checked={status === "Gratuito"}
          />
          Gratuito
        </CheckBox>
        <CheckBox>
          <CheckBoxInput
            type="checkbox" 
            onChange={() => setStatus("Efetivado")}
            checked={status === "Efetivado"}
          />
          Efetivado
        </CheckBox>
        <CheckBox>
          <CheckBoxInput 
            type="checkbox"
            onChange={() => setStatus("Não efetuado pagamento")}
            checked={status === "Não efetuado pagamento"}
          />
          Não efetuado pagamento
        </CheckBox>
      </div>
      <Table>
        <TableHeader>
          <RowHeader>
            {HEADERS.map((header) => { 
              return <CellHeader>{header}</CellHeader>
            })}
          </RowHeader>
        </TableHeader>
        <TableBody>
          {companies.map((c) => {
              return (
                <RowBody key={c.company._id}>
                  <CellBody>{c.company.fantasy_name}</CellBody>
                  <CellBody>{c.company.document}</CellBody>
                  <CellBody>{c.plan.name}</CellBody>
                  <CellBody>{c?.user?.name}</CellBody>
                  <CellBody>{c?.user?.email}</CellBody>
                  <CellBody>{c?.user?.phone}</CellBody>
                  <CellBody>{formatDate(new Date(c.company.createdAt))}</CellBody>
                </RowBody>
              )
            })} 
        </TableBody>
      </Table> 
    </GenericPage>
  )
};
