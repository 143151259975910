import React from 'react';

import { LayoutProvider } from './layout';
import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { ResearchProvider } from './research';

const AppProvider: React.FC = ({ children }) => (
  <ToastProvider>
    <AuthProvider>
      <LayoutProvider>
        <ResearchProvider>{children}</ResearchProvider>
      </LayoutProvider>
    </AuthProvider>
  </ToastProvider>
);

export default AppProvider;
