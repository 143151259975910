interface Params {
  key: string;
  value: string;
}

export const parseQueryStr = (search: string): Params[] => {
  const params = search
    .replace('?', '')
    .split('&')
    .reduce((acc, curr) => {
      const keyValue = curr.split('=');
      const param = {
        key: keyValue[0],
        value: keyValue[1]
      };
      acc.push(param);

      return acc;
    }, []);

  return params;
};
