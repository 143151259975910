import React, { useEffect } from 'react';

import { IoIosArrowRoundForward, IoMdLink } from 'react-icons/io';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { MdContentCopy } from 'react-icons/md';

import { useHistory } from 'react-router-dom';
import { MaturityHeader } from '../../../../../components/MaturityHeader';
import { ServicesHeader } from '../../../../../components/ServicesHeader';
import { useManagePolicy } from '../../../../../hooks/useManagePolicy';
import { useCreateCookieBar } from '../../../../../hooks/useCreateCookieBar';
import { AddPolicyCard } from '../../../../../components/AddPolicyCard';
import { PolicyCard } from '../../../../../components/PolicyCard';
import { GenericOverlay } from '../../../../../components/GenericOverlay';
import PublishPolicy from '../../../../../components/PublishPolicy';
import { ActivityIndicator } from '../../../../../components/MaturityButton/styles';

import { Container, Content } from './styles';
import { ModalCard as CookieBarModal } from '../../Cookies/CreateCookieBar/styles';
import { ModalMessageImportant } from '../../../../../components/ModalMessageImportant';
import { useAuth } from '../../../../../contexts/auth';

export const ManagePolicy: React.FC = () => {
  const { htmlCopied, loadCookieBarForCopy } = useCreateCookieBar();
  const {
    policies,
    showDelCookiesModal,
    showPublishCookiesModal,
    showPublishPolicy,
    onDelete,
    handleClickCloseDelCookies,
    handleClickShowPublishCookies,
    handleClosePublishCookies,
    handleShowPublishPolicy,
    handleClosePublishPolicy,
    cookieBarIdToBeCopied,
    tryFetchPolicy,
  } = useManagePolicy();

  const history = useHistory();
  const { user, controlPayment } = useAuth();

  async function controlPage() {
    const permission = await controlPayment(user);

    if (permission?.policies < 1) {
      history.push('/atividades/corporativo');
    }
  }

  useEffect(() => {
    controlPage();
  }, []);

  return (
    <Container>
      <MaturityHeader />
      <ServicesHeader pageTitle="Gerador de políticas" />

      <Content>

        {!tryFetchPolicy && (
          <GenericOverlay>
            <ActivityIndicator className="loading">
              <AiOutlineLoading3Quarters />
            </ActivityIndicator>
          </GenericOverlay>
        )}

        {tryFetchPolicy && policies.map(policy => (
          <PolicyCard
            key={policy.site_url}
            _id={policy._id}
            site_url={policy.site_url}
            publish={policy.publish}
            completed={policy.completed}
            onDelete={onDelete}
            onPublish={handleShowPublishPolicy}
          />
        ))}

        {tryFetchPolicy && policies.length === 0 && (
          <AddPolicyCard
            key="1"
            description="Política de Privacidade e Cookies Privacyflow"
            path="politicas/nova"
            active
            backgroundColor="white"
            icons={['lock', 'cookie']}
          />
        )}
      </Content>

      {/* {showDelCookiesModal && (
        <ModalMessageImportant
          title="Um recado importante sobre esta ação:"
          content="Ao excluir você deverá substituir o HTML pelo novo, somente sua política será excluída e a Barra de cookies permanecerá. Deseja excluir a Política?"
          handleSuccess={handleClickShowPublishCookies}
          handleCancel={handleClickCloseDelCookies}
        >
          <IoMdLink className="svg-link" />
        </ModalMessageImportant>
      )} */}

      {showPublishCookiesModal && (
        <GenericOverlay>
          <CookieBarModal>
            <MdContentCopy className="svg-copy" />
            <h2>Copie esse HTML e cole no seu site.</h2>
            <div className="button-wrapper">
              <span>{'<!DOCTYPE HTML>'}</span>
              <button
                type="button"
                onClick={() => loadCookieBarForCopy(cookieBarIdToBeCopied)}
                className={htmlCopied ? 'color--teal' : ''}
              >
                <MdContentCopy />
                {htmlCopied ? 'HTML copiado!' : `Copiar HTML`}
              </button>
            </div>
            {htmlCopied && (
              <button
                type="button"
                className="footer-button"
                onClick={() => handleClosePublishCookies()}
              >
                Entendi
                <IoIosArrowRoundForward />
              </button>
            )}
          </CookieBarModal>
        </GenericOverlay>
      )}

      {showPublishPolicy && (
        <PublishPolicy
          origin="POLICY"
          handleFinishCallback={handleClosePublishPolicy}
          isCompleted={false}
        />
      )}
    </Container>
  );
};
