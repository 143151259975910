import styled, { css } from 'styled-components';
import { Switch as MUI_Switch, SwitchProps } from '@material-ui/core';

interface CardProps {
  enabled: boolean;
  broad: boolean;
  clickable?: boolean;
}

export const Container = styled.section`
  width: 100%;
  margin-top: 33px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Card = styled.div<CardProps>`
  max-width: 946px;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 
  ${props =>
    props.clickable &&
    css`
      cursor: pointer;
    `};

  ${props =>
    props.broad &&
    css`
      max-width: 87%;
    `};

  .mb-17 {
    margin-bottom: 17px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .mb-73 {
    margin-bottom: 73px;
  }

  h2 {
    font: normal normal bold 14px/18px Nunito;

    ${props =>
    !props.enabled && !props.clickable &&
    css`
        cursor: not-allowed;
      `};
  }

  div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    p {
      font: normal normal normal 12px/16px 'Mulish';
      letter-spacing: 0.62px;
      color: #949494;
      width: 584px;

      ${props =>
    !props.enabled && !props.clickable &&
    css`
          cursor: not-allowed;
        `};
    }

    .MuiSwitch-root {
      padding: 7px;
      height: 30px;
    }

    .MuiSwitch-switchBase {
      top: -7px;
      left: -5px;
    }

    .MuiSwitch-thumb {
      width: 26px;
      height: 26px;
    }

    .MuiSwitch-track {
      width: 40px;
      height: 16px;
    }
  }

  @media screen and (min-width: 375px) {
    padding: 25px 25px;
  }
  @media screen and (min-width: 768px) {
    padding: 30px 45px;
    h2 {
      font: normal normal bold 16px/20px Nunito;
    }
    div {
      p {
        font: normal normal normal 14px/18px 'Mulish';
      }
    }
  }
  @media screen and (min-width: 992px) {
    padding: 40px 66px;
    h2 {
      font: normal normal bold 24px/28px Nunito;
    }
    div {
      p {
        font: normal normal normal 18px/22px 'Mulish';
      }
    }
  }
`;

export const Switch = styled(MUI_Switch) <SwitchProps>`
  && {
    ${props =>
    props.checked
      ? css`
            .MuiIconButton-label {
              color: #2ac4ea;
            }

            .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
              background-color: #2ac4ea;
            }
          `
      : css`
            .MuiSwitch-switchBase {
              color: #494949;
            }
          `}
  }
`;
