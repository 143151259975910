import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;

  overflow: scroll;

  font-family: 'Mulish', sans-serif;

  > div {
    margin: 2rem 6rem;
    h1 {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 28px;
      line-height: 38px;
      color: #2AC4EA;
    }
    h2 {
      font-family: Mulish;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 23px;
      color: #707070;
    }
  }
`;

export const Content = styled.main`
  width: 100%;
  max-width: 1800px;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
  margin: 2rem 0rem;
  align-items: center;  
  
  @media screen and (max-width: 768px) {
    margin: 0rem;
    margin-bottom: 1rem;
  }
`;

export const Header = styled.div`
  width: 100%;
  max-width: 1120px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  border-radius: 32px; 
  padding: 0.6rem 0rem;
  margin: 2rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;

    input{
      width: 100px;
      font-family:Mulish;
      font-weight: 400;
      font-style: normal;
      font-size: 18px;

      margin-left:32px;
      margin-right:32px;
      color: #707070;   
    }

    span{
      font-family:Mulish;
      font-weight: 400;
      font-style: normal;
      font-size: 18px;

      margin-left:32px;
      margin-right:32px;
      color: #707070;
    }

    .MuiIconButton-root:hover {
      background-color: unset;
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }  
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1120px;
  padding: 4rem 4.4rem;

  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.161);
  border-radius: 32px;

  @media screen and (max-width: 768px) {
    padding: 4rem 1rem;
  }
`;

export const CardHeader = styled.div`
  width: 100%;
  text-align: center;
  
  h1 {
    color: #2F46CF;
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 4.3rem;
    line-height: 5.8rem;
    text-align: center;
  }
  h2 {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 2.14rem;
    line-height: 3rem;
    color: #000000;
  }
  h3 {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 1.5rem;
    text-align: center;
    color: #696F77;
    margin-bottom: 2.4rem
  }
`;

export const CardPercentage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 1rem 2rem;
  margin-bottom: 1.6rem;
  
  background: #FFFFFF;
  border: 2px solid #EEEEEE;
  border-radius: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  span {
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2rem;

    color: #707070;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 6rem;

    div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      
      span {
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 2.14rem;
        line-height: 2.9rem;
        color: #696F77;
      }

      p {
        font-family: Mulish;
        font-style: normal;
        font-weight: normal;
        font-size: 0.8rem;
        line-height: 1rem;
        color: #707070;
      }
    }
    /* div:last-child {
      margin-left: 2.8rem;
    } */
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;

    > span {
      margin-bottom: 1rem;
    }
  }
`;

interface MenuButtonProps {
  isOpen: boolean;
}

export const MenuButton = styled.button<MenuButtonProps>`
  position: relative;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;

  background-color: inherit;

  transition: all 0.2s ease;

  ${props =>
    props.isOpen &&
    css`
      background-color: rgba(255, 255, 255, 0.2);
    `}

  > svg {
    color: var(--white);
    height: 2.5rem;
    width: 2.5rem;
    margin: auto;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  top: 0;
  width: 15rem;
  z-index: 9;
  box-shadow: 0px 3px 6px #00000029;
`;

export const ContainerButtonHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
