import React, { useEffect, useState } from 'react';

import { Container, TabItem } from './styles';

interface Tab {
  label: string;
  value: number;
}

interface NavigationTabProps {
  tabs: Tab[];
}

export const NavigationTabs: React.FC<NavigationTabProps> = ({ tabs }) => {
  const [stateTabs, setStateTabs] = useState<Tab[]>(tabs);
  const [selectedTabValue, setSelectedValue] = useState(0);

  useEffect(() => {
    setStateTabs(tabs);
  }, [tabs]);

  return (
    <Container>
      {stateTabs.map((tab, index) => (
        <TabItem
          key={tab.value}
          selected={selectedTabValue === tab.value}
          onClick={() => index === 0 && setSelectedValue(tab.value)}
          enabled={index === 0}
        >
          {tab.label}
        </TabItem>
      ))}
    </Container>
  );
};
