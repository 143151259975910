import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../contexts/auth';
import { api } from '../services/api';
import { IDSR } from '../types/IDSR';
import { IDSRInteraction } from '../types/IDSRInteraction';

interface Response {
  interactions: IDSRInteraction[];
  total_interactions: number;
}

export const useDSRPanel = () => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);

  const [showDropdown, setShowDropdown] = useState(false);
  const [interactions, setInteractions] = useState<IDSRInteraction[]>();
  const [interactionsExcel, setInteractionsExcel] = useState([]);

  const [dsrId, setDsrId] = useState<string>();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [total, setTotal] = useState(0);

  useEffect(() => {
    const loadDSR = async () => {
      const { data } = await api.get<IDSR>(`/dsr/${user.company_id?.dsr_id}`);
      setStartDate(data.createdAt)
    }
    loadDSR();
  }, [user.company_id?.dsr_id]);

  useEffect(() => {
    const loadInteractions = async () => {
      setLoading(true);
      if (!user.company_id) history.goBack();

      const response = await api.get(`/companies/${user.company_id?._id}`);

      setDsrId(response.data?.dsrs[0]?._id);
      const { data } = await api.get<Response>(
        `dsr/interactions/summary?dsr=${response.data?.dsrs[0]?._id}&startDate=${startDate || response.data?.dsrs[0]?.createdAt}&endDate=${endDate || new Date().toISOString()}`,
      );
      const interactions_formatted = data.interactions.map((interaction) => {
        return {
          ...interaction,
          percentage: `${Math.ceil((interaction.count * 100) / data.total_interactions) || 0}%`
        }
      }).sort((a, b) => b.count - a.count);

      const list_linear_interactions = interactions_formatted.map(({ name, type, count, percentage }) =>
        [name, type, count, percentage]
      );
      setInteractionsExcel(list_linear_interactions);
      setInteractions(interactions_formatted);
      setTotal(data.total_interactions);
      setLoading(false);
    };

    loadInteractions();
  }, [startDate, endDate]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return {
    dsrId,
    dropdownRef,
    showDropdown,
    interactions,
    total,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setShowDropdown,
    loading,
    interactionsExcel
  };
};
