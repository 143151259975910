import React from 'react';
import { useHistory } from 'react-router-dom';

import { IoMapOutline } from 'react-icons/io5';
import { AiOutlineTrophy, AiOutlineLock } from 'react-icons/ai';
import { BsPerson, BsCardChecklist } from 'react-icons/bs';
import { BiCookie, BiHive, BiSun } from 'react-icons/bi';

import { Container, Content } from './styles';

interface AddPolicyCardProps {
  description: string;
  path: string;
  active?: boolean;
  backgroundColor: 'white';
  icons: string[];
}

export const AddPolicyCard: React.FC<AddPolicyCardProps> = ({
  backgroundColor,
  description,
  path,
  active,
  icons,
}) => {
  const backgroundIcon: { [index: string]: JSX.Element } = {
    trophy: <AiOutlineTrophy />,
    map: <IoMapOutline />,
    person: <BsPerson />,
    checklist: <BsCardChecklist />,
    cookie: <BiCookie />,
    hive: <BiHive />,
    sun: <BiSun />,
    lock: <AiOutlineLock />,
  };

  const history = useHistory();

  const handleClick = () => {
    if (path) history.push(path);
  };

  return (
    <Container onClick={handleClick} active={active}>
      <Content backgroundColor={backgroundColor} active={active}>
        {icons && (
          <section>
            {icons.map(icon => (
              <div key={icon} className={icon}>
                {backgroundIcon[icon] || backgroundIcon.checklist}
              </div>
            ))}
          </section>
        )}

        <article>
          <h3>{description}</h3>
        </article>
      </Content>
    </Container>
  );
};
