import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 375px) {
    padding: 1rem;
  }
  
  @media screen and (max-width: 768px) {
    padding: 1.5rem;
  }
`;

export const Modal = styled.div`
  position: relative;
  width: 100%;
  max-width: 46.563rem;
  min-height: 14.375rem;
  background-color: var(--white);
  box-shadow: 0 0.188rem 0.375rem #00000029;
  border-radius: 1.25rem;
  text-align: center;
  padding: 1rem;

  @media screen and (min-width: 375px) {
    padding: 2rem;
  }
  @media screen and (min-width: 768px) {
    padding: 3rem;
  }
  @media screen and (min-width: 992px) {
    padding: 3.75rem;
  }
`;
  
export const IconSlot = styled.div`
  margin-bottom: 2rem;

  svg {
    width: 6.25rem;
    height: 6.25rem;
    color: var(--teal);
  }
`

export const Header = styled.header`
  h2 {
    font: normal normal 400 1.75rem/2rem 'Mulish';
    letter-spacing: 0;
    color: var(--medium-gray);
  }
`;
  
export const Body = styled.div`
  display: block;
  font: normal normal normal 1.375rem/1.625rem 'Mulish';
  margin-top: 0.938rem;
  color: var(--gray);
`;

export const Footer = styled.footer`
  display: block;
  margin-top: 1.875rem;
  
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-evenly;
  }

  .footer-button {
    display: flex;
    align-items: center;
    font: normal normal bold 1.5rem/1.625rem 'Mulish';
    letter-spacing: 0;
    padding: 0.875rem 1.25rem;
    border-radius: 3.313rem;
    background-color: transparent;
    color: var(--teal);
    margin: auto;
    
    svg {
      width: 1.875rem;
      height: 1.875rem;
      color: var(--teal);  
    }

    &.close {
      svg {
        margin-right: 0.625rem;
      }
    }
    
    &.save {
      svg {
        margin-left: 0.625rem;
      }
    }

    &:hover {
      background-color: var(--teal);
      color: var(--white);
      
      svg {
        color: var(--white);
      }
    }
  }
`;
