import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { IoReturnDownBackOutline, IoTrophyOutline } from 'react-icons/io5';

import { useResearch } from '../../contexts/research';
import { useToast } from '../../contexts/toast';

import { UserData } from '../../types/researchTypes';

import { MaturityHeader } from '../../components/MaturityHeader';
import { MaturityButton as Button } from '../../components/MaturityButton';

import Section from './Section';
import { Container, Content, SectionItem, ProgressIndicator } from './styles';

import arrow from '../../assets/arrow.svg';
import { api } from '../../services/maturiry_api';

interface MaturityResearchRouteParams {
  jwt: string;
}

export const MaturityResearch: React.FC = () => {
  const { jwt } = useParams<MaturityResearchRouteParams>();
  const { addToast } = useToast();
  const history = useHistory();

  const {
    user,
    setUser,
    research,
    setResearch,
    currentSection,
    switchQuestionOrSection,
    progress,
  } = useResearch();

  const [loading, setLoading] = useState(false);
  const [showTrophy, setShowTrophy] = useState(false);

  useEffect(() => {
    setShowTrophy(progress === 100);
  }, [progress, research]);

  useEffect(() => {
    try {
      const { client } = jwtDecode<{ client: UserData }>(jwt);
      setUser(client);
    } catch (error) {
      history.push('/');
      addToast({
        type: 'error',
        title: 'Acesso negado',
        description: error.response.data.message,
      });
    }
  }, [addToast, setUser, history, jwt]);

  const handleSwitchSection = (sectionNumber: number) =>
    switchQuestionOrSection({ nextSection: sectionNumber });

  const sectionProgressByIndex = useMemo(() => {
    const questionAmountBySection = research.sections.map(
      sec => sec.questions.length,
    );

    const unansweredQuestionsAmount = research.sections.map(
      sec => sec.questions.filter(q => q.options === null).length,
    );

    const progressList = research.sections.map(
      (_, index) =>
        (100 *
          (questionAmountBySection[index] - unansweredQuestionsAmount[index])) /
        questionAmountBySection[index],
    );

    return progressList;
  }, [research.sections]);

  const handleGenerateScore = useCallback(async () => {
    try {
      setLoading(true);
      await api.get(`/researches/${research._id}/score?sendEmail=true`);

      setResearch({ ...research, status: true });

      setLoading(false);

      addToast({
        type: 'info',
        title: 'E-mail enviado',
        description: 'Você recebeu uma cópia do resultado em seu e-mail.',
      });
      history.push(`/resultado-maturidade/${research._id}`);
    } catch (er) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro',
        description: er.response.data.message,
      });
    }
  }, [history, research, addToast, setResearch]);

  return (
    <Container>
      <MaturityHeader />

      <Content>
        <aside>
          <header>
            <CircularProgressbar
              value={progress}
              strokeWidth={8}
              styles={{
                path: {
                  stroke: '#298AFF',
                  transition: 'stroke-dashoffset 0.5s ease 0s',
                  transformOrigin: 'center center',
                },
                trail: {
                  stroke: '#f1f1f1',
                },
              }}
            />
            <div>
              <ProgressIndicator progress={progress}>
                {!Number.isNaN(progress) && `${progress}%`}
              </ProgressIndicator>
            </div>
          </header>

          <div>
            <h3>Etapas da avaliação</h3>
            <p>
              Essa avaliação pode levar alguns minutos. Recomendamos fazer sem
              pressa, pois ela será o ponto inicial para que nossos
              especialistas auxiliem sua empresa na adequação.
            </p>
          </div>
          <ul>
            {research.sections.map((section, index) => (
              <SectionItem
                key={section.name}
                progress={Math.round(sectionProgressByIndex[index])}
                onClick={() => {
                  handleSwitchSection(index + 1);
                  setShowTrophy(false);
                }}
              >
                <aside>
                  <h4>{index + 1}</h4>
                </aside>
                {index !== research.sections.length - 1 && (
                  <img src={arrow} alt="arrow" />
                )}
                <div>
                  <strong>{section.name}</strong>
                  <p>{section.description}</p>
                </div>
                <span>{`${Math.round(sectionProgressByIndex[index])}%`}</span>
              </SectionItem>
            ))}
          </ul>
        </aside>

        {/* Componente de cada Seção */}
        <main>
          {!showTrophy &&
            (currentSection < 1 ? (
              <header>
                <h1>{`Olá, ${user?.name?.split(' ')[0]}. Vamos começar?`}</h1>
                <p>
                  Essa avaliação pode levar alguns minutos. Recomendamos fazer
                  sem pressa, pois ela será o ponto inicial para que nossos
                  especialistas auxiliem sua empresa na adequação.
                </p>
                <Button onClick={() => handleSwitchSection(1)}>
                  Começar agora
                </Button>
              </header>
            ) : (
              <section>
                {research.sections.map(
                  section =>
                    research.sections.indexOf(section) ===
                      currentSection - 1 && (
                      <Section key={section.name} section={section} />
                    ),
                )}
              </section>
            ))}

          {progress < 100 && currentSection > research.sections.length && (
            <header>
              <h1>Falta pouco!</h1>
              <p>
                Algumas questões ficaram pra trás, responda as sessões faltantes
                para gerar o score final
                <br />
                <IoReturnDownBackOutline />
              </p>
            </header>
          )}

          {showTrophy && (
            <header>
              <h1>
                Parabéns, avaliação concluída!
                <IoTrophyOutline />
              </h1>
              <p>
                Clique abaixo para calcularmos o nível de conformidade da sua
                empresa com a LGPD.
              </p>
              <Button loading={loading} onClick={handleGenerateScore}>
                Gerar score
              </Button>
            </header>
          )}
        </main>
      </Content>
    </Container>
  );
};
