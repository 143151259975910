import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { useAuth } from '../contexts/auth';
import { useToast } from '../contexts/toast';
import { api } from '../services/api';
import { usePolicyFormProgress } from './usePolicyFormProgress';
import { usePolicySteps } from './usePolicySteps';

import { ICompanyResponse } from '../types/ICompanyResponse';
import { IPolicy } from '../types/IPolicy';
import { ICookie } from '../types/ICookie';
import { IPolicyStep } from '../types/IPolicyStep';
import { formatDate } from '../utils/getFormatDate';

interface CreateEditPolicy {
  isCreation: boolean;
  hasCookieBar: boolean;
  isCompleted: boolean;
  basic_data?: [];
  day_to_day_data?: [];
  business_data?: [];
  financial_data?: [];
  sensitive_data?: [];
  dpo_data?: [];
  policy_html?: string;
  goToPreview?: boolean;
}

interface useCreatePolicyReturn {
  stepsCompleted: number;
  setStepsCompleted: (number) => void;
  setStepsPublish: (boolean) => void;
  stepsPublish: boolean;
  url: string;
  loading: boolean;
  previewHTML: string;
  replacePolicyHTML: (_policy_url: string, _company_name: string, _basic_data: string[], _day_to_day_data: string[], _business_data: string[], _financial_data: string[], _sensitive_data: string[], _dpo_data: string[], _policy_creation_date: string, _policy_edition_date: string, _completeHTML: boolean) => string;
  setUrl: Dispatch<SetStateAction<string>>;
  handleCreateEditPolicy: ({
    isCreation,
    hasCookieBar,
  }: CreateEditPolicy) => Promise<void>;
  cookies: ICookie[];
  setCookies: Dispatch<SetStateAction<ICookie[]>>;
  handleClickBegin: () => void;
  showExistingSiteUrlModal: boolean;
  showCookiesModal: boolean;
  handleClickShowCookies: () => void;
  handleClickForward: () => void;
  handleForwardSteps: (stepsData: IPolicyStep[], goToPreview: Boolean) => void;
  handleForwardPreview: () => void;
  handleBackwardPreview: () => void;
  handleForwardPublish: (hasCookieBar: boolean) => void;
  requestSave: boolean;
  setRequestSave: Dispatch<SetStateAction<boolean>>;
  policySteps: IPolicyStep[];
  setPolicySteps: Dispatch<SetStateAction<IPolicyStep[]>>;
  saveStepsEnabled: boolean;
  isBonded: boolean,
  setIsBonded: Dispatch<SetStateAction<boolean>>,
  hasCookieBarBond: boolean,
  setHasCookieBarBond: Dispatch<SetStateAction<boolean>>,
  isPolicyCompleted: boolean,
  notCreatedCookieBar: boolean,
  setNotCreatedCookieBar: Dispatch<SetStateAction<boolean>>,
  handleCloseModalCreatedCookieBar: () => void
  pageTopRef: React.MutableRefObject<HTMLDivElement>,
}

const initialHtmlTemplate = `
<!-- Styles ============================= -->
<link
  href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap"
  rel="stylesheet" />
<link href="https://fonts.googleapis.com/css2?family=Mulish:wght@400;700;900&display=swap" rel="stylesheet">
<style type="text/css">
  .policyOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 9999999999;
    background-color: rgb(232 232 232 / 80%);
  }

  .previewModal {
    margin: auto;
    padding: 20px 10px;
    background-color: #FFFFFF;
    color: #707070;
    border-radius: 20px;
    width: 95vw;
    max-height: 85vh;
    overflow: auto;
    font-family: 'Mulish';
  }

  .previewModal h1 {
    font-size: 22px;
    line-height: 24px;
    color: #707070;
    font-family: 'Mulish';
    padding: unset;
    margin: unset;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .previewModal h2 {
    font-size: 20px;
    line-height: 22px;
    color: #707070;
    font-family: 'Mulish';
    padding: unset;
    margin: unset;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  .previewModal h3 {
    font-size: 18px;
    line-height: 20px;
    color: #707070;
    font-family: 'Mulish';
    padding: unset;
    margin: unset;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .previewModal p, .previewModal ul {
    display: block;
    padding: unset;
    margin: unset;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }
  
  .previewModal ul {
    list-style-type: disc;
    padding-inline-start: 40px;
  }

  .previewModal p, .previewModal table, .previewModal ul, .previewModal div {
    font-size: 12px;
    line-height: 20px;
    color: #707070;
    font-family: 'Mulish';
  }

  .previewModal span, .previewModal li, .previewModal em, .previewModal td {
    font-weight: normal;
    color: #707070;
    font-family: 'Mulish';
  }

  @media screen and (min-width: 375px) {
    .previewModal {
      padding: 48px 25px 34px;
      width: 85vw;
      max-height: 92vh;
    }
  }

  @media screen and (min-width: 768px) {
    .previewModal {
      padding: 54px 35px;
      width: 75vw;
      max-height: 80vh;
    }
    .previewModal h1 {
      font-size: 24px;
      line-height: 26px;
    }
    .previewModal h2 {
      font-size: 22px;
      line-height: 24px;
    }
    .previewModal h3 {
      font-size: 20px;
      line-height: 22px;
    }
    .previewModal p, .previewModal table, .previewModal ul, .previewModal div {
      font-size: 14px;
      line-height: 22px;
    }
  }

  @media screen and (min-width: 992px) {
    .previewModal {
      padding: 40px 66px;
      width: 76vw;
      max-height: 76vh;
    }
    .previewModal h1 {
      font-size: 26px;
      line-height: 28px;
    }
    .previewModal h2 {
      font-size: 24px;
      line-height: 26px;
    }
    .previewModal h3 {
      font-size: 22px;
      line-height: 24px;
    }
    .previewModal p, .previewModal table, .previewModal ul, .previewModal div {
      font-size: 16px;
      line-height: 24px;
    }
  }
</style>

<!-- HTML ============================= -->
<div class="policyOverlay">
  <div class="previewModal">
`;


const templateSectionOne = `
<section>
  <h3>1. SOBRE A POLÍTICA DE PRIVACIDADE DE DADOS</h3>

  <p>A presente política contém informações sobre coleta, uso, armazenamento, tratamento, compartilhamento e proteção dos dados pessoais de todos os usuários e visitantes do site /*replace_policy_url*/ de responsabilidade: /*replace_company_name*/.</p>
  <p>Esse documento tem como finalidade, demonstrar absoluta transparência quanto ao assunto e esclarecer a todos interessados sobre os tipos de dados que são coletados, os motivos da coleta e a forma como os usuários podem gerenciar ou excluir as suas informações pessoais.</p>
  <p>O presente documento foi elaborado em conformidade com a Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei 12.965/14) (e o Regulamento da UE n. 2016/6790). Ainda, o documento poderá ser atualizado em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultá-la periodicamente.</p>
</section>
`;

const templateSectionTwo = `
<section>
  <h3>2. COMO E QUAIS DADOS RECOLHEMOS DOS USUÁRIOS E DO VISITANTES</h3>

  <p>Os dados pessoais que coletamos varia de acordo com o relacionamento entre a /*replace_company_name*/ e o visitante ou usuário do site ou aplicativo, e o meio utilizado para essa interação. Tais dados podem ser coletadas de forma automática ou manual e sempre atenderão aos interesses explícitos do titular dos dados e da /*replace_company_name*/, tendo como base as leis vigentes.</p>
  <p>Todos os dados coletados e tratados pela tem como finalidade estabelecer vínculos contratuais, detalhar de forma precisa a entrega de seus produtos e serviços, identificar os papeis e responsabilidades de todos os envolvidos com as entregas, garantir o cumprimento de quaisquer leis, normas ou políticas vigentes que estejam direta ou indiretamente ligadas aos serviços oferecidos e proteger os interesses do negócio, dos seus clientes, fornecedores e parceiros.</p>
  <p>Abaixo apresentamos os tipos de dados coletados, seus principais objetivos e motivos pelos quais podemos tratá-los:</p>
`;

const templateSectionThree = `
<section>
  <h3>3. QUAL A FINALIDADES PARA A COLETA DOS DADOS</h3>

  <p>Dados pessoais são coletados e armazenados pela plataforma buscando o bem-estar do usuário e visitante, o aprimoramento dos produtos e/ou serviços oferecidos, para facilitar, agilizar e cumprir os compromissos estabelecidos entre o usuário e a empresa, melhorar a experiência dos usuários e fornecer funcionalidades específicas, dependendo das características básicas do usuário. Assim, temos como foco:</p>

  <ul>
    <li>
      <strong>Melhorias da plataforma:</strong>
      <span>Compreender como o usuário utiliza os serviços da plataforma, para ajudar no desenvolvimento de negócios e técnicas</span>
    </li>
    <li>
      <strong>Anúncios:</strong>
      <span>Apresentar anúncios personalizados para o usuário com base nos dados fornecidos</span>
    </li>
    <li>
      <strong>Comercial:</strong>
      <span>Os dados são usados para personalizar o conteúdo oferecido e gerar subsídio à plataforma para a melhora da qualidade no funcionamento dos serviços</span>
    </li>
    <li>
      <strong>Previsão do perfil do usuário:</strong>
      <span>Tratamento automatizados de dados pessoais para avaliar o uso na plataforma</span>
    </li>
    <li>
      <strong>Dados de cadastro:</strong>
      <span>Para permitir o acesso do usuário a conteúdos da plataforma, exclusivo para usuários cadastrados</span>
    </li>
    <li>
      <strong>Dados de contrato:</strong>
      <span>Conferir às partes segurança jurídica e facilitar a conclusão do negócio</span>
    </li>
  </ul>
  
  <p>O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá mediante comunicação prévia ao usuário, de modo que os direitos e obrigações aqui previstos permanecem aplicáveis.</p>
</section>
`;

const templateSectionFour = `
<section>
  <h3>4. POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS</h3>

  <p>Os dados pessoais do usuário e visitante são armazenados pela plataforma durante o período necessário para a prestação do serviço ou o cumprimento das finalidades previstas no presente documento, conforme o disposto no inciso I do artigo 15 da Lei 13.709/18. Os dados podem ser removidos ou anonimizados a pedido do usuário, excetuando os casos em que a lei oferecer outro tratamento.</p>
  <p>Ainda, os dados pessoais dos usuários apenas podem ser conservados após o término de seu tratamento nas seguintes hipóteses previstas no artigo 16 da referida lei:</p>

  <ul>
    <li>I - cumprimento de obrigação legal ou regulatória pelo controlador</li>
    <li>II - estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais</li>
    <li>III - transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei</li>
    <li>IV - uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados</li>
  </ul>
</section>
`;

const templateSectionFive = `
<section>
  <h3>5. SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS</h3>

  <p>A plataforma se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados.</p>
`;

const templateSectionSix = `
<section>
  <h3>6. COMPARTILHAMENTO DOS DADOS</h3>

  <p>O compartilhamento de dados do usuário ocorre apenas com os dados referentes a publicações realizadas pelo próprio usuário, tais ações são compartilhadas publicamente com os outros usuários.</p>
  <p>Os dados do perfil do usuário são compartilhados publicamente em sistemas de busca e dentro da plataforma, sendo permitido ao usuário modificar tal configuração para que seu perfil não apareça nos resultados de busca de tais ferramentas.</p>
</section>
`;

const templateSectionSeven = `
<section>
  <h3>7. OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A TERCEIROS?</h3>

  <p>Terceiros apenas receberão dados na medida do necessário para permitir que eles realizem serviços contratados.</p>
  <p>Com relação aos fornecedores de serviços terceirizados como processadores de transação de pagamento, informamos que cada qual tem sua própria política de privacidade. Desse modo, recomendamos a leitura das suas políticas de privacidade para compreensão de quais informações pessoais serão usadas por esses fornecedores.</p>
  <p>Os fornecedores podem ser localizados ou possuir instalações localizadas em países diferentes. Nessas condições, os dados pessoais transferidos podem se sujeitar às leis de jurisdições nas quais o fornecedor de serviço ou suas instalações estão localizados.</p>
  <p>Ao acessar nossos serviços e prover suas informações, você está consentindo o processamento, transferência e armazenamento desta informação em outros países.</p>
  <p>Ao ser redirecionado para um aplicativo ou site de terceiros, você não será mais regido por essa Política de Privacidade ou pelos Termos de Serviço da nossa plataforma. Não somos responsáveis pelas práticas de privacidade de outros sites e lhe incentivamos a ler as declarações de privacidade deles.</p>
</section>
`;

const templateSectionEight = `
<section>
  <h3>8. COOKIES OU DADOS DE NAVEGAÇÃO</h3>

  <p>Os cookies referem-se a arquivos de texto enviados pela plataforma ao computador do usuário e visitante e que nele ficam armazenados, com informações relacionadas à navegação no site. Tais informações são relacionadas aos dados de acesso como local e horário de acesso e são armazenadas pelo navegador do usuário e visitante para que o servidor da plataforma possa lê-las posteriormente a fim de personalizar os serviços da plataforma.</p>
  <p>O usuário e o visitante da /*replace_company_name*/  manifesta conhecer e aceitar que pode ser utilizado um sistema de coleta de dados de navegação mediante à utilização de cookies.</p>
  <p>O cookie persistente permanece no disco rígido do usuário e visitante depois que o navegador é fechado e será usado pelo navegador em visitas subsequentes ao site. Os cookies persistentes podem ser removidos seguindo as instruções do seu navegador. Já o cookie de sessão é temporário e desaparece depois que o navegador é fechado. É possível redefinir seu navegador da web para recusar todos os cookies, porém alguns recursos da plataforma podem não funcionar corretamente se a capacidade de aceitar cookies estiver desabilitada.</p>
</section>
`;

const templateSectionNine = `
<section>
  <h3>9. CONSENTIMENTO</h3>

  <p>Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o usuário está consentindo com a presente Política de Privacidade.</p>
  <p>O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus direitos de cancelar seu cadastro, acessar e atualizar seus dados pessoais e garante a veracidade das informações por ele disponibilizadas.</p>
  <p>O usuário tem direito de retirar o seu consentimento a qualquer tempo, para tanto deve entrar em contato através do e-mail /*replace_dpo_email*/ e solicitar.</p>
</section>
`;

const templateSectionTen = `
<section>
  <h3>10. ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE</h3>

  <p>Reservamos o direito de modificar essa Política de Privacidade a qualquer momento, então, é recomendável que o usuário e visitante revise-a com frequência.</p>
  <p>As alterações e esclarecimentos vão surtir efeito imediatamente após sua publicação na plataforma. Quando realizadas alterações os usuários serão notificados. Ao utilizar o serviço ou fornecer informações pessoais após eventuais modificações, o usuário e visitante demonstra sua concordância com as novas normas.</p>
  <p>Diante da fusão ou venda da plataforma à outra empresa os dados dos usuários podem ser transferidos para os novos proprietários para que a permanência dos serviços oferecidos.</p>
</section>
`;

const templateSectionEleven = `
<section>
  <h3>11. JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS</h3>

  <p>Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro.</p>
  <p>Os eventuais litígios deverão ser apresentados no foro da comarca em que se encontra a sede da empresa.</p>
</section>
`;

export const useCreatePolicy = (): useCreatePolicyReturn => {
  const { id: policyId } = useParams<{ id: string }>();

  const { addToast } = useToast();
  const { user, updateUser } = useAuth();
  const { setStepsCompleted, stepsCompleted } = usePolicyFormProgress();
  const [stepsPublish, setStepsPublish] = useState<boolean>(false);
  const history = useHistory();

  const [url, setUrl] = useState('');
  const [policyCreationDate, setPolicyCreationDate] = useState('');
  const [policyEditionDate, setPolicyEditionDate] = useState('');
  const [cookies, setCookies] = useState<ICookie[]>([]);
  const [previewHTML, setPreviewHTML] = useState('');
  const [loading, setLoading] = useState(false);
  const [tryFetch, setTryFetch] = useState(false);
  const [requestSave, setRequestSave] = useState(false);
  const [saveStepsEnabled, setSaveStepsEnabled] = useState(false);
  const { policySteps, setPolicySteps } = usePolicySteps();

  const [isBonded, setIsBonded] = useState(false);
  const [hasCookieBarBond, setHasCookieBarBond] = useState(false);
  const [notCreatedCookieBar, setNotCreatedCookieBar] = useState(false);
  const [isPolicyCompleted, setIsPolicyCompleted] = useState(false);

  // Handles the flow of when the user already has the site url in the cookie bar module
  // eslint-disable-next-line prettier/prettier
  const [showExistingSiteUrlModal, setShowExistingSiteUrlModal] = useState(false);
  // eslint-disable-next-line prettier/prettier
  const [showExistingSiteUrlModalOnce, setShowExistingSiteUrlModalOnce] = useState(false);

  // Handles the flow of when the site has cookies
  const [showCookiesModal, setShowCookiesModal] = useState(false);

  // Handles the flow of when the user wants to see the cookies
  // eslint-disable-next-line prettier/prettier
  const [showCookiesCategoriesStep, setShowCookiesCategoriesStep] = useState(false);

  const [showSteps, setShowSteps] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showPublish, setShowPublish] = useState(false);
  const [showCreateCookie, setShowCreateCookie] = useState(false);

  const pageTopRef = useRef(null)

  async function verifyBond(): Promise<boolean> {
    setLoading(true);
    const response = await api.get(`/companies/${user.company_id._id}`)
    const company = response.data;
    setLoading(false);

    if (company.cookie_bars.length > 0 && company.cookie_bars[0].policy_bond) {
      setIsBonded(true)
      setHasCookieBarBond(true)
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (policyId && !tryFetch) {
      try {
        const loadCompanyData = async () => {
          try {
            setLoading(true);
            const { data } = await api.get<ICompanyResponse>(
              `/companies/${user.company_id?._id}`,
            );

            if (data && data.cookie_bars && data.cookie_bars.length > 0) {
              setUrl(data.cookie_bars[0].site_url);
              setCookies(data.cookie_bars[0].cookie_list);
              if (!showExistingSiteUrlModalOnce) {
                setShowExistingSiteUrlModal(true);
                setShowExistingSiteUrlModalOnce(true);
              }
              if (data.cookie_bars[0].policy_bond) {
                setIsBonded(true)
                setHasCookieBarBond(true)
              }
            }
            setLoading(false);
          } catch (error) {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Falha ao carregar',
              description:
                'Ocorreu um erro ao carregar os dados da sua empresa',
            });
          }
        };

        const loadPolicy = async () => {
          try {
            setLoading(true);
            const response = await api.get<IPolicy>(`/policies/${policyId}`);

            if (response && response.data && response.data.site_url) {
              const {
                site_url,
                createdAt,
                updatedAt,
                cookie_bar_bond,
                completed,
              } = response.data;

              setIsPolicyCompleted(completed);
              setUrl(site_url);
              setPolicyCreationDate(createdAt);
              setPolicyEditionDate(updatedAt);
              setShowCookiesCategoriesStep(true);
              setShowSteps(true);
              setIsBonded(!!cookie_bar_bond)
              setHasCookieBarBond(!!cookie_bar_bond)

              if (!isBonded || !cookie_bar_bond) {
                await verifyBond();
              }
            } else {
              loadCompanyData();
            }
            setLoading(false);
          } catch (error) {
            setLoading(false);
            loadCompanyData();
          }
        };

        loadPolicy();
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Falha ao carregar dados',
          description: 'Ocorreu um erro ao carregar os dados da política',
        });
      }
      setTryFetch(true);
    }
  }, [
    addToast,
    policyId,
    showExistingSiteUrlModalOnce,
    tryFetch,
    user.company_id?._id,
    verifyBond
  ]);

  function handleCloseModalCreatedCookieBar() {
    setNotCreatedCookieBar(false);

  }

  // Steps management
  useEffect(() => {
    let stepCount = 1;
    if (!stepsPublish) {
      if (url) stepCount += 1;
      if (showCookiesCategoriesStep) stepCount += 1;
      if (showSteps) stepCount += 1;
      if (showPreview) stepCount += 1;
      if (showPublish || notCreatedCookieBar) stepCount += 1;
      // if (showCreateCookie) stepCount += 1;
      setStepsCompleted(stepCount);
    } else {
      setStepsCompleted(6);
    }
  }, [
    setStepsCompleted,
    url,
    showCookiesCategoriesStep,
    showSteps,
    showPreview,
    showPublish,
    notCreatedCookieBar,
    stepsPublish,
    showCreateCookie,
    setStepsPublish
  ]);

  // eslint-disable-next-line consistent-return
  const handleCreateEditPolicy = async ({
    isCreation,
    hasCookieBar,
    isCompleted,
    basic_data,
    day_to_day_data,
    business_data,
    financial_data,
    sensitive_data,
    dpo_data,
    policy_html,
    goToPreview,
  }: CreateEditPolicy) => {
    try {
      const reqBody = {
        company_id: user.company_id._id,
        cookie_list: cookies,
        site_url: url,
        active: true,
        completed: isCompleted,
        basic_data,
        day_to_day_data,
        business_data,
        financial_data,
        sensitive_data,
        dpo_data,
        policy_html
      };

      if (!reqBody.site_url.includes('http://') && !reqBody.site_url.includes('https://')) {
        reqBody.site_url = `https://${reqBody.site_url}`;
      }

      setLoading(true);

      let response: { data: IPolicy };

      if (isCreation) {
        response = await api.post('policies', reqBody);
        const responseData = _.get(response, 'data', {});

        const responseId = _.get(responseData, '_id', '');
        if (responseId) {
          history.replace(`${responseId}`);
        } else {
          console.error('Erro ao identificar o id da política');
        }

        const cookie_list = _.get(responseData, 'cookie_list', []);
        if (
          cookie_list.length > 0 &&
          !hasCookieBar
        ) {
          setCookies(cookie_list);
          setShowCookiesModal(true);
        } else {
          setShowCookiesCategoriesStep(true);
          setShowSteps(true);
        }

        setShowExistingSiteUrlModal(false);
      } else {
        response = await api.put(`policies/${policyId}`, {
          ...reqBody,
          cookie_bar_bond: hasCookieBarBond
        });
        setIsBonded(hasCookieBarBond);
      }

      if (isCompleted && goToPreview) {
        setShowPreview(true);
      }

      setLoading(false);

      updateUser({
        ...user,
        company_id: {
          ...user.company_id,
          hasPolicy: true,
          hasCookieBar,
        },
      });

      if (!hasCookieBar) {
        let message = '';
        if (isCreation) {
          message = 'URL inserida com sucesso!';
        }
        if (!isCreation) {
          message = `
            Política salva com sucesso!
            Para copiar o HTML da Política basta ir para o menu editar.
          `;
        }
        addToast({
          type: 'success',
          title: 'Salvo',
          description: message,
        });
      }

      if (!isCreation && !goToPreview) {
        addToast({
          type: 'success',
          title: 'Salvo',
          description: 'Caixas de seleção salvas.',
        });
      }

      // Scroll to top
      pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao salvar Política',
      });
    }
  };

  const handleClickBegin = () => {
    handleCreateEditPolicy({
      isCreation: true,
      hasCookieBar: true,
      isCompleted: false,
    });
  };

  const handleClickShowCookies = () => {
    setShowCookiesModal(false);
    setShowCookiesCategoriesStep(true);
  };

  const handleClickForward = () => {
    setShowCookiesModal(false);
    setShowCookiesCategoriesStep(true);
    setShowSteps(true);
  };

  const handleForwardSteps = (stepsData, goToPreview) => {
    const _basicData = stepsData
      .find(step => step.title === 'Dados básicos')
      .data.reduce((acc, curr) => {
        if (curr.check) {
          acc.push(curr.label);
        }
        return acc;
      }, []);
    const _dayToDayData = stepsData
      .find(step => step.title === 'Dados do dia a dia')
      .data.reduce((acc, curr) => {
        if (curr.check) {
          acc.push(curr.label);
        }
        return acc;
      }, []);
    const _businessData = stepsData
      .find(step => step.title === 'Dados comerciais')
      .data.reduce((acc, curr) => {
        if (curr.check) {
          acc.push(curr.label);
        }
        return acc;
      }, []);
    const _financialData = stepsData
      .find(step => step.title === 'Dados financeiros')
      .data.reduce((acc, curr) => {
        if (curr.check) {
          acc.push(curr.label);
        }
        return acc;
      }, []);
    const _sensitiveData = stepsData
      .find(step => step.title === 'Dados sensíveis')
      .data.reduce((acc, curr) => {
        if (curr.check) {
          acc.push(curr.label);
        }
        return acc;
      }, []);
    const _dpoData = stepsData
      .find(step => step.title === 'Dados da pessoa Encarregada (DPO)')
      .data.reduce((acc, curr) => {
        if (curr.value) {
          acc.push(curr.value);
        }
        return acc;
      }, []);

    const previewHtml = replacePolicyHTML(
      url,
      user.company_id.fantasy_name,
      _basicData,
      _dayToDayData,
      _businessData,
      _financialData,
      _sensitiveData,
      _dpoData,
      policyCreationDate,
      policyEditionDate,
      false,
    );
    setPreviewHTML(previewHtml);
    setRequestSave(false)

    setTimeout(() => {
      handleCreateEditPolicy({
        isCreation: false,
        hasCookieBar: true,
        isCompleted: (_dpoData.length > 0),
        basic_data: _basicData,
        day_to_day_data: _dayToDayData,
        business_data: _businessData,
        financial_data: _financialData,
        sensitive_data: _sensitiveData,
        dpo_data: _dpoData,
        policy_html: previewHtml,
        goToPreview,
      });
    }, 200);
  };

  const handleForwardPreview = async () => {
    const { data } = await api.get<ICompanyResponse>(
      `/companies/${user.company_id?._id}`,
    );
    if (data.cookie_bars.length === 0 && isBonded) {
      setNotCreatedCookieBar(true);
    } else {
      setShowPublish(true);
    }
  };

  const handleBackwardPreview = () => {
    setShowPreview(false);
    // Scroll to top
    pageTopRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleForwardPublish = (hasCookieBar: boolean) => {
    if (hasCookieBar) {
      history.push('/atividades/corporativo');
    } else {
      history.push('/atividades/corporativo');
      setShowCreateCookie(true);
    }
  };

  const replacePolicyHTML = (
    _policy_url: string,
    _company_name: string,
    _basic_data: string[],
    _day_to_day_data: string[],
    _business_data: string[],
    _financial_data: string[],
    _sensitive_data: string[],
    _dpo_data: string[],
    _policy_creation_date: string,
    _policy_edition_date: string,
    _completeHTML: boolean,
  ) => {
    const _policyCreationDate = _policy_creation_date ? formatDate(new Date(_policy_creation_date)) : formatDate(new Date());
    let _policyEditionDate = _policy_edition_date ? formatDate(new Date(_policy_edition_date)) : '';

    if (_policyCreationDate === _policyEditionDate)
      _policyEditionDate = '';

    let hOne = templateSectionOne;
    let hTwo = templateSectionTwo;
    let hFive = templateSectionFive;
    let hEight = templateSectionEight;
    let hNine = templateSectionNine;

    let mainHTML = '';

    if (_completeHTML) {
      mainHTML += initialHtmlTemplate;
    }

    mainHTML += '<h1>POLÍTICA DE PRIVACIDADE E USO DE COOKIES</h1>';
    mainHTML += `<h2>${_company_name}</h2>`;

    // Table
    if (_policyCreationDate) {
      let hTable = `
<table>
  <tr>
    <td>Data da entrada em vigor</td>
    <td>${_policyCreationDate}</td>
  </tr>`;

      if (_policyEditionDate) {
        hTable += `
  <tr>
    <td>Data da última alteração</td>
    <td>${_policyEditionDate}</td>
  </tr>
        `;
      }
      hTable += '</table>';
      mainHTML += hTable;
    }

    // 1. SOBRE A POLÍTICA DE PRIVACIDADE DE DADOS
    hOne = hOne.replace(/\/\*replace_policy_url\*\//g, _policy_url);
    hOne = hOne.replace(/\/\*replace_company_name\*\//g, _company_name);
    // hOne = hOne.replace(/\/\*replace_tipo_document\*\//g, _tipo_document);
    // hOne = hOne.replace(/\/\*replace_company_document\*\//g, _company_document);
    mainHTML += hOne;

    // 2.COMO E QUAIS DADOS RECOLHEMOS DOS USUÁRIOS E DO VISITANTES
    hTwo = hTwo.replace(/\/\*replace_company_name\*\//g, _company_name);

    // DADOS DE IDENTIFICAÇÃO DO TITULAR
    if (_basic_data.length > 0) {
      let templateArticleOne;
      templateArticleOne = `
<article>
  <p>
    <strong>DADOS DE IDENTIFICAÇÃO DO TITULAR:</strong>
    <span>São dados de identificação básicos. A partir deles, podemos identificar o usuário e o visitante, além de garantir maior segurança e bem-estar às suas necessidades. Ficam cientes os usuários e visitantes de que esses dados não serão compartilhados sem seu consentimento prévio. A esse grupo pertencem:</span>
  </p>

  <ul>`;

      if (_basic_data.find(b => b === 'Nome completo')) {
        templateArticleOne += '<li>Nome completo</li>';
      }
      if (_basic_data.find(b => b === 'Endereço completo')) {
        templateArticleOne += '<li>Endereço completo</li>';
      }
      if (_basic_data.find(b => b === 'Telefone / Celular')) {
        templateArticleOne += '<li>Telefone / Celular</li>';
      }
      if (_basic_data.find(b => b === 'E-mail')) {
        templateArticleOne += '<li>E-mail</li>';
      }
      if (_basic_data.find(b => b === 'Profissão')) {
        templateArticleOne += '<li>Profissão</li>';
      }
      if (_basic_data.find(b => b === 'Idade')) {
        templateArticleOne += '<li>Idade</li>';
      }

      templateArticleOne += '</ul></article>';

      hTwo += templateArticleOne;
    }

    // DADOS SOBRE INTERAÇÃO
    if (_day_to_day_data.length > 0) {
      let templateArticleTwo;
      templateArticleTwo = `
<article>
  <p>
    <strong>DADOS SOBRE INTERAÇÃO:</strong>
    <span>Quando um usuário e visitante acessa nosso site ou aplicativos as informações sobre interação e acesso são coletadas pela para garantir uma melhor experiência ao usuário e visitante. Estes dados podem tratar:</span>
  </p>

  <ul>`;

      if (
        _day_to_day_data.find(
          d => d === 'Palavras-chaves utilizadas em uma busca',
        )
      ) {
        templateArticleTwo +=
          '<li>Palavras-chaves utilizadas em uma busca</li>';
      }
      if (
        _day_to_day_data.find(
          d => d === 'Compartilhamento de documento específico',
        )
      ) {
        templateArticleTwo +=
          '<li>Compartilhamento de documento específico</li>';
      }
      if (
        _day_to_day_data.find(d => d === 'Comentários e avaliações sobre algo')
      ) {
        templateArticleTwo += '<li>Comentários e avaliações sobre algo</li>';
      }
      if (_day_to_day_data.find(d => d === 'Páginas visualizadas')) {
        templateArticleTwo += '<li>Páginas visualizadas</li>';
      }
      if (_day_to_day_data.find(d => d === 'Perfis e preferências')) {
        templateArticleTwo += '<li>Perfis e preferências</li>';
      }
      if (_day_to_day_data.find(d => d === 'URL de onde o usuário e visitante vieram﻿﻿﻿﻿')) {
        templateArticleTwo +=
          '<li>URL de onde o usuário e visitante vieram﻿﻿﻿﻿</li>';
      }
      if (_day_to_day_data.find(d => d === 'Navegador que utilizam')) {
        templateArticleTwo += '<li>Navegador que utilizam</li>';
      }
      if (
        _day_to_day_data.find(d => d === 'IPs de acesso, dentre outras que poderão ser armazenadas e retidas')
      ) {
        templateArticleTwo +=
          '<li>IPs de acesso, dentre outras que poderão ser armazenadas e retidas</li>';
      }

      templateArticleTwo += '</ul></article>';

      hTwo += templateArticleTwo;
    }

    // DADOS COMERCIAIS
    if (_business_data.length > 0) {
      let templateArticleThree;
      templateArticleThree = `
<article>
  <p>
    <strong>DADOS COMERCIAIS:</strong>
    <span>São aqueles relacionados à empresa a qual o visitante ou usuário está ligado e são utilizados apenas para questões comerciais entre essa e a ${_company_name}. A esse grupo pertencem:</span>
  </p>

  <ul>`;

      if (_business_data.find(b => b === 'Empresa em que trabalha')) {
        templateArticleThree += '<li>Empresa em que trabalha</li>';
      }
      if (_business_data.find(b => b === 'Endereço comercial completo')) {
        templateArticleThree +=
          '<li>Endereço comercial completo</li>';
      }
      if (_business_data.find(b => b === 'E-mail(s) profissional(is)')) {
        templateArticleThree += '<li>E-mail(s) profissional(is)</li>';
      }
      if (_business_data.find(b => b === 'Número do(s) telefone(s)')) {
        templateArticleThree +=
          '<li>Número do(s) telefone(s)</li>';
      }
      if (_business_data.find(b => b === 'CNPJ')) {
        templateArticleThree += '<li>CNPJ</li>';
      }

      templateArticleThree += '</ul></article>';

      hTwo += templateArticleThree;
    }

    // DADOS PROVENIENTES DE TERCEIROS
    const templateArticleFour = `
<article>
  <p>
    <strong>DADOS PROVENIENTES DE TERCEIROS:</strong>
    <span>Por intermédio de terceiros, como Facebook, Google, etc, a plataforma recebe dados quando um usuário faz login com o seu perfil de um desses sites. A utilização desses dados é autorizada previamente pelos usuários junto ao terceiro em questão.</span>
  </p>
</article>
    `;
    hTwo += templateArticleFour;

    // INFORMAÇÕES PARA CRIAÇÃO DE CONTA E PERFIL
    const templateArticleFive = `
<article>
  <p>
    <strong>INFORMAÇÕES PARA CRIAÇÃO DE CONTA E PERFIL:</strong>
    <span>Qualquer informação que seja necessária para permitir o acesso a contas ou aplicações específicas, dentro do ambiente da ${_company_name}. Fazem parte desse grupo:</span>
  </p>

  <ul>
    <li>E-mail e/ou ID</li>
    <li>Senha (em formato criptografado)</li>
    <li>Pergunta e resposta de segurança</li>
    <li>Dados biométricos</li>
  </ul>
</article>
    `;
    hTwo += templateArticleFive;

    // DADOS PARA CONSCRETIZAR TRANSAÇÕES FINANCEIRAS
    if (
      _financial_data.length > 0 &&
      !_financial_data.find(f => f === 'Não utilizo dados financeiros')
    ) {
      let templateArticleSix;
      templateArticleSix = `
<article>
  <p>
    <strong>DADOS PARA CONSCRETIZAR TRANSAÇÕES FINANCEIRAS:</strong>
    <span>Com intuito de realizar quaisquer tipos de transações financeiras disponíveis nos sites ou aplicativos da ${_company_name}, podemos coletar os seguintes dados:</span>
  </p>

  <ul>`;
      if (_financial_data.find(f => f === 'Dados sobre o cartão de crédito')) {
        templateArticleSix += '<li>Dados sobre o cartão de crédito</li>';
      }
      if (
        _financial_data.find(f => f === 'Endereço para cadastro em nota fiscal')
      ) {
        templateArticleSix += '<li>Endereço para cadastro em nota fiscal</li>';
      }
      if (_financial_data.find(f => f === 'Transação / Pagamento via Pix')) {
        templateArticleSix += '<li>Transação / Pagamento via Pix</li>';
      }
      if (_financial_data.find(f => f === 'CPF e/ou CNPJ')) {
        templateArticleSix += '<li>CPF e/ou CNPJ</li>';
      }
      if (
        _financial_data.find(
          f => f === 'Nome, agência e conta bancária para pagamentos diretos',
        )
      ) {
        templateArticleSix +=
          '<li>Nome, agência e conta bancária para pagamentos diretos</li>';
      }

      templateArticleSix += '</ul></article>';

      hTwo += templateArticleSix;
    }

    // DADOS RELACIONADOS A CONTRATOS
    const templateArticleSeven = `
<article>
  <p>
    <strong>DADOS RELACIONADOS A CONTRATOS:</strong>
    <span>diante da formalização do contrato de compra e venda ou de prestação de serviços entre a plataforma e o usuário e visitante poderão ser coletados e armazenados dados relativos à execução contratual, inclusive as comunicações realizadas entre a empresa e o usuário.</span>
  </p>
</article>
    `;
    hTwo += templateArticleSeven;

    // DADOS SENSÍVEIS
    if (
      _sensitive_data.length > 0 &&
      !_sensitive_data.find(f => f === 'Não utilizo dados sensíveis')
    ) {
      let templateArticleEight;
      templateArticleEight = `
<article>
  <p>
    <strong>DADOS SENSÍVEIS:</strong>
    <span>A plataforma poderá coletar os seguintes dados sensíveis dos usuários e visitantes:</span>
  </p>

  <ul>`;

      if (_sensitive_data.find(s => s === 'Origem étnica ou racial')) {
        templateArticleEight += '<li>Origem étnica ou racial</li>';
      }
      if (_sensitive_data.find(s => s === 'Opinião política')) {
        templateArticleEight += '<li>Opinião política</li>';
      }
      if (_sensitive_data.find(s => s === 'Convicção religiosa')) {
        templateArticleEight += '<li>Convicção religiosa</li>';
      }
      if (_sensitive_data.find(s => s === 'Dados relativos à saúde')) {
        templateArticleEight += '<li>Dados relativos à saúde</li>';
      }
      if (_sensitive_data.find(s => s === 'Dados biométricos')) {
        templateArticleEight += '<li>Dados biométricos</li>';
      }
      if (
        _sensitive_data.find(s => s === 'Dados relativos a orientação sexual')
      ) {
        templateArticleEight += '<li>Dados relativos a orientação sexual</li>';
      }

      templateArticleEight += '</ul></article>';

      hTwo += templateArticleEight;
    }
    hTwo += '</section>';
    mainHTML += hTwo;

    // 3. QUAL A FINALIDADES PARA A COLETA DOS DADOS
    mainHTML += templateSectionThree;

    // 4. POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS
    mainHTML += templateSectionFour;

    // 5. SEGURANÇA DOS DADOS PESSOAIS ARMAZENADOS
    if (
      _financial_data.length > 0 &&
      !_financial_data.find(f => f === 'Não utilizo dados financeiros')
    ) {
      hFive += `
  <div>
    <em>Os dados relativos a cartões de crédito são criptografados usando a tecnologia "secure socket layer" (SSL) que garante a transmissão de dados de forma segura e confidencial, de modo que a transmissão dos dados entre o servidor e o usuário ocorre de maneira cifrada e encriptada.</em>
  </div>
      `;
    }
    hFive += `
  <p>A plataforma não se exime de responsabilidade por culpa exclusiva de terceiro, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiros. O site se compromete a comunicar o usuário em caso de alguma violação de segurança dos seus dados pessoais.</p>
  <p>Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais. No entanto, podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo ou se você violar nossos Termos de Serviço.</p>
</section>
    `;
    mainHTML += hFive;

    // 6. COMPARTILHAMENTO DOS DADOS
    mainHTML += templateSectionSix;

    // 7. OS DADOS PESSOAIS ARMAZENADOS SERÃO TRANSFERIDOS A TERCEIROS?
    mainHTML += templateSectionSeven;
    // let hSeven = ;

    // 8. COOKIES OU DADOS DE NAVEGAÇÃO
    hEight = hEight.replace(/\/\*replace_company_name\*\//g, _company_name);
    mainHTML += hEight;

    // 9. CONSENTIMENTO
    if (_dpo_data.length > 0) {
      if (_dpo_data[0] && _dpo_data[1]) {
        // const aHtml = `<a href="mailto:${_dpo_data[1]}?subject=Política de Privacidade!&body=Olá ${_dpo_data[0]}, tudo bem?">${_dpo_data[1]}</a>`;
        hNine = hNine.replace(/\/\*replace_dpo_email\*\//g, _dpo_data[1]);
      }

      mainHTML += hNine;
    }

    // 10. ALTERAÇÕES PARA ESSA POLÍTICA DE PRIVACIDADE
    mainHTML += templateSectionTen;

    // 11. JURISDIÇÃO PARA RESOLUÇÃO DE CONFLITOS
    mainHTML += templateSectionEleven;

    if (_completeHTML) {
      mainHTML += `
    <div class="buttons">
      <div class="closePolicy">Entendi</div>
    </div>
  </div>
</div>

<!-- Scripts ============================= -->
<script>
</script>
      `;
    }

    return mainHTML;
  };

  useEffect(() => {
    setSaveStepsEnabled(policySteps.every(policy => policy.confirm === true));
  }, [policySteps]);

  return {
    stepsCompleted,
    setStepsCompleted,
    setStepsPublish,
    stepsPublish,
    url,
    loading,
    previewHTML,
    replacePolicyHTML,
    setUrl,
    handleCreateEditPolicy,
    cookies,
    setCookies,
    handleClickBegin,
    showExistingSiteUrlModal,
    showCookiesModal,
    handleClickShowCookies,
    handleClickForward,
    handleForwardSteps,
    handleForwardPreview,
    handleBackwardPreview,
    handleForwardPublish,
    requestSave,
    setRequestSave,
    policySteps,
    setPolicySteps,
    saveStepsEnabled,
    isBonded,
    setIsBonded,
    hasCookieBarBond,
    setHasCookieBarBond,
    isPolicyCompleted,
    notCreatedCookieBar,
    setNotCreatedCookieBar,
    handleCloseModalCreatedCookieBar,
    pageTopRef,
  };
};
