import { Checkbox } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { SketchPicker } from 'react-color';
import rgbHex from 'rgb-hex';

import { IoMdCheckboxOutline, IoIosArrowRoundForward, IoMdLink } from 'react-icons/io';
import { MdCheckBoxOutlineBlank, MdContentCopy } from 'react-icons/md';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { useCreateCookieBar } from '../../../../../hooks/useCreateCookieBar';

import { MaturityHeader } from '../../../../../components/MaturityHeader';
import { MaturityButton } from '../../../../../components/MaturityButton';
import { ServicesHeader } from '../../../../../components/ServicesHeader';
import { CookieCategories } from '../../../../../components/CookieCategories';
import RouteLeavingGuard from '../../../../../routes/RouteLeavingGuard';

import {
  Container,
  Content,
  Constructor,
  ConstructorURL,
  ConstructorBlock,
  ConstructorCard,
  ConstructorSubCard,
  ColorButton,
  Visualization,
  Modal,
  ModalCard,
  ButtomExa,
} from './styles';
import { ModuleBond } from '../../../../../components/ModuleBond';
import PublishPolicy from '../../../../../components/PublishPolicy';
import NotCreated from '../../../../../components/NotCreated';
import { GenericOverlay } from '../../../../../components/GenericOverlay';
import { ActivityIndicator } from '../../../../../components/MaturityButton/styles';

const FONT_OPTIONS = ['Arial', 'Roboto'];

export const CreateCookieBar: React.FC = () => {
  const {
    stepsCompleted,
    showBarPicker,
    url,
    barColor,
    fontColor,
    fontFamily,
    fontWeight,
    fontSize,
    generateHTML,
    loading,
    htmlCopied,
    cookies,
    setShowBarPicker,
    setUrl,
    setBarColor,
    setFontColor,
    setFontFamily,
    setGenerateHTML,
    handleCopyHTML,
    requestSave,
    setRequestSave,
    closeModal,
    handleCreateEditCookieBar,
    setCookies,
    isBonded,
    hasPolicyBond,
    setHasPolicyBond,
    notCreatedPolicy,
    setNotCreatedPolicy,
    showExistingSiteUrlModal,
    handleClickBegin,
    createPolicyEnabled,
  } = useCreateCookieBar();

  const colorPickerRef = useRef<HTMLDivElement>(null);
  const showHtmlRef = useRef<HTMLDivElement>(null);
  const history = useHistory();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        colorPickerRef.current &&
        !colorPickerRef.current.contains(event.target)
      ) {
        setShowBarPicker(false);
      }
      if (showHtmlRef.current && !showHtmlRef.current.contains(event.target)) {
        setGenerateHTML(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [colorPickerRef, setGenerateHTML, setShowBarPicker]);

  return (
    <Container>
      <MaturityHeader />
      <ServicesHeader
        pageTitle="Barra de Cookies"
        backRoute="/atividades/corporativo/barra-de-cookies"
      />

      <Content>
        <Constructor>
          {stepsCompleted < 2 && <h2>Informe a URL do seu site</h2>}
          {stepsCompleted > 1 && <h2>Personalize sua Barra de cookies</h2>}

          <ConstructorURL>
            {stepsCompleted < 2 && (
              <form onSubmit={(event) => {
                event.preventDefault()
                handleCreateEditCookieBar({
                  isCreation: true,
                  openPopup: false,
                })
              }}
              >
                <input
                  className="url-input first"
                  value={url.replace('https://', '')}
                  type="text"
                  placeholder="www.seusite.com.br"
                  onChange={e => setUrl(e.target.value)}
                  disabled={stepsCompleted > 1 || loading}
                />
                <MaturityButton
                  className="url-btn"
                  loading={loading}
                  type="button"
                  onClick={() =>
                    handleCreateEditCookieBar({
                      isCreation: true,
                      openPopup: false,
                    })
                  }
                >
                  Concluir
                </MaturityButton>
              </form>
            )}
            {stepsCompleted > 1 && (
              <input
                className="url-input second"
                value={url.replace('https://', '')}
                type="url"
                placeholder="www.seusite.com.br"
                onChange={e => setUrl(e.target.value)}
                disabled={stepsCompleted > 1}
              />
            )}
          </ConstructorURL>

          {stepsCompleted > 1 && (
            <CookieCategories
              cookies={cookies}
              setCookies={setCookies}
              showToggleSwitch
              showMoreDetailToggle
            />
          )}

          {stepsCompleted > 1 && (
            <ConstructorBlock>
              <ConstructorCard>
                <h2>Personalize sua Barra de cookies</h2>

                <ConstructorSubCard>
                  <div className="constructor__attributes__sub-card__row">
                    <span className="title--center">Cor da barra:</span>

                    <div className="constructor__attributes__sub-card__row__end">
                      <span>
                        <ButtomExa onClick={() => setShowBarPicker(prev => !prev)}>{barColor.toUpperCase()}</ButtomExa>
                      </span>
                      <ColorButton
                        className="bar-color"
                        color={barColor}
                        type="button"
                        onClick={() => setShowBarPicker(prev => !prev)}
                      />
                      {showBarPicker && (
                        <aside ref={colorPickerRef}>
                          <SketchPicker
                            onChangeComplete={(c) => setBarColor(`#${rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a)}`)}
                            color={barColor}
                          />
                        </aside>
                      )}
                    </div>
                  </div>

                  <div className="constructor__attributes__sub-card__row">
                    <span className="title--center">Fonte:</span>

                    <div className="constructor__attributes__sub-card__row__end">
                      {FONT_OPTIONS.map(font => (
                        <div key={font}>
                          <span className="ml-8" />
                          <Checkbox
                            icon={<MdCheckBoxOutlineBlank color="#707070" />}
                            checkedIcon={
                              <IoMdCheckboxOutline color="#707070" />
                            }
                            value={font}
                            onChange={() => setFontFamily(font)}
                            checked={fontFamily === font}
                          />
                          <button
                            type="button"
                            className="font-name"
                            onClick={() => setFontFamily(font)}
                          >
                            {font}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="constructor__attributes__sub-card__row">
                    <span className="title--center">Cor da fonte:</span>

                    <div className="constructor__attributes__sub-card__row__end">
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank color="#707070" />}
                        checkedIcon={<IoMdCheckboxOutline color="#707070" />}
                        value="#ffffff"
                        onChange={() => setFontColor('#ffffff')}
                        checked={fontColor === '#ffffff'}
                      />
                      <ColorButton
                        className="font-color"
                        color="#ffffff"
                        type="button"
                        onClick={() => setFontColor('#ffffff')}
                      />
                      <span className="ml-28" />
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank color="#707070" />}
                        checkedIcon={<IoMdCheckboxOutline color="#707070" />}
                        value="#000000"
                        onChange={() => setFontColor('#000000')}
                        checked={fontColor === '#000000'}
                      />
                      <ColorButton
                        className="font-color"
                        color="#000000"
                        type="button"
                        onClick={() => setFontColor('#000000')}
                      />
                    </div>
                  </div>
                </ConstructorSubCard>

                <Visualization
                  cookieBarColor={barColor}
                  fontFamily={fontFamily}
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                  fontColor={fontColor}
                >
                  <div className="tooltip">
                    {[1, 2, 3].map(t => (
                      <span key={`tooltip-${t}`} />
                    ))}
                  </div>

                  <div className="placeholder">
                    <div className="vt-block">
                      {[1, 2, 3].map(v => (
                        <span key={`vt-span-${v}`} />
                      ))}
                    </div>
                    <div className="hr-block">
                      {[1, 2, 3].map(h => (
                        <div key={`hr-span-${h}`} className="vt-sub-block">
                          <div className="avatar" />
                          <div className="vt-lines">
                            {[1, 2, 3, 4].map(l => (
                              <span key={`hr-span-${h}-line-${l}`} />
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="cookieBar">
                    <p>
                      {`Nós usamos cookies. Se você está de acordo, clique em "Aceitar tudo". \nClique em "Configurações" para conhecer os cookies coletados e preferências do site.
                      `}
                    </p>
                    <div className="buttons">
                      <div> Configurações </div>
                      <div> Aceitar tudo </div>
                    </div>
                  </div>
                </Visualization>

                {/* <Visual generateHTML={generateHTML}>
                  <div>
                    {generateHTML ? (
                      <MaturityButton
                        className="cookie-button"
                        onClick={() => setGenerateHTML(prev => !prev)}
                      >
                        OK
                      </MaturityButton>
                    ) : (
                      <MaturityButton
                        type="button"
                        className="cookie-button"
                        onClick={() => setGenerateHTML(prev => !prev)}
                      >
                        {`Copiar html </>`}
                      </MaturityButton>
                    )}
                  </div>
                </Visual> */}
              </ConstructorCard>
            </ConstructorBlock>
          )}

          {stepsCompleted > 1 && (
            <ModuleBond
              title="Política na Barra de cookies"
              description={
                !isBonded && !hasPolicyBond
                  ? "Ao habilitar essa opção, o link da Política de Privacidade e Cookies será adicionado ao lado do texto principal na Barra de cookies."
                  : "Sua Política está vinculada com a Barra de cookies. Esta ação só pode ser desfeita com a exclusão."
              }
              bond={hasPolicyBond}
              enabled={!isBonded && createPolicyEnabled}
              upgradeTag={!createPolicyEnabled}
              handleBondCallback={(bond) => setHasPolicyBond(bond)}
            />
          )}

          {stepsCompleted > 1 && (
            <ConstructorBlock>
              <div className="save-button-wrapper">
                <MaturityButton
                  className="cookie-button"
                  loading={loading}
                  onClick={() =>
                    handleCreateEditCookieBar({
                      isCreation: false,
                      openPopup: true,
                    })
                  }
                >
                  Salvar preferências
                </MaturityButton>
              </div>
            </ConstructorBlock>
          )}
        </Constructor>
      </Content>


      {showExistingSiteUrlModal && (
        <GenericOverlay>
          <ModalCard>
            <IoMdLink className="svg-link" />
            <article>
              <h2>{`Utilizaremos a mesma URL! (${url.replace('https://', '')})`}</h2>
              <p>
                O seu site já possui uma política, portanto,
                utilizaremos a mesma URL para prosseguir com a geração de
                barra de cookies.
              </p>
              <button type="button" onClick={handleClickBegin}>
                Começar
                {loading ? (
                  <ActivityIndicator>
                    <AiOutlineLoading3Quarters />
                  </ActivityIndicator>
                ) : (
                  <IoIosArrowRoundForward />
                )}
              </button>
            </article>
          </ModalCard>
        </GenericOverlay>
      )}

      {generateHTML && (
        <PublishPolicy
          origin="COOKIE_BAR"
          handleFinishCallback={closeModal}
          isCompleted={false}
        />
      )}

      {notCreatedPolicy && (
        <NotCreated
          title="Sua Barra de cookies foi gerada, mas você ainda não tem uma Política, portanto, vamos redirecionar você para gerar a Política."
          type="COOKIE_BAR"
          link="/atividades/corporativo/politicas/nova"
          handleCancel={() => setNotCreatedPolicy(false)}
        />
      )}

      <RouteLeavingGuard
        when={requestSave}
        navigate={path => history.push(path)}
        shouldBlockNavigation={() => requestSave}
        handleAction={() => setRequestSave(false)}
        icon="rightArrowCircle"
        title="Você esqueceu de salvar suas alterações, deseja sair da página mesmo assim?"
        message=" "
        closeMessage="Sair"
        successMessage="Continuar editando"
      />
    </Container>
  );
};
