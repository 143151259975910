import {
  Dispatch,
  useCallback,
  useEffect,
  useState,
  SetStateAction,
} from 'react';
import { useHistory } from 'react-router';

import { api } from '../services/api';
import { useAuth } from '../contexts/auth';
import { useToast } from '../contexts/toast';

import { ICompanyResponse } from '../types/ICompanyResponse';
import { IPolicy } from '../types/IPolicy';

const companyRespInit = {
  company: {
    _id: '',
    document: '',
    fantasy_name: '',
    createdAt: '',
    updatedAt: '',
  },
  cookie_bars: [],
  policies: [],
  users: [],
  dsrs: [],
  plan: null,
};

interface useManagePolicyReturn {
  policies: IPolicy[];
  showTooltip: boolean;
  setShowTooltip: Dispatch<SetStateAction<boolean>>;
  setPolicies: Dispatch<SetStateAction<IPolicy[]>>;
  onDelete: (policyId: string, straightFlow?: boolean, isBonded?: boolean) => Promise<void>;
  showDelCookiesModal: boolean;
  showPublishCookiesModal: boolean;
  showPublishPolicy: boolean;
  handleClickCloseDelCookies: () => void;
  handleClickShowPublishCookies: () => void;
  handleClosePublishCookies: () => void;
  handleShowPublishPolicy: () => void;
  handleClosePublishPolicy: () => void;
  cookieBarIdToBeCopied: string;
  tryFetchPolicy: boolean;
}

export const useManagePolicy = (): useManagePolicyReturn => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user, updateUser } = useAuth();

  const [policies, setPolicies] = useState<IPolicy[]>([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tryFetchPolicy, setTryFetchPolicy] = useState(false);

  // eslint-disable-next-line prettier/prettier
  const [companyResponse, setCompanyResponse] = useState<ICompanyResponse>(
    companyRespInit,
  );

  // Handles the flow of when deletin policy and publishing cookies again
  const [showDelCookiesModal, setShowDelCookiesModal] = useState(false);
  const [showPublishCookiesModal, setShowPublishCookiesModal] = useState(false);
  const [showPublishPolicy, setShowPublishPolicy] = useState(false);
  const [policyIdToBeDeleted, setPolicyIdToBeDeleted] = useState('');
  const [cookieBarIdToBeCopied, setCookieBarIdToBeCopied] = useState('');

  useEffect(() => {
    if (!user.company_id) history.goBack();
    try {
      const loadPolicies = async () => {
        const { data } = await api.get<ICompanyResponse>(
          `/companies/${user.company_id?._id}`,
        );
        if (data.cookie_bars && data.cookie_bars.length > 0) {
          data.company.hasCookieBar = true;
        }
        setCompanyResponse(data);
        setPolicies(data.policies);
        setTryFetchPolicy(true);
      };

      loadPolicies();
    } catch (error) {
      setTryFetchPolicy(true);
      addToast({
        type: 'error',
        title: 'Falha ao carregar',
        description: 'Ocorreu um erro ao carregar as Políticas',
      });
    }
  }, [history, user.company_id, addToast, policies.length]);

  const onDelete = useCallback(
    async (policyId: string, straightFlow = false, isBonded = false) => {
      setPolicyIdToBeDeleted(policyId);
      const policyFound = policies.find(policy => policy._id === policyId);

      try {
        await api.delete(`/policies/${policyId}`);
        setPolicies(prev => prev.filter(policy => policy._id !== policyId));
        setPolicyIdToBeDeleted('');

        updateUser({
          ...user,
          company_id: {
            ...user.company_id,
            hasPolicy: false,
            hasCookieBar: !isBonded,
            policy_slug: null
          },
        });

        let description;
        if (isBonded) {
          description = 'Política de privacidade e Barra de cookies excluídas com sucesso!';
        } else {
          description = 'Política de privacidade excluída com sucesso! Exclua a URL do seu site!';
        }

        addToast({
          type: 'success',
          title: 'Sucesso',
          description,
        });
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Falha ao Excluir',
          description: 'Ocorreu uma falha ao tentar excluir a Política',
        });
      }
    },
    [
      user,
      policies,
      updateUser,
      addToast,
    ],
  );

  const handleClickCloseDelCookies = () => {
    setShowDelCookiesModal(false);
  };

  const handleClickShowPublishCookies = () => {
    setShowDelCookiesModal(false);
    setShowPublishCookiesModal(true);
    onDelete(policyIdToBeDeleted, true);
  };

  const handleClosePublishCookies = () => {
    setShowPublishCookiesModal(false);
  };

  const handleShowPublishPolicy = () => {
    setShowPublishPolicy(true);
  };

  const handleClosePublishPolicy = () => {
    setShowPublishPolicy(false);
  };

  return {
    policies,
    showTooltip,
    setShowTooltip,
    setPolicies,
    onDelete,
    showDelCookiesModal,
    showPublishCookiesModal,
    showPublishPolicy,
    handleClickCloseDelCookies,
    handleClickShowPublishCookies,
    handleClosePublishCookies,
    handleShowPublishPolicy,
    handleClosePublishPolicy,
    cookieBarIdToBeCopied,
    tryFetchPolicy,
  };
};
