import { useState } from 'react';

export const usePolicyFormProgress = () => {
  const [stepsCompleted, setStepsCompleted] = useState(0);

  return {
    stepsCompleted,
    setStepsCompleted,
  };
};
