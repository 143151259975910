import React, { ButtonHTMLAttributes } from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { Container, ActivityIndicator } from './styles';

interface MaturityButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  light?: boolean;
  loading?: boolean;
  color?: string
}

export const MaturityButton: React.FC<MaturityButtonProps> = ({
  children,
  loading,
  disabled,
  color = "#333333",
  ...rest
}) => {
  return (
    <Container {...rest} disabled={loading || disabled} color={color}>
      {loading ? (
        <ActivityIndicator>
          <AiOutlineLoading3Quarters />
        </ActivityIndicator>
      ) : (
        children
      )}
    </Container>
  );
};
