import styled from 'styled-components';

export const ConfirmDelete = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 90%;
  z-index: 999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.5rem;
  background: var(--white);

  min-width: 11rem;
  min-height: 8rem;

  font-size: 0.85rem;
  text-align: center;

  padding: 1rem;

  box-shadow: 0px 3px 6px #00000029;

  p {
    color: var(--gray);
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    margin-top: 1.5rem;
    width: 100%;

    button {
      background: none;
      border: 1px solid var(--teal);
      color: var(--teal);
      padding: 0.5rem;
      border-radius: 0.5rem;
    }
  }
`;
