import styled, { css } from 'styled-components';
import { BarProps } from './index';

export const Container = styled.div<BarProps>`
  background-color: ${props => props.pathColor};
  border-radius: 1rem;

  width: 12.625rem;
  max-width: 19rem;

  height: 1.25rem;

  div {
    height: 1.25rem;
    border-radius: 1.25rem;
    width: ${props => `${props.value}%`};
    background-color: ${props => props.fillColor};
  }

  ${props =>
    props.vertical &&
    css`
      transform: rotate(180deg);
      max-height: 19rem;
      height: 12.625rem;

      width: 1.25rem;

      border-radius: 1.25rem;

      div {
        width: 1.25rem;
        border-radius: 1.25rem;
        height: ${`${props.value}%`};
        background-color: ${props.fillColor};
      }
    `}
`;
