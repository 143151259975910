import styled, { css } from 'styled-components';

interface Props {
  animation?: boolean;
  confirm?: boolean;
  show?: boolean;
  lastPosition?: boolean;
}

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
`;

export const Line = styled.hr`
  border: 1px solid var(--teal);
  position: absolute;
  height: 100%;
  left: 15px;

  @media screen and (min-width: 768px) {
    left: 22px;
  }
  @media screen and (min-width: 992px) {
    left: 29px;
  }
`;

export const ListWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 37px;
`;

export const ConfirmWrapper = styled.div<Props>`
  position: absolute;
  width: 30px;
  margin-top: 15px;
  height: 30px;
  border: 1px solid #28bce0;
  background: ${props =>
    !props.confirm && !props.animation ? '#fff' : '#28bce0'};
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 768px) {
    width: 45px;
    height: 45px;
  }
  @media screen and (min-width: 992px) {
    width: 60px;
    height: 60px;
  }

  span {
    font-size: 20px;
    line-height: 24px;
    color: ${props => (props.animation ? '#fff' : '#28bce0')};

    @media screen and (min-width: 768px) {
      font-size: 24px;
      line-height: 28px;
    }
    @media screen and (min-width: 992px) {
      font-size: 28px;
      line-height: 32px;
    }
  }
`;

export const ContentWrapper = styled.div<Props>`
  width: 87%;
  border-radius: 20px;
  box-shadow: 0 3px 6px #00000029;
  background: #fff;
  padding: 10px 20px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  min-height: 60px;
  ${props =>
    props.animation &&
    css`
      height: auto;
    `};

  @media screen and (min-width: 768px) {
    margin-left: 60px;
    padding: 15px 25px;
    min-height: 70px;
  }
  @media screen and (min-width: 992px) {
    margin-left: 80px;
    padding: 19px 29px;
    min-height: 75px;
  }

  .content__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    min-width: unset;
    min-height: unset;
    margin-top: unset;
    padding: unset;
    border: unset;
    border-radius: unset;
    color: unset;
    font-size: unset;
    line-height: unset;
    background-color: unset;

    h3 {
      color: #2ac4ea;
      font-size: 20px;
      line-height: 24px;

      @media screen and (min-width: 768px) {
        font-size: 24px;
        line-height: 28px;
      }
      @media screen and (min-width: 992px) {
        font-size: 28px;
        line-height: 32px;
      }
    }

    img {
      cursor: pointer;
      transform: ${props =>
    !props.animation ? 'rotateX(180deg)' : 'rotateX(0deg)'};
      transition: transform 0.5s;
    }
  }

  .content__form {
    margin-top: 15px;
    transform: scale(0);
    ${props =>
    !props.animation
      ? css`
            transform: scaleY(0);
          `
      : css`
            transform: scaleY(1);
            transition: transform 0.5s;
          `};
  }

  .content__input {
    width: 100%;
    margin-top: 25px;
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    div {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 45px;
    }

    label {
      width: 100%;
      text-align: left;
      font-size: 18px;
      line-height: 25px;
      color: #707070;
      font-weight: 500;
    }

    input {
      width: 70%;
      height: 45px;
      margin: 5px auto 5px 0;
      border-bottom: 1px solid #707070;
    }

    .error {
      color: var(--red);
      width: 100%;
      text-align: left;
      font-size: 12px;
      line-height: 16px;
      min-height: 16px;
    }
  }

  .content__gridCheckbox {
    display: grid;
    grid-template-columns: 1fr;
    margin: 30px auto 0;

    @media screen and (min-width: 768px) {
      margin: 45px auto 0;
      grid-template-columns: 1fr 1fr;
      width: 80%;
    }
  }

  .content__checkboxContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    padding-right: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    line-height: 18px;
    color: #707070;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media screen and (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media screen and (min-width: 992px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .content__checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .content__gridCheckbox label {
    width: 100%;
    position: relative;
    left: -100px;
    
    @media screen and (max-width: 768px) {
      position: relative;
      left: 0;
    }
    @media screen and (min-width: 769px) and (max-width: 1120px) {
      position: relative;
      left: -60px;
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 21px;
    width: 21px;
    border-radius: 2px;
    background-color: #eee;
  }

  .content__checkboxContainer:hover input ~ .checkmark {
    background-color: #ccc;
  }

  .content__checkboxContainer input:checked ~ .checkmark {
    background-color: #2ac4ea;
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  .content__checkboxContainer input:checked ~ .checkmark:after {
    display: block;
  }

  .content__checkboxContainer .checkmark:after {
    left: 7px;
    top: 4px;
    width: 5px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  button {
    min-width: 85px;
    min-height: 33px;
    margin-top: 25px;
    padding: 10px 20px;
    border: 2px solid #28bce0;
    border-radius: 56px;
    color: #28bce0;
    font-size: 14px;
    line-height: 18px;

    @media screen and (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
    @media screen and (min-width: 992px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
`;
