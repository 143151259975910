import React, { Dispatch, SetStateAction, useState } from 'react';

import { FiCheckCircle, FiChevronUp, FiChevronDown } from 'react-icons/fi';

import { ICookie } from '../../types/ICookie';
import { MaturityButton } from '../MaturityButton';

import {
  Container,
  Card,
  CookieCat,
  CookieList,
  CookieItem,
  Switch,
} from './styles';

interface CookieCategoriesProps {
  cookies: ICookie[];
  showToggleSwitch?: boolean;
  showMoreDetailToggle?: boolean;
  showNextButton?: boolean;
  nextButtonCallback?: () => void;
  setCookies?: Dispatch<SetStateAction<ICookie[]>>;
}

interface CookieToggleCatRequired {
  event: React.ChangeEvent<HTMLInputElement>;
  category: string;
}

interface CookieToggleRequired {
  required: boolean;
  cookie: ICookie;
}

const moreDetailEnabledInitState = {
  needed: false,
  marketing: false,
  statistics: false,
  functional: false,
};

export const CookieCategories: React.FC<CookieCategoriesProps> = ({
  cookies,
  showToggleSwitch,
  showMoreDetailToggle,
  showNextButton,
  nextButtonCallback,
  setCookies,
}) => {
  const [moreDetailEnabled, setMoreDetailEnabled] = useState(
    moreDetailEnabledInitState,
  );

  const cookieCategoryRequired = (category: string): boolean => {
    return cookies
      .filter(c => c.category === category)
      .every(cookie => cookie.required === true);
  };

  const toggleCatRequired = ({ event, category }: CookieToggleCatRequired) => {
    const required = event.target.checked;
    cookies
      .filter(c => c.category === category)
      .forEach(cookie => toggleRequired({ required, cookie }));
  };

  const toggleRequired = ({ required, cookie }: CookieToggleRequired) =>
    setCookies(prev =>
      prev.map(element =>
        element.name === cookie.name ? { ...element, required } : element,
      ),
    );

  const handleMoreDetailEnabled = (category: string) =>
    setMoreDetailEnabled(prev => ({
      ...prev,
      [category]: !prev[category],
    }));

  return (
    <Container>
      <Card>
        <h2 className="mb-17">Categorias de cookies</h2>
        <p className="mb-30">
          Elencamos todos os cookies de seu site em categorias para facilitar a
          sua tomada de decisão, por padrão todos estão pré-definidos como
          obrigatório. Com exceção da categoria &quot;Necessários&quot;, todos
          os demais podem ser sinalizados como opcional, basta configurar na
          chavinha.
        </p>

        {!!cookies.filter(item => item.category === 'Needed').length && (
          <CookieCat className={showToggleSwitch ? 'state--disabled' : ''}>
            <header>
              <div>
                <FiCheckCircle />
                <h3>Necessários</h3>
              </div>
              {showToggleSwitch && (
                <Switch
                  checked={cookieCategoryRequired('Needed')}
                  disabled
                  onChange={event =>
                    toggleCatRequired({ event, category: 'Needed' })
                  }
                  color="primary"
                />
              )}
            </header>
            <p>
              Os cookies necessários são absolutamente essenciais para o
              funcionamento adequado do site. Esses cookies garantem
              funcionalidades básicas e recursos de segurança do site, de forma
              anônima.
            </p>
            {showMoreDetailToggle && (
              <CookieList
                className={
                  moreDetailEnabled.needed ? 'toggle--opened' : 'toggle--closed'
                }
              >
                <button
                  type="button"
                  onClick={() => handleMoreDetailEnabled('needed')}
                >
                  <h4>Mais detalhes</h4>
                  {moreDetailEnabled.needed ? (
                    <FiChevronUp />
                  ) : (
                    <FiChevronDown />
                  )}
                </button>

                <div
                  className={
                    moreDetailEnabled.needed
                      ? 'toggle--opened'
                      : 'toggle--closed'
                  }
                >
                  {cookies
                    .filter(item => item.category === 'Needed')
                    .map(item => (
                      <CookieItem key={`${item.name}-${item.domain}`}>
                        <span>{item.name}</span>
                        <span>{item.domain}</span>
                      </CookieItem>
                    ))}
                </div>
              </CookieList>
            )}
          </CookieCat>
        )}

        {!!cookies.filter(item => item.category === 'Marketing').length && (
          <CookieCat>
            <header>
              <div>
                <FiCheckCircle />
                <h3>Marketing</h3>
              </div>
              {showToggleSwitch && (
                <Switch
                  checked={cookieCategoryRequired('Marketing')}
                  onChange={event =>
                    toggleCatRequired({ event, category: 'Marketing' })
                  }
                  color="primary"
                />
              )}
            </header>
            <p>
              Os cookies de marketing ajudam a personalizar anúncios com base
              nos interesses dos usuários que são inferidos a partir do padrão
              de navegação.
            </p>
            {showMoreDetailToggle && (
              <CookieList
                className={
                  moreDetailEnabled.marketing
                    ? 'toggle--opened'
                    : 'toggle--closed'
                }
              >
                <button
                  type="button"
                  onClick={() => handleMoreDetailEnabled('marketing')}
                >
                  <h4>Mais detalhes</h4>
                  {moreDetailEnabled.marketing ? (
                    <FiChevronUp />
                  ) : (
                    <FiChevronDown />
                  )}
                </button>

                <div
                  className={
                    moreDetailEnabled.marketing
                      ? 'toggle--opened'
                      : 'toggle--closed'
                  }
                >
                  {cookies
                    .filter(item => item.category === 'Marketing')
                    .map(item => (
                      <CookieItem key={`${item.name}-${item.domain}`}>
                        <span>{item.name}</span>
                        <span>{item.domain}</span>
                      </CookieItem>
                    ))}
                </div>
              </CookieList>
            )}
          </CookieCat>
        )}

        {!!cookies.filter(item => item.category === 'Analytics').length && (
          <CookieCat>
            <header>
              <div>
                <FiCheckCircle />
                <h3>Estatísticos</h3>
              </div>
              {showToggleSwitch && (
                <Switch
                  checked={cookieCategoryRequired('Analytics')}
                  onChange={event =>
                    toggleCatRequired({ event, category: 'Analytics' })
                  }
                  color="primary"
                />
              )}
            </header>
            <p>
              Os cookies estatísticos são aqueles que permitem quantificar o
              numero de visitantes e fazer uma analise estatística sobre
              usuários e serviços. Desta forma, os estudos e a navegação do site
              melhoraram o suprimento dos produtos ou serviços ofertados.
            </p>
            {showMoreDetailToggle && (
              <CookieList
                className={
                  moreDetailEnabled.statistics
                    ? 'toggle--opened'
                    : 'toggle--closed'
                }
              >
                <button
                  type="button"
                  onClick={() => handleMoreDetailEnabled('statistics')}
                >
                  <h4>Mais detalhes</h4>
                  {moreDetailEnabled.statistics ? (
                    <FiChevronUp />
                  ) : (
                    <FiChevronDown />
                  )}
                </button>

                <div
                  className={
                    moreDetailEnabled.statistics
                      ? 'toggle--opened'
                      : 'toggle--closed'
                  }
                >
                  {cookies
                    .filter(item => item.category === 'Analytics')
                    .map(item => (
                      <CookieItem key={`${item.name}-${item.domain}`}>
                        <span>{item.name}</span>
                        <span>{item.domain}</span>
                      </CookieItem>
                    ))}
                </div>
              </CookieList>
            )}
          </CookieCat>
        )}

        {!!cookies.filter(item => item.category === 'Functional').length && (
          <CookieCat>
            <header>
              <div>
                <FiCheckCircle />
                <h3>Funcionais</h3>
              </div>
              {showToggleSwitch && (
                <Switch
                  checked={cookieCategoryRequired('Functional')}
                  onChange={event =>
                    toggleCatRequired({ event, category: 'Functional' })
                  }
                  color="primary"
                />
              )}
            </header>
            <p>
              Os cookies funcionais ajudam a executar certas funcionalidades,
              como compartilhar o conteúdo do site em plataformas de mídia
              social, coletar feedbacks e outros recursos de terceiros.
            </p>
            {showMoreDetailToggle && (
              <CookieList
                className={
                  moreDetailEnabled.functional
                    ? 'toggle--opened'
                    : 'toggle--closed'
                }
              >
                <button
                  type="button"
                  onClick={() => handleMoreDetailEnabled('functional')}
                >
                  <h4>Mais detalhes</h4>
                  {moreDetailEnabled.functional ? (
                    <FiChevronUp />
                  ) : (
                    <FiChevronDown />
                  )}
                </button>

                <div
                  className={
                    moreDetailEnabled.functional
                      ? 'toggle--opened'
                      : 'toggle--closed'
                  }
                >
                  {cookies
                    .filter(item => item.category === 'Functional')
                    .map(item => (
                      <CookieItem key={`${item.name}-${item.domain}`}>
                        <span>{item.name}</span>
                        <span>{item.domain}</span>
                      </CookieItem>
                    ))}
                </div>
              </CookieList>
            )}
          </CookieCat>
        )}

        {!cookies.length && <p>Não identificamos cookies neste site.</p>}

        {showNextButton && (
          <MaturityButton className="next-button" onClick={nextButtonCallback}>
            Seguir
          </MaturityButton>
        )}
      </Card>
    </Container>
  );
};
