import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { GenericCard } from '../../../../components/GenericCard';
import { GenericPage } from '../../../../components/GenericPage';
import { GenericInput } from '../../../../components/GenericInput';
import { api } from '../../../../services/api';
import { useToast } from '../../../../contexts/toast';
import { mapObjToArray } from '../../../../utils/mapObjToArray';

import { IPlan } from '../../../../types/IPlan';

const FORM_CONFIG = [
  {
    key: 'name',
    label: 'Nome',
    type: 'text'
  },
  {
    key: 'amount',
    label: 'Valor',
    type: 'number'
  },
  {
    key: 'admin_accounts',
    label: 'Contas Admin',
    type: 'number'
  },
  {
    key: 'normal_accounts',
    label: 'Contas Normais',
    type: 'number'
  },
  {
    key: 'cookie_bars',
    label: 'Barras de Cookies',
    type: 'number'
  },
  {
    key: 'cookie_bars_interactions',
    label: 'Interações',
    type: 'number'
  },
  {
    key: 'interactions_reports',
    label: 'Exportar interações',
    placeholder: 'Permite exportar interações',
    type: 'checkbox'
  },
  {
    key: 'policies',
    label: 'Políticas',
    type: 'number'
  },
  {
    key: 'chat_requests',
    label: 'Chamadas no chat',
    type: 'number'
  },
  {
    key: 'dsr_forms',
    label: 'Formulários DSR',
    type: 'number'
  },
  {
    key: 'dsr_management',
    label: 'Gestão do DSR',
    placeholder: 'Permite a gestão do DSR',
    type: 'checkbox'
  },
  {
    key: 'items',
    label: 'Descrição para LP',
    type: 'textarea'
  },
  {
    key: 'available',
    label: 'Disponibilidade',
    placeholder: 'Disponível ou não para contratar',
    type: 'checkbox'
  },
  {
    key: 'interval_type',
    label: 'Tipo de Intervalo',
    type: 'text'
  },
  {
    key: 'payment_frequency',
    label: 'Frequência de Pagamento',
    type: 'number'
  },
  {
    key: 'payment_method',
    label: 'Método de Pagamento',
    type: 'text'
  },
  {
    key: 'createdAt', 
    label: 'Data de criação',
    placeholder: 'dd/mm/yyyy',
    type: 'date'
  },
  {
    key: 'updatedAt', 
    label:  'Data da última alteração',
    placeholder:  'dd/mm/yyyy',
    type: 'date'
  }
];

export const ManagePlan: React.FC = () => {
  const { addToast } = useToast();
  const { id: planId } = useParams<{ id: string }>();

  const [plan, setPlan] = useState<IPlan>();
  const [planArray, setPlanArray] = useState([]);
  const [pageTitle, setPageTitle] = useState<string>('Plano');
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const loadPlan = async () => {
      try {
        setLoading(true);
        const { data } = await api.get<IPlan>(`/plans/${planId}`);

        if (data) {
          setPageTitle(data.name);

          setPlanArray(
            mapObjToArray(data).reduce((acc, curr) => {
              const found = FORM_CONFIG.find(c => c.key === curr.key);
              if (found) {
                acc.push({
                  ...curr,
                  ...found,
                })
              }

              return acc;
            }, [])
          );

          setPlan({
            _id: data._id,
            name: data.name,
            description: data.description,
            code: data.code,
            amount: data.amount,
            admin_accounts: data.admin_accounts,
            normal_accounts: data.normal_accounts,
            cookie_bars: data.cookie_bars,
            cookie_bars_interactions: data.cookie_bars_interactions,
            interactions_reports: data.interactions_reports,
            policies: data.policies,
            chat_requests: data.chat_requests,
            dsr_forms: data.dsr_forms,
            dsr_management: data.dsr_management,
            items: data.items,
            available: data.available,
            interval_type: data.interval_type,
            payment_frequency: data.payment_frequency,
            payment_method: data.payment_method,
          });
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Falha ao carregar',
          description: 'Ocorreu um erro ao carregar o Plano',
        });
      }
    };     
    if (planId) loadPlan();
  },[addToast, planId]);

  const handleChange = (field: string, event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    const planClone = _.clone(plan);
    const value = _.get(event, 'target.value', undefined);
    planClone[field] = value;
    setPlan(planClone);
  };
  
  const handleSubmit = useCallback(
    async (data) => {
      console.log(data)
    },
    [],
  );

  return (
    <GenericPage
      breadcrumb="BackOffice > Planos > "
      pageTitle={pageTitle}
      loading={loading}
    >
  
      {plan && (
        <GenericCard>
          <form onSubmit={handleSubmit} style={{width: 'inherit'}}>
            {plan.name && <h3>{plan.name}</h3>}

            {planArray.map(item => (
              <GenericInput
                dark
                key={item.key}
                id={item.key}
                name={item.key}
                type={item.type}
                label={item.label}
                placeholder={item.placeholder}
                value={plan[item.key]}
                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => handleChange(item.key, event)}
              />
            ))}
          </form>
        </GenericCard>
      )}
    </GenericPage>
  )
};
