import React from 'react';
import { BiRightArrowCircle } from 'react-icons/bi';
import { CgArrowLongLeft, CgArrowLongRight } from 'react-icons/cg';

import { Overlay, Modal, IconSlot, Header, Body, Footer } from './styles';

interface Props {
  title?: string;
  message?: string;
  hasClose: boolean;
  closeMessage?: string;
  closeAction: React.MouseEventHandler<HTMLButtonElement>;
  successMessage?: string;
  successAction: React.MouseEventHandler<HTMLButtonElement>;
  icon?: string | 'rightArrowCircle';
}

const GenericModal: React.FC<Props> = ({
  title,
  message,
  hasClose,
  closeMessage,
  closeAction,
  successMessage,
  successAction,
  icon,
}) => {
  const IconEl: { [index: string]: JSX.Element } = {
    rightArrowCircle: <BiRightArrowCircle />,
  };

  return (
    <Overlay>
      <Modal>
        {icon && (
          <IconSlot>
            {IconEl[icon]}
          </IconSlot>
        )}
        {title && (
          <Header>
            <h2>{title}</h2>
          </Header>
        )}
        {message && (
          <Body>
            <p>{message}</p>
          </Body>
        )}
        <Footer>
          {hasClose && (
            <button
              type="button"
              className="footer-button close"
              onClick={closeAction}
            >
              <CgArrowLongLeft />
              {closeMessage}
            </button>
          )}
          <button
            type="button"
            className="footer-button save"
            onClick={successAction}
          >
            {successMessage}
            <CgArrowLongRight />
          </button>
        </Footer>
      </Modal>
    </Overlay>
  );
};

GenericModal.defaultProps = {
  closeMessage: 'Não',
  successMessage: 'Sim',
};

export default GenericModal;
