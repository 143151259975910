import React from 'react';

import { Container } from './styles';

export interface BarProps {
  value?: number;
  fillColor: string;
  pathColor?: string;
  vertical?: boolean;
}

export const ProgressBar: React.FC<BarProps> = ({
  value = 0,
  fillColor,
  pathColor = '#eaecf4',
  vertical,
}) => {
  return (
    <Container
      pathColor={pathColor}
      fillColor={fillColor}
      value={value}
      vertical={vertical}
    >
      <div />
    </Container>
  );
};
