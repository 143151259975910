import React from 'react';

import { CheckoutSuccess} from '../../../components/CheckoutSuccess';

import { Container, Content } from './styles';
import { MaturityHeader } from '../../../components/MaturityHeader';

export const CheckoutConfirm: React.FC = () => {

    return (
      <Container>
        <MaturityHeader />
        <Content>
          <CheckoutSuccess />
        </Content>
      </Container>
    );
};
