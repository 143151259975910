import React from 'react';
import { useHistory } from 'react-router';
import { RiLogoutBoxRLine } from 'react-icons/ri';

import { MaturityButton } from '../MaturityButton';

import { Container, Content } from './styles';

import logo from '../../assets/logo.png';
import { useAuth } from '../../contexts/auth';

interface MaturityHeaderProps {
  sendToHome?: boolean;
}

export const MaturityHeader: React.FC<MaturityHeaderProps> = ({ sendToHome = true }) => {
  const history = useHistory();
  const { user, signOut } = useAuth();

  function handleSendRouter() {
    // if (sendToHome) {
    history.push('/atividades/corporativo');
    // } else {
    //   alert('absibdj')
    //   history.replace('https://privacyflow-landing-page.vercel.app/');
    // }
  }

  return (
    <Container>
      <Content>
        <button
          onClick={() => handleSendRouter}
          type="button"
        >
          <img src={logo} alt="Privacyflow" />
        </button>

        <aside>
          <a
            href={process.env.REACT_APP_CALENDLY_LINK}
            rel="noreferrer"
            target="_blank"
          >
            <MaturityButton>Agendar com especialista</MaturityButton>
          </a>

          {!!user && (
            
            <button type="button" onClick={() => signOut()} style={{marginLeft:'1rem', cursor:'pointer'}}>
              <RiLogoutBoxRLine size={30} />
            </button>
          )}
        </aside>
      </Content>
    </Container>
  );
};
