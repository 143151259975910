import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { api } from '../services/api';
import { useManageCookieBar } from './useManageCookieBar';
import { useAuth } from '../contexts/auth';

import { IInteraction } from '../types/IInteraction';

interface InteractionResponse {
  current_page: number;
  total_pages: number;
  interactions: IInteraction[];
  total_interactions: number;
  accepted_total: number;
  accepted_partial: number;
}

export const useCookiesPanel = () => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const { cookieBars } = useManageCookieBar();
  const { user } = useAuth();

  const [showDropdown, setShowDropdown] = useState(false);
  const [interactions, setInteractions] = useState<IInteraction[]>(
    [] as IInteraction[],
  );
  const [partial, setParcial] = useState(0);
  const [total, setTotal] = useState(0);
  const [accepteds, setAccepteds] = useState(0);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    const loadInteractions = async () => {
      if (!user.company_id) history.goBack();

      const response = await api.get(`/companies/${user.company_id?._id}`);
      /* const { data } = await api.get<IInteraction[]>(`/interactions?cookie_bar=${cookieBarId}`); */
      /* @ToDo: passar o ID da barra de Cookies */
      const { data } = await api.get<InteractionResponse>(
        `/interactions?limit=${itemsPerPage}&page=${currentPage}&cookie_bar=${response.data?.cookie_bars[0]?._id}`,
      );

      setInteractions(data.interactions);
      setTotalPages(data.total_pages);
      setTotal(data.total_interactions);
      setParcial(data.accepted_partial);
      setAccepteds(data.accepted_total);
    };

    loadInteractions();
  }, [currentPage, itemsPerPage]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return {
    dropdownRef,
    showDropdown,
    interactions,
    partial,
    total,
    accepteds,
    currentPage,
    totalPages,
    setShowDropdown,
    setCurrentPage,
  };
};
