import React, { useState, useEffect } from 'react'

import { MdFileDownload, MdContentCopy } from 'react-icons/md';

import { MaturityHeader } from '../../components/MaturityHeader';
import { ServicesHeader } from '../../components/ServicesHeader';

import { Container, Content } from "./styles";
import { useAuth } from '../../contexts/auth';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function CodFontPage() {
  const { user } = useAuth();
  const [copy, setCopy] = useState(false);
  const [cookieBarDownload, setCookieBarDownload] = useState(false);
  const [dsrDownload, setDsrDownload] = useState(false);
  const [hasChooseStatus, setHasChooseStatus] = useState<boolean>(false);

  useEffect(() => {
    if (hasChooseStatus) {
      setTimeout(() => {
        setCopy(false);
        setDsrDownload(false);
        setCookieBarDownload(false);
        setHasChooseStatus(false);
      }, 5000);
    }
  }, [hasChooseStatus]);

  async function handleCopy() {
    await navigator.clipboard.writeText(`${process.env.REACT_APP_PRIVACY}/slug/${user.company_id.policy_slug}`);
    setCopy(true);
    setHasChooseStatus(true);
  }

  function handleCookieBarDownload() {
    const iframe = `<script src="${process.env.REACT_APP_PRIVACY_API}/cookie_bars/token/${user.company_id.cookie_id}" async></script>`;
    const c = document.createElement("a");
    c.download = "codigo_html_gerado.txt";

    const t = new Blob([iframe], {
      type: "text/plain"
    });
    c.href = window.URL.createObjectURL(t);
    c.click();
    setCookieBarDownload(true);
    setHasChooseStatus(true);
  }

  function handleDsrDownload() {
    const script_description = "Inserir o script abaixo na raiz do seu projeto ou na página onde será implantado o DSR, dentro da tag <head> ou na tag <body>"
    const script = `<script src="${process.env.REACT_APP_PRIVACY_API || 'http://localhost:3333/api/v1'}/dsr/token/${user.company_id.dsr_id}" async></script>`;
    const quebra = "\n"
    const div_description = "Inserir a div no local em que será adicionado o DSR"
    const positin = `<div id="implementationDSR" />`
    const c = document.createElement("a");
    c.download = "codigo_html_gerado_dsr.txt";

    const t = new Blob([script_description,quebra,script,quebra,quebra,div_description, quebra, positin], {
      type: "text/plain"
    });
    c.href = window.URL.createObjectURL(t);
    c.click();
    setDsrDownload(true);
    setHasChooseStatus(true);
  }

  return (
    <>
      <MaturityHeader />
      <ServicesHeader pageTitle="Códigos gerados" />
      <Container>

        {user.company_id.cookie_id && (
          <Content onClick={handleCookieBarDownload}>
            <span>
              Código da Barra de cookies
            </span>
            <div>
              <MdFileDownload />
              <span>{cookieBarDownload ? 'Baixado' : 'Baixar'}</span>
            </div>
          </Content>
        )}

        {user.company_id.policy_slug && (
          <>
            <Content onClick={handleCopy}>
              <span>
                URL da Política de Privacidade
              </span>
              <div>
                <MdContentCopy />
                <span>{copy ? 'Copiada' : 'Copiar'}</span>
              </div>
            </Content>
            {copy && <p>URL foi copiada, basta inserir no seu navegador.</p>}
          </>
        )}

        {user.company_id.dsr_id && (
          <Content onClick={handleDsrDownload}>
            <span>
              Código do DSR
            </span>
            <div>
              <MdFileDownload />
              <span>{dsrDownload ? 'Baixado' : 'Baixar'}</span>
            </div>
          </Content>
        )}

        {!user.company_id.cookie_id && !user.company_id.dsr_id && !user.company_id.policy_slug && (
          <h3>
            Você ainda não criou sua barra de cookie, sua política de privacidade ou seu formulário DSR. 😁
          </h3>
        )}
      </Container>
    </>
  );
}