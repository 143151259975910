import styled, { css } from 'styled-components';
import { Switch as MUI_Switch, SwitchProps } from '@material-ui/core';

export const Container = styled.section`
  width: 100%;
  margin-top: 33px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .save-button-wrapper {
    width: 100%;
    max-width: 946px;
    display: flex;
    margin-top: 22px;
  }
`;

export const Card = styled.div`
  max-width: 946px;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .mb-17 {
    margin-bottom: 17px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .mb-73 {
    margin-bottom: 73px;
  }

  h2 {
    font: normal normal bold 14px/18px Nunito;
  }

  p {
    font: normal normal normal 12px/16px 'Mulish';
    letter-spacing: 0.62px;
    color: #949494;
  }

  .next-button {
    background: var(--white);
    color: var(--teal);
    border: 2px solid var(--teal);
    min-width: 200px;
    border-radius: 53px;
    font: normal normal bold 14px/18px 'Mulish';
    letter-spacing: 0px;
    padding: 10px 15px;

    &:hover {
      background-color: var(--teal);
      color: var(--white);
      box-shadow: 0px 6px 30px #00000029;
    }
  }

  @media screen and (min-width: 375px) {
    padding: 25px 25px;
  }
  @media screen and (min-width: 768px) {
    padding: 30px 45px;
    h2 {
      font: normal normal bold 16px/20px Nunito;
    }
    p {
      font: normal normal normal 14px/18px 'Mulish';
    }
    .next-button {
      padding: 12px 18px;
      font: normal normal bold 16px/20px 'Mulish';
    }
  }
  @media screen and (min-width: 992px) {
    padding: 40px 66px;
    h2 {
      font: normal normal bold 24px/28px Nunito;
    }
    p {
      font: normal normal normal 18px/22px 'Mulish';
    }
    .next-button {
      padding: 15px 20px;
      font: normal normal bold 20px/24px 'Mulish';
    }
  }
`;

export const CookieCat = styled.article`
  margin-bottom: 30px;

  &.state--disabled {
    header {
      cursor: not-allowed;

      div {
        h3 {
          opacity: 0.7;
        }
        svg {
          opacity: 0.7;
        }
      }

      .MuiSwitch-thumb {
        opacity: 0.7;
      }
    }
  }

  header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;

    div {
      display: flex;
      align-items: center;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 16px;
      }
    }

    h3 {
      font: normal normal bold 14px/18px 'Mulish';
      letter-spacing: 0.74px;
      color: #494949;
    }

    .MuiSwitch-root {
      padding: 7px;
      height: 30px;
    }

    .MuiSwitch-switchBase {
      top: -7px;
      left: -5px;
    }

    .MuiSwitch-thumb {
      width: 26px;
      height: 26px;
    }

    .MuiSwitch-track {
      width: 40px;
      height: 16px;
    }
  }

  p {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 768px) {
    header {
      h3 {
        font: normal normal bold 16px/20px 'Mulish';
      }
    }
  }
  @media screen and (min-width: 992px) {
    header {
      h3 {
        font: normal normal bold 24px/24px 'Mulish';
      }
    }
  }
`;

export const CookieList = styled.div`
  box-shadow: 0px 5px 5px #0000002b;
  border-radius: 10px;
  transition: max-height 1s linear;

  &.toggle--closed {
    max-height: 54px;
    overflow: hidden;
    padding: 15px 20px 10px 20px;
  }
  &.toggle--opened {
    max-height: 350px;
    overflow: auto;
    padding: 15px 20px 15px 20px;
  }

  button {
    background-color: inherit;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }

  h4 {
    font: normal normal bold 12px/16px 'Mulish';
    letter-spacing: 0.62px;
    color: #2ac4ea;
  }

  svg {
    width: 2em;
    height: 2em;
    color: #2ac4ea;
  }

  div {
    &.toggle--closed {
      transition: visibility 0.8s linear, opacity 0.8s linear;
      visibility: hidden;
      opacity: 0;
    }
    &.toggle--opened {
      transition: visibility 2s linear, opacity 2s linear;
      visibility: visible;
      opacity: 1;
    }
  }

  @media screen and (min-width: 768px) {
    h4 {
      font: normal normal bold 14px/18px 'Mulish';
    }
  }
  @media screen and (min-width: 992px) {
    h4 {
      font: normal normal bold 18px/22px 'Mulish';
    }
    &.toggle--closed {
      padding: 15px 30px 14px 38px;
    }
    &.toggle--opened {
      padding: 15px 24px 18px 38px;
    }
  }
`;

export const CookieItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  flex-direction: column;
  align-items: start;

  span {
    text-align: center;
    font: normal normal bold 10px/14px 'Mulish';
    letter-spacing: 0.17px;
    color: #494949;
    word-break: break-all;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    span {
      font: normal normal bold 12px/16px 'Mulish';
    }
  }
  @media screen and (min-width: 992px) {
    span {
      font: normal normal bold 18px/22px 'Mulish';
    }
  }
`;

export const Switch = styled(MUI_Switch)<SwitchProps>`
  && {
    ${props =>
      props.checked
        ? css`
            .MuiIconButton-label {
              color: #2ac4ea;
            }

            .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
              background-color: #2ac4ea;
            }
          `
        : css`
            .MuiSwitch-switchBase {
              color: #494949;
            }
          `}
  }
`;
