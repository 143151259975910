// Transforms the object into an array of objects with key and value
export const mapObjToArray = (obj) => {
  const arr = [];

  Object.keys(obj).forEach(key => {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      arr.push({
        key,
        value: obj[key]
      });
    }
  })

  return arr;
};
