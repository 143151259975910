/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { useHistory } from 'react-router';
import { Container, Content } from './styles';

interface ServicesHeaderProps {
  pageTitle: string;
  breadcrumb?: string;
  asideElement?: React.ReactNode;
  backRoute?: string;
}

export const ServicesHeader: React.FC<ServicesHeaderProps> = ({
  pageTitle,
  breadcrumb = 'Atividades > Corporativo > ',
  asideElement: AsideElement,
  backRoute,
}) => {
  const history = useHistory();

  const redirectRoute = (path = '/atividades/corporativo') => {
    history.push(path);
  };

  return (
    <Container>
      <Content>
        <div>
          <HiOutlineArrowNarrowLeft
            onClick={() => redirectRoute(backRoute)}
            style={{
              cursor: "pointer"
            }}
          />

          <h2 onClick={() => redirectRoute(backRoute)}>{pageTitle}</h2>
        </div>
        {AsideElement && <aside>{AsideElement}</aside>}
        <h4>{`${breadcrumb} ${pageTitle}`}</h4>
      </Content>
    </Container>
  );
};
