import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { useAuth } from '../contexts/auth';
import { useToast } from '../contexts/toast';
import { api } from '../services/api';
import { useCookieBarFormProgress } from './useCookieBarFormProgress';

import { ICookie } from '../types/ICookie';
import { ICookieBar } from '../types/ICookieBar';
import { ICompanyResponse } from '../types/ICompanyResponse';

interface CookieToggleRequired {
  required: boolean;
  cookie: ICookie;
}

interface CreateEditCookieBar {
  isCreation: boolean;
  openPopup: boolean;
}

interface CookieBarStyle {
  fontColor: string;
  barColor: string;
  fontFamily: string;
  cookies: ICookie[];
}

export const useCreateCookieBar = () => {
  const { id: cookieBarId } = useParams<{ id: string }>();

  const { addToast } = useToast();
  const { user, updateUser } = useAuth();
  const { setStepsCompleted, stepsCompleted } = useCookieBarFormProgress();
  const history = useHistory();

  const [showBarPicker, setShowBarPicker] = useState(false);
  const [enabledCookieBarCircle] = useState(false);

  const [barColor, setBarColor] = useState('');
  const [fontColor, setFontColor] = useState('');

  const [url, setUrl] = useState('');

  const [fontFamily, setFontFamily] = useState('');
  const [fontSize, setFontSize] = useState('12px');
  const [fontWeight, setFontWeight] = useState('bold');
  const [isBonded, setIsBonded] = useState(false);
  const [hasPolicyBond, setHasPolicyBond] = useState(false);
  const [notCreatedPolicy, setNotCreatedPolicy] = useState(false);
  const [createPolicyEnabled, setCreatePolicyEnabled] = useState(false);

  // Handles the flow of when the user already has the site url in the policy module
  // eslint-disable-next-line prettier/prettier
  const [showExistingSiteUrlModal, setShowExistingSiteUrlModal] = useState(false);
  // eslint-disable-next-line prettier/prettier
  const [showExistingSiteUrlModalOnce, setShowExistingSiteUrlModalOnce] = useState(false);

  const [showCookieTypes, setShowCookieTypes] = useState(false);
  const [generateHTML, setGenerateHTML] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasPolicyCreated, setHasPolicyCreated] = useState(false);
  const [requestSave, setRequestSave] = useState(false);
  const [replacedHtml, setReplacedHtml] = useState('');
  const [html, setHtml] = useState(
    `
    <!-- Styles ============================= -->
    <link
      href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
    <link href="https://fonts.googleapis.com/css2?family=Mulish:wght@400;700;900&display=swap" rel="stylesheet">
    <style type="text/css">
      .privacyflowCookieBar {
        background-color: /*replace_cookie_bar_background_color*/;
        color: /*replace_cookie_bar_color*/;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: 50px;
        padding: 15px;
        display: none;
        flex-direction: column;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        z-index: 9999999998;
      }
      .privacyflowCookieBar .privacyflowButtons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        padding: 0 30px;
      }
      .privacyflowCookieBar .privacyflowButtons div:last-child {
        border: 2px solid /*replace_cookie_bar_color*/;
        padding: 5px 20px;
        border-radius: 53px;
      }
      .privacyflowCookieBar p {
        font-family: /*replace_font_family*/;
        font-size: /*replace_font_size*/;
        line-height: /*replace_line_height*/;
        font-weight: /*replace_font_weight*/;
        color: /*replace_cookie_bar_color*/;
        letter-spacing: 0px;
        margin: 0 0 14px 0;
      }
      .privacyflowCookieBar .privacyflowButtons div {
        font-family: /*replace_font_family*/;
        font-size: /*replace_font_size*/;
        font-weight: /*replace_font_weight*/;
        color: /*replace_cookie_bar_color*/;
      }
      .privacyflowCookieBar p span, .privacyflowCookieBar p a {
        color: /*replace_cookie_bar_color*/;
        text-decoration: underline;
        cursor: pointer;
      }
      .privacyflowCookieBarCircle {
        background-color: /*replace_cookie_bar_background_color*/;
        position: fixed;
        display: none;
        padding: 4px;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: 0;
        right: 20px;
        left: auto;
        cursor: pointer;
        bottom: 20px;
        margin: 0;
        min-height: 0;
        z-index: 9999999998;
      }
      .privacyflowCookieBarCircle span {
        background-color: /*replace_cookie_bar_color*/;
        border-radius: 100%;
        width: 4px;
        height: 4px;
        margin: 0;
        padding: 0;
      }
      .privacyflowCookieModal {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: none;
        z-index: 9999999999;
        background-color: rgb(232 232 232 / 80%);
      }
      .privacyflowCookieModal .privacyflowCookieList {
        margin: auto;
        padding: 20px 10px;
        background-color: #FFFFFF;
        color: #949494;
        border-radius: 20px;
        width: 95vw;
        max-height: 85vh;
        overflow: auto;
      }
      .privacyflowCookieModal .privacyflowCookieList h2 {
        color: /*replace_cookie_bar_background_color*/;
        font: normal normal bold 14px/18px Nunito;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-header-left {
        display: flex;
        align-items: center;
      }
      .privacyflowCookieModal .privacyflowCookieList svg {
        width: 18px;
            height: 18px;
            margin-right: 16px;
      }
      .privacyflowCookieModal .privacyflowCookieList h3 {
        color: #494949;
        font: normal normal bold 14px/18px 'Mulish';
        margin-top: 10px;
      }
      .privacyflowCookieModal .privacyflowCookieList span {
        color: #949494;
        font: normal normal normal 12px/16px 'Mulish';
        letter-spacing: 0.62px;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-cookie-toggle span {
        color: #494949;
        font: normal normal bold 10px/14px 'Mulish';
        letter-spacing: 0.62px;
        word-break: break-all;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflowButtons,
      .privacyflowPolicyOverlay .previewModal .privacyflowButtons {
        margin: 10px;
        justify-content: start;
        display: flex;
        cursor: pointer;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflowButtons .privacyflowSave,
      .privacyflowPolicyOverlay .previewModal .privacyflowButtons .privacyflowClosePolicy {
        color: /*replace_cookie_bar_background_color*/;
        border: 2px solid /*replace_cookie_bar_background_color*/;
        padding: 10px 15px;
        border-radius: 53px;
        font: normal normal bold 14px/18px 'Mulish';
        line-height: 22px;
        margin-top: 18px;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group {
        box-shadow: 0px 5px 5px #0000002b;
        border-radius: 10px;
        transition: max-height 1s linear;
        margin-top: 27px;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group .privacyflow-detail-toggle {
        box-shadow: 0px 5px 5px #0000002b;
        border-radius: 10px;
        -webkit-transition: max-height 1s linear;
        transition: max-height 1s linear;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group .privacyflow-detail-toggle.privacyflow-toggle--closed {
        max-height: 60px;
        overflow: hidden;
        padding: 15px;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group .privacyflow-detail-toggle.privacyflow-toggle--opened {
        max-height: 350px;
        overflow: auto;
        padding: 15px;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group button {
        background-color: inherit;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;
        border: unset;
        padding: unset;
        cursor: pointer;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group button:focus {
        outline: unset;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group h4 {
        color: /*replace_cookie_bar_background_color*/;
        font: normal normal bold 12px/16px 'Mulish';
        letter-spacing: 0.62px;
        margin: unset;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group svg {
        color: /*replace_cookie_bar_background_color*/;
        display: none;
        width: 2em;
        height: 2em;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group svg.active {
        display: block;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group div div.privacyflow-toggle--closed {
        transition: visibility 0.8s linear, opacity 0.8s linear;
        visibility: hidden;
        opacity: 0;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group div div.privacyflow-toggle--opened {
        transition: visibility 2s linear, opacity 2s linear;
        visibility: visible;
        opacity: 1;
      }
      .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group div div div {
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
      }
      .privacyflow-switch-row {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        align-items: center;
      }
      .privacyflow-switch {
        position: relative;
        display: inline-block;
        width: 40px;
        height: 14px;
      }
      .privacyflow-switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .privacyflowSlider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #929398;
        -webkit-transition: .4s;
        transition: .4s;
      }
      .privacyflowSlider:before {
        position: absolute;
        content: "";
        height: 25px;
        width: 25px;
        top: -5px;
        left: 0px;
        bottom: 4px;
        background-color: #494949;
        -webkit-transition: .4s;
        transition: .4s;
      }
      input:checked + .privacyflowSlider {
        background-color: /*replace_cookie_bar_background_less_opacity*/80;
      }
      input:focus + .privacyflowSlider {
        box-shadow: 0 0 1px /*replace_cookie_bar_background_color*/;
      }
      input:checked + .privacyflowSlider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
        background-color: /*replace_cookie_bar_background_less_opacity*/FF;
      }
      input:disabled + .privacyflowSlider {
        opacity: 0.50;
        cursor: not-allowed;
      }
      .privacyflowSlider.privacyflowRound {
        border-radius: 34px;
      }
      .privacyflowSlider.privacyflowRound:before {
        border-radius: 50%;
      }
      @media screen and (min-width: 375px) {
        .privacyflowCookieBar {
          padding: 17px 39px;
        }
        .privacyflowCookieBar .privacyflowButtons {
          padding: 0 25px;
        }
        .privacyflowCookieBar .privacyflowButtons div:last-child {
          padding: 8px 30px;
        }
        .privacyflowCookieModal .privacyflowCookieList {
          padding: 48px 25px 34px;
          width: 85vw;
          max-height: 92vh;
        }
      }
      @media screen and (min-width: 768px) {
        .privacyflowCookieBar {
          padding: 22px 19px;
          flex-direction: row;
        }
        .privacyflowCookieBar p {
          margin: 0;
        }
        .privacyflowCookieBar .privacyflowButtons {
          padding: 0;
          max-width: 240px;
        }
        .privacyflowCookieModal .privacyflowCookieList {
          padding: 54px 35px;
          width: 75vw;
          max-height: 80vh;
        }
        .privacyflowCookieModal .privacyflowCookieList h2 {
          font: normal normal bold 16px/20px Nunito;
        }
        .privacyflowCookieModal .privacyflowCookieList svg {
          width: 20px;
          height: 20px;
        }
        .privacyflowCookieModal .privacyflowCookieList h3 {
          font: normal normal bold 16px/20px 'Mulish';
        }
        .privacyflowCookieModal .privacyflowCookieList span {
          font: normal normal normal 14px/18px 'Mulish';
        }
        .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group h4 {
          font: normal normal bold 14px/18px 'Mulish';
        }
        .privacyflowCookieModal .privacyflowCookieList .privacyflow-cookie-toggle span {
          font: normal normal bold 12px/16px 'Mulish';
        }
        .privacyflowCookieModal .privacyflowCookieList .privacyflowButtons .privacyflowSave,
        .privacyflowPolicyOverlay .previewModal .privacyflowButtons .privacyflowClosePolicy {
          padding: 12px 18px;
          font: normal normal bold 14px/18px 'Mulish';
          margin-top: 46px;
        }
        .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group .privacyflow-detail-toggle.privacyflow-toggle--closed {
          max-height: 60px;
          padding: 15px 20px;
        }
        .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group .privacyflow-detail-toggle.privacyflow-toggle--opened {
          padding: 15px 20px;
        }
        .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group div div div {
          flex-direction: row;
        }
      }
      @media screen and (min-width: 992px) {
        .privacyflowCookieBar {
          padding: 10px 30px;
        }
        .privacyflowCookieBar .privacyflowButtons {
          padding: 0 25px;
          max-width: 300px;
        }
        .privacyflowCookieModal .privacyflowCookieList {
          padding: 40px 66px;
          width: 76vw;
          max-height: 76vh;
        }
        .privacyflowCookieModal .privacyflowCookieList h2 {
          font: normal normal bold 24px/28px Nunito;
        }
        .privacyflowCookieModal .privacyflowCookieList svg {
          width: 24px;
          height: 24px;
        }
        .privacyflowCookieModal .privacyflowCookieList h3 {
          font: normal normal bold 24px/24px 'Mulish';
        }
        .privacyflowCookieModal .privacyflowCookieList span {
          font: normal normal normal 18px/22px 'Mulish';
        }
        .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group h4 {
          font: normal normal bold 18px/22px 'Mulish';
        }
        .privacyflowCookieModal .privacyflowCookieList .privacyflow-cookie-toggle span {
          font: normal normal bold 18px/22px 'Mulish';
        }
        .privacyflowCookieModal .privacyflowCookieList .privacyflowButtons .privacyflowSave,
        .privacyflowPolicyOverlay .previewModal .privacyflowButtons .privacyflowClosePolicy {
          padding: 15px 20px;
          font: normal normal bold 20px/24px 'Mulish';
        }
        .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group .privacyflow-detail-toggle.privacyflow-toggle--closed {
          padding: 15px 30px 14px 38px;
        }
        .privacyflowCookieModal .privacyflowCookieList .privacyflow-switch-group .privacyflow-detail-toggle.privacyflow-toggle--opened {
          padding: 15px 24px 18px 38px;
        }
      }
    </style>
    <!-- HTML ============================= -->
    <div class="privacyflowCookieBar">
      <p>
        Nós usamos cookies. Se você está de acordo clique em "Aceitar tudo".
        Clique em "Configurações" para conhecer os cookies coletados e preferências do site. /*replace_policy_text*/
      </p>
      <div class="privacyflowButtons">
        <div class="settings"> Configurações </div>
        <div class="acceptAll"> Aceitar tudo </div>
      </div>
    </div>
    <div class="privacyflowCookieBarCircle">
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="privacyflowCookieModal">
      <div class="privacyflowCookieList">
        <h2 class="header">Categorias de cookies</h2>
        /*replace_needed_header*/
        /*replace_needed_cookies*/
        /*replace_functional_header*/
        /*replace_functional_cookies*/
        /*replace_marketing_header*/
        /*replace_marketing_cookies*/
        /*replace_statistics_header*/
        /*replace_statistics_cookies*/
        <div class="privacyflowButtons">
          <div class="privacyflowSave">Salvar preferências</div>
        </div>
      </div>
    </div>
    <!-- Scripts ============================= -->
    <script>
    function getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    }
    function deleteCookie(cname, cvalue, cdomain) {
      document.cookie =
        cname +
        "=" +
        cvalue +
        ";" +
        "expires=Thu, 01 Jan 1970 00:00:00 UTC" +
        ";path=/";
      document.cookie =
        cname +
        "=" +
        cvalue +
        ";" +
        "expires=Thu, 01 Jan 1970 00:00:00 UTC" +
        ";path=/" +
        ";domain=" +
        cdomain;
    }
    function setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    function manageCookies(shouldSaveInteraction = false) {
      var privacyFlowCatCookies = getCookie("privacyflow_cookies");
      if (privacyFlowCatCookies) {
        var parsedCatCookieList = JSON.parse(privacyFlowCatCookies);
        var parsedCookieList = JSON.parse(allCookiesString);
        var parsedCatCookieObj;
        var parsedCookieObj;
        // Cookies
        for (var j = 0; j < parsedCookieList.length; j++) {
          // Categories
          for (var i = 0; i < parsedCatCookieList.length; i++) {
            if (typeof parsedCatCookieList[i] === "string") {
              console.error("Wrong interface for cookie:", parsedCatCookieList[i]);
            }
            if (parsedCookieList[j].category === parsedCatCookieList[i].name) {
              if (!parsedCatCookieList[i].accepted) {
                parsedCookieList[j].accepted = false;
                parsedCookieList[j].value = getCookie(parsedCookieList[j].name);
                deleteCookie(
                  parsedCookieList[j].name,
                  parsedCookieList[j].value,
                  parsedCookieList[j].domain
                );
              } else {
                parsedCookieList[j].accepted = true;
              }
            }
          }
        }
        if (shouldSaveInteraction) {
          saveInteraction(parsedCookieList);
        }
        displayCookieElement("privacyflowCookieModal", "close");
        displayCookieElement("privacyflowCookieBar", "close");
        displayCookieElement("privacyflowPolicyOverlay", "close");
        if (enabledCookieBarCircle === "true" || enabledCookieBarCircle === true) {
          displayCookieElement("privacyflowCookieBarCircle", "open");
        }
      } else {
        displayCookieElement("privacyflowCookieBar", "open");
      }
    }
    function saveInteraction(privacyflowCookieList) {
      try {
        if (ipInfoData && ipInfoData.ip) {
          var interaction = {
            cookie_bar_id: cookieBarId,
            ip_address: ipInfoData.ip,
            timezone: ipInfoData.timezone,
            location: {
              city: ipInfoData.city,
              region: ipInfoData.region,
              country: ipInfoData.country,
            },
            position: {
              latitude: ipInfoData.loc.split(",")[0],
              longitude: ipInfoData.loc.split(",")[1],
            },
            cookie_list: privacyflowCookieList,
          };
          var interactionStr = JSON.stringify(interaction);
          httpAsync(apiUrl + "interactions", "POST", handleInteractionsResponse, interactionStr);
        }
      } catch (error) {
        console.error(error);
      }
    }
    function handleSaveCookies() {
      var cookieArray = [];
      for (var i = 0; i < cookieInputElement.length; i++) {
        if (cookieInputElement[i].type === "checkbox") {
          var obj = {
            name: cookieInputElement[i].name,
            accepted: cookieInputElement[i].checked
          };
          cookieArray.push(obj);
        }
      }
      var cookieString = JSON.stringify(cookieArray);
      setCookie("privacyflow_cookies", cookieString, 30);
    }
    function displayCookieElement(cookieClass, action) {
      var el = document.getElementsByClassName(cookieClass)[0];
      if (!el) return;
      if (action) {
        if (action === "close") {
          el.style.display = "none";
          return;
        }
        if (action === "open") {
          el.style.display = "flex";
          return;
        }
      }
      if (el.style.display === "flex") {
        el.style.display = "none";
        return;
      }
      el.style.display = "flex";
    }
    function httpAsync(theUrl, method, callback, data) {
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.onreadystatechange = function() { 
        if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
          callback(xmlHttp.responseText);
        }
      }
      xmlHttp.open(method, theUrl, true);
      if (data) {
        xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
        xmlHttp.send(data);
      } else {
        xmlHttp.send(null);
      }
    }
    function handleIpInfoResponse(data) {
      ipInfoData = JSON.parse(data);
    }
    function handleInteractionsResponse(data) {
    }
    function toggleDetails(category) {
      var elDetail = document.getElementsByClassName("privacyflow-detail-toggle " + category)[0];
      if (elDetail.classList.value.includes("privacyflow-toggle--closed")) {
        elDetail.classList.remove("privacyflow-toggle--closed");
        elDetail.classList.add("privacyflow-toggle--opened");
        var elChevronDown = document.getElementsByClassName("chevron-down " + category)[0];
        elChevronDown.classList.remove("active");
        var elChevronUp = document.getElementsByClassName("chevron-up " + category)[0];
        elChevronUp.classList.add("active");
      } else {
        elDetail.classList.remove("privacyflow-toggle--opened");
        elDetail.classList.add("privacyflow-toggle--closed");
        var elChevronUp = document.getElementsByClassName("chevron-up " + category)[0];
        elChevronUp.classList.remove("active");
        var elChevronDown = document.getElementsByClassName("chevron-down " + category)[0];
        elChevronDown.classList.add("active");
      }
      var elCookie = document.getElementsByClassName("privacyflow-cookie-toggle " + category)[0];
      if (elCookie.classList.value.includes("privacyflow-toggle--closed")) {
        elCookie.classList.remove("privacyflow-toggle--closed");
        elCookie.classList.add("privacyflow-toggle--opened");
      } else {
        elCookie.classList.remove("privacyflow-toggle--opened");
        elCookie.classList.add("privacyflow-toggle--closed");
      }
    }
    var allCookiesString = '/*replace_cookie_list_string*/';
    var ipInfoData = {};
    var cookieBarId = "/*replace_cookie_bar_id*/";
    var apiUrl = "/*replace_api_endpoint*/";
    var enabledCookieBarCircle = "/*replace_enabled_cookie_bar_circle*/";
    var cookieListElement = document.getElementsByClassName("privacyflowCookieList")[0];
    if (cookieListElement) {
      var cookieInputElement = cookieListElement.getElementsByTagName("input");
    }
    document.addEventListener(
      "click",
      function (event) {
        if (event.target.matches(".acceptAll")) {
          event.preventDefault();
          handleSaveCookies();
          manageCookies(true);
        }
        if (event.target.matches(".privacyflowSave")) {
          event.preventDefault();
          handleSaveCookies();
          manageCookies(true);
        }
        if (event.target.matches(".settings")) {
          event.preventDefault();
          displayCookieElement("privacyflowCookieModal", "open");
          displayCookieElement("privacyflowPolicyOverlay", "close");
        }
        if (event.target.matches(".privacyflowCookieModal")) {
          event.preventDefault();
          displayCookieElement("privacyflowCookieModal", "close");
        }
        if (event.target.matches(".privacyflowCookieBarCircle")) {
          event.preventDefault();
          displayCookieElement("privacyflowCookieBar", "open");
          displayCookieElement("privacyflowCookieBarCircle", "close");
        }
        if (event.target.matches(".policyLink")) {
          event.preventDefault();
          displayCookieElement("privacyflowPolicyOverlay", "open");
          displayCookieElement("privacyflowCookieModal", "close");
        }
        if (event.target.matches(".privacyflowPolicyOverlay")) {
          event.preventDefault();
          displayCookieElement("privacyflowPolicyOverlay", "close");
        }
        if (event.target.matches(".privacyflowClosePolicy")) {
          event.preventDefault();
          displayCookieElement("privacyflowPolicyOverlay", "close");
        }
      },
      false
    );
    setTimeout(function () {
      httpAsync("https://ipinfo.io/json", "GET", handleIpInfoResponse);
    }, 1000);
    setTimeout(function () {
      manageCookies();
    }, 2000);
    </script>
    `,
  );
  const [htmlCopied, setHTMLCopied] = useState<boolean>(false);

  const [cookies, setCookies] = useState<ICookie[]>([]);
  const [databaseCookieBar, setDatabaseCookieBar] = useState<CookieBarStyle>(
    {} as CookieBarStyle,
  );

  const loadCookieBarForCopy = async (_cookieBarId: string) => {
    try {
      setLoading(true);
      const response = await api.get<ICookieBar>(
        `/cookie_bars/${_cookieBarId}`,
      );
      const h = replaceHTML(
        html,
        response.data._id,
        response.data.cookie_list,
        response.data.font_size,
        response.data.font_weight,
        response.data.font_family,
        response.data.cookie_bar_color,
        response.data.cookie_bar_background_color,
        response.data.enabledCookieBarCircle,
        !response.data.cookie_list.filter(item => !item.required).length,
        false,
        '',
      );
      navigator.clipboard.writeText(h);
      setHTMLCopied(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  async function verifyBond(): Promise<boolean> {
    setLoading(true);
    const response = await api.get(`/companies/${user.company_id._id}`)
    const company = response.data;
    setLoading(false);

    const policyQuant = _.get(company, 'plan.policies', 0);
    if (policyQuant > 0) {
      setCreatePolicyEnabled(true);
    }

    if (company.policies.length > 0 && company.policies[0].cookie_bar_bond) {
      setIsBonded(true)
      setHasPolicyBond(true)
      return true;
    }

    return false;
  }

  const loadCompanyData = async () => {
    try {
      setLoading(true);
      const { data } = await api.get<ICompanyResponse>(
        `/companies/${user.company_id?._id}`,
      );

      if (data && data.policies && data.policies.length > 0) {
        setUrl(data.policies[0].site_url);
        setCookies(data.policies[0].cookie_list);
        if (!showExistingSiteUrlModalOnce) {
          setShowExistingSiteUrlModal(true);
          setShowExistingSiteUrlModalOnce(true);
        }
        if (data.policies[0].cookie_bar_bond) {
          setIsBonded(true)
          setHasPolicyBond(true)
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      addToast({
        type: 'error',
        title: 'Falha ao carregar',
        description:
          'Ocorreu um erro ao carregar os dados da sua empresa',
      });
    }
  };

  useEffect(() => {
    setLoading(true);
    api.get(`companies/${user?.company_id._id}`)
      .then((response) => {
        const {
          policies
        } = response.data;
        setHasPolicyCreated(policies.length > 0);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
  }, [])

  const loadCookieBar = async (_cookieBarId: string) => {
    try {
      setLoading(true);
      const response = await api.get<ICookieBar>(
        `/cookie_bars/${_cookieBarId}`,
      );

      const {
        site_url,
        cookie_bar_color,
        cookie_bar_background_color,
        font_family,
        cookie_list,
        policy_bond
      } = response.data;

      setUrl(site_url);
      setFontColor(cookie_bar_color);
      setBarColor(cookie_bar_background_color);
      setFontFamily(font_family);
      setCookies(cookie_list);
      setIsBonded(!!policy_bond)
      setHasPolicyBond(!!policy_bond)

      setTimeout(() => {
        setRequestSave(false);
      }, 200);

      if (!isBonded || !policy_bond) {
        await verifyBond();
      }

      setDatabaseCookieBar({
        fontColor: cookie_bar_color,
        barColor: cookie_bar_background_color,
        fontFamily: font_family,
        cookies: cookie_list,
      });
      setLoading(false);
    } catch (error) {
      loadCompanyData();
      setLoading(false);
      setTimeout(() => {
        setRequestSave(false);
      }, 200);
    }
  };

  useEffect(() => {
    if (cookieBarId) {
      try {
        loadCookieBar(cookieBarId);
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Falha ao carregar dados',
          description:
            'Ocorreu um erro ao carregar os dados da barra de cookies',
        });
      }
    }
  }, [addToast, cookieBarId]);

  useEffect(() => {
    let stepCount = 0;

    if (url) stepCount += 1;
    if (fontFamily) stepCount += 1;
    if (showCookieTypes) stepCount += 1;

    setStepsCompleted(stepCount);
    setRequestSave(true);
    
  }, [
    url,
    barColor,
    fontColor,
    fontFamily,
    showCookieTypes,
    setStepsCompleted,
    cookies,
    addToast,
  ]);

  const toggleRequired = ({ required, cookie }: CookieToggleRequired) =>
    setCookies(prev =>
      prev.map(element =>
        element.name === cookie.name ? { ...element, required } : element,
      ),
    );

  const calcLineHeight = size => {
    if (!size) return '20px';
    const num = parseInt(size.replace('px', ''), 10) + 4;
    return `${num}px`;
  };

  const replaceHTML = (
    _html: string,
    _cookieBarId: string,
    _cookies: ICookie[],
    _fontSize: string,
    _fontWeight: string,
    _fontFamily: string,
    _fontColor: string,
    _barColor: string,
    _enabledCookieBarCircle: boolean,
    _isAllCookiesRequired: boolean,
    _hasPolicy: boolean,
    _policySlug: string,
  ) => {
    let h = _html;
    let neededCookiesHtml = '';
    let functionalCookiesHtml = '';
    let marketingCookiesHtml = '';
    let statisticsCookiesHtml = '';

    const cookiesClone = [];
    _cookies.forEach(c => cookiesClone.push(c));

    h = h.replace(/\/\*replace_cookie_bar_id\*\//g, _cookieBarId);
    h = h.replace(/\/\*replace_api_endpoint\*\//g, `${process.env.REACT_APP_PRIVACY_API}/`);
    h = h.replace(
      /\/\*replace_enabled_cookie_bar_circle\*\//g,
      `${_enabledCookieBarCircle}`,
    );
    h = h.replace(
      /\/\*replace_cookie_list_string\*\//g,
      JSON.stringify(cookiesClone),
    );

    let policyText = ''
    if (_hasPolicy) {
      policyText += 'Clique aqui para ver nossa ';

      if (_policySlug) {
        policyText += `
<a
  href="${_policySlug}"
  rel="nofollow"
  target="_blank"
  class="policySlug"
>
  Política de Privacidade
</a>
        `;
      } else {
        policyText += '<span class="policyLink">Política de Privacidade</span>.';
      }

      h = h.replace(/\/\*replace_policy_text\*\//g, policyText);
    } else {
      h = h.replace(/\/\*replace_policy_text\*\//g, '');
    }

    h = h.replace(/\/\*replace_font_size\*\//g, _fontSize);
    h = h.replace(/\/\*replace_line_height\*\//g, calcLineHeight(_fontSize));
    h = h.replace(/\/\*replace_font_weight\*\//g, _fontWeight);
    h = h.replace(/\/\*replace_font_family\*\//g, _fontFamily);
    h = h.replace(/\/\*replace_cookie_bar_color\*\//g, _fontColor);
    h = h.replace(/\/\*replace_cookie_modal_color\*\//g, _fontColor);
    h = h.replace(/\/\*replace_cookie_bar_background_color\*\//g, _barColor);
    h = h.replace(/\/\*replace_cookie_bar_background_less_opacity\*\//g, _barColor.substring(0, 7));
    h = h.replace(/\/\*replace_cookie_modal_background_color\*\//g, _barColor);

    cookiesClone.forEach(cookie => {
      if (cookie.category === 'Needed') {
        neededCookiesHtml += `
        <div><span>${cookie.name}</span><span>${cookie.domain}</span></div>
        `;
      } else if (cookie.category === 'Marketing') {
        marketingCookiesHtml += `
        <div><span>${cookie.name}</span><span>${cookie.domain}</span></div>
        `;
      } else if (cookie.category === 'Analytics') {
        statisticsCookiesHtml += `
        <div><span>${cookie.name}</span><span>${cookie.domain}</span></div>
        `;
      } else {
        functionalCookiesHtml += `
        <div><span>${cookie.name}</span><span>${cookie.domain}</span></div>
        `;
      }
    });

    // Os cookies necessários são absolutamente essenciais para o funcionamento adequado do site. Esses cookies garantem funcionalidades básicas e recursos de segurança do site, de forma anônima.
    if (neededCookiesHtml) {
      h = h.replace(
        /\/\*replace_needed_header\*\//g,
        `
        <div class="privacyflow-switch-row">
          <div class="privacyflow-header-left">
            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
            <h3>Necessários</h3>
          </div>
          <div
            class="switch-label"
            ${_isAllCookiesRequired ? 'style="display: none;"' : ''}
          >
            <label
              for="Needed"
              class="privacyflow-switch"
            >
              <input
                disabled
                type="checkbox"
                id="Needed"
                name="Needed"
                checked="checked"
              >
              <span class="privacyflowSlider privacyflowRound"></span>
            </label>
          </div>

        </div>
        <span>
          Os cookies necessários são absolutamente essenciais para o funcionamento adequado do site.
          Esses cookies garantem funcionalidades básicas e recursos de segurança do site,
          de forma anônima.
        </span>

        <div class="privacyflow-switch-group">
          <div class="privacyflow-detail-toggle Needed privacyflow-toggle--closed">
            <button type="button" onclick="toggleDetails('Needed')">
              <h4>Mais detalhes</h4>
              <svg class="chevron-down Needed active" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 12 15 18 9"></polyline></svg>
              <svg class="chevron-up Needed" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="18 15 12 9 6 15"></polyline></svg>
            </button>
            <div class="privacyflow-cookie-toggle Needed privacyflow-toggle--closed">
        `,
      );

      neededCookiesHtml += '</div></div></div>';
    } else {
      h = h.replace(/\/\*replace_needed_header\*\//g, '');
    }

    // Os cookies de marketing ajudam a personalizar anúncios com base nos interesses dos usuários que são inferidos a partir do padrão de navegação.
    if (marketingCookiesHtml) {
      h = h.replace(
        /\/\*replace_marketing_header\*\//g,
        `
        <div class="privacyflow-switch-row">
          <div class="privacyflow-header-left">
            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
            <h3>Marketing</h3>
          </div>
          <div
            class="switch-label"
            ${_isAllCookiesRequired ? 'style="display: none;"' : ''}
          >
            <label
              for="Marketing"
              class="privacyflow-switch"
            >
              <input
                ${cookieCategoryRequired(_cookies, 'Marketing') ? 'disabled' : ''}
                type="checkbox"
                id="Marketing"
                name="Marketing"
                checked="checked"
              >
              <span class="privacyflowSlider privacyflowRound"></span>
            </label>
          </div>
        </div>
        <span>
          Os cookies de Marketing ajudam a personalizar anúncios com base nos
          interesses dos usuários que são inferidos a partir do padrão de navegação.
        </span>

        <div class="privacyflow-switch-group">
          <div class="privacyflow-detail-toggle Marketing privacyflow-toggle--closed">
            <button type="button" onclick="toggleDetails('Marketing')">
              <h4>Mais detalhes</h4>
              <svg class="chevron-down Marketing active" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 12 15 18 9"></polyline></svg>
              <svg class="chevron-up Marketing" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="18 15 12 9 6 15"></polyline></svg>
            </button>
            <div class="privacyflow-cookie-toggle Marketing privacyflow-toggle--closed">
      `,
      );
      marketingCookiesHtml += '</div></div></div>';
    } else {
      h = h.replace(/\/\*replace_marketing_header\*\//g, '');
    }

    // Os cookies estatísticos são aqueles que permitem quantificar o numero de visitantes e fazer uma analise estatística sobre usuários e serviços. Desta forma, os estudos e a navegação do site melhoraram o suprimento dos produtos ou serviços ofertados.
    if (statisticsCookiesHtml) {
      h = h.replace(
        /\/\*replace_statistics_header\*\//g,
        `
        <div class="privacyflow-switch-row">
          <div class="privacyflow-header-left">
            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
            <h3>Estatísticos</h3>
          </div>
          <div
            class="switch-label"
            ${_isAllCookiesRequired ? 'style="display: none;"' : ''}
          >
            <label
              for="Analytics"
              class="privacyflow-switch"
            >
              <input
                ${cookieCategoryRequired(_cookies, 'Analytics') ? 'disabled' : ''}
                type="checkbox"
                id="Analytics"
                name="Analytics"
                checked="checked"
              >
              <span class="privacyflowSlider privacyflowRound"></span>
            </label>
          </div>
        </div>
        <span>
          Os cookies estatísticos são aqueles que permitem quantificar o
          numero de visitantes e fazer uma analise estatística sobre usuários
          e serviços. Desta forma, os estudos e a navegação do site melhoraram
          o suprimento dos produtos ou serviços ofertados.
        </span>

        <div class="privacyflow-switch-group">
          <div class="privacyflow-detail-toggle Analytics privacyflow-toggle--closed">
            <button type="button" onclick="toggleDetails('Analytics')">
              <h4>Mais detalhes</h4>
              <svg class="chevron-down Analytics active" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 12 15 18 9"></polyline></svg>
              <svg class="chevron-up Analytics" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="18 15 12 9 6 15"></polyline></svg>
            </button>
            <div class="privacyflow-cookie-toggle Analytics privacyflow-toggle--closed">
        `,
      );
      statisticsCookiesHtml += '</div></div></div>';
    } else {
      h = h.replace(/\/\*replace_statistics_header\*\//g, '');
    }

    // Os cookies funcionais ajudam a executar certas funcionalidades, como compartilhar o conteúdo do site em plataformas de mídia social, coletar feedbacks e outros recursos de terceiros.
    if (functionalCookiesHtml) {
      h = h.replace(
        /\/\*replace_functional_header\*\//g,
        `
        <div class="privacyflow-switch-row">
          <div class="privacyflow-header-left">
            <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
            <h3>Funcionais</h3>
          </div>
          <div
            class="switch-label"
            ${_isAllCookiesRequired ? 'style="display: none;"' : ''}
          >
            <label
              for="Functional"
              class="privacyflow-switch"
            >
              <input
                ${cookieCategoryRequired(_cookies, 'Functional') ? 'disabled' : ''}
                type="checkbox"
                id="Functional"
                name="Functional"
                checked="checked"
              >
              <span class="privacyflowSlider privacyflowRound"></span>
            </label>
          </div>
        </div>
        <span>
          Os cookies funcionais ajudam a executar certas funcionalidades,
          como compartilhar o conteúdo do site em plataformas de mídia social,
          coletar feedbacks e outros recursos de terceiros.
        </span>

        <div class="privacyflow-switch-group">
          <div class="privacyflow-detail-toggle Functional privacyflow-toggle--closed">
            <button type="button" onclick="toggleDetails('Functional')">
              <h4>Mais detalhes</h4>
              <svg class="chevron-down Functional active" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="6 9 12 15 18 9"></polyline></svg>
              <svg class="chevron-up Functional" stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><polyline points="18 15 12 9 6 15"></polyline></svg>
            </button>
            <div class="privacyflow-cookie-toggle Functional privacyflow-toggle--closed">
        `,
      );
      functionalCookiesHtml += '</div></div></div>';
    } else {
      h = h.replace(/\/\*replace_functional_header\*\//g, '');
    }

    h = h.replace(/\/\*replace_needed_cookies\*\//g, neededCookiesHtml);
    h = h.replace(/\/\*replace_functional_cookies\*\//g, functionalCookiesHtml);
    h = h.replace(/\/\*replace_marketing_cookies\*\//g, marketingCookiesHtml);
    h = h.replace(/\/\*replace_statistics_cookies\*\//g, statisticsCookiesHtml);

    return h;
  };

  const handleCopyHTML = () => {
    const h = replaceHTML(
      html,
      cookieBarId,
      cookies,
      fontSize,
      fontWeight,
      fontFamily,
      fontColor,
      barColor,
      enabledCookieBarCircle,
      isAllCookiesRequired,
      false,
      '',
    );
    navigator.clipboard.writeText(h);
    setHTMLCopied(true);
  };

  const closeModal = async (hasCookieBar: boolean, hasPolicy: boolean) => {
    setGenerateHTML(false);
    setHTMLCopied(false);

    if (hasPolicy) {
      history.push('/atividades/corporativo');
    } else if (hasPolicyBond && !hasPolicyCreated) {
      setNotCreatedPolicy(true);
    } else {
      history.push('/atividades/corporativo');
    }
  };

  const handleSaveCookieTypes = () => {
    setShowCookieTypes(prev => !prev);
  };

  // eslint-disable-next-line consistent-return
  const handleCreateEditCookieBar = async ({
    isCreation,
    openPopup,
  }: CreateEditCookieBar) => {
    try {
      const reqBody = {
        company_id: user.company_id._id,
        site_url: url,
        active: true,
        cookie_bar_background_color: isCreation ? '#000000' : barColor,
        cookie_bar_color: isCreation ? '#ffffff' : fontColor,
        cookie_modal_background_color: '',
        cookie_modal_color: '',
        font_family: isCreation ? 'Arial' : fontFamily,
        font_size: fontSize,
        font_weight: fontWeight,
        cookie_list: cookies,
        enabledCookieBarCircle: false,
      };

      if (!reqBody.site_url.includes('http://') && !reqBody.site_url.includes('https://')) {
        reqBody.site_url = `https://${reqBody.site_url}`;
      }

      setLoading(true);
      let response: any;

      if (isCreation) {
        response = await api.post('cookie_bars', reqBody);
        // Disabling request to save before the history replace
        setRequestSave(false);
        history.replace(`${response.data._id}`);

        setCookies(response.data?.cookie_list);
        setShowExistingSiteUrlModal(false);
      } else {
        response = await api.put(`cookie_bars/${cookieBarId}`, {
          ...reqBody,
          policy_bond: hasPolicyBond
        });

        setIsBonded(hasPolicyBond);
        if (openPopup) {

          if (hasPolicyBond && !hasPolicyCreated) {
            setNotCreatedPolicy(true);
          } else {
            setGenerateHTML(prev => !prev);
          }
          setReplacedHtml(
            replaceHTML(
              html,
              cookieBarId,
              cookies,
              fontSize,
              fontWeight,
              fontFamily,
              fontColor,
              barColor,
              enabledCookieBarCircle,
              isAllCookiesRequired,
              false,
              '',
            ),
          );
        }
      }

      setDatabaseCookieBar({
        fontColor,
        barColor,
        fontFamily,
        cookies,
      });
      setTimeout(() => {
        // Disabling request to save after setting the cookie bar data
        setRequestSave(false);
        setLoading(false);
      }, 200);

      updateUser({
        ...user,
        company_id: {
          ...user.company_id,
          cookie_id: cookieBarId,
          hasCookieBar: true,
          hasPolicy: hasPolicyCreated,
        },
      });

      let message = '';
      if (isCreation && !openPopup) {
        message = 'URL inserida com sucesso!';
      }
      if (!isCreation && !openPopup) {
        message = `
          Barra de Cookies salva com sucesso!
          Para copiar o HTML da Barra basta ir para o menu editar.
        `;
      }
      if (!isCreation && openPopup) {
        message = 'Barra de cookies salva com sucesso!';
      }

      addToast({
        type: 'success',
        title: 'Salvo',
        description: message,
      });
    } catch (error) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao salvar Barra de Cookies',
      });
    }
  };

  const isAllCookiesRequired = useMemo(() => {
    if (cookies) return !cookies.filter(item => !item.required).length;
    return false;
  }, [cookies]);

  const cookieCategoryRequired = (cookieList: ICookie[], category: string): boolean => {
    return cookieList
      .filter(c => c.category === category)
      .every(cookie => cookie.required === true);
  };

  const handleClickBegin = () => {
    handleCreateEditCookieBar({
      isCreation: true,
      openPopup: false,
    });
  };

  return {
    stepsCompleted,
    showBarPicker,
    url,
    html,
    replacedHtml,
    replaceHTML,
    barColor,
    fontColor,
    fontFamily,
    fontWeight,
    fontSize,
    generateHTML,
    loading,
    htmlCopied,
    cookies,
    showCookieTypes,
    setShowBarPicker,
    setUrl,
    setHtml,
    setBarColor,
    setFontColor,
    setFontFamily,
    setFontWeight,
    setFontSize,
    requestSave,
    setRequestSave,
    setGenerateHTML,
    setCookies,
    setShowCookieTypes,
    toggleRequired,
    handleCopyHTML,
    closeModal,
    handleSaveCookieTypes,
    handleCreateEditCookieBar,
    isAllCookiesRequired,
    loadCookieBarForCopy,
    isBonded,
    setIsBonded,
    hasPolicyBond,
    setHasPolicyBond,
    notCreatedPolicy,
    setNotCreatedPolicy,
    showExistingSiteUrlModal,
    handleClickBegin,
    createPolicyEnabled,
  };
};
