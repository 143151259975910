import React from 'react'
import { Link } from 'react-router-dom';

import { mapObjToArray } from '../../utils/mapObjToArray';

import {
  Table,
  Caption,
  TableHeader,
  RowHeader,
  CellHeader,
  TableBody,
  RowBody,
  CellBody,
  Actions,
  ActionButton,
} from './styles';

export const GenericTable: React.FC<GenericTableProps> = ({
  headerData,
  bodyData,
  actionData,
  title,
}) => {

  // Adds an interable counter as index for each object in the array
  const mapIdxToArray = arr => {
    let idx = 0;
    const newArray = arr.map(element => {
      idx += 1;

      return {
        idx,
        ...element,
      };
    });

    return newArray;
  }
  // Gets the data value, or applies a callback function over the data value
  const getCellValue = (data, header: IHeaderData) => {
    if (header.callback) {
      return header.callback(data.value);
    }

    return data.value
  };

  // Gets the action link, or applies a callback function over the link and the data value
  const getLinkValue = (action: IActionData | IHeaderData, data) => {
    if (action.linkCallback) {
      return action.linkCallback(action.link, data)
    }

    return action.link
  };

  // Gets the order from the header config
 
  const getHeadOrder = (key: string): number => {
    return headerData.find(h => h.key === key).order || 1;
  };

  // 
  const orderCompare = (a, b) => {
    if (getHeadOrder(a.key) < getHeadOrder(b.key)) {
      return -1;
    }
    if (getHeadOrder(a.key) > getHeadOrder(b.key)) {
      return 1;
    }
    return 0;
  };

  return (
    <Table>
      {title && (
        <Caption>{title}</Caption>
      )}
      {headerData && headerData.length > 0 && (
        <TableHeader>
          <RowHeader>
            {
              mapIdxToArray(headerData).map(header => (
                <CellHeader 
                  key={`h-cell-${header.idx}`}
                  width={header.width}
                  textAlign={header.textAlign}
                >
                  {header.label}
                </CellHeader>
              ))
            }

            {actionData && actionData.length > 0 && (
              <CellHeader
                key="actions-header"
                textAlign="center"
              >
                Ações
              </CellHeader>
            )}
          </RowHeader>
        </TableHeader>
      )}

      <TableBody>
        {
          mapIdxToArray(bodyData).map(row => (
            <RowBody key={`b-row-${row.idx}`}>
              {
                mapIdxToArray(mapObjToArray(row))
                  .filter(item => headerData.find(h => h.key === item.key))
                  .sort(orderCompare)
                  .map((cell, idx) => (
                    <CellBody
                      key={`b-cell-${cell.idx}`}
                      textAlign={headerData[idx].textAlign}
                    >
                      <>
                        {headerData[idx].linkCallback && (
                          <Link to={getLinkValue(headerData[idx], row)}>
                            <span>{getCellValue(cell, headerData[idx])}</span>
                          </Link>
                        )}
                        {!headerData[idx].linkCallback && (
                          <span>{getCellValue(cell, headerData[idx])}</span>
                        )}
                      </>
                    </CellBody>
                  )
                )
              }

              {actionData && actionData.length > 0 && (
                <CellBody key="actions-body">
                  <Actions>
                    {
                    mapIdxToArray(actionData)
                      .map(action => (
                        <>
                          {action.type === 'link' && action.link && (
                            <ActionButton
                              type="button"
                              key={`link-${action.idx}`}
                              bgColor={action.bgColor}
                              color={action.color}
                            >
                              <Link to={getLinkValue(action, row)}>
                                {action.label && (
                                  <span>{ action.label }</span>
                                )}
                              </Link>
                            </ActionButton>
                          )}

                          {action.type === 'button' && action.callback && (
                            <ActionButton
                              type="button"
                              key={`btn-${action.idx}`}
                              bgColor={action.bgColor}
                              color={action.color}
                              onClick={() => action.callback(row)}
                            >
                              {action.label && (
                                <span>{ action.label }</span>
                              )}
                            </ActionButton>
                          )}
                        </>
                      ))
                    }
                  </Actions>
                </CellBody>
              )}
            </RowBody>
          ))
        }
      </TableBody>
    </Table>
  )
}

interface IHeaderData {
  key: string;
  label: string;
  link?: string;
  order?: number;
  width?: string;
  textAlign?: string;
  callback?: (value: unknown) => string;
  linkCallback?: (link: string, data: unknown) => string;
}

interface IActionData {
  type: string;
  label?: string;
  icon?: string;
  link?: string;
  color?: string;
  bgColor?: string;
  callback?: (data: unknown) => void;
  linkCallback?: (link: string, data: unknown) => string;
}

interface GenericTableProps {
  headerData: IHeaderData[];
  bodyData: Array<unknown>;
  actionData?: IActionData[];
  title?: string;
  // config?: any;
}
