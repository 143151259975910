import React from 'react';
import { IconBaseProps } from 'react-icons';
import { useAuth } from '../../contexts/auth';

import { Container, Option } from './styles';
import { UpgradeTag } from '../UpgradeTag/styles';

export interface DropdownMenuOption {
  label: string;
  action(data: DropdownMenuOption): void;
  key?: string;
  icon?: React.ComponentType<IconBaseProps>;
  alternativeIcon?: JSX.Element;
  active?: boolean;
  upgradeTag?: boolean;
  blockedTag?: boolean;
  planCode?: string;
}

interface DropdownMenuProps {
  options: DropdownMenuOption[];
  big?: boolean;
}

export const DropdownMenu: React.FC<DropdownMenuProps> = ({ options, big }) => {

  const { user } = useAuth();

  const checkOptionWithUpgrade = (opts: DropdownMenuOption[]): boolean => {
    const upgFound = opts.find(o => o.upgradeTag);
    if (upgFound) {
      return true;
    }

    return false;
  };

  return (
    <Container big={checkOptionWithUpgrade(options)}>
      {options.map(
        option => (
          <Option
            key={option.label}
            big={big}
            active={option.active}
            upgradeTag={option.upgradeTag}
            blockedTag={option.blockedTag}
            onClick={() => option.action(option)}
          >
            {option.icon && <option.icon />}
            {option.alternativeIcon && option.alternativeIcon}
            <span>{option.label}</span>
            {option.upgradeTag && user.company_id.plan.code === "PLANO_PREMIUM" && (
              <UpgradeTag absolute big={big} blockedTag={option.blockedTag}>Esgotado</UpgradeTag>
            )}
            {option.upgradeTag && (user.company_id.plan.code === "PLANO_BASIC" || user.company_id.plan.code === "PLANO_FREE") && (
              <UpgradeTag absolute big={big}>Faça upgrade</UpgradeTag>
            )}
          </Option>
        ),
      )}
    </Container>
  );
};
