import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import _ from 'lodash';

import { useToast } from '../../contexts/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/NewInput';
import { MaturityButton as Button } from '../../components/MaturityButton';

import { Container, Content } from './styles';
import { MaturityHeader } from '../../components/MaturityHeader';
import { api } from '../../services/api';

interface ForgotData {
  email: string;
}

export const ForgotPass: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: ForgotData) => {
      try {
        const clonedData = _.clone(data);
        clonedData.email = clonedData.email.trim();

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail, válido')
            .required('E-mail obrigatório'),
        });

        await schema.validate(clonedData, { abortEarly: false });

        setLoading(true);

        await api.post(`auth/forgot_pass`, {
          email: clonedData.email,
        });

        setLoading(false);

        genericToast();

        formRef.current?.clearField('email');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        genericToast();
      }
    },
    [addToast],
  );

  const genericToast = () => {
    addToast({
      type: 'success',
      title: 'Pronto!',
      description:
        'Uma mensagem foi enviada ao seu email com instruções para atualizar a sua senha',
    });
  };

  return (
    <Container>
      <MaturityHeader />

      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Esqueci minha senha</h2>

          <p>Se você estiver cadastrado em nossa base de dados você receberá um e-mail para recuperação de senha.</p>

          <section>
            <Input name="email" placeholder="Digite seu email" />
          </section>

          <Button loading={loading} light>
            Concluir
          </Button>
        </Form>
      </Content>
    </Container>
  );
};
