import React from 'react';

import { GenericContainer } from '../GenericContainer';
import { GenericContent } from '../GenericContent';
import { GenericLoading } from '../GenericLoading';
import { MaturityHeader } from '../MaturityHeader';
import { ServicesHeader } from '../ServicesHeader';

interface GenericPageProps {
  loading: boolean;
  pageTitle: string;
  breadcrumb?: string;
}

export const GenericPage: React.FC<GenericPageProps> = ({
  loading,
  pageTitle,
  breadcrumb,
  children,
}) => {
  return (
    <GenericContainer>
      <MaturityHeader />
      <ServicesHeader
        breadcrumb={breadcrumb}
        pageTitle={pageTitle}
      />
      <GenericContent>
        <GenericLoading loading={loading} />

        {children}

      </GenericContent>
    </GenericContainer>
  )
};
