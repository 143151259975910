import React, { useState, useRef, useEffect, useCallback } from 'react';

import { IoMdMore } from 'react-icons/io';
import { GoPencil } from 'react-icons/go';
import { CgTrashEmpty } from 'react-icons/cg';
import { FiTrash2 } from 'react-icons/fi';

import { useHistory } from 'react-router-dom';
import { DropdownMenu } from '../DropdownMenu';

import { Container } from './styles';
import { ConfirmDelete } from '../ConfirmDelete/styles';
import { api } from '../../services/api';
import { useToast } from '../../contexts/toast';

import { useAuth } from '../../contexts/auth';
import { ModalMessageImportant } from '../ModalMessageImportant';

interface CookieBarProps {
  _id: string;
  backgroundColor: string;
  fontFamily: string;
  fontSize: string;
  fontWeight: string;
  fontColor: string;
  onDelete(cookieBarId: string, straightFlow?: boolean, isBonded?: boolean): void;
}

export const CookieBar: React.FC<CookieBarProps> = ({ _id, onDelete, ...rest }) => {
  const { user } = useAuth();
  const [companyName, setCompanyName] = useState(null);

  const history = useHistory();
  const { addToast } = useToast();
  const [openMenu, setOpenMenu] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [isBonded, setIsBonded] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenMenu(false);
        setOpenConfirmDelete(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleClickNo = () => {
    setOpenMenu(false);
    setOpenConfirmDelete(false);
  };

  const handleDeleteCookieBar = async () => {
    try {
      await api.delete(`/cookie_bars/${_id}`);
    } catch (error) {
      addToast({
        type: 'error',
        title: 'Falha ao Excluir',
        description: 'Ocorreu uma falha ao tentar excluir a Barra de Cookies',
      });
    }
    setOpenMenu(false);
    setOpenConfirmDelete(false);
    onDelete(_id, true, isBonded);
    setIsBonded(false);
  };

  const getCompanies = useCallback(async () => {

    const response = await api.get(`/companies/${user.company_id._id}`)
    const company = response.data;
    setCompanyName(response.data.company.fantasy_name);
    if (company.cookie_bars.length > 0 && company.cookie_bars[0].policy_bond) {
      return setIsBonded(true);
    }
    return setIsBonded(false);
  }, [user.company_id]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  return (
    <Container {...rest}>
      <div className="opacityBar">
        <p>
          Barra de cookies&nbsp;
          {companyName ?? 'Certsys'}
        </p>
      </div>
      <p className="cookie-bar-message">
        {`Nós usamos cookies.\tSe você está de acordo, clique em "Aceitar tudo".\nEscolha que tipos de cookies guardar em "Configurações".`}
      </p>
      <div className="buttons">
        <div className="settings"> Configurações </div>
        <div className="acceptAll"> Aceitar tudo </div>

        <button type="button" onClick={() => setOpenMenu(prev => !prev)}>
          <IoMdMore />
        </button>
      </div>

      <div ref={ref} className="dropdownContainer">
        {openMenu && (
          <DropdownMenu
            options={[
              {
                label: 'Editar barra',
                action: () => history.push(`barra-de-cookies/${_id}`),
                icon: GoPencil,
                active: true
              },
              {
                label: 'Excluir barra',
                action: () => setOpenConfirmDelete(true),
                icon: CgTrashEmpty,
                active: true
              },
            ]}
          />
        )}

        {openConfirmDelete && (
          isBonded ? (
            <ModalMessageImportant
              title="Um recado importante sobre esta ação:"
              content="Ao excluir a Barra de cookies, a Política também será excluída."
              handleSuccess={handleDeleteCookieBar}
              handleCancel={handleClickNo}
            >
              <FiTrash2 className="svg-link" />
            </ModalMessageImportant>
          ) : (
            <ConfirmDelete>
              <p>Tem certeza que deseja excluir essa Barra de cookies?</p>
              <footer>
                <button type="button" onClick={handleDeleteCookieBar}>
                  Sim
                </button>
                <button type="button" onClick={handleClickNo}>
                  Não
                </button>
              </footer>
            </ConfirmDelete>
          )
        )}
      </div>
    </Container>
  );
};
