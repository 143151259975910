/* eslint-disable no-alert */
import {
  KeyboardDatePicker, MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import ptBRLocale from "date-fns/locale/pt-BR";
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import React, { useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { IoMdMore } from 'react-icons/io';
import { RiCalendarLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import exportExcel from '../../../../../assets/newIcons/exportExcel.svg';
import exportPDF from '../../../../../assets/newIcons/exportPDF.svg';
import {
  DropdownMenu,
  DropdownMenuOption
} from '../../../../../components/DropdownMenu';
import { GenericOverlay } from '../../../../../components/GenericOverlay';
import { MaturityHeader } from '../../../../../components/MaturityHeader';
import { ServicesHeader } from '../../../../../components/ServicesHeader';
import { useAuth } from '../../../../../contexts/auth';
import { useDSRPanel } from '../../../../../hooks/useDSRPanel';
import { ActivityIndicator } from '../../../../MaturityLanding/styles';
import { ButtonHeader } from './ButtonHeader';
import {
  CardContainer,
  CardHeader,
  CardPercentage, Container,
  ContainerButtonHeader,
  Content, DropdownContainer, Header, MenuButton
} from './styles';


export const PanelDSR: React.FC = () => {
  const {
    dsrId,
    dropdownRef,
    showDropdown,
    interactions,
    total,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    setShowDropdown,
    loading,
    interactionsExcel
  } = useDSRPanel();

  const history = useHistory();
  const { user, controlPayment } = useAuth();

  async function controlPage() {
    const permission = await controlPayment(user);

    if (!permission?.dsr_management) {
      history.push('/atividades/corporativo');
    }
  }

  useEffect(() => {
    controlPage();
  }, []);

  /*  */
  const HeaderSideMenuOptions: DropdownMenuOption[] = [
    {
      label: 'Exportar para PDF',
      action: () => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF();
        const cols = ['Tipo de solicitação', 'Nomenclatura', 'Quantidade', 'Porcentagem'];

        autoTable(doc, {
          body: [['Data de Extração:', new Date().toLocaleString()]],
        });
        autoTable(doc, {
          head: [cols],
          body: interactionsExcel,
        });

        doc.save(`Painel de DSR_${new Date().getTime()}.pdf`);
      },
      alternativeIcon: <img src={exportPDF} alt="Exportar PDF" />,
    },
    {
      label: 'Exportar para Excel',
      action: () => document.getElementById('CSVLINK').click(),
      alternativeIcon: <img src={exportExcel} alt="Exportar Excel" />,
    },
  ];

  const HeaderSideMenuDropdown = (
    <DropdownContainer ref={dropdownRef}>
      <DropdownMenu big options={HeaderSideMenuOptions} />
    </DropdownContainer>
  );

  const HeaderSideMenu = (
    <>
      <MenuButton
        type="button"
        isOpen={showDropdown}
        onClick={() => setShowDropdown(prev => !prev)}
      >
        <IoMdMore />
        {showDropdown && HeaderSideMenuDropdown}
      </MenuButton>
      {HeaderSideMenuOptions && (
        <ContainerButtonHeader>
          {HeaderSideMenuOptions.map((option) => (
            <ButtonHeader key={option.label} option={option} />
          ))}
        </ContainerButtonHeader>
      )}
    </>
  );

  type Event = {
    target: {
      value: string
    }
  }
  const handleStartDateChange = (date) => {
    setStartDate(date);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  console.log(startDate, 'startDate')

  return (
    <Container>
      <MaturityHeader />
      <ServicesHeader
        pageTitle="Painel de DSR"
        asideElement={HeaderSideMenu}
      />
      <div>
        <h1>Painel Formulário de solicitação (DSR)</h1>
        <h2>Metrificação das interações com o formulário</h2>
      </div>
      {interactions && (
        <Content>
          <Header>

            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline"
                value={startDate}
                onChange={handleStartDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                keyboardIcon={<RiCalendarLine />}
              />
            </MuiPickersUtilsProvider>
            <span>até</span>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBRLocale}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                id="date-picker-inline2"
                value={endDate}
                onChange={handleEndDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date'
                }}
                keyboardIcon={<RiCalendarLine />}
              />
            </MuiPickersUtilsProvider>

          </Header>
          <CardContainer>
            <>
              <CardHeader>
                <h1>{total}</h1>
                <h2>Cliques totais no formulário</h2>
                <h3>
                  Estamos contabilizando o número de cliques e não o número de envios*
                </h3>
              </CardHeader>
              {!loading && (interactions.map((interaction) => (
                <CardPercentage key={interaction.type}>
                  <span>
                    {interaction.name}
                  </span>

                  <div>
                    <div>
                      <span style={{ color: '#2F46CF' }}>{interaction.count}</span>
                      <p>Cliques</p>
                    </div>

                    <div>
                      <span>
                        {interaction.percentage}
                      </span>
                      <p>Do total</p>
                    </div>
                  </div>
                </CardPercentage>
              )))}
              {
                loading && (
                  <GenericOverlay>
                    <ActivityIndicator style={{ width: "50px", height: "50px" }}>
                      <AiOutlineLoading3Quarters style={{
                        width: "50px",
                        height: "50px",
                        color: "var(--teal)"
                      }}
                      />
                    </ActivityIndicator>
                  </GenericOverlay>
                )
              }
            </>
          </CardContainer>
        </Content>
      )}

      {interactionsExcel && (
        <CSVLink
          id="CSVLINK"
          data={[
            [`Data de Extração: ${new Date().toLocaleString()}`],
            [],
            [],
            ['Tipo de solicitação', 'Nomenclatura', 'Quantidade', 'Porcentagem'],
            ...interactionsExcel,
          ]}
          separator=";"
          target="_parent"
          filename={`Painel de DSR_${new Date().getTime()}.csv`}
        />
      )}
    </Container>
  );
};
