import React, { useRef, useCallback, useState, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../contexts/toast';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/NewInput';
import { MaturityButton as Button } from '../../components/MaturityButton';

import { Container, Content } from './styles';
import { MaturityHeader } from '../../components/MaturityHeader';
import { api } from '../../services/api';

interface NewPasswordData {
  newPassword: string;
  confirmNewPassword: string;
}

export const ResetPassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const [token, setToken] = useState<string>('');
  const history = useHistory();
  const { addToast } = useToast();

  const getToken = useCallback(() => {
    const { search } = window.location;

    if (search) {
      const searches = search.split('?');
      const data = searches[searches.length - 1].split('=');
      return setToken(data[1]);
    }

    return history.push('/');
  }, [history]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: NewPasswordData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          newPassword: Yup.string().required('Nova senha obrigatória'),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'As senhas devem ser iguais')
            .required('Confirmação de senha é obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        setLoading(true);

        await api.post(`/auth/reset_pass`, {
          password: data.newPassword,
          token,
        });

        setLoading(false);

        addToast({
          type: 'success',
          title: 'Pronto!',
          description: 'Sua senha foi alterada com sucesso.',
        });

        history.push('/');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao atualizar a senha',
        });
      }
    },
    [token, addToast, history],
  );

  return (
    <Container>
      <MaturityHeader />
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Faça a alteração da sua senha</h2>

          <section>
            <Input
              name="newPassword"
              type="password"
              placeholder="Nova senha"
            />
            <Input
              name="confirmPassword"
              type="password"
              placeholder="Confirme sua nova senha"
            />
          </section>

          <Button loading={loading} light>
            Redefinir minha senha
          </Button>
        </Form>
      </Content>
    </Container>
  );
};
