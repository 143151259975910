import styled, { css, keyframes } from 'styled-components';

interface rotationProps {
  color?: string;
}

const keyFrameRotation = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`;

export const Rotation = styled.div<rotationProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  animation: ${keyFrameRotation} 2s linear infinite;
  height: 1.25rem;
  width: 1.25rem;

  svg {
    height: 1.25rem;
    width: 1.25rem;
    ${props =>
      props.color &&
      css`
        color: ${props.color};
      `}
  }
`;
