import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';

import GenericModal from '../components/GenericModal';

interface Props {
  when?: boolean | undefined;
  navigate: (path: string) => void;
  shouldBlockNavigation: (location: Location) => boolean;
  handleAction: any;
  title: string;
  message: string;
  closeMessage: string;
  successMessage: string;
  icon: string;
}

const RouteLeavingGuard: React.FC<Props> = ({
  when,
  navigate,
  shouldBlockNavigation,
  handleAction,
  title,
  message,
  closeMessage,
  successMessage,
  icon,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);

  const closeModal = () => {
    setModalVisible(false);
    setTimeout(() => {
      setConfirmedNavigation(true);
    }, 200);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };

  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    handleAction({ isCreation: false, openPopup: true });
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      navigate(lastLocation.pathname);
    }
  }, [confirmedNavigation, lastLocation, navigate]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />

      {modalVisible && (
        <GenericModal
          icon={icon}
          title={title}
          message={message}
          hasClose
          closeMessage={closeMessage}
          closeAction={closeModal}
          successMessage={successMessage}
          successAction={handleConfirmNavigationClick}
        />
      )}
    </>
  );
};

export default RouteLeavingGuard;
