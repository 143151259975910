import styled, { keyframes } from 'styled-components';

import background from '../../assets/backgroundBlocks.svg';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const ContentModal = styled.div`
  animation: 1s ${appearFromLeft} ease-in-out;

  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  background: #fff;

  h2 {
    font-size: 36px;
    text-align: center;
    line-height: 58px;
    color: #28bce0;
  }

  > svg {
    position: absolute;
    color: #33333329;
    left: -1rem;
    height: 100%;
    width: auto;
    z-index: 5;
  }

  p {
    color: var(--dark-gray);
    margin-top: 2rem;
    font-size: 1.5rem;
    text-align: center;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: none;
    font-size: 1.5rem;
    margin-top: 2rem;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    transition: all 0.2s ease-in-out;
    color: #28bce0;

    svg {
      height: 1.5rem;
      width: 1.5rem;
    }

    &:hover {
      background: var(--white);
      filter: brightness(0.9);
    }
  }
`;

export const Modal = styled.div`
  position: fixed;
  top: -6rem;
  height: calc(100vh + 6rem);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;

  background: rgba(0, 0, 0, 0.2);
  color: var(--teal);

  div {
    position: relative;
    width: 45rem;
    height: 22.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--white);
    border-radius: 2rem;
    padding: 2rem;
    overflow: hidden;

    * {
      z-index: 10;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
  background-color: var(--blue);
  overflow: hidden;

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.37;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 2;

  color: var(--white);

  form {
    display: flex;
    flex-direction: column;
    padding: 5rem 3rem;

    background: #132cbdbb;
    min-height: 30rem;
    min-width: 30rem;
    border-radius: 1rem;

    h2 {
      font-weight: 400;
      margin-bottom: 2rem;
    }

    div + div {
      margin-top: 1.5rem;
    }

    section {
      display: flex;
      flex-direction: column;

      justify-content: space-around;

      margin-bottom: 3rem;
      flex: 1;
    }
  }
`;
