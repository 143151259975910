import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  button {
    width: 100%;
    height: 4rem;
    background: transparent;
    border: 0;
    border-bottom: 2px solid #ffffff99;
    padding: 1rem;
    font-size: 1rem;
    color: #fff;

    display: flex;
    align-items: center;
    justify-content: space-between;

    text-align: left;

    svg {
      color: #fff;
      height: 1rem;
      width: 1rem;
    }

    &:hover {
      cursor: pointer;
      filter: brightness(0.9);
    }
  }

  div {
    position: absolute;
    top: 0rem;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 5;
    overflow: hidden;
  }

  button:not(.main) {
    margin: 0;
    background: var(--blue);
    border-bottom: 1px solid #ffffff10;
  }
`;
