import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: scroll;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1200px;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 1rem;
  margin-top: 1rem;

  @media screen and (min-width: 375px) {
    padding: 0 2rem;
    margin-top: 2rem;
  }
  @media screen and (min-width: 768px) {
    padding: 0 3rem;
    margin-top: 3rem;
  }
  @media screen and (min-width: 992px) {
    padding: 0 4rem;
    margin-top: 4rem;
  }
`;

export const Constructor = styled.section`
  width: 100%;
  margin-bottom: 140px;

  h2 {
    color: var(--teal);
    margin-bottom: 42px;
    font: normal normal bold 14px/18px Nunito;
  }

  .title-with-subtitle{
    font-family: Nunito;
    color: #2AC4EA;
    font: normal normal bold 14px/18px Nunito;
    margin-bottom: 7px;
    
  }

  p{
      font-family:Mulish;
      font-weight: 400;
      font-style:normal;
      line-height: 25.1px;
      margin: 0px;
  }

  .cookie-button {
    min-width: 200px;
    border: 2px solid #28bce0;
    border-radius: 53px;
    font: normal normal bold 14px/18px 'Mulish';
    letter-spacing: 0px;
    color: #28bce0;
    padding: 10px 15px;

    &:hover {
      background-color: var(--teal);
    }
  }

  @media screen and (min-width: 768px) {
    h2, .title-with-subtitle {
      font: normal normal bold 16px/20px Nunito;
    }
    .cookie-button {
      padding: 12px 18px;
      font: normal normal bold 16px/20px 'Mulish';
    }
  }
  @media screen and (min-width: 992px) {
    h2, .title-with-subtitle {
      font: normal normal bold 24px/28px Nunito;
    }
    .cookie-button {
      padding: 15px 20px;
      font: normal normal bold 20px/24px 'Mulish';
    }
  }
`;


// Não esta sendo usado no momento

export const ConstructorEmail = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p{
      font-family: Nunito;
      font-weight:700;
      font-style: normal;
      line-height:21.82px;

      color: #FF0047;

      margin-top:10px;
  }

  input {
    padding: 0.5rem 1rem;
    border: 1px solid var(--dark-gray);
    border-radius: 2rem;
    background-color: transparent;
    text-align: center;
  }

  .email-input {
    font: normal normal bold 14px/18px Nunito;
    border: unset;
    border-radius: 20px;
    background-color: var(--white);
    width: 100%;
    max-width: 946px;

    &.first {
      padding: 11px 80px 11px 13px;
      box-shadow: 0px 3px 20px #00000029;
    }
    &.second {
      padding: 11px 13px;
      box-shadow: 0px 3px 6px #00000029;
      color: #949494;
    }
  }
  

  @media screen and (min-width: 768px) {
    .email-input {
      font: normal normal bold 16px/20px Nunito;
      &.first {
        padding: 11px 120px 11px 13px;
      }
    }
  }
  @media screen and (min-width: 992px) {
    .email-input {
      font: normal normal bold 24px/28px Nunito;
      &.first {
        padding: 11px 176px 11px 13px;
      }
    }
  }
`;

export const ConstructorURL = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;

  form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input {
    padding: 0.5rem 1rem;
    border: 1px solid var(--dark-gray);
    border-radius: 2rem;
    background-color: transparent;
    text-align: center;
  }

  .url-input {
    font: normal normal bold 14px/18px Nunito;
    border: unset;
    border-radius: 20px;
    background-color: var(--white);
    width: 100%;
    max-width: 946px;

    &.first {
      padding: 11px 80px 11px 13px;
      box-shadow: 0px 3px 20px #00000029;
    }
    &.second {
      padding: 11px 13px;
      box-shadow: 0px 3px 6px #00000029;
      color: #949494;
    }
  }

  .url-btn {
    padding: 0rem 1rem;
    border: unset;
    border-radius: 10px 20px 20px 10px;
    background-color: var(--teal);
    color: var(--white);
    min-width: 80px;
    min-height: 40px;
    margin-left: -80px;
    font: normal normal bold 14px/18px Nunito;

    &:hover {
      background-color: var(--teal);
      box-shadow: 0px 6px 30px #00000029;
    }
  }

  @media screen and (min-width: 768px) {
    .url-input {
      font: normal normal bold 16px/20px Nunito;
      &.first {
        padding: 11px 120px 11px 13px;
      }
    }
    .url-btn {
      font: normal normal bold 16px/20px Nunito;
      min-width: 120px;
      margin-left: -120px;
      min-height: 42px;
    }
  }
  @media screen and (min-width: 992px) {
    .url-input {
      font: normal normal bold 24px/28px Nunito;
      &.first {
        padding: 11px 176px 11px 13px;
      }
    }
    .url-btn {
      font: normal normal bold 24px/28px Nunito;
      min-width: 176px;
      margin-left: -176px;
      min-height: 50px;
    }
  }
`;


export const ConstructorBlock = styled.section`
  width: 100%;
  margin-top: 33px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .save-button-wrapper {
    width: 100%;
    max-width: 946px;
    display: flex;
    margin-top: 22px;
  }
`;

export const ConstructorCard = styled.div`
  max-width: 946px;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .mb-17 {
    margin-bottom: 17px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .mb-73 {
    margin-bottom: 73px;
  }

  h2 {
    font: normal normal bold 14px/18px Nunito;
  }

  p {
    font: normal normal normal 12px/16px 'Mulish';
    letter-spacing: 0.62px;
    color: #949494;
  }

  @media screen and (min-width: 375px) {
    padding: 25px 25px;
  }
  @media screen and (min-width: 768px) {
    padding: 30px 45px;
    h2 {
      font: normal normal bold 16px/20px Nunito;
    }
    p {
      font: normal normal normal 14px/18px 'Mulish';
    }
  }
  @media screen and (min-width: 992px) {
    padding: 40px 66px;
    h2 {
      font: normal normal bold 24px/28px Nunito;
    }
    p {
      font: normal normal normal 18px/22px 'Mulish';
    }
  }
`;

export const ConstructorSubCard = styled.article`
  border: 2px solid #D3D3D3;
  border-radius: 12px;
  display: grid;
  grid-gap: 21px;
  width: 100%;
  padding: 20px;
  margin-bottom:64px;

  .constructor__attributes__sub-card__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font: normal normal normal 12px/16px 'Mulish';
    letter-spacing: 0.62px;
    color: #949494;

    .title--end {
      align-items: flex-end;
      display: flex;
    }
    .title--center {
      align-items: center;
      display: flex;
    }
    .check-background-opacity{
      cursor: not-allowed;
    }
  }

  .constructor__attributes__sub-card__row__end {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .bar-color {
      width: 53px;
      height: 42px;
      border-radius: 20px;
      border: 1px solid #0000001f;
    }

    .font-name {
      display: inline-block;
      border: 1px solid #0000001f;
      border-radius: 20px;
      width: 113px;
      min-width: 113px;
      height: 42px;
      padding: 9px;
      text-align: center;
      background-color: unset;
      color: inherit;
      font: inherit;
    }

    .font-color {
      border: 1px solid #0000001f;
      width: 45px;
      height: 42px;
      border-radius: 20px;
      margin-left: unset;
    }

    .ml-8 {
      margin-left: 8px;
    }
    .ml-28 {
      margin-left: 28px;
    }
  }

  aside {
    position: absolute;
    top: 45px;
    z-index: 8888;
  }

  @media screen and (min-width: 375px) {
    padding: 20px 25px;
  }
  @media screen and (min-width: 768px) {
    padding: 25px 40px;
    .constructor__attributes__sub-card__row {
      font: normal normal normal 14px/18px 'Mulish';
    }
  }
  @media screen and (min-width: 992px) {
    padding: 31px 55px;
    .constructor__attributes__sub-card__row {
      font: normal normal normal 18px/22px 'Mulish';
    }
    .constructor__attributes__sub-card__row__end {
      min-width: 220px;
    }
  }
`;
interface ColorButtonProps {
    color: string;
}
export const ColorButton = styled.button<ColorButtonProps>`
  height: 2rem;
  width: 2rem;
  border-radius: 2rem;
  border: 0.5px solid var(--dark-gray);
  background: ${props => props.color};
  margin-left: 1rem;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--green);
    height: 1.5rem;
    width: 1.5rem;
  }
`;

export const CookieTypesContainer = styled.section`
  display: flex;
  flex-direction: column;
  min-width: 25rem;
  width: calc(69% - 1rem);
  margin-left: auto;

  border: 1px solid var(--dark-gray);
  border-radius: 1rem;
  padding: 3rem 2rem;

  strong {
    margin-top: 1.5rem;
  }

  header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  > div {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    flex: 1;

    > span {
      max-width: 50%;
      word-wrap: break-word;
    }
  }

  p {
    text-align: justify;
    margin: 1rem 0;
  }

  .buttonContainer {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
  }

  button {
    height: 33px;
    width: 85px;
    margin: 0px 10px;
    font-size: 14px;
    border: 2px solid #333333;
    border-radius: 10px;
    border-radius: 53px;
    background-color: transparent;
  }

  .mandatoryCookies {
    color: var(--gray);
  }
`;

export const CustomButton = styled.button`
  height: 33px;
  width: 85px;
  margin: 0px 10px;
  font-size: 14px;
  border: 2px solid #333333;
  border-radius: 10px;
  border-radius: 53px;
  background-color: transparent;
`;

interface SectionTitleProps {
    color?: string;
}
export const SectionTitle = styled.strong<SectionTitleProps>`
  margin-top: 2rem;
  color: ${props => props.color};
`;

interface VisualizationProps {
    emphasisColor: string;
    fontFamily: string;
    fontSize: string;
    fontWeight: string;
    fontColor: string;
}
export const Visualization = styled.article<VisualizationProps>`
  width: 100%;
  border-radius: 12px;
  margin-top: 23px;
  position: relative;
  z-index: 0;
  border: 2px solid #D3D3D3;

  padding-top: 50px ;

  .tooltip {
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    border-radius: 9px 9px 0px 0px;
    cursor: default;
    background-color: #d3d3d3;
    position: absolute;
    display: flex;
    align-items: center;

    span:first-child {
      margin-left: 15px;
    }
    span {
      margin-right: 5px;
      background-color: white;
      height: 12px;
      width: 12px;
      border-radius: 50%;
    }
  }

  .geral{
      background-color:${props => props.fontColor === '#EEEEEE' ? '#707070':'#FFFFFF00'};
      border-radius: 0px 0px 10px 10px; 
  }

  #title {
    font-family: ${props => props.fontFamily};
    font-weight: 400;
    font-style: normal;
    line-height: 25.1px;

    color: ${props => props.fontColor};

    margin-bottom: 32px;

    cursor: default;
  }

  .itens-title {
    font-family: ${props => props.fontFamily};
    font-weight: 700;
    font-style: normal;
    line-height: 22.59px;

    color:  ${props => props.fontColor};

    padding: 0px;
    margin: 0px;

    cursor: default;
  }

  .itens-content {
    font-family: ${props => props.fontFamily};
    font-weight: 400;
    font-style: normal;
    line-height: 22.59px;

    color: ${props => props.fontColor};;

    padding: 0px;
    margin: 0px;

    cursor: default;
  }

  .group-itens {
    display: flex;
    margin-bottom: 24px;
  }

  .radio-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: default;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .radio-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .checkmark {
    position: absolute;
    top: 10px;
    right: 12px;
    height: 24px;
    width: 24px;
    border: 2px solid #D0D0D0;
    border-radius: 50%;
  }

  .radio-container input:checked~.checkmark {
    height: 24px;
    width: 24px;
    border: 8px solid ${props => props.emphasisColor};
  }

  .button-content{
            display: flex;
            justify-content: center;
    }
    button{
        width: 127px;
        height: 48px;
        border-radius: 8px;
        padding: 14px 32px 14px 32px;
        background-color: ${props => props.emphasisColor};
        border:none;

        color: #FFFFFF;
        font-size: 16px;
        font-family: ${props => props.fontFamily};
        font-weight: 400;   
        cursor: default;
    }

    @media screen and (max-width: 425px) {
        .geral{
            padding: 10px;
        }
        .itens-title {
            font-size: 16px;
        }

        .itens-content{
            font-size: 12px;
        }
    }

    @media screen and (min-width: 425px) {
        .geral{
            padding-top: 32px;
            padding-left: 45px;
            padding-right: 45px;
            padding-bottom: 32px;
        }
        .itens-title {
            font-size: 16px;
        }

        .itens-content{
            font-size: 12px;
        }
    }

    @media screen and (min-width: 768px) {
        .geral{
            padding-top: 64px;
            padding-left: 92px;
            padding-right: 92px;
            padding-bottom: 64px;
        }
        .itens-title {
            font-size: 16px;
        }

        .itens-content{
            font-size: 12px;
        }
    }
    @media screen and (min-width: 992px) {
        .geral {
            padding-top: 64px;
            padding-left: 92px;
            padding-right: 92px;
            padding-bottom: 64px;
        }

        #title {
            font-size: 20px;
        }

        .itens-title {
            font-size: 18px;
        }

        .itens-content {
            font-size: 18px;
        }
    }
  
`;

interface VisualProps {
    generateHTML: Boolean;
}
export const Visual = styled.section<VisualProps>`
  .generateHTML {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      width: 541px;
      text-align: left;
      font-size: 16px;
      letter-spacing: 0.15px;
      color: #333333;
    }

    div button {
      height: 33px;
      width: ${prop => (prop.generateHTML ? '50px' : '119px')};
      margin: 0px 10px;
      font-size: 14px;
      border: ${prop => (prop.generateHTML ? '' : '2px solid #333333')};
      border-radius: 10px;
      border-radius: 53px;
    }
  }
`;

interface HtmlContainerProps {
    htmlCopied: Boolean;
    cookieBarColor: string;
}

export const HtmlContainer = styled.div<HtmlContainerProps>`
  box-shadow: 0px 3px 6px #00000029;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 30px 0px;
  text-align: left;
  height: 100px;
  width: 600px;
  max-width: 600px;
  padding: 20px;
  background-color: rgb(255 255 255 / 50%);
  position: relative;
  z-index: 2;

  p {
    white-space: pre-line;
    font-family: sans-serif;
    font-weight: 100;
    letter-spacing: 0px;
    color: #949494;
    line-break: anywhere;
  }
`;

export const Modal = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;

  background: rgba(0, 0, 0, 0.2);
`;

export const ModalCard = styled.div`
  position: relative;
  width: 100%;
  max-width: 750px;
  background-color: var(--white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  text-align: center;
  padding: 20px;

  article {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 40px 20px;
    z-index: 2;
  }

  .svg-link {
    position: absolute;
    left: -12px;
    top: 0px;
    width: 200px;
    height: 250px;
    color: #494949;
    opacity: 0.2;
    z-index: 1;
  }

  .svg-copy {
    position: absolute;
    left: -18px;
    top: 2px;
    width: 217px;
    height: 251px;
    color: #494949;
    opacity: 0.2;
    z-index: 1;
  }

  h2 {
    font: normal normal bold 24px/28px 'Mulish';
    letter-spacing: 0px;
    color: var(--teal);
    margin-bottom: 20px;
  }

  p {
    font: normal normal normal 20px/24px 'Mulish';
    letter-spacing: 0px;
    color: #707070;
    margin-bottom: 20px;
  }


  button {
    font: normal normal bold 20px/24px 'Mulish';
    letter-spacing: 0px;
    color: var(--teal);
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 5px 20px;
    border-radius: 53px;

    &:hover {
      background-color: var(--teal);
      color: var(--white);
    }

    svg {
      color: inherit;
      width: 43px;
      height: 43px;
    }

    div {
      margin-left: 15px;
    }
  }

  .button-wrapper {
    border: 1px solid #707070;
    border-radius: 36px;
    padding: 20px 28px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(255 255 255 / 50%);
    position: relative;
    z-index: 2;
    margin-top: 40px;

    span {
      font: normal normal 600 22px/26px 'Mulish';
      letter-spacing: 0px;
      color: #28bce0;
    }

    button {
      font: normal normal 600 18px/22px 'Mulish';
      letter-spacing: 0px;
      color: inherit;
      background-color: var(--white);
      display: flex;
      align-items: center;
      min-width: 160px;
 
      &.color--teal {
        color: var(--teal);
      }

      svg {
        width: 19px;
        height: 22px;
        color: inherit;
        margin-right: 14px;
      }
    }
  }

  .footer-button {
    font: normal normal bold 20px/24px 'Mulish';
    letter-spacing: 0px;
    color: #28bce0;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 22px auto 0;
    padding: 5px 20px;
    border-radius: 53px;

    &:hover {
      background-color: var(--teal);
      color: var(--white);
    }

    svg {
      color: inherit;
      width: 43px;
      height: 43px;
    }
  }

  @media screen and (min-width: 425px) {
    min-height: 340px;
    padding: 40px 20px;
  }
  @media screen and (min-width: 768px) {
    width: 600px;
    padding: 50px 70px;
    article {
      padding: 50px 70px;
    }
    .svg-link {
      width: 250px;
    }
    h2 {
      font: normal normal bold 26px/30px 'Mulish';
    }
    p {
      font: normal normal normal 22px/26px 'Mulish';
    }
    button {
      font: normal normal bold 22px/26px 'Mulish';
    }
    footer {
      flex-direction: row;
      height: auto;
    }
  }
  @media screen and (min-width: 992px) {
    width: 720px;
    padding: 48px 72px 63px 72px;
    article {
      padding: 48px 72px 63px 72px;
    }
    .svg-link {
      width: 300px;
      height: 300px;
    }
    h2 {
      font: normal normal bold 28px/32px 'Mulish';
    }
    p {
      font: normal normal normal 24px/28px 'Mulish';
    }
    button {
      font: normal normal bold 24px/28px 'Mulish';
    }
    .button-wrapper {
      flex-direction: row;
    }
  }
`;

export const ButtomExa = styled.div`
 cursor: pointer;
`;