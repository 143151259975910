import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';
import _ from 'lodash';

import { api } from '../services/api';
import { useToast } from './toast';

import { ICompany } from '../types/ICompany';
import { ICompanyResponse } from '../types/ICompanyResponse';

export interface Permission {
  admin_accounts: number;
  existing_quantity_admin: number;
  normal_accounts: number;
  existing_quantity_normal: number;
  cookie_bars: number;
  cookie_bars_interactions: number;
  interactions_reports: boolean;
  policies: number;
  chat_requests: number;
  dsr_forms: number;
  dsr_management: boolean;
}

interface User {
  _id: string;
  roles: string[];
  name: string;
  company_id: ICompany;
  email: string;
  no_pass: boolean;
}

interface AuthState {
  access_token: string;
  user: User;
  permission: Permission;
}

export interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  permission: Permission;
  updateUser(user: User): void;
  signIn(credentials: SignInCredentials): Promise<User | boolean>;
  signOut(): void;
  controlPayment(user: User): Promise<Permission>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const { addToast } = useToast();
  const [tryFetch, setTryFetch] = useState(false);
  const [permission, setPermission] = useState<Permission>();

  const [data, setData] = useState<AuthState>(() => {
    const access_token = sessionStorage.getItem('@Privacyflow:access_token');
    const user = sessionStorage.getItem('@Privacyflow:user');

    if (access_token && user) {
      return {
        access_token,
        user: JSON.parse(user),
        permission
      };
    }
    return {} as AuthState;
  });

  useEffect(() => {
    if (
      data.user?.company_id &&
      !data.user?.company_id.hasCookieBar &&
      !tryFetch
    ) {
      const loadCompanyCookie = async () => {
        const response = await api.get<ICompanyResponse>(
          `companies/${data.user.company_id._id}`,
        );

        setData(prev => ({
          ...prev,
          user: {
            ...prev.user,
            company_id: {
              ...prev.user.company_id,
              hasCookieBar: response.data.cookie_bars.length > 0,
              hasPolicy: response.data.policies.length > 0,
              hasDSR: response.data.dsrs.length > 0,
              dsr_id: response.data.dsrs.length > 0 ? response.data.dsrs[0]._id : '',
              plan: response.data.plan,
              status:response.data.company.status,
            },
          },
        }));

        const _company = _.get(response, 'data.company', false);
        if (!data.user.no_pass && _company) {
          await controlPayment(data.user)
        };
      };

      loadCompanyCookie();
      setTryFetch(true);
    }
  }, [data]);

  // eslint-disable-next-line consistent-return
  async function controlPayment(_user: User): Promise<Permission> {
    try {
      const companyId = _.get(_user, 'company_id', false);
      const userId = _.get(_user, '_id', false);
      const paymentResponse = await api.get(`users/${userId}/payment`);
      const paymentData = _.get(paymentResponse, 'data', {});
      const status = _.get(paymentData, 'status', 0);
      const planCode = _.get(paymentData, 'planCode', '');

      if ([2, 3].includes(status)) {
        // Enforce plan free permitions
        const response = await api.get<Permission>(
          `plans/code/PLANO_FREE`
        );
        setPermission(response.data);
        const link = process.env.REACT_APP_PRIVACY || 'https://app.privacyflow.ai/'
        window.location.href = `${link}/checkout/index.html?plan=${planCode}`
        return response.data;
      }

      if (status === 4) {
        // Block access
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Entre em contato com o Privacyflow.',
        });
        signOut();
      }

      if (status === 7) {
        // Block access
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Entre em contato com o seu administrador.',
        });
        signOut();
      }

      const response = await api.get<Permission>(
        `plans/company/${data.user?.company_id?._id || companyId}`
      );

      setPermission(response.data);
      return response.data;

      // status === 1 - Plano Free.
      // status === 2 - Direcionar cliente para checkout.
      // status === 3 - Cliente processando novo pagamento.
      // status === 4 - Cliente inadimplente.
      // status === 5 - Cliente novo processando pagamento.
      // status === 6 - Cliente está em dia.
      // status === 7 - Sem permissão. Favor Contatar o administrador.
    } catch (error) {
      console.error(error);
    }
  }

  const signIn = async ({ email, password }: SignInCredentials) => {
    try {
      const response = await api.post<AuthState>('/auth', {
        email: email.trim(),
        password: password.trim(),
      });

      const { access_token, user } = response.data;

      sessionStorage.setItem('@Privacyflow:access_token', access_token);
      sessionStorage.setItem('@Privacyflow:user', JSON.stringify(user));

      setData({ user, permission, access_token });
      // await controlPayment(user.company_id);

      if (!user.no_pass) {
        addToast({
          type: 'success',
          title: 'Olá!',
          description: 'Login efetuado com sucesso',
        });
      }

      return user;
    } catch (error) {
      const status = _.get(error, 'response.status', false);

      if (status >= 400 && status < 500) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Email ou senha inválidos',
        });

        return false;
      }

      // @TODO: add other status specific error handling
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao comunicar com Servidor',
      });
      return false;
    }
  };

  const signOut = useCallback(() => {
    sessionStorage.removeItem('@Privacyflow:token');
    sessionStorage.removeItem('@Privacyflow:user');

    setData({} as AuthState);
  }, []);

  const updateUser = (updatedUser: User) => {
    setData(prev => ({ ...prev, user: updatedUser }));
    sessionStorage.setItem('@Privacyflow:user', JSON.stringify(updatedUser));
  };

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        updateUser,
        signIn,
        signOut,
        permission,
        controlPayment
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within an AuthProvider');

  return context;
}
