import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
  background: rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ModalCard = styled.div`
  position: relative;
  width: 90%;
  max-width: 750px;
  min-height: 365px;
  background-color: var(--white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  text-align: center;
  padding: 40px 20px;

  article {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 40px 20px;
    z-index: 2;
  }

  .svg-link {
    position: absolute;
    left: -12px;
    top: 0px;
    width: 200px;
    height: 250px;
    color: #494949;
    opacity: 0.2;
    z-index: 1;
  }

  h2 {
    font: normal normal bold 24px/28px 'Mulish';
    letter-spacing: 0px;
    color: var(--teal);
    margin-bottom: 20px;
  }

  p {
    font: normal normal normal 20px/24px 'Mulish';
    letter-spacing: 0px;
    color: #707070;
    margin-bottom: 20px;
  }

  button {
    font: normal normal bold 20px/24px 'Mulish';
    letter-spacing: 0px;
    color: var(--teal);
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 5px 20px;
    border-radius: 53px;

    &:hover {
      background-color: var(--teal);
      color: var(--white);
    }

    svg {
      color: inherit;
      width: 43px;
      height: 43px;
    }

    div {
      margin-left: 15px;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 100%;
    height: 120px;

    button {
      border: 2px solid var(--teal);
      border-radius: 53px;
      padding: 10px 20px;
      min-width: 175px;

      &:hover {
        box-shadow: 0px 3px 20px #00000029;
      }

      &.forward {
        background-color: var(--teal);
        color: var(--white);

        &:hover {
          background-color: transparent;
          color: var(--teal);
        }
      }

      &.cookies {
        background-color: transparent;
        color: var(--teal);

        &:hover {
          background-color: var(--teal);
          color: var(--white);
        }
      }
    }
  }

  @media screen and (min-width: 425px) {
    min-height: 340px;
  }
  @media screen and (min-width: 768px) {
    width: 600px;
    padding: 50px 70px;
    article {
      padding: 50px 70px;
    }
    .svg-link {
      width: 250px;
    }
    h2 {
      font: normal normal bold 26px/30px 'Mulish';
    }
    p {
      font: normal normal normal 22px/26px 'Mulish';
    }
    button {
      font: normal normal bold 22px/26px 'Mulish';
    }
    footer {
      flex-direction: row;
      height: auto;
    }
  }
  @media screen and (min-width: 992px) {
    width: 720px;
    padding: 48px 72px 63px 72px;
    article {
      padding: 48px 72px 63px 72px;
    }
    .svg-link {
      width: 300px;
      height: 300px;
    }
    h2 {
      font: normal normal bold 28px/32px 'Mulish';
    }
    p {
      font: normal normal normal 24px/28px 'Mulish';
    }
    button {
      font: normal normal bold 24px/28px 'Mulish';
    }
  }
`;
