import styled from 'styled-components';

export const Container = styled.aside`
  position: relative;

  &:hover {
    span {
      min-width: 10rem;
      opacity: 1;
      visibility: visible;
      text-align: center;
    }
  }

  span {
    padding: 0.5rem;
    border-radius: 0.25rem;
    font-size: 1rem;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.4s;

    position: absolute;

    bottom: calc(100% + 0.75rem);
    left: 50%;
    transform: translateX(-50%);

    color: #312e38;

    &::before {
      content: '';
      border-style: solid;
      border-color: #ff9000 transparent;
      border-width: 0.5rem 0.5rem 0 0.5rem;
      bottom: 1.25rem;
      top: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;
