import styled, { css } from 'styled-components';

interface ContainerProps {
  backgroundColor?: string;
  active?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const Content = styled.div<ContainerProps>`
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 1rem;
  background: var(--yellow);
  min-height: 252px;
  max-width: 262px;
  height: 100%;
  width: 100%;

  color: var(--white);

  background: ${props => `var(--${props.backgroundColor})`};

  box-shadow: 0px 3px 0.5rem #00000029;

  transition: all 0.4s;

  ${props =>
    !props.active &&
    css`
      cursor: not-allowed;
      background: #888;
    `};

  ${props =>
    props.active &&
    css`
      &:hover {
        box-shadow: 0px 3px 1rem #000000aa;
        transform: translateY(2px);
        cursor: pointer;

        footer button {
          filter: drop-shadow(1px 1px 1px var(--white));
        }
      }
    `};

  section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2rem;

    div {
      position: relative;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      background: var(--teal);

      > svg {
        position: absolute;
        width: 100%;
        height: 75%;
        top: 10px;
      }
    }
  }

  article {
    background: var(--teal);
    color: var(--white);
    padding: 2rem;
    border-radius: 1rem;

    h3 {
      display: block;
      width: 100%;
      font: normal normal bold 16px/20px Nunito;
      letter-spacing: 0px;
    }
  }
`;
