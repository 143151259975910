import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  padding: 33px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .save-button-wrapper {
    width: 100%;
    max-width: 946px;
    display: flex;
    margin-top: 22px;

    button {
      border-radius: 53px;
      font: normal normal bold 14px/18px 'Mulish', serif;
      letter-spacing: 0;
      padding: 10px 15px;
      min-width: 140px;
      align-items: center;
      display: flex;
      justify-content: center;

      &:hover {
        box-shadow: 0 6px 30px #00000029;
      }
    }

    .previous-button {
      border: unset;
      color: var(--teal);
      margin-right: 10px;

      svg {
        margin-right: 10px;
        height: 20px;
        width: 20px;
      }

      &:hover {
        background-color: var(--white);
      }
    }

    .next-button {
      background: transparent;
      color: var(--teal);
      border: 2px solid var(--teal);

      &:hover {
        background-color: var(--teal);
        color: var(--white);
      }
    }
  }

  @media screen and (min-width: 768px) {
    .button {
      padding: 12px 18px;
      font: normal normal bold 16px/20px 'Mulish', serif;
    }
  }
  @media screen and (min-width: 992px) {
    .button {
      padding: 15px 20px;
      font: normal normal bold 20px/24px 'Mulish', serif;
    }
  }
`;

export const Card = styled.div`
  max-width: 946px;
  width: 100%;
  background-color: var(--white);
  box-shadow: 0 3px 6px #00000029;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    font-size: 22px;
    line-height: 24px;
    color: #707070;
    font-family: 'Mulish', serif;
    padding: unset;
    margin: unset;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
  h2 {
    font-size: 20px;
    line-height: 22px;
    color: #707070;
    font-family: 'Mulish', serif;
    padding: unset;
    margin: unset;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
  h3 {
    font-size: 18px;
    line-height: 20px;
    color: #707070;
    font-family: 'Mulish', serif;
    padding: unset;
    margin: unset;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }

  p, ul {
    display: block;
    padding: unset;
    margin: unset;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
  }
  
  ul {
    list-style-type: disc;
    padding-inline-start: 40px;
  }

  p, table, ul, div {
    font-size: 12px;
    line-height: 20px;
    color: #707070;
    font-family: 'Mulish', serif;
  }

  span, li, em, td {
    font-weight: normal;
  }

  @media screen and (min-width: 375px) {
    padding: 25px 25px;
  }
  @media screen and (min-width: 768px) {
    padding: 30px 45px;
    h1 {
      font-size: 24px;
      line-height: 26px;
    }
    h2 {
      font-size: 22px;
      line-height: 24px;
    }
    h3 {
      font-size: 20px;
      line-height: 22px;
    }
    p, table, ul, div {
      font-size: 14px;
      line-height: 22px;
    }
  }
  @media screen and (min-width: 992px) {
    padding: 40px 66px;
    h1 {
      font-size: 26px;
      line-height: 28px;
    }
    h2 {
      font-size: 24px;
      line-height: 26px;
    }
    h3 {
      font-size: 22px;
      line-height: 24px;
    }
    p, table, ul, div {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;
