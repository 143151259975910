import React from "react";
import { SupporUserForm } from './Form';
import {Container} from "./styles";


export const SupporUser: React.FC = () => {
    return(
      <Container>
        <SupporUserForm />
      </Container>     
    )
}

