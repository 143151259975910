import styled from 'styled-components';

export const Container = styled.div`
  width: 712px;
  border-radius: 32px;

  background-color: #FFFF;

  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 45px;
  padding-right: 45px;
  margin: 0px 16px 0px 16px;

  @media (max-width: 768px){
    height: 363px
  }

  @media (min-width: 769px){
    height: 328px;
  }

  @media (min-width: 1224px){
    height: 328px;
  }
  
`;
export const Title = styled.h1`
  font-family: Nunito;
  font-weight: 600;
  font-style: normal;
  font-size: 30px;
  line-height: 32px;

  color:#298AFF;

  @media (max-width: 768px){
    text-align: center;
  }
`;

export const Message = styled.p`
  font-family: Nunito;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  text-align: center;

  color:#494949;

  @media (max-width: 768px){
    font-family: Mulish;
    font-size: 18px;
    line-height: 22,59px;
  }

  @media (min-width: 769px){
    font-family: Mulish;
    font-size: 24px;
    line-height: 32px;
  }

  @media (min-width: 1224px){
    font-family: Mulish;
    font-size: 24px;
    line-height: 32px;
  }
  
`