import React, {
  createContext,
  useEffect,
  useState,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import mockSections from '../mocks/sections.json';
import { useToast } from './toast';
import { api } from '../services/maturiry_api';

import {
  UserData,
  Research,
  ResearchesResponse,
  SectionProps,
} from '../types/researchTypes';

interface SwitchSectionOrQuestionParams {
  nextQuestion?: number;
  nextSection?: number;
  updatedSection?: SectionProps;
}

interface ResearchContextData {
  user: UserData;
  setUser(user: UserData): void;
  research: Research;
  setResearch(research: Research): void;
  currentSection: number;
  setCurrentSection(sectionNumber: number): void;
  currentQuestion: number;
  setCurrentQuestion(questionNumber: number): void;
  switchQuestionOrSection(params: SwitchSectionOrQuestionParams): void;
  questionsToBeAnswered: number;
  progress: number;
}

const ResearchContext = createContext<ResearchContextData>(
  {} as ResearchContextData,
);

export const ResearchProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const { addToast } = useToast();

  const [user, setUser] = useState<UserData>({} as UserData);
  const [research, setResearch] = useState<Research>({
    sections: [] as SectionProps[],
  } as Research);

  const [currentSection, setCurrentSection] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(1);

  const loadResearches = useCallback(async () => {
    if (!user._id) return;

    const response = await api.get<ResearchesResponse>(
      `/researches?clientId=${user._id}`,
    );

    const { researches } = response.data;

    if (researches.length) {
      setResearch(researches[researches.length - 1]);
    } else {
      api
        .post<Research>('/researches', {
          clientId: user._id,
          type: 'privacyflow',
        })
        .then(res => {
          setResearch({ ...res.data, sections: mockSections });
          api.put(`/researches/${res.data._id}`, { sections: mockSections });
        })
        .catch(err => {
          history.push('/');
          addToast({
            type: 'error',
            title: 'Falha do Servidor',
            description: err.response.data,
          });
        });
    }
  }, [addToast, history, user._id]);

  useEffect(() => {
    loadResearches();
  }, [loadResearches]);

  const switchQuestionOrSection = useCallback(
    ({
      nextQuestion,
      nextSection,
      updatedSection,
    }: SwitchSectionOrQuestionParams) => {
      // Finds the Section to be replaced by UpdatedSection
      /// if Question was skipped, updatedSection will be undefined
      if (updatedSection)
        setResearch(prevResearch => ({
          ...prevResearch,
          sections: prevResearch.sections.map(prevSection =>
            prevResearch.sections.indexOf(prevSection) + 1 === currentSection
              ? updatedSection
              : prevSection,
          ),
        }));

      // If switched Question, sets newQuestion
      if (nextQuestion) setCurrentQuestion(nextQuestion);
      // If switched Section, sets newSection
      else if (nextSection) {
        setCurrentSection(nextSection);
        setCurrentQuestion(1); // Setar aqui a última Pergunta não respondida
      }
    },
    [currentSection],
  );

  useEffect(() => {
    if (research._id) {
      api.put(`researches/${research._id}`, { sections: research.sections });
    }
  }, [research]);

  const questionsToBeAnswered = useMemo(
    () =>
      research.sections
        .map(r_section =>
          r_section.questions.map(r_question => r_question.options),
        )
        .flat()
        .filter(value => value === null).length,
    [research.sections],
  );

  const progress = useMemo(() => {
    // eslint-disable-next-line prettier/prettier
    const totalQuestions = research.sections.map(sec => sec.questions).flat()
      .length;

    const value =
      (100 * (totalQuestions - questionsToBeAnswered)) / totalQuestions;

    return Math.round(value);
  }, [questionsToBeAnswered, research.sections]);

  return (
    <ResearchContext.Provider
      value={{
        user,
        setUser,
        research,
        setResearch,
        currentSection,
        setCurrentSection,
        currentQuestion,
        setCurrentQuestion,
        switchQuestionOrSection,
        questionsToBeAnswered,
        progress,
      }}
    >
      {children}
    </ResearchContext.Provider>
  );
};

export function useResearch(): ResearchContextData {
  const context = useContext(ResearchContext);

  if (!context)
    throw new Error('useResearch must be used within a ResearchProvider');

  return context;
}

export default useResearch;
