import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  background-color: var(--white);
  box-shadow: 0px 3px 20px #00000029;
  border-radius: 20px;
  padding: 20px 30px 20px 20px;
  color: #707070;
  width: 100%;
  min-height: 225px;

  .position {
    position: absolute;
    width: 84%;
    border-radius: 20px;
    z-index: 2;
  }

  .svg-lock {
    position: absolute;
    left: -32px;
    top: 0px;
    width: 200px;
    height: 250px;
    color: #707070;
    opacity: 0.1;
    z-index: 1;
  }

  h2 {
    font: normal normal bold 14px/18px Nunito;
    margin-bottom: 20px;
  }

  p {
    font: normal normal normal 14px/18px 'Mulish';
    margin-bottom: 20px;
  }

  .buttons {
    button {
      position: absolute;
      top: 20px;
      right: 10px;
      height: 1.5rem;
      width: 1.5rem;
      background: transparent;
      color: var(--teal);

      svg {
        height: 100%;
        width: 100%;
      }
    }

    > div + div {
      padding: 0px 15px;
      border-radius: 10px;
      margin: 0px 10px;
      min-width: 110px;
    }
  }

  .dropdown-container {
    position: absolute;
    right: 0;
    top: 20%;
    z-index: 998;
  }

  .footer {
    justify-content: space-around;
    display: flex;

    .footer-button {
      min-width: 200px;
      border: 2px solid #28bce0;
      border-radius: 53px;
      font: normal normal bold 14px/18px 'Mulish';
      letter-spacing: 0px;
      color: #28bce0;
      padding: 10px 15px;
      cursor: pointer;

      &:hover {
        background-color: var(--teal);
        color: var(--white);
      }
    }
  }

  @media screen and (min-width: 425px) {
    .svg-lock {
      top: -25px;
    }
  }
  @media screen and (min-width: 768px) {
    width: 350px;
    min-height: 280px;
    padding: 30px 40px;
    .position {
      width: 76%;
    }
    .svg-lock {
      left: -45px;
      top: 0px;
      width: 270px;
      height: 280px;
    }
    h2 {
      font: normal normal bold 16px/20px Nunito;
    }
    p {
      font: normal normal normal 16px/20px 'Mulish';
    }
    .dropdown-container {
      right: -105px;
    }
    .footer {
      .footer-button {
        padding: 12px 18px;
        font: normal normal bold 16px/20px 'Mulish';
      }
    }
  }
  @media screen and (min-width: 992px) {
    width: 350px;
    min-height: 325px;
    padding: 30px 40px;
    .position {
      width: 78%;
    }
    .svg-lock {
      top: 25px;
    }
    h2 {
      font: normal normal bold 24px/28px Nunito;
    }
    p {
      font: normal normal normal 18px/22px 'Mulish';
    }
    .dropdown-container {
      top: 15%;
    }
    .footer {
      .footer-button {
        padding: 15px 20px;
        font: normal normal bold 20px/24px 'Mulish';
      }
    }
  }
`;
