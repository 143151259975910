import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../contexts/auth';
import { useToast } from '../contexts/toast';
import { api } from '../services/api';

import { ICompanyResponse } from '../types/ICompanyResponse';
import { ICookieBar } from '../types/ICookieBar';

const companyRespInit = {
  company: {
    _id: '',
    document: '',
    fantasy_name: '',
    createdAt: '',
    updatedAt: '',
  },
  cookie_bars: [],
  policies: [],
  users: [],
  dsrs: [],
  plan: null,
};

interface useManageCookieBarReturn {
  cookieBars: ICookieBar[];
  showTooltip: boolean;
  tryFetchPolicy: boolean;
  showDelCookiesModal: boolean;
  showPublishPolicy: boolean;
  setShowTooltip: Dispatch<SetStateAction<boolean>>;
  onDelete: (cookieBarId: string, straightFlow?: boolean, isBonded?: boolean) => Promise<void>;
  handleClickCloseDelCookies: () => void;
  handleClickShowPublishPolicy: () => void;
  handleClosePublishPolicy: () => void;
}

export const useManageCookieBar = (): useManageCookieBarReturn => {
  const history = useHistory();
  const { addToast } = useToast();
  const { user, updateUser } = useAuth();

  const [companyResponse, setCompanyResponse] = useState<ICompanyResponse>(
    companyRespInit,
  );
  const [cookieBars, setCookieBars] = useState<ICookieBar[]>([]);
  const [showTooltip, setShowTooltip] = useState(false);
  const [tryFetchPolicy, setTryFetchPolicy] = useState(false);
  const [cookieBarIdToBeDeleted, setCookieBarIdToBeDeleted] = useState('');

  // Delete flow
  const [showDelCookiesModal, setShowDelCookiesModal] = useState(false);
  const [showPublishPolicy, setShowPublishPolicy] = useState(false);

  useEffect(() => {
    if (!user.company_id) history.goBack();

    try {
      const loadCookieBars = async () => {
        setTryFetchPolicy(false);
        const { data } = await api.get<ICompanyResponse>(
          `/companies/${user.company_id?._id}`,
        );

        if (data.policies && data.policies.length > 0) {
          data.company.hasPolicy = true;
        }

        setCookieBars(data.cookie_bars);
        setCompanyResponse(data);
        setTryFetchPolicy(true);
      };

      loadCookieBars();
    } catch (error) {
      setTryFetchPolicy(true);
      addToast({
        type: 'error',
        title: 'Falha ao carregar',
        description: 'Ocorreu um erro ao carregar as Barras de Cookies',
      });
    }
  }, [history, user.company_id, addToast]);

  const onDelete = useCallback(
    async (cookieBarId: string, straightFlow = false, isBonded = false) => {
      setCookieBarIdToBeDeleted(cookieBarId);

      // @TODO Check if the policy was published as HTML or not
      // Check if the user has policy
      if (companyResponse.company.hasPolicy && !straightFlow) {
        setShowDelCookiesModal(true);
      } else {
        try {
          setTryFetchPolicy(false);
          // await api.delete(`/policies/${cookieBarId}`);

          setCookieBars(prev =>
            prev.filter(cookieBar => cookieBar._id !== cookieBarId),
          );
          setCookieBarIdToBeDeleted('');

          const { hasPolicy } = user.company_id;

          updateUser({
            ...user,
            company_id: {
              ...user.company_id,
              hasCookieBar: false,
              hasPolicy: isBonded ? false : hasPolicy,
              cookie_id: null
            },
          });

          let description;
          if (isBonded) {
            description = 'Política de privacidade e Barra de cookies excluídas com sucesso!';
          } else {
            description = 'Barra de cookies excluída com sucesso! Exclua o HTML do seu site!';
          }

          addToast({
            type: 'success',
            title: 'Sucesso',
            description,
          });
          setTryFetchPolicy(true);
        } catch (error) {
          setTryFetchPolicy(true);
          addToast({
            type: 'error',
            title: 'Falha ao Excluir',
            description: 'Ocorreu uma falha ao tentar excluir a barra de cookies',
          });
        }
      }
    }, [user, companyResponse.company.hasPolicy, addToast, updateUser],
  );

  const handleClickCloseDelCookies = () => {
    setShowDelCookiesModal(false);
  };

  const handleClickShowPublishPolicy = () => {
    setShowDelCookiesModal(false);
    // setShowPublishPolicy(true);
    onDelete(cookieBarIdToBeDeleted, true);
  };

  const handleClosePublishPolicy = () => {
    setShowPublishPolicy(false);
  };


  return {
    cookieBars,
    showTooltip,
    tryFetchPolicy,
    showDelCookiesModal,
    showPublishPolicy,
    setShowTooltip,
    onDelete,
    handleClickCloseDelCookies,
    handleClickShowPublishPolicy,
    handleClosePublishPolicy
  };
};
