import React, { useState, useRef, useEffect, useCallback } from 'react';

import { IoMdMore } from 'react-icons/io';
import { CgTrashEmpty } from 'react-icons/cg';
import { BiLock, BiPencil } from 'react-icons/bi';

import { useHistory } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';
import { DropdownMenu } from '../DropdownMenu';
import { MaturityButton } from '../MaturityButton';

import { Container } from './styles';
import { ConfirmDelete } from '../ConfirmDelete/styles';
import { ModalMessageImportant } from '../ModalMessageImportant';
import { useAuth } from '../../contexts/auth';
import { api } from '../../services/api';

interface PolicyCardProps {
  _id: string;
  site_url: string;
  publish: string;
  completed: boolean;
  onDelete(policyId: string, straightFlow?: boolean, isBonded?: boolean): void;
  onPublish(): void;
}

export const PolicyCard: React.FC<PolicyCardProps> = ({
  _id,
  completed,
  onDelete,
  onPublish,
}) => {
  const { user } = useAuth();
  const history = useHistory();
  const [openMenu, setOpenMenu] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [isBonded, setIsBonded] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setOpenMenu(false);
        setOpenConfirmDelete(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    const options = [];

    if (completed) {
      options.push({
        label: 'Editar Política',
        action: () => history.push(`politicas/${_id}`),
        icon: BiPencil,
        upgradeTag: false,
        active: true
      });
    }

    options.push({
      label: 'Excluir Política',
      action: () => setOpenConfirmDelete(true),
      icon: CgTrashEmpty,
      upgradeTag: false,
      active: true
    });

    setDropdownOptions(options);
  }, [_id, completed, onPublish, history]);

  const handleClickNo = () => {
    setOpenMenu(false);
    setOpenConfirmDelete(false);
  };

  const handleDeletePolicyCard = async () => {
    setOpenMenu(false);
    setOpenConfirmDelete(false);
    onDelete(_id, false, isBonded);
  };

  const getCompanies = useCallback(async () => {

    const response = await api.get(`/companies/${user.company_id._id}`)
    const company = response.data;

    if (company.policies.length > 0 && company.policies[0].cookie_bar_bond) {
      return setIsBonded(true);
    }
    return setIsBonded(false);
  }, [user.company_id]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  return (
    <Container>
      <BiLock className="svg-lock" />
      <div className="position">
        <h2>Política de Privacidade Privacyflow</h2>

        {completed && (
          <p>
            Esta é a política de privacidade do Privacyflow, um produto Certsys
            que possui o objetivo de atender as necessidades das empresas para
            relacionamento com o titular de dados de forma simples e inovadora.
            Todas as disposições nesta política irão de acordo com a Política...
          </p>
        )}

        {!completed && (
          <>
            <p>
              Sua política ainda não foi publicada e o preenchimento ainda está
              em andamento, por favor conclua para publicar.
            </p>
            <div className="footer">
              <MaturityButton
                className="footer-button"
                onClick={() => history.push(`politicas/${_id}`)}
              >
                Continuar com política
              </MaturityButton>
            </div>
          </>
        )}
      </div>

      <div className="buttons">
        <button type="button" onClick={() => setOpenMenu(prev => !prev)}>
          <IoMdMore />
        </button>
      </div>

      <div ref={ref} className="dropdown-container">
        {openMenu && <DropdownMenu options={dropdownOptions} />}
        {openConfirmDelete && (
          isBonded ? (
            <ModalMessageImportant
              title="Um recado importante sobre esta ação:"
              content="Ao excluir a Política, a Barra de cookies também será excluída."
              handleSuccess={handleDeletePolicyCard}
              handleCancel={handleClickNo}
            >
              <FiTrash2 className="svg-link" />
            </ModalMessageImportant>
          ) : (
            <ConfirmDelete>
              <p>Tem certeza que deseja excluir essa Política?</p>
              <footer>
                <button type="button" onClick={handleDeletePolicyCard}>
                  Sim
                </button>
                <button type="button" onClick={handleClickNo}>
                  Não
                </button>
              </footer>
            </ConfirmDelete>
          )
        )}
      </div>
    </Container>
  );
};
