/* eslint-disable jsx-a11y/label-has-associated-control */
import { Checkbox } from '@material-ui/core';
import { SketchPicker } from 'react-color';
import { useHistory } from 'react-router-dom';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import { IoMdCheckboxOutline } from 'react-icons/io';
import rgbHex from 'rgb-hex';


import React, { useEffect, useRef, useState } from 'react';
import { MaturityHeader } from '../../../../../components/MaturityHeader';
import { ServicesHeader } from '../../../../../components/ServicesHeader';
import { MaturityButton } from '../../../../../components/MaturityButton';
import imgBackgroundOpacity from '../../../../../assets/backgroundOpacity.png';


import {
    Container,
    Content,
    Constructor,
    ConstructorEmail,
    ConstructorBlock,
    ConstructorCard,
    ConstructorSubCard,
    ButtomExa,
    ColorButton,
    Visualization,
    ConstructorURL
} from './styles'
import { useCreateDSR } from '../../../../../hooks/useCreateDSR';
import PublishPolicy from '../../../../../components/PublishPolicy';
import RouteLeavingGuard from '../../../../../routes/RouteLeavingGuard';

const FONT_OPTIONS = ['Roboto', 'Arial'];


export const CreateDSR: React.FC = () => {
    const {
        stepsCompleted,
        showBarPicker,
        url,
        fontWeight,
        fontSize,
        generateHTML,
        loading,
        setShowBarPicker,
        setUrl,
        setGenerateHTML,
        requestSave,
        closeModal,
        handleCreateEditDSR,

        emphasisColor,
        fontColor,
        fontFamily,
        setEmphasisColor,
        setFontColor,
        setFontFamily,
        email,
        setEmail,
        firstAccess,
        setRequestSave,
      } = useCreateDSR();

      const colorPickerRef = useRef<HTMLDivElement>(null);
      const showHtmlRef = useRef<HTMLDivElement>(null);

      const [emailValid, setEmailValid] = useState(true);
      const history = useHistory();

      // Validate the email
      function validationEmail(e: string){
        const validation = /\S+@\S+\.\S+/;
        if(!validation.test(e)){
           setEmailValid(false)
           return
        }
        setEmailValid(true) 
      }
    useEffect(() => {
        function handleClickOutside(event: any) {
          if (
            colorPickerRef.current &&
            !colorPickerRef.current.contains(event.target)
          ) {
            setShowBarPicker(false);
          }
          if (showHtmlRef.current && !showHtmlRef.current.contains(event.target)) {
            setGenerateHTML(false);
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [colorPickerRef, setGenerateHTML, setShowBarPicker]);

    return (
      <Container>
        <MaturityHeader />
        <ServicesHeader pageTitle="Gerador de Formulário de solicitação (DSR)" />
        <Content>
          <Constructor>
            {stepsCompleted < 2 && <h2>Informe a URL do seu site</h2>}
            {stepsCompleted > 1 && <h2>Personalizar</h2>}
            <ConstructorURL>
              {stepsCompleted < 2 && (
              <form onSubmit={(event) => {
                event.preventDefault()
                handleCreateEditDSR({
                  isCreation: true,
                  openPopup: false,
                })
              }}
              >
                <input
                  className="url-input first"
                  value={url.replace('https://', '')}
                  type="text"
                  placeholder="www.seusite.com.br"
                  onChange={e => setUrl(e.target.value)}
                  disabled={stepsCompleted > 1 || loading}
                />
                <MaturityButton
                  className="url-btn"
                  loading={loading}
                  type="button"
                  onClick={() =>
                    handleCreateEditDSR({
                      isCreation: true,
                      openPopup: false,
                    })
                  }
                >
                  Concluir
                </MaturityButton>
              </form>
            )}
            </ConstructorURL>
            
            {stepsCompleted > 1 && (
            <ConstructorEmail>
              <input
                className="email-input second"
                value={email}
                type="email"
                placeholder="exemplo@email.com"
                onChange={e => {setEmail(e.target.value); validationEmail(e.target.value)}}
              />
              {!emailValid && <p>E-mail inválido</p>}
            </ConstructorEmail>
            )}
            {(email && emailValid) && (
            <ConstructorBlock>
              <ConstructorCard>
                <h2>Personalize seu Formulário de solicitação (DSR)</h2>
                <ConstructorSubCard>
                  <div className="constructor__attributes__sub-card__row">
                    <span className="title--center">Cor destaque:</span>

                    <div className="constructor__attributes__sub-card__row__end">
                      <span>
                        <ButtomExa onClick={() => setShowBarPicker(prev => !prev)}>{emphasisColor.toUpperCase()}</ButtomExa>
                      </span>
                      <ColorButton
                        className="bar-color"
                        color={emphasisColor}
                        type="button"
                        onClick={() => setShowBarPicker(prev => !prev)}
                      />
                      {showBarPicker && (
                        <aside ref={colorPickerRef}>
                          <SketchPicker
                            onChangeComplete={(c) => setEmphasisColor(`#${rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a)}`)}
                            color={emphasisColor}
                          />
                        </aside>
                      )}
                    </div>
                  </div>

                  <div className="constructor__attributes__sub-card__row">
                    <span className="title--center">Fonte:</span>

                    <div className="constructor__attributes__sub-card__row__end">
                      {FONT_OPTIONS.map(font => (
                        <div key={font}>
                          <span className="ml-8" />
                          <Checkbox
                            icon={<MdCheckBoxOutlineBlank color="#707070" />}
                            checkedIcon={
                              <IoMdCheckboxOutline color="#707070" />
                            }
                            value={font}
                            onChange={() => setFontFamily(font)}
                            checked={fontFamily === font}
                          />
                          <button
                            type="button"
                            className="font-name"
                            onClick={() => setFontFamily(font)}
                          >
                            {font}
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="constructor__attributes__sub-card__row">
                    <span className="title--center">Cor da fonte:</span>

                    <div className="constructor__attributes__sub-card__row__end">
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank color="#707070" />}
                        checkedIcon={<IoMdCheckboxOutline color="#707070" />}
                        value="#EEEEEE"
                        onChange={() => setFontColor('#EEEEEE')}
                        checked={fontColor === '#EEEEEE'}
                      />
                      <ColorButton
                        className="font-color"
                        color="#EEEEEE"
                        type="button"
                        onClick={() => setFontColor('#EEEEEE')}
                      />
                      <span className="ml-28" />
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank color="#707070" />}
                        checkedIcon={<IoMdCheckboxOutline color="#707070" />}
                        value="#707070"
                        onChange={() => setFontColor('#707070')}
                        checked={fontColor === '#707070'}
                      />
                      <ColorButton
                        className="font-color"
                        color="#707070"
                        type="button"
                        onClick={() => setFontColor('#707070')}
                      />
                    </div>
                  </div>

                  <div className="constructor__attributes__sub-card__row">
                    <span className="title--center">Cor de fundo:</span>

                    <div className="constructor__attributes__sub-card__row__end">
                      <span className="ml-28" />
                      <Checkbox
                        icon={<MdCheckBoxOutlineBlank color="#707070" />}
                        checkedIcon={<IoMdCheckboxOutline color="#707070" />}
                        className="check-background-opacity"
                        checked
                        
                      />
                      <img className="font-color" src={imgBackgroundOpacity} alt="Imagem de fundo transparente" />
                    </div>
                  </div>
                </ConstructorSubCard>
                <h2 className="title-with-subtitle">Prévia do formulário</h2>
                <p>Essa interface é como os titulares de dados enxergarão o formulário</p>
                <Visualization 
                  emphasisColor={emphasisColor}
                  fontFamily={fontFamily}
                  fontSize={fontSize}
                  fontWeight={fontWeight}
                  fontColor={fontColor}
                >
                  <div className="tooltip">
                    {[1, 2, 3].map(t => (
                      <span key={`tooltip-${t}`} />
                    ))}
                  </div>

                  <div className='geral'>
                    <p id="title">
                      Como titular de seus dados pessoais, você pode exercer seus direitos e solicitar as opções abaixo.
                    </p>
                    <div className='group-itens'>
                      <label className='radio-container'>
                        <input type="radio" name="radio" id="" disabled />
                        <span className='checkmark' />
                      </label>
                      <div>
                        <p className='itens-title'>Retificar</p>
                        <p className='itens-content'>Gostaria de portar meus dados para outra empresa de serviço ou produto</p>
                      </div>
                    </div>
                    <div className='group-itens'>
                      <label className='radio-container'>
                        <input type="radio" name="radio" id="" disabled />
                        <span className='checkmark' />
                      </label>
                      <div>
                        <p className='itens-title'>Portabilidade</p>
                        <p className='itens-content'>Gostaria de portar meus dados para outra empresa de serviço ou produto</p>
                      </div>
                    </div>
                    <div className='group-itens'>
                      <label className='radio-container'>
                        <input type="radio" name="radio" id="" checked disabled />
                        <span className='checkmark' />
                      </label>
                      <div>
                        <p className='itens-title'>Confirmação</p>
                        <p className='itens-content'>Quero confirmar/consultar quais dados vocês tem a meu respeito</p>
                      </div>
                    </div>
                    <div className='group-itens'>
                      <label className='radio-container'>
                        <input type="radio" name="radio" id="" disabled />
                        <span className='checkmark' />
                      </label>
                      <div>
                        <p className='itens-title'>Acesso</p>
                        <p className='itens-content'>Quero uma cópia de todos os dados que vocês tem a meu respeito</p>
                      </div>
                    </div>
                    <div className='group-itens'>
                      <label className='radio-container'>
                        <input type="radio" name="radio" id="" disabled />
                        <span className='checkmark' />
                      </label>
                      <div>
                        <p className='itens-title'>Eliminação</p>
                        <p className='itens-content'>Me arrependi de dar o meu consentimento e quero apagar todos os meus dados</p>
                      </div>
                    </div>
                    <div className='group-itens'>
                      <label className='radio-container'>
                        <input type="radio" name="radio" id="" disabled />
                        <span className='checkmark' />
                      </label>
                      <div>
                        <p className='itens-title'>Compartilhamento de dados</p>
                        <p className='itens-content'>Quero consultar se vocês compartilham os meus dados, com quem e porquê.</p>
                      </div>
                    </div>
                    <div className='button-content'>
                      <button type="submit">Solicitar</button>
                    </div>
                  </div>
                </Visualization>
              </ConstructorCard>
            </ConstructorBlock>
            )}
            
            {(email && emailValid) && (
            <ConstructorBlock>
              <div className="save-button-wrapper">
                <MaturityButton
                  className="cookie-button"
                  loading={loading}
                  onClick={() =>
                    handleCreateEditDSR({
                      isCreation: false,
                      openPopup: true,
                    })
                  }
                >
                  Salvar preferências
                </MaturityButton>
              </div>
            </ConstructorBlock>
            )}

            {generateHTML && (
              <PublishPolicy
                origin="DSR"
                handleFinishCallback={closeModal}
                isCompleted={!firstAccess}
              />
            )}
            
          </Constructor>
        </Content>

        <RouteLeavingGuard
          when={requestSave}
          navigate={path => history.push(path)}
          shouldBlockNavigation={() => requestSave}
          handleAction={() => setRequestSave(false)}
          icon="rightArrowCircle"
          title="Você esqueceu de salvar suas alterações, deseja sair da página mesmo assim?"
          message=" "
          closeMessage="Sair"
          successMessage="Continuar editando"
        />
      </Container>
  );
};
