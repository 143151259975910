/* eslint-disable react/no-danger */
import React from 'react';

import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';

import { MaturityButton } from '../MaturityButton';

import { Container, Card } from './styles';

interface PolicyPreviewProps {
  rawHTML: string;
  showNextButton?: boolean;
  showPreviousButton?: boolean;
  nextButtonCallback?: () => void;
  previousButtonCallback?: () => void;
}

export const PolicyPreview: React.FC<PolicyPreviewProps> = ({
  rawHTML,
  showNextButton,
  showPreviousButton,
  nextButtonCallback,
  previousButtonCallback,
}) => {

  return (
    <Container>
      <Card>
        <div dangerouslySetInnerHTML={{ __html: rawHTML }} />
      </Card>

      {/* @TODO: Only enable the buttons below when the user scrolls 
            until the bottom of the page */}
      <div className="save-button-wrapper">
        {showPreviousButton && (
          <MaturityButton
            className="previous-button"
            onClick={previousButtonCallback}
          >
            <HiOutlineArrowNarrowLeft />
            Voltar
          </MaturityButton>
        )}
        {showNextButton && (
          <MaturityButton
            className="next-button"
            onClick={nextButtonCallback}
          >
            Concluir etapa
          </MaturityButton>
        )}
      </div>
    </Container>
  );
};
