import styled from 'styled-components';

export const Container = styled.header`
  height: 6rem;
  min-width: 100%;
  background: var(--white-gray);
  display: flex;
  justify-content: center;
  box-shadow: 0px 3px 30px #00000029;
  padding: 1rem;
  z-index: 250;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1800px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  cursor: default;

  button {
    background: none;
    cursor: default;

    &:hover {
      cursor: default;
      filter: brightness(0.9);
    }
  }

  img {
    width: 12.5rem;
    cursor: default;
  }

  > aside {
    display: flex;
    align-items: center;
  }
`;
