import { Dispatch, SetStateAction, useState } from 'react';

import { IPolicyStep } from '../types/IPolicyStep';

interface usePolicyStepsReturn {
  policySteps: IPolicyStep[];
  setPolicySteps: Dispatch<SetStateAction<IPolicyStep[]>>;
}

export const usePolicySteps = (): usePolicyStepsReturn => {
  const [policySteps, setPolicySteps] = useState<IPolicyStep[]>([
    {
      type: 'checkbox',
      title: 'Dados básicos',
      subtitle: 'Selecione os dados que o seu site captura do usuário final.',
      show: false,
      confirm: false,
      isActive: true,
      data: [
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Nome completo',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Endereço completo',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Telefone / Celular',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'E-mail',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Profissão',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Idade',
          type: '',
        },
      ],
    },
    {
      type: 'checkbox',
      title: 'Dados do dia a dia',
      subtitle:
        'Selecione os dados que o seu site captura no dia a dia de uso.',
      show: false,
      confirm: false,
      isActive: false,
      data: [
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Palavras-chaves utilizadas em uma busca',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Compartilhamento de documento específico',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Comentários e avaliações sobre algo',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Páginas visualizadas',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Perfis e preferências',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'URL de onde o usuário e visitante vieram﻿﻿﻿﻿',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Navegador que utilizam',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'IPs de acesso, dentre outras que poderão ser armazenadas e retidas',
          type: '',
        },
      ],
    },
    {
      type: 'checkbox',
      title: 'Dados comerciais',
      subtitle: 'Selecione os dados financeiros que o seu site captura.',
      show: false,
      confirm: false,
      isActive: false,
      data: [
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Empresa em que trabalha',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Endereço comercial completo',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          type: '',
          label: 'E-mail(s) profissional(is)',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          type: '',
          label: 'Número do(s) telefone(s)',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          type: '',
          label: 'CNPJ',
        },
      ],
    },
    {
      type: 'checkbox',
      title: 'Dados financeiros',
      subtitle: 'Selecione os dados financeiros que o seu site captura.',
      show: false,
      confirm: false,
      isActive: false,
      data: [
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Dados sobre o cartão de crédito',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Endereço para cadastro em nota fiscal',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Transação / Pagamento via Pix',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'CPF e/ou CNPJ',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Nome, agência e conta bancária para pagamentos diretos',
          type: '',
        },
        {
          check: false,
          isOnly: true,
          value: '',
          label: 'Não utilizo dados financeiros',
          type: '',
        },
      ],
    },
    {
      type: 'checkbox',
      title: 'Dados sensíveis',
      subtitle: 'Selecione os dados sensíveis que seu site captura.',
      show: false,
      confirm: false,
      isActive: false,
      data: [
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Origem étnica ou racial',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Opinião política',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Convicção religiosa',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Dados relativos à saúde',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Dados biométricos',
          type: '',
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Dados relativos a orientação sexual',
          type: '',
        },
        {
          check: false,
          isOnly: true,
          value: '',
          label: 'Não utilizo dados sensíveis',
          type: '',
        },
      ],
    },
    {
      type: 'input',
      title: 'Dados da pessoa Encarregada (DPO)',
      subtitle:
        'De acordo com A Lei Geral de Proteção de Dados Pessoais, Lei nº 13.709/2018, uma pessoa da empresa precisa ser o ponto de contato com quem usa o seu site. Esta pessoa é o Encarregado de Dados, também conhecida como DPO (Data Protection Officer), e deve deixar um contato explícito na política para dúvidas, exclusões ou gerenciamento de dados.',
      show: false,
      confirm: false,
      isActive: false,
      data: [
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'Nome da pessoa Encarregada de Dados',
          type: 'text',
          required: true,
          minLength: 3,
          maxLength: 200,
        },
        {
          check: false,
          isOnly: false,
          value: '',
          label: 'E-mail comercial da pessoa Encarregada de Dados',
          type: 'email',
          required: true,
          minLength: 5,
          maxLength: 200,
        }
      ],
    },
  ]);

  // Input retirado
  // {
  //   check: false,
  //   isOnly: false,
  //   value: '',
  //   label: 'Telefone comercial',
  //   type: 'tel',
  // },

  return {
    setPolicySteps,
    policySteps,
  };
};
