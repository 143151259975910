import styled, { css } from 'styled-components';

interface ContainerProps {
  backgroundColor?: string;
  active?: boolean;
  upgradeTag?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const Content = styled.div<ContainerProps>`
  position: relative;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  padding: 30px 15px;
  border-radius: 1rem;
  background: var(--yellow);
  height: 100%;
  width: 100%;

  color: var(--white);

  background: ${props => `var(--${props.backgroundColor})`};

  box-shadow: 0 3px 0.5rem #00000029;

  transition: all 0.4s;

  ${props =>
    !props.active &&
    css`
      cursor: not-allowed;
      background: #b0b0b0b0;
    `};

  ${props =>
    !props.active && props.upgradeTag &&
    css`
      cursor: pointer;
    `};

  ${props =>
    !props.active && !props.upgradeTag &&
    css`
      cursor: not-allowed;
    `};

  ${props =>
    props.active &&
    css`
      &:hover {
        box-shadow: 0px 3px 1rem #000000aa;
        transform: translateY(2px);
        cursor: pointer;
      }
    `};

  > svg {
    opacity: 0.18;
    position: absolute;
    top: -8%;
    left: -16%;
    width: 110%;
    height: 110%;
  }

  header {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: auto;

    font-size: 1.5rem;
    font-style: italic;
    font-weight: 200;
  }

  section {
    h2 {
      display: block;
      width: 100%;
      font: normal normal bold 16px/20px Nunito;
      letter-spacing: 0px;
      color: var(--white);
    }

    p {
      display: block;
      width: 100%;
      margin-top: 1rem;
      font: normal normal 600 12px/16px 'Mulish';
      letter-spacing: 0px;
      color: var(--white);
    }
  }

  footer button {
    position: relative;
    display: flex;
    align-items: center;
    background: transparent;
    color: var(--white);
    margin-top: 2rem;
    transition: all 0.2s ease-in-out;

    ${props =>
      !props.active && props.upgradeTag &&
      css`
        cursor: pointer;
      `};

    ${props =>
      !props.active && !props.upgradeTag &&
      css`
        cursor: not-allowed;
      `};

    svg {
      height: 1.25rem;
      width: 1.25rem;
      margin-left: 1.5rem;
    }
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  left: 10%;
  top: calc(100% - 20px);
  z-index: 9;
  box-shadow: 0 3px 6px #00000029;
`;

interface FooterButtonProps {
  flipIcon?: boolean;
}

export const FooterButton = styled.button.attrs({
  type: 'button',
})<FooterButtonProps>`
  font: normal normal bold 12px/16px 'Mulish';
  letter-spacing: 0px;
  color: var(--white);

  ${props =>
    props.flipIcon &&
    css`
      svg {
        transform: rotateX(180deg);
      }
    `}
`;
