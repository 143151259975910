import { darken } from 'polished';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;

  overflow: scroll;

  font-family: 'Mulish', sans-serif;
`;

export const Content = styled.main`
  width: 100%;
  max-width: 1800px;
  align-self: center;
  display: flex;
  flex-direction: column;
  padding: 0 4rem;
  margin-top: 2rem;

  section {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 3rem;

    &:first-child > div {
      &:first-child {
        padding: 2rem;

        height: 14.75rem;
        width: 19.75rem;

        h1 {
          font-size: 5rem;
        }

        span {
          font-size: 1.5rem;
        }
      }

      background: var(--white);
      box-shadow: 0px 3px 30px #00000029;
      border-radius: 1.25rem;
      padding: 1rem;

      height: 12.75rem;
      width: 17.75rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;

      h1 {
        color: var(--purple);
        font-size: 4rem;
        font-weight: 900;
      }

      span {
        color: var(--gray);
        font-size: 1.2rem;
      }
    }
  }

  section + section {
    margin-top: 5rem;
    flex-direction: column;
    gap: 1.5rem;

    > div {
      &:first-child span {
        color: var(--gray);
        font-size: 1.25rem;
        font-weight: bold;
        border: none;
      }

      display: flex;
      justify-content: space-between;
      gap: 4rem;

      span {
        border-bottom: 1px solid var(--gray);
        width: 17.5rem;
        text-align: center;
        font-size: 1.5rem;
        padding: 1rem 0;
      }
    }

    footer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      color: var(--gray);
      font-size: 1.25rem;
      font-weight: bold;
      margin-top: 2rem;
      margin-bottom: 4rem;

      button {
        position: relative;
        background: transparent;
        height: 1rem;
        width: 1rem;

        svg {
          position: absolute;
          top: -7.5px;
          height: 2rem;
          width: 2rem;
          margin: auto;
          color: var(--black);
        }

        .chevron--left {
          right: 15px;
        }
        .chevron--right {
          left: 15px;
        }
      }
    }
  }
`;

interface MenuButtonProps {
  isOpen: boolean;
}

export const MenuButton = styled.button<MenuButtonProps>`
  position: relative;
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;

  background-color: inherit;

  transition: all 0.2s ease;

  ${props =>
    props.isOpen &&
    css`
      background-color: rgba(255, 255, 255, 0.2);
    `}

  > svg {
    color: var(--white);
    height: 2.5rem;
    width: 2.5rem;
    margin: auto;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  left: 0;
  transform: translateX(-100%);
  top: 0;
  width: 15rem;
  z-index: 9;
  box-shadow: 0px 3px 6px #00000029;
`;

export const ContainerButtonHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
