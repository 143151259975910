import React, { useEffect, useState } from 'react';

import { GenericTable } from '../../../../components/GenericTable';
import { GenericPage } from '../../../../components/GenericPage';

import { api } from '../../../../services/api';
import { useToast } from '../../../../contexts/toast';
import { formatDate } from '../../../../utils/getFormatDate';

import { ICookieBar } from '../../../../types/ICookieBar';
import { ICookie } from '../../../../types/ICookie';

const HEADER = [
  { 
    key: 'site_url',
    order: 1,
    label: 'URL do site', 
    textAlign: 'left'
  },
  { 
    key: 'font_family',
    order: 2,
    label: 'Fonte', 
    textAlign: 'left'
  },
  { 
    key: 'font_size',
    order: 3,
    label: 'Tamanho', 
    textAlign: 'left'
  },
  { 
    key: 'cookie_list',
    order: 4,
    label: 'Cookies', 
    textAlign: 'left',
    callback: (v: ICookie[]) => `${v.length} Cookies`
  },
  {
    key: 'createdAt',
    order: 5,
    label: 'Criação',
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  },
  {
    key: 'updatedAt',
    order: 6,
    label:  'Última alteração',
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  }
];

const ACTIONS = [
  { 
    type: 'link', 
    label: 'Selecionar',  
    link: '/backoffice/barras-de-cookies', 
    bgColor: 'var(--dark-gray)', 
    color: 'var(--white)',
    linkCallback: (l: string, v: ICookieBar) => `${l}/${v._id}`
  },
  { 
    type: 'button', 
    label: 'Apagar', 
    link: '', 
    callback: (v: ICookieBar) => console.log('Tem certeza que deseja excluir?', v), 
    bgColor: 'var(--red)', 
    color: 'var(--white)'
  }
];

export const CookieBarList: React.FC = () => {
  const { addToast } = useToast();

  const [cookieBars, setCookieBars] = useState<ICookieBar[]>([]);
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const loadCookieBars = async () => {
      try {
        setLoading(true);
        const { data } = await api.get<ICookieBar[]>('/cookie_bars');
        if (data && data.length > 0) {
          setCookieBars(data)
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Falha ao carregar',
          description: 'Ocorreu um erro ao carregar as barras de cookies',
        });
      }
    };
      
    loadCookieBars();
      
  }, [addToast]);

  return (
    <GenericPage
      breadcrumb="BackOffice > "
      pageTitle="Barras de Cookies"
      loading={loading}
    >
      {cookieBars.length > 0 && (
        <GenericTable 
          headerData={HEADER}
          actionData={ACTIONS}
          bodyData={cookieBars}
          title="Barras de Cookies"
        />
      )}
    </GenericPage>
  )
};
