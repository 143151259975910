import React from 'react';
import {
  Route as DOMRoute,
  RouteProps as ReactDOMRouteProps,
  Redirect,
} from 'react-router-dom';
import _ from 'lodash';

import { useAuth } from '../contexts/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();

  const redirectPathname = () => {
    if (isPrivate) return '/';

    if (user.no_pass) {
      return '/trocar-senha';
    }
    return '/atividades/corporativo';
  };

  return (
    <DOMRoute
      {...rest}
      render={({ location }) => {
        let pathName = _.get(location, 'pathname', '');
        pathName = pathName === '/' ? '' : pathName;
        const search = _.get(location, 'search', '');

        if (isPrivate === !!user) {
          return <Component />
        }
        
        if (pathName || search) {
          return (
            <Redirect
              to={{
                pathname: redirectPathname(),
                state: { from: location },
                search: pathName ? `?redirect=${pathName}` : search
              }}
            />
          )
        }

        return (
          <Redirect
            to={{
              pathname: redirectPathname(),
              state: { from: location },
            }}
          />
        )
      }}
    />
  );
};

export default Route;
