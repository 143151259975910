import React, { useRef, useCallback, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { FiArrowRight } from 'react-icons/fi';
import { CgSmile } from 'react-icons/cg';
import _ from 'lodash';

import { useHistory } from 'react-router-dom';
import { useToast } from '../../contexts/toast';
import { useAuth } from '../../contexts/auth';
import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/NewInput';
import { MaturityButton as Button } from '../../components/MaturityButton';

import { Container, Content, Modal, ContentModal } from './styles';
import { MaturityHeader } from '../../components/MaturityHeader';
import { api } from '../../services/api';
import { parseQueryStr } from '../../utils/parseQueryString';

interface NewPasswordData {
  newPassword: string;
  confirmNewPassword: string;
}

export const ChangePassword: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { user, updateUser } = useAuth();
  const { addToast } = useToast();

  const [openModal, setOpenModal] = useState(true);

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: NewPasswordData) => {
      try {
        const search = _.get(history, 'location.search', false);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          newPassword: Yup.string().required('Nova senha obrigatória'),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'As senhas devem ser iguais')
            .required('Confirmação de senha é obrigatória'),
        });

        await schema.validate(data, { abortEarly: false });

        setLoading(true);

        await api.put(`users/${user._id}/pass`, {
          password: data.newPassword,
        });

        updateUser({ ...user, no_pass: false });

        setLoading(false);

        addToast({
          type: 'success',
          title: 'Pronto!',
          description: 'Sua senha foi alterada com sucesso.',
        });

        // Redirect logic
        if (search) {
          const searchParams = parseQueryStr(search);
          const redirectFound = searchParams.find(p => p.key === 'redirect');
          const params = searchParams.reduce((acc, curr) => {
            if (curr.key !== 'redirect') {
              if (acc) {
                // eslint-disable-next-line no-param-reassign
                acc += `&${curr.key}=${curr.value}`;
              } else {
                // eslint-disable-next-line no-param-reassign
                acc += `?${curr.key}=${curr.value}`;
              }
            }
            return acc;
          }, '');
          if (redirectFound) {
            if (redirectFound.value.includes('checkout')) {
              setTimeout(() => {
                window.location.href = redirectFound.value + params;
              }, 3000);
              return;
            }
            history.push(redirectFound.value + params);
            return;
          }
        }

        history.push('/atividades/corporativo');
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao atualizar a senha',
        });
      }
    },
    [addToast, updateUser, user, history],
  );

  return (
    <Container>
      {openModal && (
        <Modal>
          <ContentModal>
            <h2>Olá, que bom que você está aqui!</h2>
            <CgSmile />
            <p>
              Para a sua segurança, pedimos que altere sua senha conforme
              preferir.
            </p>
            <button type="button" onClick={() => setOpenModal(false)}>
              Ok, entendi!
              <FiArrowRight size={30} />
            </button>
          </ContentModal>
        </Modal>
      )}
      <MaturityHeader />

      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Faça a alteração da sua senha</h2>

          <section>
            <Input
              name="newPassword"
              type="password"
              placeholder="Nova senha"
            />
            <Input
              name="confirmPassword"
              type="password"
              placeholder="Confirme sua nova senha"
            />
          </section>

          <Button loading={loading} light>
            Concluir
          </Button>
        </Form>
      </Content>
    </Container>
  );
};
