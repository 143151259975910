import styled from 'styled-components';

import background from '../../assets/backgroundBlocks.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
  background-color: var(--blue);
  overflow: hidden;

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.37;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 2;

  color: var(--white);

  form {
    display: flex;
    flex-direction: column;
    padding: 5rem 3rem;

    background: #132cbdbb;
    min-height: 28rem;
    min-width: 28rem;
    border-radius: 1rem;
    margin: 0 1rem;

    input[type=text],
    input[type=tel],
    input[type=email],
    input[type=password] {
      -webkit-box-shadow: 0 0 0px 100px rgb(19 44 189 / 95%) inset;
    }

    h2 {
      font-weight: 400;
      margin-bottom: 2rem;
    }

    div + div {
      margin-top: 1.5rem;
    }

    section {
      display: flex;
      flex-direction: column;

      justify-content: space-around;

      margin-bottom: 3rem;
      flex: 1;
    }

    a {
      margin-top: 2rem;
      color: #fff;
      text-decoration: none;
      transition: color 0.5s;
      text-align: center;

      &:hover {
        color: #ccc;
      }
    }

    div.footer {
      display: flex;
      justify-content: center;
      margin-top: 1.5rem;

      span {
        color: #fff;
        margin-top: 15px;
        text-decoration: none;
        transition: color 0.5s;
        text-align: center;
        font-weight: 400;
        cursor: default;
      }

      a {
        color: #fff;
        margin-top: 15px;
        text-decoration: underline;
        transition: color 0.5s;
        text-align: center;

        &:hover {
          color: #ccc;
        }
      }
    }
  }
`;
