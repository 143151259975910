import styled from 'styled-components';

import background from '../../assets/backgroundBlocks.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;
  background-color: var(--blue);
  overflow: hidden;

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.37;
    background-image: url(${background});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;
  z-index: 2;

  color: var(--white);

  form {
    display: flex;
    flex-direction: column;
    padding: 5rem 3rem;

    background: #132cbdbb;
    min-height: 30rem;
    min-width: 30rem;
    max-width: 30rem;
    border-radius: 1rem;

    h2 {
      font-weight: 400;
      margin-bottom: 2rem;
    }

    div + div {
      margin-top: 1.5rem;
    }

    section {
      display: flex;
      flex-direction: column;

      justify-content: space-around;

      margin-bottom: 3rem;
      flex: 1;
    }
  }
`;
