import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { IoCheckmark, IoChevronBack, IoChevronForward } from 'react-icons/io5';
import { IoMdInformation } from 'react-icons/io';
import { BsQuestion } from 'react-icons/bs';

import { QuestionProps } from '../../../types/researchTypes';
import { useResearch } from '../../../contexts/research';

import {
  QuestionContainer,
  AnswerButton,
  NotSureBalloon,
  ExplanationBalloon,
  IconContainer,
} from './styles';

interface QuestionComponentProps {
  question: QuestionProps;
}

const ANSWER_OPTIONS = [
  { label: 'Estou conforme', value: 100, dontKnow: false },
  { label: 'Parcialmente conforme', value: 50, dontKnow: false },
  { label: 'Não conforme', value: 0, dontKnow: false },
  { label: 'Não sei', value: 0, dontKnow: true },
];

const Question: React.FC<QuestionComponentProps> = ({ question }) => {
  const {
    research,
    currentSection,
    currentQuestion,
    switchQuestionOrSection,
    setCurrentSection,
    setCurrentQuestion,
    questionsToBeAnswered,
  } = useResearch();

  const [questionState, setQuestionState] = useState<QuestionProps>({
    explanation: question.explanation, // explicação
    answer: question.answer, // justificativa
    options: question.options, // 0-100
    dontKnow: question.dontKnow, // T ou F
    question: question.question, // enunciado
  });

  /* const [answerText, setAnswerText] = useState(question.answer);
  const [answerDontKnow, setAnswerDontKnow] = useState(question.dontKnow);
  const [answerValue, setAnswerValue] = useState<number | null>(
    question.options,
  ); */

  const isLastSection = useMemo(() => {
    const lastSection = research.sections.length;

    if (lastSection === currentSection) return true;

    return false;
  }, [currentSection, research.sections]);

  const isLastQuestion = useMemo(() => {
    const lastQuestion = research.sections[currentSection - 1].questions.length;

    if (lastQuestion === currentQuestion) return true;

    return false;
  }, [currentQuestion, currentSection, research.sections]);

  const isFirstSection = useMemo(() => currentSection === 1, [currentSection]);

  // eslint-disable-next-line prettier/prettier
  const isFirstQuestion = useMemo(() => currentQuestion === 1, [currentQuestion]);

  const handleSwitchQuestion = useCallback(
    (operator: '+' | '-') => {
      switchQuestionOrSection({
        nextQuestion:
          operator === '+' ? currentQuestion + 1 : currentQuestion - 1,
        /* Section with Updated Question --> Updated Section */
        updatedSection: {
          name: research.sections[currentSection - 1].name,
          description: research.sections[currentSection - 1].description,
          questions: research.sections[currentSection - 1].questions.map(
            (s_question, index) => {
              // Is this the Current Question?
              if (index === currentQuestion - 1) {
                // Any values changed?
                if (
                  s_question.options !== questionState.options ||
                  s_question.answer !== questionState.answer
                )
                  //  Updated Question
                  return questionState;
                // Values didn't change?
                return s_question; // Same Question
              }
              return s_question;
            },
          ),
        },
      });
      if (isLastQuestion && operator === '+') {
        setCurrentSection(currentSection + 1);
        setCurrentQuestion(1);
        return;
      }

      if (isFirstQuestion && operator === '-') {
        setCurrentSection(currentSection - 1);
        setCurrentQuestion(1);
      }

      /*  if (questionsToBeAnswered <= 1) {
        setCurrentSection(research.sections.length + 1);
      } */
    },
    [
      currentQuestion,
      currentSection,
      isFirstQuestion,
      isLastQuestion,
      questionState,
      research.sections,
      setCurrentQuestion,
      setCurrentSection,
      switchQuestionOrSection,
    ],
  );

  const skipQuestion = () => {
    if (isLastQuestion)
      switchQuestionOrSection({ nextSection: currentSection + 1 });
    else switchQuestionOrSection({ nextQuestion: currentQuestion + 1 });
  };

  useEffect(() => {
    if (
      questionState.options !== question.options ||
      questionState.dontKnow !== question.dontKnow
    )
      handleSwitchQuestion('+');
  }, [
    question,
    handleSwitchQuestion,
    questionState.options,
    questionState.dontKnow,
  ]);

  const handleAnswerQuestion = useCallback(option => {
    setQuestionState(prev => ({
      ...prev,
      options: option.value,
      dontKnow: option.dontKnow,
    }));
  }, []);

  return (
    <>
      <QuestionContainer>
        <header>
          <div>
            <h2>{question.question}</h2>
            {/* <p>{question.explanation}</p> */}
          </div>

          <IconContainer>
            <IoMdInformation />
          </IconContainer>

          <ExplanationBalloon>
            <h1>Explicação:</h1>
            <strong>{question.explanation}</strong>
          </ExplanationBalloon>
        </header>

        <div>
          {ANSWER_OPTIONS.map(option => (
            <AnswerButton
              key={option.value}
              type="button"
              onClick={() => handleAnswerQuestion(option)}
              selected={
                questionState.options === option.value &&
                questionState.dontKnow === option.dontKnow
              }
            >
              {option.label}
            </AnswerButton>
          ))}

          <IconContainer>
            <BsQuestion />
          </IconContainer>

          <NotSureBalloon>
            <h1>Estou em conformidade?</h1>
            <strong>Veja como responder suas questões:</strong>

            <div>
              <p>
                {`- Não sei ou não aplico na empresa. Então responda "Não
                conformidade".`}
              </p>
              <p>
                {`- Entendo e já aplico na empresa. Então responda "Estou
                conforme".`}
              </p>
              <p>
                {` - Não sei ou a empresa aplica parcialmente. Então responda "Parcialmente conforme".`}
              </p>
            </div>
            <p>
              Ainda tem dúvidas? Agende uma conversa com nosso especialista.
            </p>
          </NotSureBalloon>
        </div>

        <div>
          {/*  {Number(answerValue) > 0 && (
            <textarea
              value={answerText}
              placeholder="Compartilhe mais detalhes deste ponto conosco. Não é obrigatório mas nos ajudará bastante"
              onChange={e => setAnswerText(e.target.value)}
            />
          )} */}
        </div>

        <footer>
          <button
            type="button"
            disabled={isFirstQuestion && isFirstSection}
            onClick={() => handleSwitchQuestion('-')}
          >
            <IoChevronBack />
            <span>Voltar</span>
          </button>

          <strong>
            {`${currentQuestion}/${
              research.sections[currentSection - 1].questions.length
            }`}
          </strong>

          {(isLastSection && isLastQuestion) || questionsToBeAnswered <= 1 ? (
            <button
              type="button"
              onClick={() => handleSwitchQuestion('+')}
              disabled={
                questionsToBeAnswered > 1 || questionState.options === null
              }
            >
              <span>Finalizar</span>
              <IoCheckmark />
            </button>
          ) : (
            <button type="button" onClick={skipQuestion}>
              <span>Avançar</span>
              <IoChevronForward />
            </button>
          )}
        </footer>
      </QuestionContainer>
    </>
  );
};

export default Question;
