import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: calc(100vh - 12rem);

  p {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 25px;
    color: #696F77;

    cursor: default;
  }

  h3 {
    font-family: Nunito;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    color: #696F77;

    cursor: default;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 60%;
  height: 64px;

  background: #FFFFFF;
  border: 1px solid #C4CCD4;
  box-sizing: border-box;
  border-radius: 32px;
  cursor: pointer;

  padding: 0px 32px 0px 32px;
  margin-bottom: 24px;

  > span {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: #696F77;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-family: Nunito;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
      color: #298AFF;

      margin-left: 8px;
    }
    svg {
      color: #298AFF;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  @media screen and (max-width: 768px) {
    width: 90%;
  }
`;