import React, { useEffect, useState } from 'react';
import { Card } from '../Card';
import { DropdownMenuOption } from '../DropdownMenu';

import { Container } from './styles';

export interface Service {
  key?: string;
  indicator: string;
  title: string;
  icon: string;
  description: string;
  active: boolean;
  upgradeTag?: boolean,
  planCode?: string;
  path?: string;
  message?: string;
  dropdownOptions?: {
    message: string;
    options: DropdownMenuOption[];
  };
}

interface GridProps {
  serviceList: Service[];
}

export const Grid: React.FC<GridProps> = ({ serviceList }) => {
  const [list, setList] = useState(serviceList);

  useEffect(() => {
    setList(serviceList);
  }, [serviceList]);

  return (
    <Container>
      {list.map(service => (
        <div key={service.title} className="small">
          <Card
            data={service}
            backgroundImage={service.icon}
            backgroundColor="teal"
            planCode={service.planCode}
            active={service.active}
            upgradeTag={service.upgradeTag}
          />
        </div>
      ))}
    </Container>
  );
};
