import { darken } from 'polished';
import styled, { css, keyframes } from 'styled-components';

export const Container = styled.div`
  background: var(--light-gray);
  min-width: 100%;
  min-height: 100vh;

  > header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
`;

export const Content = styled.main`
  margin-top: 6rem;
  height: 100%;
  width: 100%;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--white-grayer);

  > ul,
  > div {
    width: 100%;
    max-width: 1800px;
  }

  > div {
    position: relative;
    padding: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      font: normal normal bold 18px/22px Nunito;
      letter-spacing: 0px;
      color: var(--dark-gray);

      & + h1 {
        font-style: italic;
        font-weight: 200;
      }
    }
  }

  @media screen and (min-width: 375px) {
    > div {
      padding: 20px 25px;
      h1 {
        font: normal normal bold 20px/24px Nunito;
      }
    }
  }
  @media screen and (min-width: 768px) {
    > div {
      padding: 25px 30px;
      h1 {
        font: normal normal bold 22px/26px Nunito;
      }
    }
  }
  @media screen and (min-width: 992px) {
    > div {
      padding: 30px 40px;
      h1 {
        font: normal normal bold 24px/28px Nunito;
      }
    }
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const CompanyDataModal = styled.div`
  position: fixed;
  top: -6rem;
  height: calc(100vh + 6rem);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;

  background: rgba(0, 0, 0, 0.2);
  color: var(--teal);

  div {
    animation: 1s ${appearFromLeft} ease-in-out;

    position: relative;
    width: 45rem;
    height: 22.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--white);
    border-radius: 2rem;
    padding: 2rem;
    overflow: hidden;

    * {
      z-index: 10;
    }

    > svg {
      position: absolute;
      color: #33333329;
      left: -2rem;
      top: 18%;
      height: 100%;
      width: auto;
      z-index: 5;
    }

    p {
      color: var(--dark-gray);
      margin-top: 2rem;
      font-size: 1.5rem;
      text-align: center;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: none;
      color: var(--teal);
      font-size: 1.5rem;
      width: 10rem;
      margin-top: 2rem;
      border-radius: 0.5rem;
      padding: 0.5rem 1rem;
      transition: all 0.2s ease-in-out;

      svg {
        height: 1.5rem;
        width: 1.5rem;
      }

      &:hover {
        background: var(--white);
        filter: brightness(0.9);
      }
    }
  }
`;

export const DropdownContainer = styled.div`
  position: absolute;
  right: 0;
  transform: translateX(-40%);
  top: 70%;
  z-index: 9;
  box-shadow: 0px 3px 6px #00000029;
`;

interface MenuButtonProps {
  isOpen: boolean;
}

export const MenuButton = styled.button<MenuButtonProps>`
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  margin-left: 1rem;
  margin-right: 1rem;

  background-color: inherit;

  transition: all 0.2s ease;

  ${props =>
    props.isOpen &&
    css`
      background-color: ${darken(0.05, '#EAEAEA')};
    `}

  svg {
    color: var(--teal);
    height: 3rem;
    width: 3rem;
  }

  &:hover {
    background-color: ${darken(0.05, '#E8E8E8')};
  }
`;
