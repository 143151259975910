/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import background from '../../assets/backgroundFinalScreen.svg';
import backgroundMobile from '../../assets/backgroundResultMobile.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  min-width: 100%;

  background: transparent url(${background}) no-repeat 46vw 10vh;
  background-size: clamp(400px, 50%, 800px);
  background-position-y: center;
  background-position-x: clamp(700px, 85%, 1250px);

  @media (max-width: 769px) {
    background: none;

    &::before {
      content: ' ';
      position: fixed;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.7;
      background-image: url(${backgroundMobile});
      z-index: 1;
    }
  }
`;

export const Content = styled.main`
  width: 100%;
  height: 100%;
  max-width: 1800px;

  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50%;

  grid-template-areas:
    'result graph'
    'message graph';

  margin-top: 4rem;
  align-self: center;
  padding: 0 4rem;
  padding-bottom: 2rem;

  z-index: 2;

  > section {
    max-width: 900px;

    footer {
      margin-top: 3rem;
    }

    p {
      margin-top: 1rem;
    }

    a {
      margin-top: 2rem;
      width: 60%;

      button {
        width: 100%;
      }
    }

    img {
      align-self: center;
      margin-top: 2rem;
      width: 20rem;
    }
  }

  @media (max-width: 769px) {
    margin-top: 3.5rem;

    grid-template-columns: 1fr;
    grid-template-rows: 25%;
    grid-template-areas:
      'result'
      'graph'
      'message';
  }

  @media (max-width: 426px) {
    padding: 1rem;

    > section {
      a {
        width: 100%;
      }

      p {
        display: none;
      }
    }
  }
`;

interface ResultMessageSectionProps {
  color: string;
}

export const ResultMessageSection = styled.section<ResultMessageSectionProps>`
  &:first-child {
    grid-area: result;
  }
  &:last-child {
    grid-area: message;
  }

  min-height: 12.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;

  /* Padrão enquanto a cor com score não funciona */
  h1,
  h2,
  h3 {
    color: ${props => props.color};
  }

  h1 {
    display: flex;
    align-items: flex-end;
    font-size: 8rem;
    margin-bottom: 2rem;
    line-height: 0.5;
  }

  h2 {
    font-size: 4rem;
    text-align: center;
  }

  h3 {
    font-size: 3rem;
  }

  h4 {
    font-size: 2rem;
  }

  a {
    align-self: flex-start;
  }

  @media (max-width: 426px) {
    h1 {
      font-size: 4.5rem;
    }

    h2 {
      font-size: 2.5rem;
    }

    h3 {
      font-size: 2.5rem;
    }
  }
`;

export const ResultGraphSection = styled.section`
  grid-area: graph;

  display: flex;
  flex-direction: row;
  margin-left: 6%;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 6rem;

    img {
      height: 1.5rem;
      width: 1.5rem;
    }

    svg {
      margin: 1rem 0;
    }

    span {
      text-align: center;
      height: 3rem;
    }
  }

  @media (max-width: 769px) {
    margin: 0;
  }

  @media (max-width: 426px) {
    width: 95vw;
    overflow-x: visible;

    position: relative;
    left: -2vw;

    > div {
      width: 100%;
      overflow: hidden;
    }

    span {
      font-size: 0.75rem;
    }
  }
`;
