import styled, { css, keyframes } from 'styled-components';
import { Form } from '@unform/web';

import { lighten, shade } from 'polished';
import background from '../../assets/backgroundBlocks.svg';
import backgroundMobile from '../../assets/backgroundMobile.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100%;
  background-color: var(--blue);
  overflow: hidden;

  &::before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.37;
    background-image: url(${background});
    z-index: 1;
  }

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    z-index: 2;
  }

  a {
    color: var(--white);
    transition: color 0.3s;

    &:hover {
      color: ${shade(0.1, '#fff')};
    }

    &:visited {
      color: #ccc;
      &:hover {
        color: ${lighten(0.1, '#ccc')};
      }
    }
  }

  @media (max-width: 769px) {
    background-image: url(${backgroundMobile});
    background-position-x: center;
    background-size: 150%;
    background-repeat: no-repeat;

    &::before {
      display: none;
    }
  }
`;

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: hidden;

  button + button {
    margin-left: 2rem;
  }

  button {
    font-size: 1.125rem;
  }

  section {
    position: relative;
    display: flex;
    flex-direction: column;
    color: #fff;
    max-width: 41rem;
    margin: 0 7.3%;

    padding: 1.5rem;

    overflow: visible;

    h1 {
      display: block;
      font-size: 3rem;
    }

    > p {
      display: block;
      font-size: 1rem;
      letter-spacing: 0px;
      margin: 1.25rem 0;
    }

    > div {
      margin-top: 1.25rem;
    }
  }

  section + section {
    height: 90%;
    width: 40%;
  }

  @media (max-width: 769px) {
    section {
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;
      height: 85%;
      align-self: center;

      & + section {
        height: 100%;
      }

      div {
        display: block;

        div + div {
          margin: 0;
        }
      }

      > footer {
        margin-top: 10%;
      }
    }
  }

  @media (max-width: 426px) {
    section {
      padding: 1.5rem;

      > footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: auto;

        button {
          width: 100%;

          + button {
            margin-left: 0;
            margin-top: 1rem;
          }
        }
      }
    }
  }

  @media (max-height: 600px) {
    &.formContainer section {
      padding-top: 80%;

      div button {
        font-size: 1rem;
      }
    }
  }
`;

export const ContinueButton = styled.button`
  height: 2.5rem;
  padding: 0 1rem;
  border-radius: 1.25rem;
  border: 2px solid #fff;

  min-width: 12.5rem;
  background: transparent;
  color: #fff;
  font-weight: bold;

  transition: all 0.3s;

  &:not(:disabled):hover {
    color: #000;
    background-color: #fff;
  }
`;

export const ScheduleButton = styled.button`
  height: 2.5rem;
  padding: 0.5rem 1rem;
  border-radius: 1.25rem;
  border: 2px solid transparent;

  min-width: 12.5rem;
  background: transparent;
  color: #fff;
  font-weight: bold;

  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

interface FormWrapperProps {
  isCompleted: boolean;
}

export const FormWrapper = styled.section<FormWrapperProps>`
  width: 100%;

  border: 1px solid transparent;
  border-radius: 1.25rem;

  ${props =>
    props.isCompleted &&
    css`
      &:after {
        content: ' ';
        cursor: not-allowed;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: var(--blue);
        opacity: 0.5;
        border-radius: 1.25rem;
      }

      border: 1px solid var(--white);
    `}

  > svg {
    color: var(--white);
    height: 2rem;
    width: 2rem;
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    z-index: 3;
  }

  @media (max-width: 769px) {
    ${props =>
      props.isCompleted &&
      css`
        display: none !important;
      `}
  }
`;

export const StyledForm = styled(Form)`
  margin: auto 0;

  > div {
    display: flex;
    align-items: center;

    margin-bottom: 1rem;

    div + div {
      margin-left: 3rem;
    }
  }

  @media (max-width: 426px) {
    button {
      width: 100%;
    }
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const FinalMessage = styled.main`
  display: flex;
  flex-direction: column;

  align-self: center;

  align-items: center;
  justify-content: center;

  background: var(--pink);
  color: #fff;

  padding: 5.75rem;

  height: 57.8%;
  max-height: 39rem;

  width: 67%;
  max-width: 75.25rem;

  border-radius: 1.25rem;

  animation: ${appearFromLeft} 1.5s;

  h1 {
    display: inline;
    font-size: 4rem;
    text-align: center;
  }

  p {
    display: block;
    font-size: 1.75rem;
    margin-top: 3rem;
    text-align: center;
  }

  footer {
    display: flex;
    align-items: center;
    margin-top: 3rem;

    span {
      display: block;
      font-size: 1rem;
    }
    svg {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 1rem;
    }
  }

  @media (max-width: 769px) {
    width: 87%;

    padding: 1rem;

    h1 {
      font-size: 2.5rem;
    }

    p {
      font-size: 1.5rem;
    }

    footer svg {
      height: 2rem;
      width: 2rem;
    }
  }

  @media (max-width: 426px) {
    min-height: 65%;

    footer svg {
      height: 3rem;
      width: 3rem;
    }
  }

  @media (max-height: 600px) {
    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1.25rem;
    }
  }
`;

const infiniteRotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ActivityIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  animation: ${infiniteRotation} 2s linear infinite;
  height: 1.25rem;
  width: 1.25rem;

  svg {
    color: var(--white);
    height: 1.25rem;
    width: 1.25rem;
  }
`;

export const IframeWrapper = styled.div`
  margin-right: auto;
  animation: ${appearFromRight} 1s;
  height: 85%;
  width: 40%;

  iframe {
    border-radius: 1.25rem;
  }

  @media (max-width: 769px) {
    width: 90%;
    margin: 0 auto;
  }

  @media (max-width: 426px) {
    width: 100%;
  }
`;
