import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

import { api } from '../../services/api';
import { GenericOverlay } from '../../components/GenericOverlay';
import { ActivityIndicator } from '../../components/MaturityButton/styles';

import {
  Container,
  Card
} from '../../components/PolicyPreview/styles';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function SlugPage() {
  const { slug } = useParams<{ slug: string }>();
  const history = useHistory();
  const [hasSlug, setHasSlug] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [html, setHTML] = useState<string>("");

  useEffect(() => {
    setLoading(true)
    api.get(`/policies/slug/${slug}`).then((response) => {
      setHTML(response.data.policy_html);
      setHasSlug(true);
      setLoading(false);
      if (!response.data) {
        setHasSlug(false);
      }
    }).catch(() => {
      setHTML("");
      setHasSlug(false);
      setLoading(false)
    })
  }, [slug])

  function handleHome() {
    history.push(`${process.env.REACT_APP_PRIVACY}/atividades/corporativo`);
  }

  if (!hasSlug && !loading) return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
      }}
    >
      <h1>
        404
      </h1>
      <h2 style={{ marginBottom: "24px" }}>
        Política não encontrada 😢
      </h2>
    </div>
  )

  if (hasSlug && !loading) return (
    <Container>
      <Card>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </Card>
    </Container>
  );

  return (
    <GenericOverlay>
      <ActivityIndicator style={{ width: "50px", height: "50px" }}>
        <AiOutlineLoading3Quarters style={{
          width: "50px",
          height: "50px",
          color: "var(--teal)"
        }}
        />
      </ActivityIndicator>
    </GenericOverlay>
  );
}