import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { GenericCard } from '../../../../components/GenericCard';
import { GenericTable } from '../../../../components/GenericTable';
import { GenericPage } from '../../../../components/GenericPage';
import { GenericInput } from '../../../../components/GenericInput';
import { api } from '../../../../services/api';
import { useToast } from '../../../../contexts/toast';
import { mapObjToArray } from '../../../../utils/mapObjToArray';
import { formatDate } from '../../../../utils/getFormatDate';

import { ICookieBar } from '../../../../types/ICookieBar';
import { IInteraction } from '../../../../types/IInteraction';
import { ICookie } from '../../../../types/ICookie';

interface IInteractionGroup {
  accepted_partial: number;
  accepted_total: number;
  current_page: number;
  interactions: IInteraction[];
  total_interactions: Number;
  total_pages: number;
}


const HEADER = [
  { 
    key: 'ip_address',
    order: 1,
    label: 'IP', 
    textAlign: 'left'
  },
  { 
    key: 'location',
    order: 2,
    label: 'Localização', 
    textAlign: 'left',
    callback: (v) => `${v.city}, ${v.region} - ${v.country}`
  },
  { 
    key: 'timezone',
    order: 3,
    label: 'Timezone', 
    textAlign: 'left'
  },
  { 
    key: 'cookie_list',
    order: 4,
    label: 'Cookies', 
    textAlign: 'left',
    callback: (v: ICookie[]) => `${v.length} Cookies`,
  },
  {
    key: 'createdAt',
    order: 5,
    label: 'Criação',
    textAlign: 'left',
    callback: (v: string) => formatDate(new Date(v))
  }
];
const FORM_CONFIG = [
  { 
    key: 'site_url', 
    label: 'URL do site', 
    type: 'text'
  },
  { 
    key: 'font_family', 
    label: 'Fonte',
    type: 'text'
  },
  { 
    key: 'font_size',
    label: 'Tamanho', 
    type: 'text'
  },
  {
    key: 'createdAt', 
    label: 'Data de criação',
    placeholder: 'dd/mm/yyyy',
    type: 'date'
  },
  {
    key: 'updatedAt', 
    label:  'Data da última alteração',
    placeholder:  'dd/mm/yyyy',
    type: 'date'
  }
];

export const CookieBar: React.FC = () => {
  const { addToast } = useToast();
  const { id: cookieBarId } = useParams<{ id: string }>();

  const [cookieBar, setCookieBar] = useState<ICookieBar>();
  const [cookieBarArray, setCookieBarArray] = useState([]);
  const [interactions, setInteractions] = useState<IInteraction[]>([]);
  const [pageTitle, setPageTitle] = useState<string>('Barra de Cookies');
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const loadCookieBar = async () => {
      try {
        setLoading(true);
        const response = await api.get<ICookieBar>(`/cookie_bars/${cookieBarId}`);

        const cookieBarData = _.get(response, 'data', false)
        if (cookieBarData) {
          setPageTitle(cookieBarData.site_url);

          setCookieBarArray(
            mapObjToArray(cookieBarData).reduce((acc, curr) => {
              const found = FORM_CONFIG.find(c => c.key === curr.key);
              if (found) {
                acc.push({
                  ...curr,
                  ...found,
                })
              }

              return acc;
            }, [])
          );

          setCookieBar({
            _id: cookieBarData._id,
            site_url: cookieBarData.site_url,
            font_family: cookieBarData.font_family,
            font_size: cookieBarData.font_size,
            cookie_list: cookieBarData.cookie_list,
            active: cookieBarData.active,
            cookie_bar_background_color: cookieBarData.cookie_bar_background_color,
            cookie_bar_color: cookieBarData.cookie_bar_color,
            cookie_modal_background_color: cookieBarData.cookie_modal_background_color,
            cookie_modal_color: cookieBarData.cookie_modal_color,
            font_weight: cookieBarData.font_weight,
            enabledCookieBarCircle: cookieBarData.enabledCookieBarCircle,
            createdAt: new Date(cookieBarData.createdAt)
              .toISOString()
              .substr(0,10),
            updatedAt: new Date(cookieBarData.updatedAt)
              .toISOString()
              .substr(0,10),
          });

          const {data} = await api.get<IInteractionGroup>(`/interactions?cookie_bar=${cookieBarId}`);
          const IntData = _.get(data, 'interactions', false)
          if (IntData) {
            setInteractions(IntData);
          }
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Falha ao carregar',
          description: 'Ocorreu um erro ao carregar a barra de cookies',
        });
      }
    };
      
    if (cookieBarId) loadCookieBar();
  }, [addToast, cookieBarId]);

  const handleChange = (field: string, event: ChangeEvent<HTMLInputElement>): void => {
    const cookieBarClone = _.clone(cookieBar);
    const value = _.get(event, 'target.value', undefined);
    cookieBarClone[field] = value;
    setCookieBar(cookieBarClone);
  };
  
  const handleSubmit = useCallback(
    async (data) => {
      console.log(data)
    },
    [],
  );

  return (
    <GenericPage
      breadcrumb="BackOffice > Barras de Cookies > "
      pageTitle={pageTitle}
      loading={loading}
    >
  
      {cookieBar && (
        <GenericCard>
          <form onSubmit={handleSubmit} style={{width: 'inherit'}}>
            {cookieBar.site_url && <h3>{cookieBar.site_url}</h3>}

            {cookieBarArray.map(item => (
              <GenericInput
                dark
                disabled
                key={item.key}
                id={item.key}
                name={item.key}
                type={item.type}
                label={item.label}
                placeholder={item.placeholder}
                value={cookieBar[item.key]}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(item.key, event)}
              />
            ))}
          </form>
        </GenericCard>
      )}

      {interactions && interactions.length > 0 && (
        <GenericTable 
          headerData={HEADER}
          bodyData={interactions}
          title="Interações"
        />
      )}
    </GenericPage>
  )
};
