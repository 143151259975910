import styled, { css, keyframes } from 'styled-components';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-3rem);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 2rem;
  height: 100%;
  width: 100%;
  animation: ${appearFromLeft} 0.5s;

  > header {
    display: flex;
    width: 100%;

    > aside {
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--dark-gray);
      color: var(--white);
      height: 4.75rem;
      width: 4.75rem;
      border-radius: 2.5rem;
    }

    h1 {
      font-size: 2.25rem;
    }

    section {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin-left: 1rem;
      height: 4.75rem;
    }

    h3 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
  }

  @media (max-width: 426px) {
    > header {
      aside {
        height: 3.125rem;
        width: 3.125rem;

        h1 {
          font-size: 2rem;
        }
      }

      section {
        flex: 1;
      }
    }
  }
`;

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  height: 100%;
  width: 70%;
  border: 1px solid red;
  margin-right: 2rem;
  padding: 2rem;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 2.5rem;

    button {
      width: 150px;
      min-height: 4.375rem;
      padding: 0.5rem;
    }

    textarea {
      padding: 0.625rem;
      line-height: 1.5;
      border-radius: 0.25rem;
      border: 1px solid #ccc;
      width: 100%;
      min-height: 100px;
      margin-right: 2rem;
    }
  }
`;

interface ButtonInterface {
  selected: boolean;
}

export const AnswerButton = styled.button<ButtonInterface>`
  ${props =>
    props.selected &&
    css`
      background: blue;
      color: #fff;
    `};
`;

export const ExplanationContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: 1px solid blue;
  height: 60%;
  width: 30%;
`;
