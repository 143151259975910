/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router';

import Confirm from '../../assets/icons/certinho.svg';
import Indicator from '../../assets/icons/indicador.svg';

import {
  ConfirmWrapper,
  Container,
  ContentWrapper,
  ListWrapper,
  Line,
} from './styles';

import { maskEmail, maskPhone } from '../../utils/maskValidationOFInput';
import { api } from '../../services/api';

import { IPolicy } from '../../types/IPolicy';
import { IPolicyStep } from '../../types/IPolicyStep';

interface PoliciesStepsProps {
  policySteps: IPolicyStep[];
  setPolicySteps: Dispatch<SetStateAction<IPolicyStep[]>>;
  setRequestSave?: (data) => void;
}

// eslint-disable-next-line no-useless-escape
const formatEmail = /\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/;

export const PoliciesSteps: React.FC<PoliciesStepsProps> = ({ policySteps, setPolicySteps, setRequestSave }) => {
  const { id: policyId } = useParams<{ id: string }>();

  const handleSetSteps = useCallback(
    (idx, data) => {
      let valid = true;

      const newSteps = policySteps.map((itm, index) =>
        idx === index ? data : itm,
      );

      if (data.type === 'input') {
        data.data.forEach(item => {
          let validItem = true;

          if (item.required && !item.value && data.confirm) {
            item.error = `${item.label} deve ser preenchido.`;
            validItem = false;
            valid = false;
          }
          if (item.value && item.value.length < item.minLength) {
            item.error = `${item.label} deve ter no mínimo ${item.minLength} caracteres.`;
            validItem = false;
            valid = false;
          }
          if (item.value && item.value.length > item.maxLength) {
            item.error = `${item.label} deve ter no máximo ${item.maxLength} caracteres.`;
            validItem = false;
            valid = false;
          }
          if (
            item.value &&
            item.type === 'email' &&
            !formatEmail.test(item.value)
          ) {
            item.error = 'Digite um email válido.';
            validItem = false;
            valid = false;
          }

          if (validItem) {
            item.error = '';
          }
        })
      }

      if (!valid) {
        data.isActive = true;
        data.show = true;
        data.confirm = false;
      }

      if (idx < newSteps.length - 1 && data.confirm && valid) {
        newSteps[idx + 1].isActive = true;
      }

      setPolicySteps(newSteps);
    },
    [policySteps, setPolicySteps],
  );

  useEffect(() => {
    api.get<IPolicy>(`/policies/${policyId}`).then((response) => {
      let isConfirmed = false;
      const {
        basic_data,
        day_to_day_data,
        business_data,
        financial_data,
        sensitive_data,
        dpo_data,
      } = response.data;

      const arrayPolicySteps = [];
      const arrayPolicies = [];

      if (basic_data.length > 0) {
        isConfirmed = true;
        arrayPolicies.push('Dados básico');
        basic_data.map(data => arrayPolicySteps.push(data));
      }
      if (day_to_day_data.length > 0) {
        isConfirmed = true;
        arrayPolicies.push('Dados do dia a dia');
        day_to_day_data.map(data => arrayPolicySteps.push(data));
      }
      if (business_data.length > 0) {
        isConfirmed = true;
        arrayPolicies.push('Dados comerciais');
        business_data.map(data => arrayPolicySteps.push(data));
      }
      if (financial_data.length > 0) {
        isConfirmed = true;
        arrayPolicies.push('Dados financeiros');
        financial_data.map(data => arrayPolicySteps.push(data));
      }
      if (sensitive_data.length > 0) {
        isConfirmed = true;
        arrayPolicies.push('Dados sensíveis');
        sensitive_data.map(data => arrayPolicySteps.push(data));
      }
      if (dpo_data.length > 0) {
        isConfirmed = true;
        arrayPolicies.push('Dados da pessoa Encarregada (DPO)');
        dpo_data.map(data => {
          /* eslint-disable no-useless-escape */
          // const formatEmail = /\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+/;
          // const formatPhone = /\(([^)]+)\)/;

          const policyIndex = policySteps.findIndex(policy => policy.type === "input");
          // if (formatPhone.test(data)) {
          //   arrayPolicySteps.push("Telefone comercial");
          //   const recoverIndexValueOfInput = policySteps[policyIndex].data.findIndex(step => step.label === "Telefone comercial")
          //   policySteps[policyIndex].data[recoverIndexValueOfInput].value = data;
          // }
          if (formatEmail.test(data)) {
            arrayPolicySteps.push("E-mail comercial da pessoa Encarregada de Dados");
            const recoverIndexValueOfInput = policySteps[policyIndex].data.findIndex(step => step.label === "E-mail comercial da pessoa Encarregada de Dados")
            policySteps[policyIndex].data[recoverIndexValueOfInput].value = data;
          }
          // if (!formatPhone.test(data) && !formatEmail.test(data)) {
          if (!formatEmail.test(data)) {
            arrayPolicySteps.push("Nome da pessoa Encarregada de Dados");
            const recoverIndexValueOfInput = policySteps[policyIndex].data.findIndex(step => step.label === "Nome da pessoa Encarregada de Dados")
            policySteps[policyIndex].data[recoverIndexValueOfInput].value = data;
          }
          return setPolicySteps(policySteps);
        })
      }
      const newPolicySteps = policySteps.map(policy => {
        return {
          ...policy,
          confirm: isConfirmed,
          // isActive: !arrayPolicies.find(item => item === policy.title) || true,
          // confirm: arrayPolicies.find(item => item === policy.title) || false,
          data: policy.data.map(step => {
            return {
              ...step,
              check: arrayPolicySteps.some(item => item === step.label || step.value)
            }
          })
        }
      })

      setPolicySteps(newPolicySteps);
      setTimeout(() => {
        setRequestSave(false);
      }, 200);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err)
    })
  }, [])

  const handleUpdateCheck = (indx, dataArray, newValue) => {
    if (dataArray[indx].isOnly && newValue) {
      dataArray.map(data => {
        data.check = false;
        return data
      })
    } else {
      dataArray.map(data => {
        if (data.isOnly) {
          data.check = false;
        }
        return data
      })
    }
    
    dataArray[indx].check = newValue;
    setRequestSave(true);
    return dataArray;
  };

  const handleUpdateValue = (indx, dataArray, newValue) => {
    dataArray[indx].value = newValue;
    setRequestSave(true);
    return dataArray;
  };

  const validateIsActiveAccordion = (
    confirm: boolean,
    active: boolean,
    show: boolean,
  ): boolean => {
    return (!confirm && active) || (confirm && show);
  };

  const validateValueInput = (type: string, value: string): string => {
    if (type === 'email') return maskEmail(value);
    if (type === 'tel') return maskPhone(value);
    return value;
  }

  return (
    <Container>
      <Line />
      {policySteps.map((itm, idx) => (
        <ListWrapper key={idx.toString()} style={{ cursor: "default" }}>
          <ConfirmWrapper
            animation={validateIsActiveAccordion(
              itm.confirm,
              itm.isActive,
              itm.show,
            )}
            confirm={itm.confirm}
            lastPosition={idx + 1 === policySteps.length}
            style={{ cursor: "default" }}
          >
            {itm.confirm && <img src={Confirm} style={{ height: "30px", width: "30px", cursor: "default" }} alt="" />}
            {!itm.confirm && <span style={{ cursor: "default" }}>{idx + 1}</span>}
          </ConfirmWrapper>
          <ContentWrapper
            animation={validateIsActiveAccordion(
              itm.confirm,
              itm.isActive,
              itm.show,
            )}
            style={{ cursor: "default" }}
          >
            <button
              type="button"
              className="content__header"
              style={{ cursor: "pointer" }}
              onClick={() => handleSetSteps(idx, { ...itm, show: !itm.show })}
            >
              <h3 style={{ cursor: "pointer" }}>{itm.title}</h3>
              <img
                src={Indicator}
                alt=""
              />
            </button>

            {validateIsActiveAccordion(itm.confirm, itm.isActive, itm.show) && (
              <div className="content__form">
                <p style={{ cursor: "default" }}>{itm.subtitle}</p>
                {itm.type === 'checkbox' && (
                  <div className="content__gridCheckbox">
                    {itm.data.map((item, index) => (
                      <label
                        className="content__checkboxContainer"
                        key={`${idx.toString()}-${index.toString()}`}
                      >
                        {item.label}
                        <input
                          type="checkbox"
                          checked={item.check}
                          onChange={() =>
                            handleSetSteps(idx, {
                              ...itm,
                              isActive: true,
                              confirm: false,
                              data: handleUpdateCheck(
                                index,
                                itm.data,
                                !item.check,
                              ),
                            })
                          }
                        />
                        <span className="checkmark" />
                      </label>
                    ))}
                  </div>
                )}
                {itm.type === 'input' && (
                  <div className="content__input">
                    {itm.data.map((item, index: number) => (
                      <div key={`${idx.toString()}-${index.toString()}`}>
                        <label>
                          <span>{item.label}</span>
                          {item.required && (<span>*</span>)}
                        </label>
                        <input
                          value={validateValueInput(item.type, item.value)}
                          type={item.type}
                          onChange={e =>
                            handleSetSteps(idx, {
                              ...itm,
                              confirm: false,
                              isActive: true,
                              data: handleUpdateValue(
                                index,
                                itm.data,
                                e.target.value,
                              ),
                            })
                          }
                        />
                        <span className="error">{item.error}</span>
                      </div>
                    ))}
                  </div>
                )}
                <button
                  type="button"
                  disabled={itm.confirm}
                  onClick={() =>
                    handleSetSteps(idx, {
                      ...itm,
                      confirm: true,
                      isActive: false,
                      show: false,
                    })
                  }
                >
                  Concluído
                </button>
              </div>
            )}
          </ContentWrapper>
        </ListWrapper>
      ))}
    </Container>
  );
}
