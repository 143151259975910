import React, { ChangeEvent, InputHTMLAttributes } from 'react';

import { FormGroup, Label, Input, TextArea, FlexDiv, Error } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type: string;
  value: string;
  label?: string;
  error?: string;
  placeholder?: string;
  dark?: boolean;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const GenericInput: React.FC<InputProps> = ({
  name,
  type,
  label,
  value,
  error,
  placeholder,
  dark,
  disabled,
  onChange,
}) => {
  return (
    <FormGroup>

      {label || placeholder && (
        <Label
          dark={dark}
          htmlFor={name}
        >
          {label || placeholder}
        </Label>
      )}

      {['text', 'number', 'date'].includes(type) && (
        <Input
          dark={dark}
          disabled={disabled}
          id={name}
          name={name}
          placeholder={placeholder || label}
          value={value}
          type={type}
          onChange={onChange}
        />
      )}

      {['checkbox', 'radio'].includes(type) && (
        <FlexDiv>
          <Input
            width="auto"
            margin="0.5rem 1rem"
            dark={dark}
            disabled={disabled}
            id={name}
            name={name}
            placeholder={placeholder || label}
            value={value}
            type={type}
            onChange={onChange}
          />
          {placeholder || label && (
            <Label
              dark={dark}
              htmlFor={name}
            >
              {placeholder || label}
            </Label>
          )}
        </FlexDiv>
      )}

      {type === 'textarea' && (
        <TextArea
          dark={dark}
          disabled={disabled}
          id={name}
          name={name}
          value={value}
          rows={6}
          cols={12}
          onChange={onChange}
        />
      )}

      {error && <Error>{error}</Error>}

    </FormGroup>
  );
};
