import styled, { css } from 'styled-components';

export const Container = styled.ul`
  padding: 0 1rem;
  display: flex;
  list-style: none;

  @media screen and (min-width: 375px) {
    padding: 0 2rem;
  }
  @media screen and (min-width: 768px) {
    padding: 0 3rem;
  }
  @media screen and (min-width: 992px) {
    padding: 0 4rem;
  }
`;

interface TabItemProps {
  selected: boolean;
  enabled: boolean;
}

export const TabItem = styled.li<TabItemProps>`
  position: relative;

  padding: 3px 0px 12px;
  width: 250px;
  text-align: center;
  font: normal normal bold 16px/20px Nunito;
  letter-spacing: 0px;
  color: var(--dark-gray);
  opacity: 0.3;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    filter: contrast(0.9);
  }

  ${props =>
    props.selected &&
    css`
      opacity: 1;

      &::after {
        content: '';
        height: 3px;
        border-radius: 3px 3px 0 0;
        width: 100%;
        position: absolute;
        bottom: 1px;
        left: 0;
        background: var(--dark-gray);
      }
    `}

  ${props =>
    !props.enabled &&
    css`
      cursor: not-allowed;
    `}
`;
