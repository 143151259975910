import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;

  overflow: scroll;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1800px;
  align-self: center;

  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 0 1rem;
  margin-top: 2rem;

  > footer + footer {
    margin-top: 3.5rem;
  }

  .loading {
    width: 50px;
    height: 50px;

    svg {
      width: 50px;
      height: 50px;
      color: var(--teal);
    }
  }

  @media screen and (min-width: 375px) {
    padding: 0 2rem;
  }
  @media screen and (min-width: 768px) {
    padding: 0 3rem;
  }
  @media screen and (min-width: 992px) {
    padding: 0 4rem;
  }
`;

export const AddCookieBar = styled.button.attrs({
  type: 'button',
})`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 5rem;
  padding: 1rem;
  background: #eaecf4;
  border-radius: 1rem;

  color: ${props => (props.disabled ? 'var(--gray)' : 'var(--teal)')};

  transition: all 0.2s ease-in-out;

  svg {
    height: 3rem;
    width: 3rem;
  }

  span {
    margin-top: 0.5rem;
  }

  .dropdownContainer {
    position: absolute;
    top: -2rem;
    left: 20%;
    transform: translateX(50%);
    z-index: 998;
  }

  &:not([disabled]):hover {
    filter: brightness(0.9);
  }
`;

export const DisabledMessage = styled.div`
  position: absolute;

  top: 50%;
  left: 50%;

  width: 11rem;
  font-size: 0.75rem;
  padding: 1rem;
  background: var(--white);
  color: var(--gray);
  border-radius: 0.5rem;

  box-shadow: 0px 3px 6px #00000029;

  z-index: 40;
`;
