import styled from 'styled-components';

import background from '../../assets/backgroundBlocks.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  min-height: 100%;
  background-color: rgba(47, 70, 207, 0.9);
  /* overflow: hidden; */
  background-image: url(${background});
  background-size: cover;
  background-repeat: repeat-y;
  background-position: center;
  z-index: 1;
`;

export const Content = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  z-index: 2;
  color: var(--white);

  @media screen and (min-width: 375px) {
    margin: 1rem 0;
  }
  @media screen and (min-width: 768px) {
    margin: 1rem 0;
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 5rem 3rem;

    background-color: var(--blue);
    min-height: 28rem;
    min-width: 28rem;
    border-radius: 1rem;
    margin: 3.5rem 1rem;

    input[type=text],
    input[type=tel],
    input[type=email],
    input[type=password] {
      -webkit-box-shadow: 0 0 0px 100px var(--blue) inset;
    }

    h2 {
      font-weight: 400;
      margin-bottom: 2rem;
    }

    div + div {
      margin-top: 1.5rem;
    }

    section {
      display: flex;
      flex-direction: column;

      justify-content: space-around;

      margin-bottom: 3rem;
      flex: 1;

      span.error {
        margin-top: 1rem;
        color: var(--red)
      }
    }

    div.terms {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      span {
        color: #fff;
        text-decoration: none;
        transition: color 0.5s;
        text-align: center;
        font-weight: 400;
        cursor: default;
        margin-left: 1rem;
        margin-right: 0.5rem;
      }

      a {
        color: #fff;
        text-decoration: underline;
        transition: color 0.5s;
        text-align: center;

        &:hover {
          color: #ccc;
        }
      }
    }

    div.footer {
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      span {
        color: #fff;
        margin-top: 15px;
        text-decoration: none;
        transition: color 0.5s;
        text-align: center;
        font-weight: 400;
        cursor: default;
      }

      a {
        color: #fff;
        margin-top: 15px;
        text-decoration: underline;
        transition: color 0.5s;
        text-align: center;

        &:hover {
          color: #ccc;
        }
      }
    }
  }
`;
