import React from "react";

import { MdContentCopy } from 'react-icons/md';
import { IoIosArrowRoundForward } from 'react-icons/io';

import { GenericOverlay } from '../GenericOverlay';

import { ModalCard as CookieBarModal } from '../../pages/Activities/Corporate/Cookies/CreateCookieBar/styles';
import { SecondCopySection, Link } from "./styles";

interface GenerateCodHTMLProps {
  mainCopied: boolean;
  secondCopied: boolean;
  showSecondSection: boolean;
  hasPolicy: boolean;
  type: string | "HTML" | "URL";
  title: string;
  loadCookieBarForCopy: (type: string, second: boolean) => void;
  handleClosePublishCookies: () => void;
  displaySecondButton: () => void;
  origin: 'COOKIE_BAR' | 'POLICY' | 'DSR';
  hasPolicyBond: boolean;
  isCompleted?:boolean;
}
export const GenerateCodHTML: React.FC<GenerateCodHTMLProps> = ({
  mainCopied,
  secondCopied,
  showSecondSection,
  hasPolicy,
  type,
  title,
  loadCookieBarForCopy,
  handleClosePublishCookies,
  displaySecondButton,
  origin,
  hasPolicyBond,
  isCompleted
}) => {
  return (
    <GenericOverlay>
      <CookieBarModal>
        <MdContentCopy className="svg-copy" />

        {((title && (isCompleted !== true && origin === 'DSR')) || (title && origin !== 'DSR')) && (
          <p>{title}</p>
        )}

        {(title && (isCompleted === true && origin === 'DSR')) && (
          <p>
            Alterações feitas com sucesso! Seu site já está atualizado, caso deseje copiar seu script novamente,
            {' '}
            <Link href="/atividades/corporativo/codigo-fonte">clique aqui</Link>
            . Se quiser seguir para home, clique no botão abaixo.
          </p>
        )}

        {(origin !== 'DSR' || (isCompleted === false && origin === 'DSR')) && (
          <div className="button-wrapper">
            <span style={{ cursor: 'not-allowed' }}>{type === "HTML" ? "<!DOCTYPE HTML>" : "https://privacyflow.app/politica"}</span>
            <button
              type="button"
              onClick={() => loadCookieBarForCopy(type, false)}
              className={mainCopied ? 'color--teal' : ''}
            >
              <MdContentCopy />
              {type === 'HTML' && (
              <small>
                {mainCopied ? `Script Baixado!` : `Baixar Script`}
              </small>
            )}
              {type === 'URL' && (
              <small>
                {mainCopied ? `URL copiada!` : `Copiar URL`}
              </small>
            )}
            </button>
          </div>
        )}

        {(origin === 'COOKIE_BAR' && hasPolicyBond) && type === 'HTML' && !showSecondSection && (
          <SecondCopySection>
            <p>Deseja publicar a Política em outro lugar do seu site?</p>
            <button
              type="button"
              onClick={displaySecondButton}
            >
              Clique aqui para gerar uma URL.
            </button>
          </SecondCopySection>
        )}

        {origin === 'POLICY' && hasPolicy && type === 'HTML' && !showSecondSection && (
          <SecondCopySection>
            <p>Deseja publicar a Política em outro lugar do seu site?</p>
            <button
              type="button"
              onClick={displaySecondButton}
            >
              Clique aqui para gerar uma URL.
            </button>
          </SecondCopySection>
        )}

        {hasPolicy && type === 'HTML' && showSecondSection && (
          <div className="button-wrapper">
            <span
              style={{ cursor: 'not-allowed' }}
            >
              https://privacyflow.app/politica
            </span>
            <button
              type="button"
              onClick={() => loadCookieBarForCopy('URL', true)}
              className={secondCopied ? 'color--teal' : ''}
            >
              <MdContentCopy />
              <small>
                {secondCopied ? `URL copiada!` : `Copiar URL`}
              </small>
            </button>
          </div>
        )}

        {(mainCopied || secondCopied || (isCompleted === true && origin === 'DSR')) && (
          <button
            type="button"
            className="footer-button"
            onClick={handleClosePublishCookies}
          >
            Entendi
            <IoIosArrowRoundForward />
          </button>
        )}

      </CookieBarModal>
    </GenericOverlay>
  );
}