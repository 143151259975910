import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';

import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { IoMdMore } from 'react-icons/io';

import { useHistory } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownMenuOption,
} from '../../../../../components/DropdownMenu';
import { MaturityHeader } from '../../../../../components/MaturityHeader';
import { ServicesHeader } from '../../../../../components/ServicesHeader';

import { useCookiesPanel } from '../../../../../hooks/useCookiesPanel';
import { useManageCookieBar } from '../../../../../hooks/useManageCookieBar';

import { Container, ContainerButtonHeader, Content, DropdownContainer, MenuButton } from './styles';

import exportExcel from '../../../../../assets/newIcons/exportExcel.svg';
import exportPDF from '../../../../../assets/newIcons/exportPDF.svg';
import { api } from '../../../../../services/api';
import { ButtonHeader } from './ButtonHeader';
import { useAuth } from '../../../../../contexts/auth';

export const CookiesPanel: React.FC = () => {
  const {
    dropdownRef,
    showDropdown,
    interactions,
    accepteds,
    partial,
    total,
    currentPage,
    totalPages,
    setShowDropdown,
    setCurrentPage,
  } = useCookiesPanel();

  const [list, setList] = useState([]);

  const { cookieBars } = useManageCookieBar();

  const history = useHistory();
  const { user, controlPayment } = useAuth();

  async function controlPage() {
    const permission = await controlPayment(user);

    if (!permission?.interactions_reports) {
      history.push('/atividades/corporativo');
    }
  }

  useEffect(() => {
    controlPage();
  }, []);

  const exportToExcel = useCallback(async () => {
    try {
      const response = await api.get(
        `/exports/interactions/excel?cookie_bar=${interactions[0]?.cookie_bar_id}`,
      );
      return setList(response.data);
    } catch (error) {
      return console.error(error);
    }
  }, [interactions]);

  useEffect(() => {
    exportToExcel();
  }, [exportToExcel]);

  const excelData = useMemo(
    () =>
      list.map(({ ip_address, createdAt, cookie_list }) => [
        ip_address,
        cookie_list.every(cookie => cookie.accepted) ? 'Total' : 'Parcial',
        new Date(createdAt).toLocaleString(),
      ]),
    [list],
  );

  /*  */
  const HeaderSideMenuOptions: DropdownMenuOption[] = [
    {
      label: 'Exportar para PDF',
      action: () => {
        // eslint-disable-next-line new-cap
        const doc = new jsPDF();
        const cols = ['IP', 'Status', 'Data/Hora'];

        autoTable(doc, {
          body: [['Data de Extração:', new Date().toLocaleString()]],
        });
        autoTable(doc, {
          body: [['URL:', cookieBars[0].site_url]],
        });
        autoTable(doc, {
          head: [cols],
          body: excelData,
        });

        doc.save(`Painel de Cookies_${new Date().getTime()}.pdf`);
      },
      alternativeIcon: <img src={exportPDF} alt="Exportar PDF" />,
    },
    {
      label: 'Exportar para Excel',
      action: () => document.getElementById('CSVLINK').click(),
      alternativeIcon: <img src={exportExcel} alt="Exportar Excel" />,
    },
  ];

  const HeaderSideMenuDropdown = (
    <DropdownContainer ref={dropdownRef}>
      <DropdownMenu big options={HeaderSideMenuOptions} />
    </DropdownContainer>
  );

  const HeaderSideMenu = (
    <>
      <MenuButton
        type="button"
        isOpen={showDropdown}
        onClick={() => setShowDropdown(prev => !prev)}
      >
        <IoMdMore />
        {showDropdown && HeaderSideMenuDropdown}
      </MenuButton>
      {HeaderSideMenuOptions && (
        <ContainerButtonHeader>
          {HeaderSideMenuOptions.map((option) => (
            <ButtonHeader key={option.label} option={option} />
          ))}
        </ContainerButtonHeader>
      )}
    </>
  );

  return (
    <Container>
      <MaturityHeader />
      <ServicesHeader
        pageTitle="Painel de Cookies"
        asideElement={HeaderSideMenu}
      />
      {interactions && (
        <Content>
          <section>
            <div>
              <h1>{total}</h1>
              <span>Geral</span>
            </div>
            <div>
              <h1>{accepteds}</h1>
              <span>Aceite em todos os cookies (Total)</span>
            </div>
            <div>
              <h1>{partial}</h1>
              <span>Aceite em alguns cookies (Parcial)</span>
            </div>
          </section>
          <section>
            <div>
              <span>IP</span>
              <span>Data e hora</span>
              <span>Status (total/parcial)</span>
            </div>
            {interactions.map(({ _id, ip_address, cookie_list, createdAt }) => (
              <div key={_id}>
                <span>{ip_address}</span>
                <span>{new Date(createdAt).toLocaleString()}</span>
                <span>
                  {cookie_list.every(cookie => cookie.accepted)
                    ? 'Total'
                    : 'Parcial'}
                </span>
              </div>
            ))}
            {totalPages > 0 && (
              <footer>
                <button
                  type="button"
                  disabled={currentPage <= 1}
                  onClick={() =>
                    setCurrentPage(prev =>
                      currentPage === 1 ? prev : prev - 1,
                    )
                  }
                >
                  <MdChevronLeft className="chevron--left" />
                </button>

                <strong>
                  {`Página `}
                  <span>{currentPage}</span>
                  {` de `}
                  <span>{totalPages}</span>
                </strong>

                <button
                  type="button"
                  disabled={currentPage === totalPages || totalPages <= 1}
                  onClick={() =>
                    setCurrentPage(prev =>
                      currentPage === totalPages ? prev : prev + 1,
                    )
                  }
                >
                  <MdChevronRight className="chevron--right" />
                </button>
              </footer>
            )}
          </section>
        </Content>
      )}

      <CSVLink
        id="CSVLINK"
        data={[
          [`Data de Extração: ${new Date().toLocaleString()}`],
          [],
          /* @ToDo: Cookie Bar real URL */
          [`URL: ${cookieBars[0]?.site_url}`],
          [],
          ['IP', 'Status', 'Data/Hora'],
          ...excelData,
        ]}
        separator=";"
        target="_parent"
        filename={`Painel de Cookies_${new Date().getTime()}.csv`}
      />
    </Container>
  );
};
