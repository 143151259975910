import styled from 'styled-components';

export const Container = styled.header`
  height: 6rem;
  min-width: 100%;
  background: var(--teal);
  display: flex;
  justify-content: center;
  box-shadow: 0px 3px 30px #00000029;
  padding: 1rem 0;
  z-index: 99;
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1800px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;

  color: var(--white);

  > div {
    display: flex;
    align-items: center;

    svg {
      width: 2rem;
      height: 2rem;
      margin-right: 1rem;
    }
  }

  aside {
    position: absolute;
    right: 4rem;
    top: 50%;
    transform: translateY(-50%);
  }

  h2 {
    display: flex;
    align-items: center;
    line-height: 2;
    font: bold normal normal 14px/18px Nunito;

    & {
      :hover {
        text-decoration: underline;
        cursor: pointer;
      }

      + h2 {
        font-style: italic;
        font-weight: 200;
      }
    }
  }

  h4 {
    display: inline;
    font: 200 normal normal 12px/16px Nunito;
  }

  @media screen and (min-width: 375px) {
    padding: 0 2rem;
    h2 {
      font: bold normal normal 16px/20px Nunito;
    }
  }
  @media screen and (min-width: 768px) {
    padding: 0 3rem;
    h2 {
      font: bold normal normal 18px/22px Nunito;
    }
    h4 {
      font: 200 normal normal 14px/18px Nunito;
    }
  }
  @media screen and (min-width: 992px) {
    padding: 0 4rem;
    h2 {
      font-size: bold normal normal 20px/24px Nunito;
    }
  }
`;
