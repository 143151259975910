import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { CgArrowLongRight } from 'react-icons/cg';
import { IoMapOutline } from 'react-icons/io5';
import { AiOutlineTrophy } from 'react-icons/ai';
import { BsPerson, BsCardChecklist } from 'react-icons/bs';
import { BiCookie, BiHive, BiSun } from 'react-icons/bi';
import { FiChevronDown } from 'react-icons/fi';
import { HiOutlineDocumentText } from 'react-icons/hi';
import { RiFile2Line } from 'react-icons/ri';

import { DropdownMenu } from '../DropdownMenu';

import { Service } from '../Grid';

import { Container, Content, DropdownContainer, FooterButton } from './styles';
import { UpgradeTag } from '../UpgradeTag/styles';

interface CardProps {
  data: Service;
  active?: boolean;
  upgradeTag?: boolean;
  planCode?: string;
  backgroundColor: 'teal' | 'light-blue' | 'green' | 'pink' | 'yellow';
  backgroundImage:
  | string
  | 'trophy'
  | 'map'
  | 'person'
  | 'checklist'
  | 'cookie'
  | 'hive'
  | 'sun'
  | 'file2Line'
  | 'document';
}

export const Card: React.FC<CardProps> = ({
  backgroundColor,
  backgroundImage = 'map',
  data,
  active,
  upgradeTag,
  planCode,
}) => {
  const backgroundIcon: { [index: string]: JSX.Element } = {
    trophy: <AiOutlineTrophy />,
    map: <IoMapOutline />,
    person: <BsPerson />,
    checklist: <BsCardChecklist />,
    cookie: <BiCookie />,
    hive: <BiHive />,
    sun: <BiSun />,
    document: <HiOutlineDocumentText />,
    file2Line: <RiFile2Line />,
  };

  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory();
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropdown(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const renderSeeMore = (path: string, message = 'Começar') => (
    <button type="button">
      <strong>{message}</strong>
      <CgArrowLongRight />
    </button>
  );

  const handleClick = () => {
    if (!data.active && data.upgradeTag) handleUpgrade();
    if (!data.active) return;
    if (data.path) history.push(data.path);
    else if (data.dropdownOptions) setShowDropdown(prev => !prev);
  };

  const handleUpgrade = () => {
    setTimeout(() => {
      const link = process.env.REACT_APP_PRIVACY || 'https://app.privacyflow.ai'
      window.location.href = `${link}/checkout/index.html?plan=${planCode}`
    }, 200);
  };

  return (
    <Container onClick={handleClick} active={active}>
      <Content backgroundColor={backgroundColor} active={active} upgradeTag={upgradeTag}>
        <header>
          <span>{/* data.indicator */}</span>
        </header>

        <section>
          <h2>{data.title}</h2>
          <p>{data.description}</p>
        </section>

        <footer>
          {data.path && renderSeeMore(data.path, data.message)}
          {data.dropdownOptions && (
            <FooterButton flipIcon={showDropdown}>
              {data.dropdownOptions.message}
              {data.dropdownOptions.message !== 'Em breve!' && (
                <FiChevronDown />
              )}
            </FooterButton>
          )}
        </footer>

        {backgroundIcon[backgroundImage] || backgroundIcon.checklist}

        {upgradeTag && (
          <UpgradeTag absolute onClick={handleUpgrade}>Faça upgrade</UpgradeTag>
        )}
      </Content>
      {data.dropdownOptions && showDropdown && (
        <DropdownContainer ref={ref}>
          <DropdownMenu options={data.dropdownOptions.options} />
        </DropdownContainer>
      )}
    </Container>
  );
};
