import React, {ReactElement } from 'react';
import { BiRightArrowCircle } from 'react-icons/bi';
import { CgArrowLongLeft, CgArrowLongRight } from 'react-icons/cg';

import { Overlay, Modal, Header, Body, Footer, IconHeader } from './styles';

interface Props {
  title: string;
  icon: ReactElement;
  message: string;
  hasClose: boolean;
  PlanOpciones?: boolean;
  DirectActionBasic?:React.MouseEventHandler<HTMLButtonElement>;
  DirectActionPremium?: React.MouseEventHandler<HTMLButtonElement>;
}

const GenericModal: React.FC<Props> = ({
  title,
  icon,
  message,
  PlanOpciones,
  DirectActionBasic,
  DirectActionPremium,
}) => {
  return (
    <Overlay>
      <Modal>
        {icon&& (
          <IconHeader>
            <i>{icon}</i>
          </IconHeader>
        )}
        {title && (
          <Header>
            <h2>{title}</h2>
          </Header>
        )}
        {message && (
          <Body>
            <p>{message}</p>
          </Body>
        )}
        <Footer>
          {PlanOpciones && (
            <button
              type="button"
              className="footer-button close"
              onClick={DirectActionBasic}
            >
              Ir para o plano Basic
            </button>
          )}
          {PlanOpciones && (
            <button
              type="button"
              className="footer-button close"
              onClick={DirectActionPremium}
            >
              Ir para o plano Premium
            </button>
          )}
        </Footer>
      </Modal>
    </Overlay>
  );
};

export default GenericModal;
