import styled, { css, keyframes } from 'styled-components';

interface ContainerProps {
  light?: boolean;
  color?: string;
}

export const Container = styled.button<ContainerProps>`
  min-height: 2.5rem;
  padding: 0.5rem 1rem;
  border-radius: 5rem;

  min-width: 12.5rem;
  background: transparent;

  font-weight: bold;

  transition: all 0.3s;

  font-size: 1.1rem;

  ${({ light, color }) =>
    light
      ? css`
          border: 2px solid var(--white);
          color: var(--white);

          &:not(:disabled):hover {
            background-color: var(--white);
            color: ${color};
          }
        `
      : css`
          border: 2px solid ${color};
          color: ${color};

          &:not(:disabled):hover {
            background-color: ${color};
            color: var(--white);
          }
        `}
`;

const infiniteRotation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ActivityIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  animation: ${infiniteRotation} 2s linear infinite;
  height: 1.25rem;
  width: 1.25rem;

  svg {
    height: 1.25rem;
    width: 1.25rem;
  }
`;
