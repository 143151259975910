import styled from 'styled-components';

export const Content = styled.div`
  width: 100%;
  max-width: 1800px;
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 0 1rem;
  margin-top: 1rem;
  justify-content: center;

  @media screen and (min-width: 375px) {
    padding: 0 2rem;
    margin-top: 2rem;
  }
  @media screen and (min-width: 768px) {
    padding: 0 3rem;
    margin-top: 3rem;
  }
  @media screen and (min-width: 992px) {
    padding: 0 4rem;
    margin-top: 4rem;
  }
`;
