export const formatDate = (date: Date, type = 'DD/MM/YYYY'): string => {
  let dd = `${date.getDate()}`;
  if (dd.length === 1)
    dd = `0${dd}`;

  let mm = `${date.getMonth() + 1}`;
  if (mm.length === 1)
    mm = `0${mm}`;

  const yyyy = `${date.getFullYear()}`;

  if (type === 'DD') return `${dd}`;
  if (type === 'DD/MM') return `${dd}/${mm}`;
  if (type === 'DD/MM/YYYY') return `${dd}/${mm}/${yyyy}`;
  if (type === 'MM/YYYY') return `${mm}/${yyyy}`;

  return `${dd}/${mm}/${yyyy}`
};
