import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { AiFillPlusCircle, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FiTrash2 } from 'react-icons/fi';

import { CookieBar } from '../../../../../components/CookieBar';
import { GenericOverlay } from '../../../../../components/GenericOverlay';
import { MaturityHeader } from '../../../../../components/MaturityHeader';
import { ServicesHeader } from '../../../../../components/ServicesHeader';
import { useManageCookieBar } from '../../../../../hooks/useManageCookieBar';

import { ActivityIndicator } from '../../../../../components/MaturityButton/styles';
import { Container, Content, AddCookieBar, DisabledMessage } from './styles';
import { ModalMessageImportant } from '../../../../../components/ModalMessageImportant';
import { useAuth } from '../../../../../contexts/auth';


export const ManageCookieBar: React.FC = () => {
  const history = useHistory();

  const {
    cookieBars,
    showTooltip,
    tryFetchPolicy,
    showDelCookiesModal,
    setShowTooltip,
    onDelete,
    handleClickCloseDelCookies,
    handleClickShowPublishPolicy,
  } = useManageCookieBar();

  const { user, controlPayment } = useAuth();

  async function controlPage() {
    const permission = await controlPayment(user);

    if (permission?.cookie_bars < 1) {
      history.push('/atividades/corporativo');
    }
  }

  useEffect(() => {
    controlPage();
  }, []);

  return (
    <Container>
      <MaturityHeader />
      <ServicesHeader pageTitle="Criar ou gerenciar Barra de Cookies" />

      <Content>

        {!tryFetchPolicy && (
          <GenericOverlay>
            <ActivityIndicator className="loading">
              <AiOutlineLoading3Quarters />
            </ActivityIndicator>
          </GenericOverlay>
        )}

        {tryFetchPolicy && cookieBars.map(cookieBar => (
          <CookieBar
            _id={cookieBar._id}
            backgroundColor={cookieBar.cookie_bar_background_color}
            fontColor={cookieBar.cookie_bar_color}
            fontFamily={cookieBar.font_family}
            fontSize={cookieBar.font_size}
            fontWeight={cookieBar.font_weight}
            key={cookieBar.site_url}
            onDelete={onDelete}
          />
        ))}

        {tryFetchPolicy && (
          <AddCookieBar
            onMouseOver={() => setShowTooltip(true)}
            onMouseOut={() => setShowTooltip(false)}
            disabled={cookieBars.length > 0}
            onClick={() => history.push('barra-de-cookies/nova')}
          >
            <AiFillPlusCircle />
            <span>Criar barra de Cookies </span>

            {showTooltip && cookieBars.length > 0 && (
              <DisabledMessage>
                <p>
                  Permitimos uma barra por empresa, para criar uma nova exclua a
                  atual.
                </p>
              </DisabledMessage>
            )}
          </AddCookieBar>
        )}
      </Content>

      {showDelCookiesModal && (
        <ModalMessageImportant
          title="Um recado importante sobre esta ação:"
          content="Ao excluir a Barra de cookies, a Política também será excluída."
          handleSuccess={handleClickShowPublishPolicy}
          handleCancel={handleClickCloseDelCookies}
        >
          <FiTrash2 className="svg-link" />
        </ModalMessageImportant>
      )}

      {/* {showPublishPolicy && (
        <PublishPolicy origin="POLICY" handleFinishCallback={handleClosePublishPolicy} />
      )} */}

    </Container>
  );
};
