import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import { GenericCard } from '../../../../components/GenericCard';
import { GenericPage } from '../../../../components/GenericPage';
import { GenericInput } from '../../../../components/GenericInput';
import { api } from '../../../../services/api';
import { useToast } from '../../../../contexts/toast';
import { mapObjToArray } from '../../../../utils/mapObjToArray';

import { IUser } from '../../../../types/IUser';

const FORM_CONFIG = [
  { 
    key: 'name', 
    label: 'Nome', 
    type: 'text'
  },
  { 
    key: 'email', 
    label: 'Email',
    type: 'text'
  },
  { 
    key: 'roles', 
    label: 'Perfis',
    type: 'textarea'
  },
  {
    key: 'createdAt', 
    label: 'Data de criação',
    placeholder: 'dd/mm/yyyy',
    type: 'date'
  },
  {
    key: 'updatedAt', 
    label:  'Data da última alteração',
    placeholder:  'dd/mm/yyyy',
    type: 'date'
  }
];

export const ManageUser: React.FC = () => {
  const { addToast } = useToast();
  const { id: userId } = useParams<{ id: string }>();

  const [user, setUser] = useState<IUser>();
  const [userArray, setUserArray] = useState([]);
  const [pageTitle, setPageTitle] = useState<string>('Usuário');
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    const loadUser = async () => {
      try {
        setLoading(true);
        const response = await api.get<IUser>(`/users/${userId}`);

        const userData = _.get(response, 'data', false)
        if (userData) {
          setPageTitle(userData.name);

          setUserArray(
            mapObjToArray(userData).reduce((acc, curr) => {
              const found = FORM_CONFIG.find(c => c.key === curr.key);
              if (found) {
                acc.push({
                  ...curr,
                  ...found,
                })
              }

              return acc;
            }, [])
          );

          setUser({
            _id: userData._id,
            name: userData.name,
            email: userData.email,
            roles: [userData.roles.join('; ')],
            createdAt: new Date(userData.createdAt)
              .toISOString()
              .substr(0,10),
            updatedAt: new Date(userData.updatedAt)
              .toISOString()
              .substr(0,10),
          });
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Falha ao carregar',
          description: 'Ocorreu um erro ao carregar a usuário',
        });
      }
    };
      
    if (userId) loadUser();
  }, [addToast, userId]);

  const handleChange = (field: string, event: ChangeEvent<HTMLInputElement>): void => {
    const userClone = _.clone(user);
    const value = _.get(event, 'target.value', undefined);
    userClone[field] = value;
    setUser(userClone);
  };
  
  const handleSubmit = useCallback(
    async (data) => {
      console.log(data)
    },
    [],
  );

  return (
    <GenericPage
      breadcrumb="BackOffice > Usuários > "
      pageTitle={pageTitle}
      loading={loading}
    >
  
      {user && (
        <GenericCard>
          <form onSubmit={handleSubmit} style={{width: 'inherit'}}>
            {user.name && <h3>{user.name}</h3>}

            {userArray.map(item => (
              <GenericInput
                dark
                disabled
                key={item.key}
                id={item.key}
                name={item.key}
                type={item.type}
                label={item.label}
                placeholder={item.placeholder}
                value={user[item.key]}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleChange(item.key, event)}
              />
            ))}
          </form>
        </GenericCard>
      )}
    </GenericPage>
  )
};
