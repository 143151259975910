import styled, { css } from 'styled-components';

interface ContainerProps {
  isShowing?: boolean;
}

export const Container = styled.div<ContainerProps>`
  position: fixed;
  right: 0;
  top: 0;
  ${props =>
    props.isShowing &&
    css`
      padding: 2rem;
    `}
  overflow: hidden;
  z-index: 999999999999999999999999999999999999999;
`;
