import React, { useCallback, useRef, useState, useEffect } from 'react';
import { BiChevronDown } from 'react-icons/bi';

import { Container } from './styles';

export interface Option {
  label: string;
  value: string | number;
}

interface SelectProps {
  options: Option[];
  value: Option;
  setValue(value: Option): void;
}

const Select: React.FC<SelectProps> = ({ options, value, setValue }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showOptions, setShowOptions] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowOptions(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleSelectOption = useCallback(
    (option: Option) => {
      setValue(option);
      setShowOptions(false);
    },
    [setValue],
  );

  return (
    <Container ref={ref}>
      <button
        className="main"
        type="button"
        onClick={() => setShowOptions(true)}
      >
        <span>{value?.label}</span>
        <BiChevronDown />
      </button>

      <div>
        {showOptions &&
          options.map(option => (
            <button
              type="button"
              key={option.value}
              onClick={() => handleSelectOption(option)}
            >
              {option.label}
            </button>
          ))}
      </div>
    </Container>
  );
};

export default Select;
