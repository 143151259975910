import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ProgressBar } from '../../components/ProgressBar';
import { MaturityButton } from '../../components/MaturityButton';
import { MaturityHeader } from '../../components/MaturityHeader';
import { api } from '../../services/maturiry_api';
import { Research } from '../../types/researchTypes';

import {
  Container,
  Content,
  ResultMessageSection,
  ResultGraphSection,
} from './styles';

import adicionais from '../../assets/icons/adicionais.svg';
import dadosPessoais from '../../assets/icons/dados-pessoais.svg';
import direitos from '../../assets/icons/direitos.svg';
import garantia from '../../assets/icons/garantia.svg';
import programa from '../../assets/icons/programa.svg';
import tratamento from '../../assets/icons/tratamento.svg';
import posturaGeral from '../../assets/icons/postura-geral.svg';

interface MaturityResultRouteParams {
  researchId: string;
}

interface ResearchScoreResponse {
  research: Research;
  title: string;
  message: string;
  color: string;
}

export const MaturityResult: React.FC = () => {
  const { researchId } = useParams<MaturityResultRouteParams>();
  const [scoreResponse, setScoreResponse] = useState<ResearchScoreResponse>();

  useEffect(() => {
    const loadResearchScore = async () => {
      const response = await api.get<ResearchScoreResponse>(
        `/researches/${researchId}/score`,
      );

      setScoreResponse(response.data);
    };

    loadResearchScore();
  }, [researchId]);

  const iconBySection = useCallback((sectionName: string) => {
    if (sectionName.toLowerCase().includes('adicionais')) return adicionais;
    if (sectionName.toLowerCase().includes('tratamento')) return tratamento;
    if (sectionName.toLowerCase().includes('dados pessoais'))
      return dadosPessoais;
    if (sectionName.toLowerCase().includes('postura')) return posturaGeral;
    if (sectionName.toLowerCase().includes('direitos')) return direitos;
    if (sectionName.toLowerCase().includes('programa')) return programa;
    return garantia;
  }, []);

  const shortSectionName = useCallback((sectionName: string) => {
    const splitName = sectionName.split(' ');
    if (splitName.length > 2) {
      if (splitName[1].length <= 3) return splitName[0];

      return `${splitName[0]} ${splitName[1]}`;
    }

    return sectionName;
  }, []);

  return (
    <Container>
      <MaturityHeader />

      {scoreResponse && (
        <Content>
          <ResultMessageSection color={scoreResponse.color}>
            <h1>
              {scoreResponse.research.score}
              <h2>%</h2>
            </h1>
            <h3>{scoreResponse.title}</h3>
          </ResultMessageSection>

          <ResultGraphSection>
            {scoreResponse.research.sections.map(section => (
              <div>
                <strong>{`${section.sectionScore}%`}</strong>
                <ProgressBar
                  fillColor={String(section.sectionColor)}
                  pathColor="#fff"
                  value={section.sectionScore}
                  vertical
                />
                <img src={iconBySection(section.name)} alt={section.name} />
                <span>{shortSectionName(section.name)}</span>
              </div>
            ))}
          </ResultGraphSection>

          <ResultMessageSection color={scoreResponse.color}>
            <h4>
              {scoreResponse.research.score === 100
                ? 'Não pare por aqui, vamos revisar suas informações com nosso time?'
                : 'Quer resolver seus problemas de adequação?'}
            </h4>
            <p>{scoreResponse.message}</p>

            <a
              href={process.env.REACT_APP_CALENDLY_LINK}
              rel="noreferrer"
              target="_blank"
            >
              <MaturityButton>Agendar com especialista </MaturityButton>
            </a>
          </ResultMessageSection>
        </Content>
      )}
    </Container>
  );
};
