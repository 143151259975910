import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { Container, Content, ContinueButton, ScheduleButton } from './styles';
import * as gtag from '../../utils/gtag';

export const MaturityLanding: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    gtag.pageview(`${history.location}`);
  }, [history]);

  return (
    <Container>
      <Content>
        <section className="first">
          <h1>
            Avaliação de maturidade
            <br />
            em LGPD
          </h1>
          <p>
            Inicie sua avaliação agora mesmo e em instantes você receberá o
            score da sua empresa com a LGPD. Se precisar de ajuda, converse com
            nosso especialista clicando abaixo.
          </p>
          <footer>
            <ContinueButton onClick={() => history.push('/maturidade/iniciar')}>
              Iniciar avaliação
            </ContinueButton>
            <ScheduleButton onClick={() => history.push('/maturidade/agendar')}>
              Agendar com especialista
            </ScheduleButton>
          </footer>
        </section>
      </Content>
    </Container>
  );
};
