import React from 'react';
import { FiCheck } from 'react-icons/fi';

import { Container, Title, Message } from './styles';

export const CheckoutSuccess: React.FC = () => {
  return (
    <Container>
      <FiCheck size="74" color="#298AFF" />
      <Title>Cadastro efetuado com sucesso!</Title>
      <Message>Seu pagamento está em processamento, avisaremos por e-mail quando for aprovado.</Message>
    </Container>
  );
};
