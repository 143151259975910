import React, { useState, useEffect } from "react";
import _ from 'lodash';

import { AiOutlineLoading3Quarters } from "react-icons/ai";

import { Container } from "./styles";
import { ActivityIndicator } from "../MaturityButton/styles";

import { GenerateCodHTML } from "../GenerateCodHTML";
import { useCreateCookieBar } from "../../hooks/useCreateCookieBar";
import { useCreateDSR } from "../../hooks/useCreateDSR";
import { useManagePolicy } from "../../hooks/useManagePolicy";
import { api } from "../../services/api";
import { useAuth } from "../../contexts/auth";
import { GenericOverlay } from "../GenericOverlay";

import { IPolicy } from "../../types/IPolicy";
import { ICookieBar } from "../../types/ICookieBar";
import { IDSR } from "../../types/IDSR";

interface PublishPolicyProps {
  origin: 'COOKIE_BAR' | 'POLICY' | 'DSR';
  isCompleted: boolean;
  handleFinishCallback: (hasCookieBar: boolean, hasPolicy: boolean) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function PublishPolicy({ origin, isCompleted, handleFinishCallback }: PublishPolicyProps) {
  const { replaceHTML, html, hasPolicyBond } = useCreateCookieBar();
  const { replaceHTMLDSR, htmlDSR } = useCreateDSR();
  const { handleClosePublishCookies } = useManagePolicy();
  const { user, updateUser } = useAuth();

  const [type, setType] = useState<string | 'HTML' | 'URL'>('');
  const [title, setTitle] = useState('');

  const [loading, setLoading] = useState<boolean>(false);
  const [mainCopied, setMainCopied] = useState<boolean>(false);
  const [showSecondSection, setShowSecondSection] = useState<boolean>(false);
  const [secondCopied, setSecondCopied] = useState<boolean>(false);

  const [hasCookieId, setHasCookieId] = useState<boolean>(false);
  const [cookieBar, setCookieBar] = useState<ICookieBar>();
  const [hasPolicyId, setHasPolicyId] = useState<boolean>(false);
  const [policy, setPolicy] = useState<IPolicy>();
  const [companyName, setCompanyName] = useState('');
  const [policySlug, setPolicySlug] = useState('');

  const [DSR, setDSR] = useState<IDSR>();

  useEffect(() => {
    let _hasPolicy = false;
    let _hasCookieBar = false;

    setLoading(true);
    api.get(`/companies/${user.company_id?._id}`).then(({ data }) => {
      setCompanyName(data.company.fantasy_name);

      if (data.cookie_bars.length > 0) {
        _hasCookieBar = true;
        setHasCookieId(data.cookie_bars[0]._id);
        setCookieBar(data.cookie_bars[0]);
      };

      if (data.policies.length > 0) {
        _hasPolicy = true;
        setHasPolicyId(data.policies[0]._id)
        setPolicy(data.policies[0])
      };

      if (data.dsrs.length > 0) {
        setDSR(data.dsrs[0]);
      };

      // Publishing Policy with bond and has CookieBar
      if (origin === 'POLICY') {
        if (!!data.policies[0].cookie_bar_bond && _hasCookieBar) {
          if (isCompleted) {
            setType('HTML');
            setTitle('Coloque esse código HTML no seu site para ter a sua Barra de cookies e Política atualizadas');
          } else {
            setType('HTML');
            setTitle('Sua Barra de cookies com a Política foi gerada com sucesso! Para utilizar coloque esse código HTML em seu site');
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (isCompleted) {
            setType('URL');
            setTitle('Coloque essa URL no seu site para ter a Política de Privacidade e Cookies atualizada');
          } else {
            setType('URL');
            setTitle('Coloque essa URL no seu site para ter a Política de Privacidade e Cookies');
          }
        }
      } else if (origin === 'COOKIE_BAR') {
        if (!!data.cookie_bars[0].policy_bond && _hasPolicy) {
          if (isCompleted) {
            setType('HTML');
            setTitle('Coloque esse código HTML no seu site para ter a sua Barra de cookies e Política atualizadas');
          } else {
            setType('HTML');
            setTitle('Sua Barra de cookies com a Política foi gerada com sucesso! Para utilizar coloque esse código HTML em seu site');
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (isCompleted) {
            setType('HTML');
            setTitle('Coloque esse HTML no seu site para ter a Barra de cookies atualizada');
          } else {
            setType('HTML');
            setTitle('Coloque esse HTML no seu site para ter a Barra de cookies');
          }
        }
      }else if (origin === 'DSR'){
        if (isCompleted) {
          setType('HTML');
          setTitle('Alterações feitas com sucesso! Seu site já está atualizado, caso deseje copiar seu script novamente, clique aqui. Se quiser seguir para home, clique no botão abaixo.');
        } else {
          setType('HTML');
          setTitle('Baixe esse script e cole no seu site.');
        }
      }

      setLoading(false);
    }).catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err)
      setLoading(false);
    })
  }, [user.company_id?._id])

  const handlePublished = async (_type: string, secondCopy = false) => {
    let policySlugLink = '';
    let slug;
    if (policy) {
      slug = policy.policy_slug ? policy.policy_slug : policySlug;
      setPolicySlug(slug);
    }

    // Generate the policy slug
    if (!slug && policy) {
      try {
        const hashSlug = String(new Date().getTime())
          .concat('-')
          .concat(companyName
            .replace(/[` ~!@#$%^&*()_|+=?;:'",.<>{}[\]\\/]/g, '-')
            .toLowerCase()
          );

        const reqBody = {
          ...policy,
          policy_slug: hashSlug,
          publish: type,
        };
        setLoading(true);
        const response = await api.put(`policies/${policy._id}`, reqBody);
        setLoading(false);
        if (response) {
          slug = hashSlug;
          setPolicySlug(hashSlug);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    // Generate the policy slug with environment link
    if (slug && policy) {
      policySlugLink = String(`${process.env.REACT_APP_PRIVACY}/` || 'https://app.privacyflow.ai/').concat(String('slug/').concat(slug))

      updateUser({
        ...user,
        company_id: {
          ...user.company_id,
          policy_slug: policySlugLink
        },
      });
    }
    if(origin !== 'DSR'){
      if (_type === 'HTML') {
        let recoverHTML;
        if (hasCookieId) {
          recoverHTML = replaceHTML(
            html,
            cookieBar._id,
            cookieBar.cookie_list,
            cookieBar.font_size,
            cookieBar.font_weight,
            cookieBar.font_family,
            cookieBar.cookie_bar_color,
            cookieBar.cookie_bar_background_color,
            cookieBar.enabledCookieBarCircle,
            !cookieBar.cookie_list.filter(item => !item.required).length,
            !!cookieBar.policy_bond,
            cookieBar.policy_bond ? policySlugLink : '',
          );
  
          try {
            await api.put(`cookie_bars/${cookieBar._id}`, {
              slug_cookie_bar: recoverHTML
            });
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
        }
  
        try {
          const iframe = `<script src="${process.env.REACT_APP_PRIVACY_API}/cookie_bars/token/${cookieBar._id}" async></script>`;
          const c = document.createElement("a");
          c.download = "codigo_html_gerado.txt";
  
          const t = new Blob([iframe], {
            type: "text/plain"
          });
          c.href = window.URL.createObjectURL(t);
          c.click();
  
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      } else if (_type === 'URL') {
        try {
          await navigator.clipboard.writeText(policySlugLink);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    }

    if(origin === 'DSR'){
      if (_type === 'HTML') {
        let recoverHTML;
        if (hasCookieId) {
          recoverHTML = replaceHTMLDSR(
            htmlDSR,
            DSR._id,
            DSR.font_family,
            DSR.font_color,
            DSR.emphasis_color,
            DSR.email
          );
  
          try {
            await api.put(`dsr/${DSR._id}`, {
              ...DSR,
              dsr_html: recoverHTML,

            });
          } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error)
          }
        }
  
        try {
          const script_description = "Inserir o script abaixo na raiz do seu projeto ou na página onde será implantado o DSR, dentro da tag <head> ou na tag <body>"
          const script = `<script src="${process.env.REACT_APP_PRIVACY_API || 'http://localhost:3333/api/v1'}/dsr/token/${DSR._id}" async></script>`;
          const quebra = "\n"
          const div_description = "Inserir a div no local em que será adicionado o DSR"
          const positin = `<div id="implementationDSR" />`
          const c = document.createElement("a");
          c.download = "codigo_html_gerado_dsr.txt";
  
          const t = new Blob([script_description,quebra,script,quebra,quebra,div_description, quebra, positin], {
            type: "text/plain"
          });
          c.href = window.URL.createObjectURL(t);
          c.click();
  
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      } else if (_type === 'URL') {
        try {
          await navigator.clipboard.writeText(policySlugLink);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    }
    
    if (secondCopy) {
      setSecondCopied(true);
    } else {
      setMainCopied(true);
    }
  }

  const handleCloneModal = () => {
    setType('');
    handleClosePublishCookies();
    setMainCopied(false);

    const hasCookieBar = !!_.get(cookieBar, '_id', false);
    const hasPolicy = !!_.get(policy, '_id', false);
    handleFinishCallback(hasCookieBar, hasPolicy);
  }

  const displaySecondButton = () => setShowSecondSection(true);
  return (
    <>
      <GenericOverlay>
        {!loading && (
          <Container>
            {type && (
              <GenerateCodHTML
                mainCopied={mainCopied}
                secondCopied={secondCopied}
                showSecondSection={showSecondSection}
                hasPolicy={hasPolicyId}
                type={type}
                title={title}
                loadCookieBarForCopy={handlePublished}
                handleClosePublishCookies={handleCloneModal}
                displaySecondButton={displaySecondButton}
                origin={origin}
                hasPolicyBond={hasPolicyBond}
                isCompleted={isCompleted}
              />
            )}
          </Container>
        )}

        {loading && (
          <ActivityIndicator style={{
            width: "50px",
            height: "50px",
          }}
          >
            <AiOutlineLoading3Quarters style={{
              width: "50px",
              height: "50px",
              color: "var(--teal)",
            }}
            />
          </ActivityIndicator>
        )}
      </GenericOverlay>
    </>
  );
}