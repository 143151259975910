import React, { useRef, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import _ from 'lodash';
import { RiEyeLine, RiEyeCloseLine } from 'react-icons/ri';
import { cpf, cnpj } from 'cpf-cnpj-validator';

import getValidationErrors from '../../utils/getValidationErrors';

import Input from '../../components/NewInput';
import InputOld from '../../components/old_Input';
import { MaturityButton as Button } from '../../components/MaturityButton';

import { Container, Content } from './styles';
import { MaturityHeader } from '../../components/MaturityHeader';
import { parseQueryStr } from '../../utils/parseQueryString';
import { api } from '../../services/api';
import { useToast } from '../../contexts/toast';
import { useAuth } from '../../contexts/auth';

interface SignInCredentials {
  email: string;
  name: string;
  company: string;
  phone?: string;
  password: string;
  confirm_password: string;
}

export const SignUp: React.FC = () => {
  const { addToast } = useToast();
  const { signIn } = useAuth();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [hide, setHide] = useState<boolean>(true);
  const [isAccept, setIsAccept] = useState<boolean>(false);
  const [document, setDocument] = useState<string>('');
  const [errorValidate, setErrorValidate] = useState('');

  const handleSubmit = useCallback(
    async (formData: SignInCredentials) => {
      try {
        const search = _.get(history, 'location.search', false);
        const clonedData = _.clone(formData);
        clonedData.email = clonedData.email.trim();

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Digite um e-mail, válido')
            .required('E-mail obrigatório'),
          name: Yup.string().required('Nome é obrigatório'),
          company: Yup.string().required('Empresa é obrigatória'),
          phone: Yup.string().matches(/^\([1-9]{2}\) [9]{0,1}[1-9]{1}[0-9]{3}-[0-9]{4}$/i,'Telefone incorreto').required('Telefone é obrigatório'),
          password: Yup.string().required('Senha obrigatória').min(6, 'No mínimo 6 caracteres'),
          confirm_password: Yup.string().oneOf([Yup.ref('password'), null], 'As duas senhas devem ser iguais')
        });
        await schema.validate(clonedData, { abortEarly: false });

        // Redirect logic
        let params;
        let redirectFound;
        let plan = 'PLANO_FREE';
        if (search) {
          const searchParams = parseQueryStr(search);
          console.log('searchParams', searchParams)
          redirectFound = searchParams.find(p => p.key === 'redirect');
          console.log('redirectFound', redirectFound)
          params = searchParams.reduce((acc, curr) => {
            if (curr.key !== 'redirect') {
              console.log('curr.key', curr.key)
              console.log('curr.value', curr.value)
              plan = curr.value;
              if (acc) {
                // eslint-disable-next-line no-param-reassign
                acc += `&${curr.key}=${curr.value}`;
              } else {
                // eslint-disable-next-line no-param-reassign
                acc += `?${curr.key}=${curr.value}`;
              }
            }
            return acc;
          }, '');
        }

        setLoading(true);
        const { data } = await api.post('/users', {
          name: clonedData.name,
          company: clonedData.company,
          document_company: document,
          phone: clonedData.phone,
          email: clonedData.email,
          password: clonedData.password,
          roles: ["admin"],
          code: plan,
        });
        setLoading(false);

        if (data) {
          const noPass = _.get(data, 'no_pass', false);
          if (noPass) {
            return;
          }

          setLoading(true);
          const userSuccess = await signIn(clonedData);
          setLoading(false);

          if (userSuccess) {
            if (redirectFound) {
              if (redirectFound.value.includes('checkout')) {
                setTimeout(() => {
                  window.location.href = redirectFound.value + params;
                }, 200);
                return;
              }
              history.push(redirectFound.value + params);
              return;
            }

            history.push('/');
          }
        }
      } catch (err) {
        setLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
        }

        const status = _.get(err, 'response.status', false);

        if (status >= 400 && status < 500) {
          addToast({
            type: 'info',
            title: 'Aviso',
            description: 'Você já possui cadastro conosco.',
            linkName: 'Acesse sua conta',
            linkURL: '/',
          });
        }

        if (status >= 500) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao comunicar com Servidor',
          });
        }
      }
    },
    [history, document, signIn],
  );

  function cpfMask(value: string): string {
    if ((value.length === 14 && !cpf.isValid(value)) && value.length <= 14) {
      setErrorValidate('O CPF não é válido.');
    } else {
      setErrorValidate('')
    }
    // return cpf.format(value);
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2');
  };

  function cnpjMask(value: string): string {
    if ((value.length === 18 && !cnpj.isValid(value)) && value.length <= 18) {
      setErrorValidate('O CNPJ não é válido.');
    } else {
      setErrorValidate('')
    }
    // return cnpj.format(value);
    return value
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1/$2')
      .replace(/(\d{4})(\d)/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  return (
    <Container>
      <MaturityHeader />

      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <h2>Faça seu cadastro</h2>

          <section>
            <Input name="name" type="text" placeholder="Nome *" />
            <Input name="company" type="text" placeholder="Nome da empresa *" />
            <Input name="email" type="email" placeholder="E-mail *" />
            <InputOld
              name="document"
              type="text"
              placeholder="CPF / CNPJ *"
              onChange={e =>
                setDocument(
                  document && document.length <= 14
                    ? cpfMask(e.target.value)
                    : cnpjMask(e.target.value),
                )
              }
              value={document}
              maxLength={18}
              dark={false}
            />
            <span className="error">
              {errorValidate}
            </span>
            <Input 
              name="phone" 
              type="text" 
              placeholder="Celular *" 
              mask="(99) 99999-9999" 
            />
            <Input
              name="password"
              type={hide ? "password" : "text"}
              placeholder="Senha *"
              icon={hide ? RiEyeCloseLine : RiEyeLine}
              handle={() => setHide(!hide)}
            />
            <Input
              name="confirm_password"
              type={hide ? "password" : "text"}
              placeholder="Confirmar sua senha *"
              icon={hide ? RiEyeCloseLine : RiEyeLine}
              handle={() => setHide(!hide)}
            />
          </section>

          <div className="terms">
            <input
              name="term_use"
              type="checkbox"
              onChange={() => setIsAccept(!isAccept)}
            />
            <span>Aceito a</span>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://drive.google.com/file/d/1zIF23ACUuswZi1PZ02ULsNvkMpUGbJhA/view"
            >
              Política de Uso de Dados
            </a>
          </div>

          <Button
            loading={loading}
            light
            disabled={!isAccept || (document.length < 14 || errorValidate && (document.length === 14 || document.length === 18))}
          >
            Concluir
          </Button>
          <div className="footer">
            <span>
              Já se cadastrou no Privacyflow?&nbsp;
            </span>
            <Link to="/">Entre</Link>
          </div>
        </Form>
      </Content>
    </Container>
  );
};
