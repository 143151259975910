import styled from 'styled-components';

interface UpgradeProps {
  big?: boolean;
  absolute? : boolean;
  blockedTag? : boolean;
}

export const UpgradeTag = styled.span<UpgradeProps>`
  position: ${props => (props.absolute ? 'absolute' : '')};
  right: 1rem;
  top: ${props => (props.big ? '1rem' : '0.5rem')};
  bottom: 0;
  padding: 0.125rem 0.75rem 0.188rem;
  background: ${props => (props.blockedTag ? 'var(--red)' : 'var(--light-green)')};
  border-radius: 1.938rem;
  width: 7.313rem;
  height: 1.438rem;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.125rem;
  text-align: center;
  color: var(--white);
  cursor: ${props => (props.blockedTag ? 'not-allowed' : 'pointer')};
`;
