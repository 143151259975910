import styled, { css, keyframes } from 'styled-components';
import { shade, lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const Content = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2.5fr;
  grid-template-areas: 'aside main main main';

  min-height: calc(100vh - 6rem);
  width: 100%;
  padding: 2rem;

  > aside {
    grid-area: aside;
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 25rem;

    background: #fff;
    padding: 2.5rem;
    border-radius: 1.25rem;
    box-shadow: 0px 3px 30px #00000029;

    h3 {
      font-size: 1.5rem;
      font-weight: 800;
    }

    header {
      position: relative;
      height: 11rem;
      width: 11rem;

      > div {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        h1 {
          font-size: 2.75rem;
          font-weight: 900;
        }
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 1.875rem;
      width: 100%;
    }

    ul {
      list-style-type: none;
      width: 100%;
      margin-top: 2rem;

      button:first-child {
        margin-top: 1.25rem;
      }

      button {
        display: flex;
        justify-content: center;
        padding: 0.5rem;
        background: #eaecf4;
        width: 100%;
        border-radius: 0.25rem;

        transition: color 0.2s ease-in-out;

        &:hover {
          cursor: pointer;
          background: ${shade(0.1, '#eaecf4')};
        }

        &:active {
          cursor: pointer;
          background: ${shade(0.3, '#eaecf4')};
          transform: translateY(0.5px);
        }
      }

      button + button {
        margin-top: 0.625rem;
      }
    }
  }

  main {
    grid-area: main;

    display: flex;
    flex-direction: column;
    margin-left: 5rem;
    height: 80%;

    > header {
      margin-top: 10%;

      h1 {
        font-size: 3rem;

        svg {
          margin-left: 1rem;
        }
      }

      button {
        margin-top: 20%;
        margin-bottom: 5%;
      }

      p {
        font-size: 1.5rem;
        margin-top: 3rem;

        & > svg {
          height: 5rem;
          width: 5.5rem;
          color: var(--dark-gray);
        }
      }
    }

    > section {
      display: flex;
      flex-direction: column;
      width: 100%;
      flex: 1;
      align-items: center;
      margin-top: 2.5rem;
    }
  }

  @media (max-width: 769px) {
    grid-template-columns: auto;
    grid-template-rows: 1fr 5rem;
    grid-template-areas:
      'main main main'
      'aside aside aside';

    padding: 1rem 0.5rem;

    > aside {
      height: 4rem;
      align-self: flex-end;
      padding: 2.5rem 1rem;
      flex-direction: row;
      justify-content: space-between;
      min-width: 100%;

      header {
        height: 4rem;
        width: 4rem;

        > div h1 {
          font-size: 1rem;
        }
      }

      > div {
        display: none;
      }

      ul {
        display: flex;
        justify-content: space-between;

        width: calc(100% - 4.4rem);
        margin: 0;
        overflow-x: scroll;
      }
    }

    main {
      margin: 0;
      height: 43%;
      padding: 0 0.5rem;

      > header {
        h1 {
          width: 90%;
        }
      }
    }
  }

  @media (max-width: 769px) {
    min-height: calc(100vh - 6rem);

    main > header button {
      width: 100%;
    }
  }
`;

export const SectionItem = styled.li<ProgressIndicatorProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;

  transition: all 0.3s;

  & + li {
    margin-top: 1rem;
  }

  &:hover {
    cursor: pointer;
    color: ${lighten(0.2, '#333')};

    aside {
      background: ${lighten(0.2, '#333')};
    }
  }

  ${props =>
    props.progress === 100 &&
    css`
      color: var(--teal);

      &:hover {
        cursor: pointer;
        color: ${lighten(0.2, '#298AFF')};

        aside {
          background: ${lighten(0.2, '#298AFF')};
        }
      }
    `}

  p {
    font-size: 0.75rem;
  }

  aside {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    height: 2rem;
    width: 2rem;
    border-radius: 1rem;
    background: var(--dark-gray);
    color: var(--white);

    z-index: 10;

    transition: all 0.3s;
    ${props =>
      props.progress === 100 &&
      css`
        background: var(--teal);
      `}
  }

  img {
    position: absolute;
    top: 50%;
    left: 1.5rem;
    transform: translateX(-50%);
    height: 2rem;
    width: 0.5rem;
  }

  div {
    flex: 1;
    margin: 0 1rem;
  }

  @media (max-width: 769px) {
    flex-direction: row;
    margin: 0;

    img {
      transform: rotate(-90deg);
      top: 0.5rem;
      left: 140%;
    }

    span,
    div {
      display: none;
    }

    & + li {
      margin: 0 0 0 0.25rem;
    }
  }

  @media (max-width: 426px) {
    img {
      left: 80%;
    }
  }
`;

interface ProgressIndicatorProps {
  progress: number;
}

export const ProgressIndicator = styled.h1<ProgressIndicatorProps>`
  ${props =>
    props.progress === 100 &&
    css`
      color: var(--teal);
    `}
`;
