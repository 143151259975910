import styled from 'styled-components';

export const ModalCard = styled.div`
  position: relative;
  width: 90%;
  max-width: 750px;
  min-height: 180px;
  background-color: var(--white);
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 20px;
  text-align: center;
  padding: 40px 20px;

  article {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 40px 20px;
    z-index: 2;
    display: 'flex';
    flex-direction: 'column';
    justify-content: 'center';
    align-items: 'center'
  }

  .svg-link {
    position: absolute;
    left: -12px;
    top: 0px;
    width: 200px;
    height: 250px;
    color: #494949;
    opacity: 0.2;
    z-index: 1;
  }

  h2 {
    font: normal normal bold 24px/28px 'Mulish';
    letter-spacing: 0px;
    color: var(--teal);
    margin-bottom: 20px;
  }

  p {
    font: normal normal normal 'Mulish';
    letter-spacing: 0px;
    color: #494949;
    margin-bottom: 20px;
    font-size: 28px;
    font-weight: 400;
    line-height: 32px;
    text-align: 'center';
  }

  button {
    font: normal normal bold 20px/24px 'Mulish';
    letter-spacing: 0px;
    color: var(--teal);
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 5px 20px;
    border-radius: 53px;

    svg {
      color: inherit;
      width: 43px;
      height: 43px;
    }

    div {
      margin-left: 15px;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: auto;
    width: 100%;
    height: 180px;

    button {
      border-radius: 53px;
      padding: 10px 20px;
      min-width: 175px;
    }
  }

  @media screen and (min-width: 425px) {
    min-height: 340px;
  }
  @media screen and (min-width: 768px) {
    width: 600px;
    padding: 50px 70px;
    article {
      padding: 50px 70px;
    }
    .svg-link {
      width: 250px;
    }
    h2 {
      font: normal normal bold 26px/30px 'Mulish';
    }
    p {
      font: normal normal normal 22px/26px 'Mulish';
    }
    button {
      font: normal normal bold 22px/26px 'Mulish';
    }
    footer {
      flex-direction: row;
      height: auto;
    }
  }
  @media screen and (min-width: 992px) {
    width: 720px;
    padding: 48px 72px 63px 72px;
    article {
      padding: 48px 72px 63px 72px;
    }
    .svg-link {
      width: 300px;
      height: 300px;
    }
    h2 {
      font: normal normal bold 28px/32px 'Mulish';
    }
    p {
      font: normal normal normal 24px/28px 'Mulish';
    }
    button {
      font: normal normal bold 24px/28px 'Mulish';
    }
  }
`;